import { Alert, Box, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { CustomTabPanel } from "./CustomTabPanel";

type Props = {
  tabs: any;
  alertText?: string;
};
export const CustomTab = ({ tabs, alertText }: Props) => {
  const [value, setValue] = useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        {alertText !== undefined && <Alert severity="info">{alertText}</Alert>}

        <Tabs value={value} onChange={handleChange}>
          {tabs.map((tab: any, idx: number) => (
            <Tab label={`${tab.labelKey}`} key={idx} />
          ))}
        </Tabs>
      </Box>
      {tabs.map((tab: any, idx: number) => (
        <CustomTabPanel index={idx} value={value} key={idx}>
          {tab.component}
        </CustomTabPanel>
      ))}
    </Box>
  );
};
