import { Box, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import AddIcon from "@mui/icons-material/Add";
import PublishIcon from "@mui/icons-material/Publish";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import SaveIcon from "@mui/icons-material/Save";
import Sticky from "react-stickynode";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import HistoryIcon from "@mui/icons-material/History";
import { ToolbarButton } from "./ToolbarButton/ToolbarButton";
import { useTranslation } from "../../helpers/useTranslation";
import { Restricted } from "../Restricted/Restricted";

type Props = {
  updateDocument: any;
  initiateDocumentPublish: any;
  unPublishDocument: any;
  verifyPublishDocument: any;
  newVersionDocument: any;
  savePDFDocument: any;
  isDirty: boolean;
  CanCreateNewVersion: boolean;
  CanDelete: boolean;
  CanUnPublish: boolean;
  CanEdit: boolean;
  CanPublish: boolean;
  isPublished?: boolean;
  isTemplate?: boolean;
  versionHistory: any;
  createXML: any;
  setShowCopy: any;
  setShowDelete: any;
  documentStatusId?: number;
  userGroupId?: number;
};
export const DocumentToolbar = ({
  updateDocument,
  initiateDocumentPublish,
  unPublishDocument,
  newVersionDocument,
  savePDFDocument,
  isDirty,
  CanCreateNewVersion,
  CanDelete,
  CanUnPublish,
  CanEdit,
  CanPublish,
  isPublished,
  isTemplate,
  versionHistory,
  createXML,
  setShowCopy,
  setShowDelete,
  documentStatusId,
  userGroupId,
}: Props) => {
  const [status, setStatus] = useState<number>(Sticky.STATUS_ORIGINAL);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { get } = useTranslation();

  return (
    <Sticky innerZ={5} onStateChange={(status) => setStatus(status.status)}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Box
          sx={{
            background: "#f4f4f3",
            padding: 2,
            gap: 2,
            display: "inline-flex",
          }}
        >
          <ToolbarButton
            icon={<PictureAsPdfIcon />}
            text={get("toolBarCreatePDF")}
            status={status}
            onClick={savePDFDocument}
          />
          <Restricted userGroupId={userGroupId} permission="CanCreateNewVersion">
            <ToolbarButton
              icon={<AddIcon />}
              text={get("toolBarNewVersion")}
              status={status}
              disabled={!(!isTemplate && CanCreateNewVersion)}
              onClick={newVersionDocument}
            />
          </Restricted>
          {!isPublished ? (
            <Restricted userGroupId={userGroupId} permission="CanPublish">
              <ToolbarButton
                icon={<PublishIcon />}
                text={get("toolBarPublish")}
                status={status}
                disabled={isTemplate || !CanPublish || isDirty || documentStatusId === 99}
                onClick={initiateDocumentPublish}
              />
            </Restricted>
          ) : (
            <Restricted userGroupId={userGroupId} permission="CanUnPublish">
              <ToolbarButton
                icon={<UnpublishedIcon />}
                text={get("toolBarUnPublish")}
                status={status}
                disabled={isTemplate || !CanUnPublish}
                onClick={unPublishDocument}
              />
            </Restricted>
          )}
          <ToolbarButton
            icon={<SaveIcon />}
            text={get("toolBarSave")}
            status={status}
            onClick={updateDocument}
            disabled={!isDirty || documentStatusId === 99}
          />
          <IconButton
            color="default"
            aria-controls={open ? "menuList" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>
          <Menu id="menuList" anchorEl={anchorEl} open={open} onClose={handleClose}>
            <MenuItem
              onClick={() => {
                handleClose();
                setShowCopy(true);
              }}
            >
              <ListItemIcon>
                <ContentCopyIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>{get("menuCopy")}</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                createXML();
              }}
            >
              <ListItemIcon>
                <ExitToAppRoundedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>{get("menuCreateXML")}</ListItemText>
            </MenuItem>
            <Restricted userGroupId={userGroupId} permission="CanDelete">
              <MenuItem
                onClick={() => {
                  handleClose();
                  setShowDelete(true);
                }}
                disabled={!CanDelete}
              >
                <ListItemIcon>
                  <DeleteIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>{get("menuDelete")}</ListItemText>
              </MenuItem>
            </Restricted>
            <MenuItem
              onClick={() => {
                handleClose();
                versionHistory();
              }}
            >
              <ListItemIcon>
                <HistoryIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>{get("menuShowVersions")}</ListItemText>
            </MenuItem>
          </Menu>
        </Box>
      </Box>
    </Sticky>
  );
};
