import { Grid } from "@mui/material";
import { useTranslation } from "../../helpers/useTranslation";
import { useFormContext } from "react-hook-form";
import { CustomTextField } from "../../components/CustomTextField/CustomTextField";
import { CustomErrorTypography } from "../../components/CustomTypography/CustomErrorTypography";

export const RegistrationForm = () => {
  const { get } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useFormContext();
  return (
    <form onSubmit={handleSubmit as any}>
      <Grid item xs={12} sx={{ mt: 3 }}>
        <CustomTextField
          name="Name"
          isDisabled={false}
          controlValue={control}
          defaultValue={""}
          isMultiLine={false}
          rowSpan={0}
          label={get("userName")}
          errors={errors}
        />
        <CustomErrorTypography errorMessage={errors.Name && errors.Name.message} />
      </Grid>
    </form>
  );
};
