import { Grid, Typography } from "@mui/material";
import { useTranslation } from "../../../../helpers/useTranslation";
import { CustomTextField } from "../../../../components/CustomTextField/CustomTextField";
import { CustomCheckBox } from "../../../../components/CustomCheckBox/CustomCheckBox";
import { useFormContext } from "react-hook-form";

type Props = {
  data?: UserGroup;
};

export const CompanyPolicies = ({ data }: Props) => {
  const { get } = useTranslation();
  const { control, handleSubmit } = useFormContext();
  return (
    <form onSubmit={handleSubmit as any}>
      <Grid container rowSpacing={1} px={1} columnSpacing={{ md: 3 }}>
        <Grid item md={12} sx={{ mt: 3 }}>
          <CustomCheckBox
            name="HasSocialPolicy"
            label={get("fieldCompanyPolicy")}
            dataValue={data?.HasSocialPolicy}
            controlValue={control}
            disable={false}
          />
        </Grid>
        <Grid item md={12} sx={{ mt: 3 }}>
          <CustomCheckBox
            name="SocialPolicyIsAudited"
            label={get("fieldCompanyReviewed")}
            dataValue={data?.SocialPolicyIsAudited}
            controlValue={control}
            disable={false}
          />
        </Grid>
        <Grid item xs={12} md={12} sx={{ mt: 3 }}>
          <Typography>{get("fieldCompanyGuidelines1")}</Typography>
        </Grid>
        <Grid item md={12}>
          <CustomCheckBox
            name="FN"
            label={get("fieldCompanyGuidelines2")}
            dataValue={data?.FN}
            controlValue={control}
            disable={false}
          />
        </Grid>
        <Grid item md={12} sx={{ mt: 3 }}>
          <CustomCheckBox
            name="ILO"
            label={get("fieldCompanyILO")}
            dataValue={data?.ILO}
            controlValue={control}
            disable={false}
          />
        </Grid>
        <Grid item md={12} sx={{ mt: 3 }}>
          <CustomCheckBox
            name="OECD"
            label={get("fieldCompanyOECD")}
            dataValue={data?.OECD}
            controlValue={control}
            disable={false}
          />
        </Grid>
        <Grid item md={12} sx={{ mt: 3 }}>
          <CustomCheckBox
            name="FNGlobalCompact"
            label={get("fieldCompanyUN")}
            dataValue={data?.FNGlobalCompact}
            controlValue={control}
            disable={false}
          />
        </Grid>
        <Grid item md={12} sx={{ mt: 3 }}>
          <CustomCheckBox
            name="ISO26000"
            label={get("fieldCompanyISO26000")}
            dataValue={data?.ISO26000}
            controlValue={control}
            disable={false}
          />
        </Grid>
        <Grid item md={12} sx={{ mt: 3, mb: 3 }}>
          <CustomTextField
            name={"OtherSocialSystems"}
            isDisabled={false}
            controlValue={control}
            defaultValue={data?.OtherSocialSystems}
            label={get("fieldCompanyOtherGuidelines")}
          />
        </Grid>
      </Grid>
    </form>
  );
};
