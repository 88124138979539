import { useState, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { Grid, Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useTranslation } from "../../../../../helpers/useTranslation";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import NumberFormat from "react-number-format";
import { DocumentIdSystem } from "../../../../../constants/documentIdConstants";
import { useDocumentIdSystem } from "../../../../../helpers/useDocumentIdSystem";
import { CustomTextField } from "../../../../../components/CustomTextField/CustomTextField";
import { CustomCheckBox } from "../../../../../components/CustomCheckBox/CustomCheckBox";
import { useGetUser } from "../../../../../services/userService";
import { useGetUserGroup } from "../../../../../services/userGroupService";
import { useGetDateFnsLocale } from "../../../../../helpers/useGetDateFnsLocale";

type Props = {
  documentData?: EBVDDocument;
};

export const CopyDocumentForm = ({ documentData }: Props) => {
  const { get } = useTranslation();
  const { handleSubmit, control, setValue, register } = useFormContext();
  const [documentID, setDocumentID] = useState(documentData?.DocumentSystemId);
  const { formatDocumentIDSystem, documentSystemCode } = useDocumentIdSystem();
  const [userGroupId, setUserGroupId] = useState(documentData?.UserGroupId);
  const { data: userGroup } = useGetUserGroup(userGroupId);
  const { data } = useGetUser();

  useEffect(() => {
    setValue("ProductName", documentData?.Product.Name);
    setValue("UserGroupId", documentData?.UserGroupId);
    setValue("DocumentSystemId", documentData?.DocumentSystemId);
    setValue("DocumentSystemCode", documentData?.DocumentSystemCode);
    setValue("DocumentValidFromDate", documentData?.DocumentValidFromDate);
  }, [
    setValue,
    documentData?.Product.Name,
    documentData?.UserGroupId,
    documentData?.DocumentSystemId,
    documentData?.DocumentSystemCode,
    documentData?.DocumentValidFromDate,
  ]);

  const documentFunction = (id: string) => {
    setDocumentID(id);
    setValue("DocumentSystemId", id);
    setValue("DocumentSystemCode", documentSystemCode(id, userGroup || ({} as UserGroup)));
  };

  const handleChange = (id: number) => {
    setUserGroupId(id);
    setValue("UserGroupId", id);
  };

  const dateFnsLocale = useGetDateFnsLocale();

  return (
    <form onSubmit={handleSubmit as any}>
      <Grid container sx={{ mt: 2 }} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <CustomTextField
            name={"ProductName"}
            isDisabled={false}
            controlValue={control}
            defaultValue={documentData?.Product.Name}
            isMultiLine={false}
            rowSpan={0}
            label={get("documentModalProductName")}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 1 }}>
          <Controller
            name="UserGroupId"
            control={control}
            defaultValue={documentData?.UserGroupId}
            render={() => (
              <FormControl variant="outlined" sx={{ width: "100%" }}>
                <InputLabel shrink>{get("documentModalOrganisation")}</InputLabel>
                <Select
                  value={userGroupId}
                  onChange={(e: SelectChangeEvent<number>) => handleChange(e.target.value as number)}
                  labelId={get("documentModalOrganisation")}
                  input={<OutlinedInput notched label={get("documentModalOrganisation")} />}
                >
                  {data?.UserGroups.map((item: EBVDUserGroup) => (
                    <MenuItem key={item.UserGroupId} value={item.UserGroupId}>
                      {item.UserGroupName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={6} sx={{ mt: 1 }}>
          <Controller
            name="DocumentSystemId"
            control={control}
            defaultValue={documentData?.DocumentSystemId}
            rules={{ required: true }}
            render={() => (
              <FormControl variant="outlined" sx={{ width: "100%" }}>
                <InputLabel shrink>{get("documentModalDocumentIDSystem")}</InputLabel>
                <Select
                  onChange={(e) => documentFunction(e.target.value)}
                  value={documentID}
                  inputProps={register("DocumentSystemId")}
                  input={<OutlinedInput notched label={get("documentModalDocumentIDSystem")} />}
                >
                  {DocumentIdSystem.map((item: any) => (
                    <MenuItem key={item.key} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={6} sx={{ mt: 1 }}>
          <Controller
            control={control}
            name="DocumentSystemCode"
            defaultValue={documentSystemCode(documentID, userGroup || ({} as UserGroup))}
            render={({ field }) => (
              <NumberFormat
                {...field}
                displayType="input"
                type="text"
                sx={{ width: "100%" }}
                customInput={TextField}
                disabled={documentID ? false : true}
                format={formatDocumentIDSystem(documentID)}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} sx={{ mt: 1 }}>
          <Controller
            name="DocumentValidFromDate"
            control={control}
            defaultValue={new Date()}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={dateFnsLocale}>
                <DesktopDatePicker
                  label={get("documentModalValidFrom")}
                  inputFormat="yyyy/MM/dd"
                  value={field.value}
                  onChange={field.onChange}
                  renderInput={(params) => <TextField {...params} sx={{ width: "100%" }} />}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 1 }}>
          <Typography>{get("copyDocumentInformation")}</Typography>
        </Grid>
        <Grid item xs={6} sx={{ mt: 2.5, p: 0 }}>
          <CustomCheckBox
            name="CopyBusinessObjectPropertyGroup3"
            label={"3." + get("sectionChemicalContent")}
            dataValue={true}
            controlValue={control}
          />
        </Grid>
        <Grid item xs={6} sx={{ mt: 2.5, p: 0 }}>
          <CustomCheckBox
            name="CopyBusinessObjectPropertyGroup4"
            label={"4." + get("sectionRawMaterials")}
            dataValue={true}
            controlValue={control}
          />
        </Grid>
        <Grid item xs={6} sx={{ mt: 2.5, p: 0 }}>
          <CustomCheckBox
            name="CopyBusinessObjectPropertyGroup5"
            label={"5." + get("sectionEnvironmentalImpact")}
            dataValue={true}
            controlValue={control}
          />
        </Grid>
        <Grid item xs={6} sx={{ mt: 2.5, p: 0 }}>
          <CustomCheckBox
            name="CopyBusinessObjectPropertyGroup6"
            label={"6." + get("sectionDistribution")}
            dataValue={true}
            controlValue={control}
          />
        </Grid>
        <Grid item xs={6} sx={{ mt: 2.5, p: 0 }}>
          <CustomCheckBox
            name="CopyBusinessObjectPropertyGroup7"
            label={"7." + get("sectionConstructionPhase")}
            dataValue={true}
            controlValue={control}
          />
        </Grid>
        <Grid item xs={6} sx={{ mt: 2.5, p: 0 }}>
          <CustomCheckBox
            name="CopyBusinessObjectPropertyGroup8"
            label={"8." + get("sectionUsagePhase")}
            dataValue={true}
            controlValue={control}
          />
        </Grid>
        <Grid item xs={6} sx={{ mt: 2.5, p: 0 }}>
          <CustomCheckBox
            name="CopyBusinessObjectPropertyGroup9"
            label={"9." + get("sectionDemolition")}
            dataValue={true}
            controlValue={control}
          />
        </Grid>
        <Grid item xs={6} sx={{ mt: 2.5, p: 0 }}>
          <CustomCheckBox
            name="CopyBusinessObjectPropertyGroup10"
            label={"10." + get("sectionWasteManagement")}
            dataValue={true}
            controlValue={control}
          />
        </Grid>
        <Grid item xs={6} sx={{ mt: 2.5, p: 0 }}>
          <CustomCheckBox
            name="CopyBusinessObjectPropertyGroup11"
            label={"11." + get("sectionIndoorEnvironment")}
            dataValue={true}
            controlValue={control}
          />
        </Grid>
        <Grid item xs={6} sx={{ mt: 2.5, p: 0 }}>
          <Controller
            render={({ field }) => <TextField {...field} variant="filled" sx={{ display: "none", width: "100%" }} />}
            name="DocumentId"
            control={control}
            defaultValue={documentData?.DocumentId}
          />
        </Grid>
      </Grid>
    </form>
  );
};
