import { Button, IconButton } from "@mui/material";
import { ReactNode } from "react";
import Sticky from "react-stickynode";

type Props = {
  status: number;
  icon: ReactNode;
  text: string;
  onClick?: () => void;
  disabled?: boolean;
};

export const ToolbarButton = ({ status, icon, text, onClick, disabled = false }: Props) => {
  switch (status) {
    case Sticky.STATUS_FIXED: {
      return (
        <IconButton color="primary" disabled={disabled} onClick={onClick}>
          {icon}
        </IconButton>
      );
    }

    default: {
      return (
        <Button variant="contained" startIcon={icon} disabled={disabled} onClick={onClick}>
          {text}
        </Button>
      );
    }
  }
};
