import { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "../../../../../helpers/useTranslation";
import { CustomTextField } from "../../../../../components/CustomTextField/CustomTextField";
import { CustomRadioButton } from "../../../../../components/CustomRadioButton/CustomRadioButton";
import { CustomUsagePhaseRadioButton } from "../../../../../components/CustomRadioButton/CustomUsagePhaseRadioButton";
import { useFormContext } from "react-hook-form";
import ErrorIcon from "@mui/icons-material/Error";
import { ErrorMessage } from "@hookform/error-message";

type Props = {
  data?: ProductUsage;
  DocumentIsReadOnly: boolean;
};

export const UsagePhaseComponent = ({ data, DocumentIsReadOnly }: Props) => {
  const { get } = useTranslation();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useFormContext<EBVDDocument>();

  useEffect(() => {
    if (data?.LongevityMax === 5 && data?.LongevityMin === 5) {
      setValue("ProductUsage.Longevitysets", 5);
    } else if (data?.LongevityMax === 10 && data?.LongevityMin === 10) {
      setValue("ProductUsage.Longevitysets", 10);
    } else if (data?.LongevityMax === 15 && data?.LongevityMin === 15) {
      setValue("ProductUsage.Longevitysets", 15);
    } else if (data?.LongevityMax === 25 && data?.LongevityMin === 25) {
      setValue("ProductUsage.Longevitysets", 25);
    } else if (data?.LongevityMax === null && data?.LongevityMin === null) {
      setValue("ProductUsage.Longevitysets", 0);
    } else if (data?.LongevityMin === 50) {
      setValue("ProductUsage.Longevitysets", 50);
    }
  }, [data?.LongevityMax, data?.LongevityMin, setValue]);

  const handleResetEnergyLabelOne = () => {
    setValue("ProductUsage.EnergyLabelId", null);
  };
  const handleResetEnergyLabelTwo = () => {
    setValue("ProductUsage.EnergyLabel2Id", null);
  };

  return (
    <form onSubmit={handleSubmit as any}>
      <Grid container rowSpacing={1} columnSpacing={{ md: 3 }}>
        <Grid item xs={12}>
          <Typography>{get("usagePhaseOpertaionTitle")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <CustomRadioButton
            name={"ProductUsage.HasMaintenanceRequirements"}
            controlValue={control}
            dataValue={data?.HasMaintenanceRequirements}
            isNA={true}
            yesValue={1}
            noValue={2}
            disabled={DocumentIsReadOnly}
            errorFieldMessage={errors?.ProductUsage?.HasMaintenanceRequirements?.message}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 1 }}>
          <CustomTextField
            name={"ProductUsage.MaintenanceRequirements"}
            isDisabled={DocumentIsReadOnly}
            controlValue={control}
            defaultValue={data?.MaintenanceRequirements}
            isMultiLine={true}
            rowSpan={4}
            label={get("usagePhaseOperationSpecify")}
            errors={errors}
          />
        </Grid>
        <Grid item md={0} sx={{ mt: 3 }}>
          <ErrorMessage
            message=""
            as={
              <ErrorIcon
                color="error"
                style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "center" }}
              />
            }
            errors={errors}
            name="ProductUsage.MaintenanceRequirements"
          />
        </Grid>
        <Grid item md={11} sx={{ mt: 3 }} style={{ padding: "0" }}>
          <ErrorMessage
            as={<Typography variant="subtitle1" sx={{ color: "#E95450", margin: "3px 12px 0px 12px" }} />}
            errors={errors}
            name="ProductUsage.MaintenanceRequirements"
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Typography>{get("usagePhaseEnergySupply")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <CustomRadioButton
            name={"ProductUsage.HasEnergyRequirements"}
            controlValue={control}
            dataValue={data?.HasEnergyRequirements}
            isNA={true}
            yesValue={1}
            noValue={2}
            disabled={DocumentIsReadOnly}
            errorFieldMessage={errors?.ProductUsage?.HasEnergyRequirements?.message}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 1 }}>
          <CustomTextField
            name={"ProductUsage.EnergyRequirements"}
            isDisabled={DocumentIsReadOnly}
            controlValue={control}
            defaultValue={data?.EnergyRequirements}
            isMultiLine={true}
            rowSpan={4}
            label={get("usagePhaseOperationSpecify")}
            errors={errors}
          />
        </Grid>
        <Grid item md={0} sx={{ mt: 3 }}>
          <ErrorMessage
            message=""
            as={
              <ErrorIcon
                color="error"
                style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "center" }}
              />
            }
            errors={errors}
            name="ProductUsage.EnergyRequirements"
          />
        </Grid>
        <Grid item md={11} sx={{ mt: 3 }} style={{ padding: "0" }}>
          <ErrorMessage
            as={<Typography variant="subtitle1" sx={{ color: "#E95450", margin: "3px 12px 0px 12px" }} />}
            errors={errors}
            name="ProductUsage.EnergyRequirements"
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Typography>{get("usagePhaseTechnicalServiceLife") + " *"}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>{get("usagePhaseTechnicalServiceLifeEstimate")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <CustomUsagePhaseRadioButton
            name={"ProductUsage.Longevitysets"}
            controlValue={control}
            dataValue={data?.Longevitysets}
            isReferenceServiceRequired={true}
            isEnergyHasLabel={false}
            isEnergyHasLabelTwo={false}
            clearSelectedValue={""}
            errors={errors}
            DocumentIsReadOnly={DocumentIsReadOnly}
            setValue={setValue}
          />
          <Typography>{get("usagePhaseTechnicalServiceLiftEstimate")}</Typography>
        </Grid>
        <Grid item md={3} sx={{ mt: 2 }}>
          <CustomTextField
            name={"ProductUsage.LongevityMin"}
            isDisabled={DocumentIsReadOnly}
            controlValue={control}
            defaultValue={data?.LongevityMin}
            type="number"
            label={""}
            errors={errors}
          />
        </Grid>
        <Grid item md={0} sx={{ mt: 5 }}>
          <span>-</span>
        </Grid>
        <Grid item md={3} sx={{ mt: 2 }}>
          <CustomTextField
            name={"ProductUsage.LongevityMax"}
            isDisabled={DocumentIsReadOnly}
            controlValue={control}
            defaultValue={data?.LongevityMax}
            type="number"
            label={""}
            errors={errors}
          />
        </Grid>
        <Grid item md={5} sx={{ mt: 5 }}>
          <Typography>{get("usagePhaseYears")}</Typography>
        </Grid>
        {/* <Grid item md={1} sx={{ mt:3}}  >
          <ErrorMessage message=""  as={<ErrorIcon color="error" style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }} />
        } errors={errors} name="ProductUsage.LongevityMin" />
        </Grid>
        <Grid item md={11} sx={{ mt: 3 }} style={{ padding:'0'}}>
        <ErrorMessage  as={<Typography variant="subtitle1" sx={{ color: "#E95450", margin: "3px 12px 0px 12px" }} />} errors={errors} name="ProductUsage.LongevityMin" />
      </Grid> */}
        <Grid item xs={12} sx={{ mt: 3 }}>
          <CustomTextField
            name={"ProductUsage.LongevityComment"}
            isDisabled={DocumentIsReadOnly}
            controlValue={control}
            defaultValue={data?.LongevityComment}
            isMultiLine={true}
            rowSpan={4}
            label={get("usagePhaseComment")}
            errors={errors}
          />
        </Grid>
        <Grid item md={0} sx={{ mt: 3 }}>
          <ErrorMessage
            message=""
            as={
              <ErrorIcon
                color="error"
                style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "center" }}
              />
            }
            errors={errors}
            name="ProductUsage.LongevityComment"
          />
        </Grid>
        <Grid item md={11} sx={{ mt: 3 }} style={{ padding: "0" }}>
          <ErrorMessage
            as={<Typography variant="subtitle1" sx={{ color: "#E95450", margin: "3px 12px 0px 12px" }} />}
            errors={errors}
            name="ProductUsage.LongevityComment"
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Typography>{get("usagePhaseEnergyLabelForProduct")}</Typography>
          <CustomRadioButton
            name={"ProductUsage.EnergyHasLabel"}
            controlValue={control}
            dataValue={data?.EnergyHasLabel}
            isNA={true}
            yesValue={1}
            noValue={2}
            disabled={DocumentIsReadOnly}
            errorFieldMessage={errors?.ProductUsage?.EnergyHasLabel?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>{get("usagePhaseEnterLabelTitle")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <CustomUsagePhaseRadioButton
            name={"ProductUsage.EnergyLabelId"}
            controlValue={control}
            dataValue={data?.EnergyLabelId}
            isReferenceServiceRequired={false}
            isEnergyHasLabel={true}
            isEnergyHasLabelTwo={false}
            clearSelectedValue={handleResetEnergyLabelOne}
            errors={""}
            DocumentIsReadOnly={DocumentIsReadOnly}
          />
        </Grid>
        <Grid item md={0} sx={{ mt: 3 }}>
          <ErrorMessage
            message=""
            as={
              <ErrorIcon
                color="error"
                style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "center" }}
              />
            }
            errors={errors}
            name="ProductUsage.EnergyLabelId"
          />
        </Grid>
        <Grid item md={11} sx={{ mt: 3 }} style={{ padding: "0" }}>
          <ErrorMessage
            as={<Typography variant="subtitle1" sx={{ color: "#E95450", margin: "3px 12px 0px 12px" }} />}
            errors={errors}
            name="ProductUsage.EnergyLabelId"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>{get("usagePhaseEnterLabelTitleTwo")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <CustomUsagePhaseRadioButton
            name={"ProductUsage.EnergyLabel2Id"}
            controlValue={control}
            dataValue={data?.EnergyLabel2Id}
            isReferenceServiceRequired={false}
            isEnergyHasLabel={false}
            isEnergyHasLabelTwo={true}
            clearSelectedValue={handleResetEnergyLabelTwo}
            errors={""}
            DocumentIsReadOnly={DocumentIsReadOnly}
          />
        </Grid>
        <Grid item md={0} sx={{ mt: 3 }}>
          <ErrorMessage
            message=""
            as={
              <ErrorIcon
                color="error"
                style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "center" }}
              />
            }
            errors={errors}
            name="ProductUsage.EnergyLabel2Id"
          />
        </Grid>
        <Grid item md={11} sx={{ mt: 3 }} style={{ padding: "0" }}>
          <ErrorMessage
            as={<Typography variant="subtitle1" sx={{ color: "#E95450", margin: "3px 12px 0px 12px" }} />}
            errors={errors}
            name="ProductUsage.EnergyLabel2Id"
          />
        </Grid>
        <Grid item md={12} sx={{ mt: 2 }}>
          <CustomTextField
            name={"ProductUsage.Comment"}
            isDisabled={DocumentIsReadOnly}
            controlValue={control}
            defaultValue={data?.Comment}
            isMultiLine={true}
            rowSpan={4}
            label={get("usagePhaseOtherInformation")}
            errors={errors}
          />
        </Grid>
      </Grid>
    </form>
  );
};
