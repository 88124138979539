import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTranslation } from "../../../../../../helpers/useTranslation";
import { CustomTextField } from "../../../../../../components/CustomTextField/CustomTextField";
import { CustomRadioButton } from "../../../../../../components/CustomRadioButton/CustomRadioButton";
import { DeliveredArticleTable } from "../DeliveredArticleTable/DeliveredArticleTable";
import { MountedArticleTable } from "../MountedArticleTable/MountedArticleTable";
import { useFormContext } from "react-hook-form";
import ErrorIcon from '@mui/icons-material/Error';
import { ErrorMessage } from '@hookform/error-message';
type Props = {
  data?: EBVDDocument;
};

export const DeliveredArticle = ({ data }: Props) => {
  const { get } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext();
  return (
    <form onSubmit={handleSubmit as any}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} md={12}>
          <section>
            <label>{get("coveredByRegulation")}</label>
            <br />
            <CustomRadioButton
              name={"ProductWasteManagement.CoveredByRegulation2014_1075"}
              controlValue={control}
              dataValue={data?.ProductWasteManagement?.CoveredByRegulation2014_1075}
              isNA={false}
              yesValue={true}
              noValue={false}
              disabled={data?.DocumentIsReadOnly || false}
              errorFieldMessage={errors?.CoveredByRegulation2014_1075?.message}
            />
          </section>
        </Grid>
        <Grid item xs={12} md={12}>
          <section>
            <label>{get("reusable")}</label>
            <br />
            <CustomRadioButton
              name={"ProductWasteManagement.Reusable"}
              controlValue={control}
              dataValue={data?.ProductWasteManagement?.Reusable}
              isNA={true}
              yesValue={1}
              noValue={2}
              disabled={data?.DocumentIsReadOnly || false}
              errorFieldMessage={errors?.Reusable?.message}
            />
          </section>
        </Grid>
        <Grid item xs={12} md={12}>
          <CustomTextField
            isDisabled={data?.DocumentIsReadOnly || false}
            name={"ProductWasteManagement.ReusableComment"}
            controlValue={control}
            defaultValue={data?.ProductWasteManagement?.ReusableComment}
            isMultiLine={true}
            rowSpan={5}
            label={get("ifYesSpecify")}
            errors={errors}
          />
        </Grid>
        <Grid item md={0} sx={{ mt: 3 }}>
          <ErrorMessage
            message=""
            as={
              <ErrorIcon
                color="error"
                style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "center" }}
              />
            }
            errors={errors}
            name="ProductWasteManagement.ReusableComment"
          />
        </Grid>
        <Grid item md={11} sx={{ mt: 3 }} style={{ padding: "0" }}>
          <ErrorMessage
            as={<Typography variant="subtitle1" sx={{ color: "#E95450", margin: "3px 12px 0px 12px" }} />}
            errors={errors}
            name="ProductWasteManagement.ReusableComment"
          />
        </Grid>
        <Grid item xs={12} md={12} sx={{ mt: 2 }}>
          <label>{get("canRecycle")}</label>
        </Grid>
        <Grid item xs={12} md={12}>
          <CustomRadioButton
            name={"ProductWasteManagement.CanRecycle"}
            controlValue={control}
            dataValue={data?.ProductWasteManagement?.CanRecycle}
            isNA={true}
            yesValue={1}
            noValue={2}
            disabled={data?.DocumentIsReadOnly || false}
            errorFieldMessage={errors?.CanRecycle?.message}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <CustomTextField
            isDisabled={data?.DocumentIsReadOnly || false}
            name={"ProductWasteManagement.CanRecycleComment"}
            controlValue={control}
            defaultValue={data?.ProductWasteManagement?.CanRecycleComment}
            isMultiLine={true}
            rowSpan={5}
            errors={errors}
            label={get("ifYesSpecify")}
          />
        </Grid>
        <Grid item md={0} sx={{ mt: 3 }}>
          <ErrorMessage
            message=""
            as={
              <ErrorIcon
                color="error"
                style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "center" }}
              />
            }
            errors={errors}
            name="ProductWasteManagement.CanRecycleComment"
          />
        </Grid>
        <Grid item md={11} sx={{ mt: 3 }} style={{ padding: "0" }}>
          <ErrorMessage
            as={<Typography variant="subtitle1" sx={{ color: "#E95450", margin: "3px 12px 0px 12px" }} />}
            errors={errors}
            name="ProductWasteManagement.CanRecycleComment"
          />
        </Grid>
        <Grid item xs={12} md={12} sx={{ mt: 2 }}>
          <label>{get("energyRecovery")}</label>
        </Grid>
        <Grid item xs={12} md={12}>
          <CustomRadioButton
            name={"ProductWasteManagement.EnergyRecovery"}
            controlValue={control}
            dataValue={data?.ProductWasteManagement?.EnergyRecovery}
            isNA={true}
            yesValue={1}
            noValue={2}
            disabled={data?.DocumentIsReadOnly || false}
            errorFieldMessage={errors?.CanRecycle?.message}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <CustomTextField
            isDisabled={data?.DocumentIsReadOnly || false}
            name={"ProductWasteManagement.EnergyRecoveryComment"}
            controlValue={control}
            defaultValue={data?.ProductWasteManagement?.EnergyRecoveryComment}
            isMultiLine={true}
            rowSpan={5}
            label={get("ifYesSpecify")}
            errors={errors}
          />
        </Grid>
        <Grid item md={0} sx={{ mt: 3 }}>
          <ErrorMessage
            message=""
            as={
              <ErrorIcon
                color="error"
                style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "center" }}
              />
            }
            errors={errors}
            name="ProductWasteManagement.EnergyRecoveryComment"
          />
        </Grid>
        <Grid item md={11} sx={{ mt: 3 }} style={{ padding: "0" }}>
          <ErrorMessage
            as={<Typography variant="subtitle1" sx={{ color: "#E95450", margin: "3px 12px 0px 12px" }} />}
            errors={errors}
            name="ProductWasteManagement.EnergyRecoveryComment"
          />
        </Grid>
        <Grid item xs={12} md={12} sx={{ mt: 2 }}>
          <label>{get("restrictionsAndRecommendations")}</label>
        </Grid>
        <Grid item xs={12} md={12}>
          <CustomRadioButton
            name={"ProductWasteManagement.RestrictionsAndRecommendations"}
            controlValue={control}
            dataValue={data?.ProductWasteManagement?.RestrictionsAndRecommendations}
            isNA={true}
            yesValue={1}
            noValue={2}
            disabled={data?.DocumentIsReadOnly || false}
            errorFieldMessage={errors?.RestrictionsAndRecommendations?.message}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <CustomTextField
            isDisabled={data?.DocumentIsReadOnly || false}
            name={"ProductWasteManagement.RestrictionsAndRecommendationsComment"}
            controlValue={control}
            defaultValue={data?.ProductWasteManagement?.RestrictionsAndRecommendationsComment}
            isMultiLine={true}
            rowSpan={5}
            label={get("ifYesSpecify")}
            errors={errors}
          />
        </Grid>
        <Grid item md={0} sx={{ mt: 3 }}>
          <ErrorMessage
            message=""
            as={
              <ErrorIcon
                color="error"
                style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "center" }}
              />
            }
            errors={errors}
            name="ProductWasteManagement.RestrictionsAndRecommendationsComment"
          />
        </Grid>
        <Grid item md={11} sx={{ mt: 3 }} style={{ padding: "0" }}>
          <ErrorMessage
            as={<Typography variant="subtitle1" sx={{ color: "#E95450", margin: "3px 12px 0px 12px" }} />}
            errors={errors}
            name="ProductWasteManagement.RestrictionsAndRecommendationsComment"
          />
        </Grid>
        <Grid item md={12}>
          <DeliveredArticleTable
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            DocumentIsReadOnly={data?.DocumentIsReadOnly || false}
          />
        </Grid>
        <Grid item xs={12} md={12} sx={{ mt: 2 }}>
          <label>{get("hazardousWasteDelivered")}</label>
        </Grid>
        <Grid item xs={12} md={12}>
          <CustomRadioButton
            name={"ProductWasteManagement.HazardousWasteDelivered"}
            controlValue={control}
            dataValue={data?.ProductWasteManagement?.HazardousWasteDelivered}
            isNA={false}
            yesValue={true}
            noValue={false}
            disabled={data?.DocumentIsReadOnly || false}
            errorFieldMessage={errors?.HazardousWasteDelivered?.message}
          />
        </Grid>
        <Grid item md={12} sx={{ mb: 2 }}>
          <Typography component="div">{get("wasteTreatmentTabMountedGroupBoxInfoText")}</Typography>
        </Grid>
        <Grid item md={12}>
          <MountedArticleTable
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            DocumentIsReadOnly={data?.DocumentIsReadOnly || false}
          />
        </Grid>
        <Grid item xs={12} md={12} sx={{ mt: 2 }}>
          <label>{get("hazardousWasteMounted")}</label>
        </Grid>
        <Grid item xs={12} md={12}>
          <CustomRadioButton
            name={"ProductWasteManagement.HazardousWasteMounted"}
            controlValue={control}
            dataValue={data?.ProductWasteManagement?.HazardousWasteMounted}
            isNA={false}
            yesValue={true}
            noValue={false}
            disabled={data?.DocumentIsReadOnly || false}
            errorFieldMessage={errors?.HazardousWasteMounted?.message}
          />
        </Grid>
        <Grid item xs={12} md={12} sx={{ mt: 1 }}>
          <CustomTextField
            isDisabled={data?.DocumentIsReadOnly || false}
            name={"ProductWasteManagement.Comment"}
            controlValue={control}
            defaultValue={data?.ProductWasteManagement?.Comment}
            isMultiLine={true}
            rowSpan={5}
            label={get("wasteMaterialComment")}
            errors={errors}
          />
        </Grid>
      </Grid>
    </form>
  );
};
