import { Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { messageAtom } from "../../recoil/atom";

export const NotificationSnackbar = () => {
  const [show, setShow] = useState(false);
  const [text, setText] = useRecoilState(messageAtom);

  useEffect(() => {
    if (text) setShow(true);
  }, [text]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={show}
      autoHideDuration={6000}
      onClose={() => {
        setShow(false);
        setText("");
      }}
      message={text}
    />
  );
};
