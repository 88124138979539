import { Grid, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import { useTranslation } from "../../../../../../helpers/useTranslation";
import { ArticleIdSystem } from "../../../../../../constants/articleIDConstants";
import { CustomTextField } from "../../../../../../components/CustomTextField/CustomTextField";
import { CustomErrorTypography } from "../../../../../../components/CustomTypography/CustomErrorTypography";
import { useGetUserGroup } from "../../../../../../services/userGroupService";

type Props = {
  handleSubmit: any;
  controlValue: any;
  setValue: any;
  errors: any;
  setArticleItemId: any;
  articleItemID: string;
  userGroupId: number;
  register: any;
};

export const ArticleForm = ({
  handleSubmit,
  controlValue,
  setValue,
  errors,
  setArticleItemId,
  articleItemID,
  userGroupId,
  register,
}: Props) => {
  const { get } = useTranslation();
  const { data: userGroup } = useGetUserGroup(userGroupId);

  const handleChange = (event: SelectChangeEvent) => {
    if (event.target.value.includes("VAT")) {
      setValue("VATNumber", userGroup?.VatNo);
      setValue("ProductId", "");
    } else {
      setValue("VATNumber", "");
      setValue("ProductId", "");
    }
    setArticleItemId(event.target.value);
    setValue("ProductSystemId", event.target.value);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container sx={{ mt: 1 }} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12}>
            <label>{get("articleSelectIdSystemNumber") + " *"}</label>
          </Grid>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <Controller
              name="ProductSystemId"
              control={controlValue}
              defaultValue=""
              render={() => (
                <FormControl variant="outlined" sx={{ width: "60%", mt: 2 }}>
                  <InputLabel shrink>{get("articleSelectIdSystemNumber")}</InputLabel>
                  <Select
                    onChange={handleChange}
                    value={articleItemID}
                    error={errors.ProductSystemId}
                    inputProps={register("ProductSystemId")}
                    input={<OutlinedInput notched label={get("articleSelectIdSystemNumber")} />}
                  >
                    {ArticleIdSystem.map((item) => (
                      <MenuItem value={item.value} key={item.key}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            <CustomErrorTypography errorMessage={errors.ProductSystemId && errors.ProductSystemId.message} />
          </Grid>
          {articleItemID.includes("VAT") && (
            <Grid item xs={12} sx={{ mb: 5 }}>
              <Controller
                control={controlValue}
                name="VATNumber"
                render={({ field }) => (
                  <NumberFormat
                    {...field}
                    displayType="input"
                    type="text"
                    sx={{ width: "100%" }}
                    value={field.value}
                    defaultValue={""}
                    customInput={TextField}
                    format="SE############"
                    error={errors.VATNumber}
                    helperText={errors.VATNumber ? errors.VATNumber?.message : ""}
                  />
                )}
              />
            </Grid>
          )}
          {articleItemID !== "" && (
            <Grid item xs={12} sx={{ mb: 5 }}>
              <CustomTextField
                name={"ProductId"}
                isDisabled={false}
                controlValue={controlValue}
                defaultValue={""}
                label={
                  articleItemID.includes("VAT")
                    ? get("articleVATNumber")
                    : articleItemID + " - " + get("articleSelectIdSystemNumberLabel")
                }
                errors={errors}
              />
              <CustomErrorTypography errorMessage={errors.ProductId && errors.ProductId.message} />
            </Grid>
          )}
        </Grid>
      </form>
    </>
  );
};
