import { Grid, Typography } from "@mui/material";
import { useForm, useFormContext } from "react-hook-form";
import { useState } from "react";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "../../../../../../helpers/useTranslation";
import { useFormValidation } from "../../../../../../helpers/useFormValidation";
import { CustomTextField } from "../../../../../../components/CustomTextField/CustomTextField";
import { ConfirmationModal } from "../../../../../../components/ConfirmationModal/ConfirmationModal";
import { RawMaterialsTableForm } from "./RawMaterialsTableForm";
import { useGetCountries } from "../../../../../../services/countryService";

type Props = {
  data?: EBVDDocument;
};

const RawMaterialsTable = ({ data }: Props) => {
  const { rawMaterialArticleValidationSchema } = useFormValidation();
  const { get } = useTranslation();
  const { data: countriesDataValues } = useGetCountries();

  const {
    reset,
    handleSubmit,
    control: controlValue,
    formState: { errors: rawMaterialError },
  } = useForm<ProductRawMaterialComponent>({
    resolver: yupResolver(rawMaterialArticleValidationSchema),
  });

  const {
    getValues,
    setValue,
    control,
    formState: { errors },
  } = useFormContext();

  const [rawMaterialData, setRawMaterialData] = useState<ProductRawMaterialComponent[]>(
    getValues("ProductRawMaterialComponents") || [],
  );
  const [rowColor, setRowColorRequired] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [index, setIndex] = useState(0);

  const handleConfirmation = (rawMaterialdata: ProductRawMaterialComponent) => {
    setRawMaterialData([...rawMaterialData, rawMaterialdata]);
    setValue("ProductRawMaterialComponents", [...rawMaterialData, rawMaterialdata], { shouldDirty: true });
    setRowColorRequired(false);
    setShowAdd(false);
    setIndex(0);
    reset();
  };

  const handleUpdateConfirmation = (rawMaterialdata: ProductRawMaterialComponent) => {
    rawMaterialData[index - 1] = rawMaterialdata;
    setRawMaterialData([...rawMaterialData]);
    setValue("ProductRawMaterialComponents", [...rawMaterialData], { shouldDirty: true });
    setIndex(0);
    setShowEdit(false);
    reset();
  };

  const remove = (idx: number) => {
    if (idx !== 0) rawMaterialData.splice(idx - 1, 1);
    setRawMaterialData([...rawMaterialData]);
    setValue("ProductRawMaterialComponents", [...rawMaterialData], { shouldDirty: true });
    setIndex(0);
  };

  const handleRowClick = (idx: number) => {
    setIndex(idx + 1);
    setRowColorRequired(true);
  };
  const handleModalClose = () => {
    setShowAdd(false);
    setIndex(0);
    reset();
  };

  const handleEditModalClose = () => {
    setShowEdit(false);
    setIndex(0);
    reset();
  };

  const handleAdd = () => {
    setIndex(-1);
    setShowAdd(true);
  };
  return (
    <>
      <Grid container rowSpacing={1} sx={{ mb: 3 }}>
        <Grid item md={12}>
          <Typography>{get("rawMaterialPurchaseSystem")}</Typography>
        </Grid>
        <Grid item md={12} sx={{ mt: 2 }}>
          <CustomTextField
            name={"ProductRawMaterial.PurhcaseSystem"}
            isDisabled={data?.DocumentIsReadOnly || false}
            controlValue={control}
            defaultValue={data?.ProductRawMaterial.PurhcaseSystem || ""}
            isMultiLine={false}
            rowSpan={0}
            label={get("rawMaterialPurchaseSystemlabel")}
            errors={errors}
          />
        </Grid>
        {/* <Grid item md={0} sx={{ mt:3}}  >
          <ErrorMessage message=""  as={<ErrorIcon color="error" style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }} />
        } errors={errors} name="ProductRawMaterial.PurhcaseSystem" />
        </Grid>
        <Grid item md={11} sx={{ mt: 3 }} style={{ padding:'0'}}>
        <ErrorMessage  as={<Typography variant="subtitle1" sx={{ color: "#E95450", margin: "3px 12px 0px 12px" }} />} errors={errors} name="ProductRawMaterial.PurhcaseSystem" />
      </Grid> */}
        <Grid item md={12} sx={{ mt: 2 }}>
          <Typography>{get("rawMaterialTableTitle")}</Typography>
        </Grid>
        <Grid item md={11}>
          <Paper sx={{ overflow: "hidden" }}>
            <TableContainer sx={{ height: 300, width: "100%" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="body1">
                        <b>{get("rawMaterialTableComponentTitle")}</b>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        <b>{get("rawMaterialTableMaterialTitle")}</b>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        <b>{get("rawMaterialCityofRawMaterialsTitle")}</b>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        <b>{get("rawMaterialCountryofRawMaterialsTitle")}</b>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        <b>{get("rawMaterialTransportTitle")}</b>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        <b>{get("rawMaterialCityOfProductionTitle")}</b>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        <b>{get("rawMaterialCountryOfProductionTitle")}</b>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        <b>{get("rawMaterialCommentTitle")}</b>
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {rawMaterialData.map((item: ProductRawMaterialComponent, idx) => (
                    <TableRow
                      key={idx}
                      onClick={() => handleRowClick(idx)}
                      sx={{
                        backgroundColor: rowColor && idx + 1 === index ? "#b1b8b8" : "",
                        cursor: "pointer",
                      }}
                    >
                      <TableCell>{item.Component}</TableCell>
                      <TableCell>{item.Material}</TableCell>
                      <TableCell>{item.PrimaryProductionCity}</TableCell>
                      <TableCell>
                        {countriesDataValues?.Items?.find((x) => x.CountryId === item.PrimaryProductionCountryId)?.Name}
                      </TableCell>
                      <TableCell>{item.TransportType}</TableCell>
                      <TableCell>{item.ProductionCity}</TableCell>
                      <TableCell>
                        {countriesDataValues?.Items?.find((x) => x.CountryId === item.ProductionCountryId)?.Name}
                      </TableCell>
                      <TableCell>{item.Comment}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid item md={1}>
          <Stack direction="column" spacing={2} sx={{ float: "right" }}>
            <Button
              variant="contained"
              onClick={handleAdd}
              size="small"
              disabled={data?.DocumentIsReadOnly || false}
              sx={{ width: "36px", minWidth: "0px" }}
            >
              <AddIcon />
            </Button>
            <Button
              variant="contained"
              onClick={() => setShowEdit(true)}
              size="small"
              disabled={index === 0 || data?.DocumentIsReadOnly}
              sx={{ m: 1, width: "36px", minWidth: "0px" }}
            >
              <EditIcon />
            </Button>
            <Button
              color="error"
              size="small"
              sx={{ m: 1, width: "36px", minWidth: "0px" }}
              disabled={index === 0 || data?.DocumentIsReadOnly}
              onClick={() => remove(index)}
            >
              <DeleteIcon />
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <ConfirmationModal
        show={showAdd}
        handleClose={() => handleModalClose()}
        handleConfirmation={() => handleSubmit(handleConfirmation)()}
        title={get("rawMaterialModalTitle")}
        saveButtonName={get("saveButtonText")}
        cancelButtonName={get("cancelButtonText")}
      >
        <RawMaterialsTableForm
          handleSubmit={handleSubmit}
          control={controlValue}
          reset={reset}
          data={undefined}
          errors={rawMaterialError}
        />
      </ConfirmationModal>
      <ConfirmationModal
        show={showEdit}
        handleClose={() => handleEditModalClose()}
        handleConfirmation={() => handleSubmit(handleUpdateConfirmation)()}
        title={get("rawMaterialModalTitle")}
        saveButtonName={get("saveButtonText")}
        cancelButtonName={get("cancelButtonText")}
      >
        <RawMaterialsTableForm
          handleSubmit={handleSubmit}
          control={controlValue}
          reset={reset}
          data={rawMaterialData[index - 1]}
          errors={rawMaterialError}
        />
      </ConfirmationModal>
    </>
  );
};

export default RawMaterialsTable;
