import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  TextField,
  Typography,
  Divider,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "../../../../helpers/useTranslation";
import { useCreateNewResource } from "../../../../services/epdService";
import { fileToByteArray } from "../../../../helpers/fileToByteArray";

interface CreateNewResourceModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const CreateNewResourceModal: React.FC<CreateNewResourceModalProps> = ({ isOpen, onClose }) => {
  const { get } = useTranslation();
  const [dataSource, setDataSource] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const [systemOptions, setSystemOptions] = useState<string[]>([]);
  const [selectedSystem, setSelectedSystem] = useState<string>("");
  const [name, setName] = useState<string>("");

  const createResourceMutation = useCreateNewResource();

  // Fetch system options based on dataSource
  useEffect(() => {
    const fetchSystemOptions = async () => {
      if (dataSource === "ILCD" || dataSource === "GaBi") {
        try {
          const response = await fetch("/api/systems");
          if (!response.ok) throw new Error("Failed to fetch systems");
          const data = await response.json();
          setSystemOptions(data.systems);
        } catch (error) {
          console.error("Error fetching systems:", error);
        }
      } else {
        setSystemOptions([]);
      }
    };
    fetchSystemOptions();
  }, [dataSource]);

  // File drop handler
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept: [".xlsx", ".xls", ".json", ".xml"],
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        setFile(acceptedFiles[0]);
      }
    },
  });

  const handleUpload = async () => {
    if (!file || !dataSource) {
      console.error("File or Data Source is missing");
      return;
    }

    try {
      // Convert the file to byte array
      const fileByteArray = (await fileToByteArray(file)) as number[];
      console.log("File Byte Array:", fileByteArray);
      const newResourceData: CreateResource = {
        file: file,
        fileBytes: fileByteArray,
        dataSource,
        selectedSystem,
        name,
      };

      createResourceMutation.mutate(newResourceData, {
        onSuccess: () => {
          console.log("Resource created successfully");
          handleClose();
        },
        onError: (error) => {
          console.error("Failed to create resource:", error);
        },
      });
    } catch (error) {
      console.error("Error converting file to byte array:", error);
    }
  };

  const handleDataSourceChange = (event: SelectChangeEvent<string>) => {
    setDataSource(event.target.value);
    setSelectedSystem("");
    setName("");
  };

  const handleClose = () => {
    setDataSource("");
    setFile(null);
    setSystemOptions([]);
    setSelectedSystem("");
    setName("");
    onClose();
  };

  const acceptedFileTypes = () => {
    switch (dataSource) {
      case "ILCD":
        return ".xml fil";
      case "IVLs JSON":
        return ".json fil";
      default:
        return "Excel fil";
    }
  };

  const dropzoneStyle: React.CSSProperties = {
    border: "2px dashed #d9d9d9",
    borderRadius: "4px",
    backgroundColor: "#fafafa",
    textAlign: "center",
    padding: "20px",
    margin: "10px 0px",
    cursor: "pointer",
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>{get("EpdCreateNewResource")}</DialogTitle>
      <Divider />
      <DialogContent style={{ minWidth: 400 }}>
        <FormControl fullWidth margin="dense" variant="outlined">
          <InputLabel>{get("EpdChooseFileType")}</InputLabel>
          <Select value={dataSource} onChange={handleDataSourceChange} label={get("EpdChooseFileTypeLabel")}>
            <MenuItem value="ILCD">ILCD</MenuItem>
            <MenuItem value="GaBi">GaBi</MenuItem>
            <MenuItem value="IVLs JSON">IVLs JSON</MenuItem>
            <MenuItem value="EPDxls">EPDxls</MenuItem>
          </Select>
        </FormControl>

        {(dataSource === "ILCD" || dataSource === "GaBi") && (
          <FormControl fullWidth margin="dense" variant="outlined">
            <InputLabel>System</InputLabel>
            <Select value={selectedSystem} onChange={(e) => setSelectedSystem(e.target.value)} label="System">
              {systemOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {dataSource === "GaBi" && (
          <TextField
            fullWidth
            margin="dense"
            variant="outlined"
            label={get("EpdName")}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        )}

        <div {...getRootProps()} style={dropzoneStyle}>
          <input {...getInputProps()} />
          <p>{file ? file.name : get("importDragAndDrop")}</p>
        </div>
        {!file && (
          <Typography variant="body2" sx={{ color: "#888", mt: 1 }}>
            {get("EpdUploadFile")}
            {acceptedFileTypes()}
          </Typography>
        )}
        {file && <Typography variant="body2">{file.name}</Typography>}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {get("documentCancel")}
        </Button>
        <Button
          onClick={handleUpload}
          color="primary"
          variant="contained"
          disabled={!file || !dataSource || createResourceMutation.isLoading}
        >
          {createResourceMutation.isLoading ? get("EpdCreatingResource") : get("EpdCreateResource")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
