import React, { useState, useEffect } from "react";
import {
  Paper,
  Typography,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Grid,
  Alert,
  Box,
} from "@mui/material";
import { UnauthenticatedTemplate, useIsAuthenticated } from "@azure/msal-react";
import { useTranslation } from "../../../../helpers/useTranslation";
import { RowData } from "../../data";
import { ToolbarButton } from "../../../../components/DocumentToolbar/ToolbarButton/ToolbarButton";
import SaveIcon from "@mui/icons-material/Save";

interface EnvironmentalIndicatorsTabProps {
  data: RowData;
  onSave?: (updatedData: Partial<RowData>) => void;
}

export const EnvironmentalIndicatorsTab: React.FC<EnvironmentalIndicatorsTabProps> = ({ data, onSave }) => {
  const { get } = useTranslation();
  const isAuth = useIsAuthenticated();

  const initialUseCalculatedValue = data.calculatedGwpGhg && data.calculatedGwpGhg > 0 ? "yes" : "no";
  const [useCalculatedValue, setUseCalculatedValue] = useState(initialUseCalculatedValue);
  const [calculatedGwpGhg, setCalculatedGwpGhg] = useState(data.calculatedGwpGhg || 0);
  const [saveEnabled, setSaveEnabled] = useState(false);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setUseCalculatedValue(value);
    setSaveEnabled(value !== initialUseCalculatedValue);
  };

  const handleSave = () => {
    if (onSave && saveEnabled) {
      onSave({
        calculatedGwpGhg,
      });
      setSaveEnabled(false);
    }
  };

  const calculateGwpGhg = () => {
    const gwpFossil = data.gwpFossil || 0;
    const gwpBiogenic = data.gwpBiogenic || 0;
    const gwpLuluc = data.gwpLuluc || 0;

    return gwpFossil + gwpBiogenic + gwpLuluc;
  };

  useEffect(() => {
    if (useCalculatedValue === "yes") {
      setCalculatedGwpGhg(calculateGwpGhg());
    } else {
      setCalculatedGwpGhg(0);
    }
  }, [useCalculatedValue, data]);

  return (
    <>
      <Box display={"flex"} justifyContent={"flex-end"} mb={2}>
        {isAuth && (
          <ToolbarButton
            status={0}
            icon={<SaveIcon />}
            text={get("toolBarSave")}
            onClick={handleSave}
            disabled={!saveEnabled}
          />
        )}
      </Box>

      <Paper sx={{ padding: "20px" }}>
        <Typography display={"flex"} variant="h4" gutterBottom>
          {get("EpdEnvironmentalIndicators")}
          <UnauthenticatedTemplate>
            <Grid sx={{ ml: 3 }}>
              <Alert severity="info">{get("EpdAlertToEdit")}</Alert>
            </Grid>
          </UnauthenticatedTemplate>
        </Typography>

        <TextField
          fullWidth
          label="GWP-fossil, kg CO₂e"
          value={data.gwpFossil ?? ""}
          variant="outlined"
          margin="dense"
          InputProps={{ readOnly: true }}
          sx={{ marginBottom: 2 }}
        />
        <TextField
          fullWidth
          label="GWP-biogenic, kg CO₂e"
          value={data.gwpBiogenic ?? ""}
          variant="outlined"
          margin="dense"
          InputProps={{ readOnly: true }}
          sx={{ marginBottom: 2 }}
        />
        <TextField
          fullWidth
          label="GWP-luluc, kg CO₂e"
          value={data.gwpLuluc ?? ""}
          variant="outlined"
          margin="dense"
          InputProps={{ readOnly: true }}
          sx={{ marginBottom: 2 }}
        />
        <TextField
          fullWidth
          label="GWP-total, kg CO₂e"
          value={data.gwpTotal ?? ""}
          variant="outlined"
          margin="dense"
          InputProps={{ readOnly: true }}
          sx={{ marginBottom: 2 }}
        />
        <TextField
          fullWidth
          label="GWP-GHG, kg CO₂e"
          value={data.gwpGhg ?? ""}
          variant="outlined"
          margin="dense"
          InputProps={{ readOnly: true }}
          sx={{ marginBottom: 2 }}
        />
        <TextField
          fullWidth
          label="Biogenic carbon in product, kg C"
          value={data.biogenicCarbonInProduct ?? ""}
          variant="outlined"
          margin="dense"
          InputProps={{ readOnly: true }}
          sx={{ marginBottom: 2 }}
        />
        <TextField
          fullWidth
          label="Biogenic carbon in packaging, kg C"
          value={data.biogenicCarbonInPackaging ?? ""}
          variant="outlined"
          margin="dense"
          InputProps={{ readOnly: true }}
          sx={{ marginBottom: 2 }}
        />
        <TextField
          fullWidth
          label="Calculated GWP-GHG"
          value={calculatedGwpGhg.toString()}
          variant="outlined"
          margin="dense"
          InputProps={{ readOnly: true }}
          sx={{ marginBottom: 2 }}
        />

        <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
          {get("EpdEnvironmentalInfo")}
        </Typography>

        <FormControl component="fieldset" sx={{ marginTop: 2 }}>
          <Typography variant="subtitle1">{get("EpdUseCalculatedValue")}</Typography>
          <RadioGroup
            row
            aria-label="use-calculated-value"
            name="use-calculated-value"
            value={useCalculatedValue}
            onChange={handleRadioChange}
          >
            <FormControlLabel value="no" control={<Radio />} label={get("EpdNo")} disabled={!isAuth} />
            <FormControlLabel value="yes" control={<Radio />} label={get("EpdYes")} disabled={!isAuth} />
          </RadioGroup>
        </FormControl>
      </Paper>
    </>
  );
};
