import { Grid, Typography } from "@mui/material";
import { useTranslation } from "../../../../../../helpers/useTranslation";
import { CustomRadioButton } from "../../../../../../components/CustomRadioButton/CustomRadioButton";
import { CustomTextField } from "../../../../../../components/CustomTextField/CustomTextField";
import { CustomCheckBox } from "../../../../../../components/CustomCheckBox/CustomCheckBox";
import { useFormContext } from "react-hook-form";
import ErrorIcon from '@mui/icons-material/Error';
import { ErrorMessage } from '@hookform/error-message';

type Props = {
  data?: ProductIndoorEnvironment;
  DocumentIsReadOnly: boolean;
};
export const IndoorEnvironmentComponent = ({ data, DocumentIsReadOnly }: Props) => {
  const { get } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item md={12}>
          <CustomCheckBox
            name="ProductIndoorEnvironment.NoIndoorUsage"
            label={get("indoorEnvironmentNotIntended")}
            dataValue={data?.NoIndoorUsage}
            controlValue={control}
            disable={DocumentIsReadOnly}
          />
        </Grid>
        <Grid item md={12}>
          <CustomCheckBox
            name="ProductIndoorEnvironment.NoEmissions"
            label={get("indoorEnvironmentNotProduceEmission")}
            dataValue={data?.NoEmissions}
            controlValue={control}
            disable={DocumentIsReadOnly}
          />
        </Grid>
        <Grid item md={12}>
          <CustomCheckBox
            name="ProductIndoorEnvironment.EmissionsNotMeasured"
            label={get("indoorEnvironmentNotEmissionMeasure")}
            dataValue={data?.EmissionsNotMeasured}
            controlValue={control}
            disable={DocumentIsReadOnly}
          />
        </Grid>

        <Grid item md={12}>
          <label>{get("indoorEnvironmentMoistureLevel")}</label>
        </Grid>
        <Grid item md={12}>
          <CustomRadioButton
            name={"ProductIndoorEnvironment.HasCriticalMoistureCondition"}
            controlValue={control}
            dataValue={data?.HasCriticalMoistureCondition}
            isNA={false}
            yesValue={true}
            noValue={false}
            disabled={DocumentIsReadOnly}
          />
        </Grid>
        <Grid item md={12} sx={{ mb: 1 }}>
          <CustomTextField
            isDisabled={DocumentIsReadOnly}
            name={"ProductIndoorEnvironment.CriticalMoistureCondition"}
            controlValue={control}
            defaultValue={data?.CriticalMoistureCondition}
            label={get("indoorEnvironmentEnterYes")}
            errors={errors}
          />
        </Grid>
        <Grid item md={0} sx={{ mt:3}} >
          <ErrorMessage message=""  as={<ErrorIcon color="error" style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }} />
        } errors={errors} name="ProductIndoorEnvironment.CriticalMoistureCondition" />
        </Grid>
        <Grid item md={11} sx={{ mt: 3 }} style={{ padding:'0'}}>
        <ErrorMessage  as={<Typography variant="subtitle1" sx={{ color: "#E95450", margin: "3px 12px 0px 12px" }} />} errors={errors} name="ProductIndoorEnvironment.CriticalMoistureCondition" />
      </Grid>
        <Grid item md={3} sx={{ mt: 2 }}>
          <Typography sx={{ fontWeight: "bold" }}>{get("indoorEnvironmentNoiseBlock")}</Typography>
          <Typography>{get("indoorEnvironmentProductNoise")}</Typography>

          <CustomRadioButton
            name={"ProductIndoorEnvironment.CanProduceNoise"}
            controlValue={control}
            dataValue={data?.CanProduceNoise}
            isNA={false}
            yesValue={1}
            noValue={0}
            disabled={DocumentIsReadOnly}
          />
        </Grid>

        <Grid item md={3} sx={{ mt: 2 }}>
          <Typography sx={{ fontWeight: "bold" }}>{get("indoorEnvironmentElectricityFieldBlock")}</Typography>
          <Typography>{get("indoorEnvironmentProductElectricField")}</Typography>

          <CustomRadioButton
            name={"ProductIndoorEnvironment.CanInduceElectricFields"}
            controlValue={control}
            dataValue={data?.CanInduceElectricFields}
            isNA={false}
            yesValue={1}
            noValue={0}
            disabled={DocumentIsReadOnly}
          />
        </Grid>

        <Grid item md={3} sx={{ mt: 2 }}>
          <Typography sx={{ fontWeight: "bold" }}>{get("indoorEnvironmentMagneticFieldBlock")}</Typography>
          <Typography>{get("indoorEnvironmentProductMagneticField")}</Typography>

          <CustomRadioButton
            name={"ProductIndoorEnvironment.CanProduceMagneticFields"}
            controlValue={control}
            dataValue={data?.CanProduceMagneticFields}
            isNA={false}
            yesValue={1}
            noValue={0}
            disabled={DocumentIsReadOnly}
          />
        </Grid>

        <Grid item md={3} sx={{ mt: 2 }}>
          <Typography sx={{ fontWeight: "bold" }}>{get("indoorEnvironmentProductFungusBlock")}</Typography>
          <Typography>{get("indoorEnvironmentProductResistantToFungus")}</Typography>
          <CustomCheckBox
            name="ProductIndoorEnvironment.ProductResistantToFungusAndAlgae"
            label={""}
            dataValue={data?.ProductResistantToFungusAndAlgae}
            controlValue={control}
            disable={DocumentIsReadOnly}
          />
        </Grid>
      </Grid>

      <Grid container rowSpacing={1} sx={{ mt: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item md={3} sx={{ mb: 3 }}>
          {" "}
          <CustomTextField
            isDisabled={DocumentIsReadOnly}
            name={"ProductIndoorEnvironment.NoiseValue"}
            controlValue={control}
            defaultValue={data?.NoiseValue}
            label={get("indoorEnvironmentValue")}
          />
        </Grid>
        <Grid item md={3}>
          <CustomTextField
            isDisabled={DocumentIsReadOnly}
            name={"ProductIndoorEnvironment.ElectricFieldsValue"}
            controlValue={control}
            defaultValue={data?.ElectricFieldsValue}
            label={get("indoorEnvironmentValue")}
          />
        </Grid>
        <Grid item md={3}>
          <CustomTextField
            isDisabled={DocumentIsReadOnly}
            name={"ProductIndoorEnvironment.MagneticFieldsValue"}
            controlValue={control}
            defaultValue={data?.MagneticFieldsValue}
            label={get("indoorEnvironmentValue")}
          />
        </Grid>

        <Grid container rowSpacing={1} sx={{ pl: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item md={3} sx={{ mb: 3 }}>
            <CustomTextField
              isDisabled={DocumentIsReadOnly}
              name={"ProductIndoorEnvironment.NoiseUnit"}
              controlValue={control}
              defaultValue={data?.NoiseUnit}
              label={get("indoorEnvironmentUnit")}
            />
          </Grid>
          <Grid item md={3}>
            <CustomTextField
              isDisabled={DocumentIsReadOnly}
              name={"ProductIndoorEnvironment.ElectricFieldsUnit"}
              controlValue={control}
              defaultValue={data?.ElectricFieldsUnit}
              label={get("indoorEnvironmentUnit")}
            />
          </Grid>
          <Grid item md={3}>
            <CustomTextField
              isDisabled={DocumentIsReadOnly}
              name={"ProductIndoorEnvironment.MagneticFieldsUnit"}
              controlValue={control}
              defaultValue={data?.MagneticFieldsUnit}
              label={get("indoorEnvironmentUnit")}
            />
          </Grid>
        </Grid>

        <Grid container rowSpacing={1} sx={{ pl: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item md={3} sx={{ mb: 3 }}>
            <CustomTextField
              isDisabled={DocumentIsReadOnly}
              name={"ProductIndoorEnvironment.NoiseMethod"}
              controlValue={control}
              defaultValue={data?.NoiseMethod}
              label={get("indoorEnvironmentMesauringMethod")}
            />
          </Grid>
          <Grid item md={3}>
            <CustomTextField
              isDisabled={DocumentIsReadOnly}
              name={"ProductIndoorEnvironment.ElectricFieldsMethod"}
              controlValue={control}
              defaultValue={data?.ElectricFieldsMethod}
              label={get("indoorEnvironmentMesauringMethod")}
            />
          </Grid>
          <Grid item md={3}>
            <CustomTextField
              isDisabled={DocumentIsReadOnly}
              name={"ProductIndoorEnvironment.MagneticFieldsMethod"}
              controlValue={control}
              defaultValue={data?.MagneticFieldsMethod}
              label={get("indoorEnvironmentMesauringMethod")}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
