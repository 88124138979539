import { Box, Grid } from "@mui/material";
import { ContactSection } from "../../Index/RightSideContent/ContactSection";
import { AboutSection } from "./AboutSection";

export const RightSideContent = () => {
  return (
    <Box role="tabpanel" sx={{ width: "100%" }}>
      <Box bgcolor="white">
        <AboutSection />
        <Grid
          container
          sx={{
            p: 3,
            backgroundColor: "#f3f8f9",
            paddingBottom: "30px",
          }}
        ></Grid>
        <ContactSection />
      </Box>
    </Box>
  );
};
