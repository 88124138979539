import { memo } from "react";
import { ArticleSubComponentTableRow } from "./ArticleSubComponentTableRow";

type Props = {
  productContentComponents: ProductContentComponent[] | undefined;
  productContentComponentTree: ProductContentComponentForTree[] | undefined;
  renameContentComponents: any;
  hideTable: boolean;
  productContentType: number | undefined;
  hPhrasesData?: HPhrases;
  selected: string;
  setSelected: any;
  disableEditButton: boolean;
  DocumentIsReadOnly: boolean;
  handleItemClick: (
    id: string | undefined,
    productContentComponentForTree: ProductContentComponentForTree | undefined,
    productContentComponent: ProductContentComponent | undefined,
  ) => void;
  handleEdit: any;
  isCollapsed: boolean;
};

export const ArticleSubComponentTableBody = memo(
  ({
    productContentComponents,
    productContentComponentTree,
    renameContentComponents,
    hideTable,
    hPhrasesData,
    selected,
    setSelected,
    disableEditButton,
    DocumentIsReadOnly,
    handleItemClick,
    handleEdit,
    isCollapsed
  }: Props) => {
    return (
      <>
        {productContentComponentTree &&
          productContentComponentTree
            .map((productContentComponentForTree, componentIdx) => (
              <ArticleSubComponentTableRow
                key={
                  productContentComponentForTree?.ProductContentComponentId +
                  "-" +
                  productContentComponentForTree?.ProductContentComponentType
                }
                productContentComponents={productContentComponents}
                productContentComponentForTree={productContentComponentForTree}
                id={
                  productContentComponentForTree?.ProductContentComponentId +
                  "-" +
                  productContentComponentForTree?.ProductContentComponentType
                }
                renameContentComponents={renameContentComponents}
                hideTable={hideTable}
                hPhrasesData={hPhrasesData}
                isOpen={true}
                selected={selected}
                setSelected={setSelected}
                handleItemClick={handleItemClick}
                handleEdit={handleEdit}
                disableEditButton={disableEditButton}
                DocumentIsReadOnly={DocumentIsReadOnly}
                isCollapsed={isCollapsed}
              ></ArticleSubComponentTableRow>
            ))}
      </>
    );
  },
);
