import { ReactNode } from "react";
import { useGetUser } from "../../services/userService";

type Props = {
  children?: ReactNode;
};

export const ImportAuthenticationTemplate = ({ children }: Props) => {
  const { data } = useGetUser();

  if (
    data &&
    data?.UserGroups.filter((x) => (x.CanCreateNew && x.CanEdit && x.CanCreateNewVersion) || x.IsUserGroupAdmin)
      .length > 0
  ) {
    return <>{children}</>;
  }
  return <></>;
};
