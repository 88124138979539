import { useState } from "react";
import Paper from "@mui/material/Paper";
import { useForm, useFormContext } from "react-hook-form";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { Grid, Typography, Button } from "@mui/material";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "../../../../../../../helpers/useTranslation";
import { ConfirmationModal } from "../../../../../../../components/ConfirmationModal/ConfirmationModal";
import IndoorEnvironmentTableForm from "./IndoorEnvironmentTableForm";
import { CustomTextField } from "../../../../../../../components/CustomTextField/CustomTextField";
import { CustomErrorTypography } from "../../../../../../../components/CustomTypography/CustomErrorTypography";
import { useFormValidation } from "../../../../../../../helpers/useFormValidation";
import { useEmissionMeasureUnit } from "../../../../../../../helpers/useEmissionMeasureUnit";
import ErrorIcon from "@mui/icons-material/Error";
import { useGetComparisonOperatorDataItems } from "../../../../../../../services/comparisonOperatorService";

type Props = {
  data?: EBVDDocument;
};

export const IndoorEmissionTable = ({ data }: Props) => {
  const { emissionTableValidationSchema } = useFormValidation();
  const { get } = useTranslation();
  const { emissionMeasureUnit } = useEmissionMeasureUnit();
  const { data: comparisonOperators } = useGetComparisonOperatorDataItems();
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors: emmissionError },
  } = useForm<ProductEmission>({
    resolver: yupResolver(emissionTableValidationSchema),
  });

  const {
    getValues,
    setValue,
    control: controlValue,
    formState: { errors },
  } = useFormContext();

  const [productEmissionData, setProductEmissionData] = useState<ProductEmission[]>(
    getValues("ProductEmissions") || [],
  );

  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [index, setIndex] = useState(0);
  const [rowColor, setRowColorRequired] = useState(false);

  const handleConfirmation = (data: ProductEmission) => {
    setProductEmissionData([...productEmissionData, data]);
    setValue("ProductEmissions", [...productEmissionData, data], { shouldDirty: true });
    setRowColorRequired(false);
    setShowAdd(false);
    setIndex(0);
    reset();
  };
  const handleUpdateConfirmation = (data: ProductEmission) => {
    productEmissionData[index - 1] = data;
    setProductEmissionData([...productEmissionData]);
    setValue("ProductEmissions", [...productEmissionData], { shouldDirty: true });
    setIndex(0);
    setShowEdit(false);
    reset();
  };
  const remove = (index: number) => {
    if (index !== 0) productEmissionData.splice(index - 1, 1);
    setProductEmissionData([...productEmissionData]);
    setValue("ProductEmissions", [...productEmissionData], { shouldDirty: true });
    setIndex(0);
    reset();
  };

  const handleRowClick = (idx: number) => {
    setIndex(idx + 1);
    setRowColorRequired(true);
  };
  const handleModalClose = () => {
    setShowAdd(false);
    setRowColorRequired(false);
    setIndex(0);
    reset();
  };

  const handleEditModalClose = () => {
    setShowEdit(false);
    setRowColorRequired(false);
    setIndex(0);
    reset();
  };

  const handleAdd = () => {
    setIndex(-1);
    setShowAdd(true);
    reset();
  };
  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item md={12}>
          <Typography color="text.secondary" gutterBottom>
            {get("indoorEnvironmentEmissionerTableTitle") + " *"}
          </Typography>
        </Grid>
        <Grid item md={11} sx={{ mb: 4 }}>
          <Paper
            sx={{ overflow: "hidden", border: errors?.ProductEmissions?.message ? "1px solid red!important" : "" }}
          >
            <TableContainer sx={{ height: 300, width: "100%" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="body1">
                        <b>{get("indoorEnvironmentTableEmissionType")}</b>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        <b>{get("indoorEnvironmentTableMeasuringMethod1")}</b>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        <b>{get("indoorEnvironmentTableEmissionResult1")}</b>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        <b>{get("indoorEnvironmentTableEmissionMeasuringInterval1")}</b>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        <b>{get("indoorEnvironmentTableMeasuringMethod2")}</b>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        <b>{get("indoorEnvironmentTableResult2")}</b>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        <b>{get("indoorEnvironmentTableMeasuringInterval2")}</b>
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {productEmissionData.map((item: ProductEmission, idx: number) => (
                    <TableRow
                      key={idx}
                      onClick={() => handleRowClick(idx)}
                      sx={{
                        backgroundColor: rowColor && idx + 1 === index ? "#b1b8b8" : "",
                        cursor: "pointer",
                      }}
                    >
                      <TableCell>{item.EmissionType}</TableCell>
                      <TableCell>{item.Method1}</TableCell>
                      <TableCell>
                        {comparisonOperators?.Items.find(
                          (x) => x.ComparisonOperatorId === item.Result1ComparisonOperatorId,
                        )?.Name + " "}
                        {item.Result1}
                      </TableCell>
                      <TableCell>
                        {item.MeasurementTimeInterval1 + " "}
                        {emissionMeasureUnit(item.MeasurementTimeInterval1Unit)}
                      </TableCell>
                      <TableCell>{item.Method2}</TableCell>
                      <TableCell>
                        {comparisonOperators?.Items.find(
                          (x) => x.ComparisonOperatorId === item.Result2ComparisonOperatorId,
                        )?.Name === undefined
                          ? ""
                          : comparisonOperators?.Items.find(
                              (x) => x.ComparisonOperatorId === item.Result2ComparisonOperatorId,
                            )?.Name + " "}
                        {item.Result2}
                      </TableCell>
                      <TableCell>
                        {item.MeasurementTimeInterval2 === null ? "" : item.MeasurementTimeInterval2 + " "}
                        {emissionMeasureUnit(item.MeasurementTimeInterval2Unit)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>

        <Grid item md={1}>
          <Stack direction="column" spacing={2} sx={{ float: "right" }}>
            <Button
              variant="contained"
              onClick={handleAdd}
              size="small"
              disabled={data?.DocumentIsReadOnly || false}
              sx={{ width: "36px", minWidth: "0px" }}
            >
              <AddIcon />
            </Button>
            <Button
              variant="contained"
              onClick={() => setShowEdit(true)}
              size="small"
              disabled={index === 0 || data?.DocumentIsReadOnly}
              sx={{ m: 1, width: "36px", minWidth: "0px" }}
            >
              <EditIcon />
            </Button>
            <Button
              color="error"
              size="small"
              disabled={index === 0 || data?.DocumentIsReadOnly}
              onClick={() => remove(index)}
              sx={{ m: 1, width: "36px", minWidth: "0px" }}
            >
              <DeleteIcon />
            </Button>
          </Stack>
        </Grid>
        <Grid item md={0} sx={{ mt: 3 }}>
          {errors?.ProductEmissions && (
            <ErrorIcon
              color="error"
              style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "center" }}
            />
          )}
        </Grid>
        <Grid item md={11} sx={{ mt: 3 }} style={{ padding: "0" }}>
          <CustomErrorTypography errorMessage={errors?.ProductEmissions && errors.ProductEmissions?.message} />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            name={"ProductIndoorEnvironment.Comment"}
            isDisabled={data?.DocumentIsReadOnly || false}
            controlValue={controlValue}
            defaultValue={data?.ProductIndoorEnvironment?.Comment}
            isMultiLine={true}
            rowSpan={4}
            label={get("indoorEnvironmentOtherInformation")}
          />
        </Grid>
      </Grid>
      <ConfirmationModal
        show={showAdd}
        handleClose={() => handleModalClose()}
        handleConfirmation={() => handleSubmit(handleConfirmation)()}
        title={get("indoorEmissionAdd")}
        saveButtonName={get("saveButtonText")}
        cancelButtonName={get("cancelButtonText")}
      >
        <IndoorEnvironmentTableForm
          control={control}
          handleSubmit={handleSubmit}
          errors={emmissionError}
          reset={reset}
        />
      </ConfirmationModal>
      <ConfirmationModal
        show={showEdit}
        handleClose={() => handleEditModalClose()}
        handleConfirmation={() => handleSubmit(handleUpdateConfirmation)()}
        title={get("indoorEmissionEdit")}
        saveButtonName={get("saveButtonText")}
        cancelButtonName={get("cancelButtonText")}
      >
        <IndoorEnvironmentTableForm
          control={control}
          handleSubmit={handleSubmit}
          data={productEmissionData[index - 1]}
          errors={emmissionError}
          reset={reset}
        />
      </ConfirmationModal>
    </>
  );
};
