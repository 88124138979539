export const ArticleIdSystem = [
  { key: 1, value: "E", label: "E" },
  { key: 2, value: "GMN", label: "GMN" },
  { key: 3, value: "GTIN", label: "GTIN" },
  { key: 4, value: "RSK", label: "RSK" },
  { key: 5, value: "VAT-ID", label: "VAT-ID" },
  { key: 6, value: "VAT-NAME", label: "VAT-NAME" },
];

export const ArticleIdSystemPattern = {
  E: "",
  GMN: "",
  GTIN: "",
  RSK: "",
  VAT_ID: "SE##########01",
  VAT_NAME: "SE##########01",
};

export const ArticleProductIdSystem = [
  { key: 1, value: "BK04", label: "BK04" },
  { key: 2, value: "BSAB96", label: "BSAB96" },
  { key: 3, value: "ETIM", label: "ETIM" },
  { key: 4, value: "KN", label: "KN" },
  { key: 5, value: "SNI", label: "SNI" },
  { key: 6, value: "UNSPSC", label: "UNSPSC" },
];

export const ArticleProductIdSystemPattern = {
  BK04: "",
  BSAB96: "",
  ETIM: "",
  KN: "",
  SNI: "",
  UNSPC: "",
};
