import { FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import { useTranslation } from "../../../../../helpers/useTranslation";
import { CustomTextField } from "../../../../../components/CustomTextField/CustomTextField";
import { CustomCheckBox } from "../../../../../components/CustomCheckBox/CustomCheckBox";
import { Controller, useFormContext } from "react-hook-form";
import { useState } from "react";
type Props = {
  data?: UserGroupDigital;
};
export const DigitalTransferInformation = ({ data }: Props) => {
  const { get } = useTranslation();

  const { control, handleSubmit, setValue } = useFormContext();
  const [isDisableRight, setDisableRight] = useState(data?.UserRoleId === null ? false : true);

  const setDefaultAccess = () => {
    setDisableRight(false);
    setValue("CanRead", false);
    setValue("CanEdit", false);
    setValue("CanCreateNew", false);
    setValue("CanCreateNewVersion", false);
    setValue("CanDelete", false);
    setValue("CanPublish", false);
    setValue("CanUnPublish", false);
    setValue("CanEditUserGroup", false);
  };

  const handleChange = (value: string) => {
    setValue("UserRoleId", value === "" ? null : value);
    setDefaultAccess();
    if (value === "1" || value === "2" || value === "3") {
      setDisableRight(true);
      setValue("CanRead", true);
      setValue("CanEdit", true);
      if (value === "1" || value === "3") {
        setValue("CanCreateNew", true);
        setValue("CanCreateNewVersion", true);
        setValue("CanDelete", true);
        setValue("CanPublish", true);
        setValue("CanUnPublish", true);
      }
      if (value === "3") {
        setValue("CanEditUserGroup", true);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit as any}>
      <Grid container sx={{ mt: 2 }} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <CustomTextField
            name="UserGroupName"
            isDisabled={true}
            controlValue={control}
            defaultValue={data?.UserGroupName}
            label={get("digitalTransferOrganization")}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 3 }}>
          <CustomTextField
            name="WebApiConsumerUserGroupName"
            isDisabled={true}
            controlValue={control}
            defaultValue={data?.WebApiConsumerUserGroupName}
            label={get("digitalTransferWebApiConsumer")}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 3 }}>
          <CustomTextField
            name="UserGroupAddress"
            isDisabled={true}
            controlValue={control}
            defaultValue={data?.UserGroupAddress}
            label={get("digitalTransferAddress")}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 3 }}>
          <CustomTextField
            name="UserGroupHomePage"
            isDisabled={true}
            controlValue={control}
            defaultValue={data?.UserGroupHomePage}
            label={get("digitalTransferHomePage")}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 3 }}>
          <CustomTextField
            name="UserGroupReference"
            isDisabled={true}
            controlValue={control}
            defaultValue={data?.UserGroupReference}
            label={get("digitalTransferReference")}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 3 }}>
          <CustomTextField
            name="UserGroupPhone"
            isDisabled={true}
            controlValue={control}
            defaultValue={data?.UserGroupPhone}
            label={get("digitalTransferPhoneNumber")}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 3 }}>
          <CustomTextField
            name="UserGroupEmail"
            isDisabled={true}
            controlValue={control}
            defaultValue={data?.UserGroupEmail}
            label={get("digitalTransferEMail")}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 3 }}>
          <Controller
            render={({ field: { value } }) => (
              <RadioGroup name="RoleGroup" row={true} value={value} onChange={(e) => handleChange(e.target.value)}>
                <FormControlLabel value={1} control={<Radio />} label={get("radioButtonCoWorker")} />
                <FormControlLabel value={2} control={<Radio />} label={get("radioButtonSubcontractor")} />
                <FormControlLabel value={3} control={<Radio />} label={get("radioButtonAdministrator")} />
                <FormControlLabel value={null} control={<Radio />} label={get("radioButtonCustomRole")} />
              </RadioGroup>
            )}
            name="UserRoleId"
            control={control}
            defaultValue={data?.UserRoleId}
          />
        </Grid>
        <Grid item xs={6} sx={{ mt: 3 }}>
          <CustomCheckBox
            name="CanCreateNew"
            label={get("userRoleCreateNew")}
            dataValue={data?.CanCreateNew}
            controlValue={control}
            disable={isDisableRight}
          />
        </Grid>
        <Grid item xs={6} sx={{ mt: 3 }}>
          <CustomCheckBox
            name="CanCreateNewVersion"
            label={get("userRoleCreateNewVersion")}
            dataValue={data?.CanCreateNewVersion}
            controlValue={control}
            disable={isDisableRight}
          />
        </Grid>
        <Grid item xs={6} sx={{ mt: 3 }}>
          <CustomCheckBox
            name="CanDelete"
            label={get("userRoleDelete")}
            dataValue={data?.CanDelete}
            controlValue={control}
            disable={isDisableRight}
          />
        </Grid>
        <Grid item xs={6} sx={{ mt: 3 }}>
          <CustomCheckBox
            name="CanRead"
            label={get("userRoleRead")}
            dataValue={data?.CanRead}
            controlValue={control}
            disable={isDisableRight}
          />
        </Grid>
        <Grid item xs={6} sx={{ mt: 3 }}>
          <CustomCheckBox
            name="CanEdit"
            label={get("userRoleEdit")}
            dataValue={data?.CanEdit}
            controlValue={control}
            disable={isDisableRight}
          />
        </Grid>
        <Grid item xs={6} sx={{ mt: 3 }}>
          <CustomCheckBox
            name="CanPublish"
            label={get("userRolePublish")}
            dataValue={data?.CanPublish}
            controlValue={control}
            disable={isDisableRight}
          />
        </Grid>
        <Grid item xs={6} sx={{ mt: 3 }}>
          <CustomCheckBox
            name="CanUnPublish"
            label={get("userRoleUnpublish")}
            dataValue={data?.CanUnPublish}
            controlValue={control}
            disable={isDisableRight}
          />
        </Grid>
        <Grid item xs={6} sx={{ mt: 3 }}>
          <CustomCheckBox
            name="CanEditUserGroup"
            label={get("roleCanEditOrganization")}
            dataValue={data?.CanEditUserGroup}
            controlValue={control}
            disable={isDisableRight}
          />
        </Grid>
      </Grid>
    </form>
  );
};
