import Paper from "@mui/material/Paper";
import { useTranslation } from "../../../../../../helpers/useTranslation";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { Grid, Typography, Button } from "@mui/material";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { ConfirmationModal } from "../../../../../../components/ConfirmationModal/ConfirmationModal";
import { useState } from "react";
import { useFormValidation } from "../../../../../../helpers/useFormValidation";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useGetWasteCodes } from "../../../../../../services/wasteCodeService";
import { MountedArticleTableForm } from "./MountedArticleTableForm";
type Props = {
  errors: any;
  getValues: any;
  setValue: any;
  DocumentIsReadOnly: boolean;
};

export const MountedArticleTable = ({ errors, getValues, setValue, DocumentIsReadOnly }: Props) => {
  const { get } = useTranslation();
  const { documentWasteCodeMountedItemsValidationSchema } = useFormValidation();
  const [showAdd, setShowAdd] = useState(false);
  const [wasteMountedItemData, setWasteMountedItemData] = useState<DocumentWasteCodeMountedItem[]>(
    getValues("DocumentWasteCodeMountedItems") || [],
  );
  const {
    handleSubmit,
    reset,
    formState: { errors: errorsMountedItem },
    setValue: setDocumentMountedWasteCodeValue,
  } = useForm<DocumentWasteCodeMountedItem>({
    defaultValues: {
      WasteCodeMountedId: 0,
    },
    resolver: yupResolver(documentWasteCodeMountedItemsValidationSchema),
  });
  const [index, setIndex] = useState(0);
  const [rowColor, setRowColorRequired] = useState(false);
  const filter = {
    TextFilter: " ",
  };

  const { data: wasteCodeDataValues } = useGetWasteCodes(filter);

  const handleModalClose = () => {
    setShowAdd(false);
    setRowColorRequired(false);
    setIndex(0);
    reset();
  };

  const handleConfirmation = (wasteCodeMountedData: DocumentWasteCodeMountedItem) => {
    setWasteMountedItemData([...wasteMountedItemData, wasteCodeMountedData]);
    setValue("DocumentWasteCodeMountedItems", [...wasteMountedItemData, wasteCodeMountedData], {
      shouldDirty: true,
    });
    setRowColorRequired(false);
    setIndex(0);
    setShowAdd(false);
    reset();
  };
  const remove = (index: number) => {
    if (index !== 0) wasteMountedItemData.splice(index - 1, 1);
    setWasteMountedItemData([...wasteMountedItemData]);
    setValue("DocumentWasteCodeMountedItems", [...wasteMountedItemData], { shouldDirty: true });
    setIndex(0);
  };

  const handleRowClick = (idx: any) => {
    setIndex(idx + 1);
    setRowColorRequired(true);
  };

  const handleAdd = () => {
    setIndex(-1);
    setShowAdd(true);
  };

  function getWasteCodeValue(item: item | undefined) {
    if (item) {
      return item.Code + " - " + item.Name;
    }

    return "";
  }

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item md={11} sx={{ mt: 3 }}>
          <Paper sx={{ overflow: "hidden" }}>
            <TableContainer sx={{ width: "100%" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="body1">
                        <b>{get("documentWasteCodeMounteds")}</b>
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {wasteMountedItemData?.map((item, idx) => (
                    <TableRow
                      key={idx}
                      onClick={() => handleRowClick(idx)}
                      sx={{
                        backgroundColor: rowColor && idx + 1 === index ? "#b1b8b8" : "",
                        cursor: "pointer",
                      }}
                    >
                      <TableCell>
                        {getWasteCodeValue(
                          wasteCodeDataValues?.Items?.find((x) => x.WasteCodeId === item.WasteCodeMountedId),
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid item md={1} sx={{ mt: 3 }}>
          <Stack direction="column" spacing={2} sx={{ float: "right" }}>
            <Button
              variant="contained"
              onClick={handleAdd}
              disabled={DocumentIsReadOnly}
              size="small"
              sx={{ width: "36px", minWidth: "0px" }}
            >
              <AddIcon />
            </Button>
            <Button
              color="error"
              disabled={index === 0 || DocumentIsReadOnly}
              onClick={() => remove(index)}
              size="small"
              sx={{ m: 1, width: "36px", minWidth: "0px" }}
            >
              <DeleteIcon />
            </Button>
          </Stack>
        </Grid>
        <ConfirmationModal
          show={showAdd}
          handleClose={() => handleModalClose()}
          handleConfirmation={() => handleSubmit(handleConfirmation)()}
          title={get("wasteManagementMountedTableTitle")}
          saveButtonName={get("saveButtonText")}
          cancelButtonName={get("cancelButtonText")}
          modalHeight="360px"
        >
          <MountedArticleTableForm
            handleSubmit={handleSubmit}
            errors={errorsMountedItem}
            setValue={setDocumentMountedWasteCodeValue}
            reset={reset}
            data={undefined}
            key={index}
          />
        </ConfirmationModal>
      </Grid>
    </>
  );
};
