import { Box, Typography } from "@mui/material";
import { CustomBreadcrumbs } from "../CustomBreadcrumbs/CustomBreadcrumbs";

type Props = {
  title?: string;
};

export const MainBanner = ({ title }: Props) => {
  return (
    <Box
      sx={{
        pb: 0,
        height: "150px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          px: 4,
          py: 4,
        }}
      >
        <CustomBreadcrumbs />
      </Box>
      <Box
        sx={{
          color: "white",
          px: 4,
          maxWidth: "70%",
        }}
      >
        <Typography variant="h4">{title}</Typography>
      </Box>
    </Box>
  );
};
