export const useECNumberFormat = () => {
  const ecNumberFunction = (numArray: any) => {
    let numArrayValue = numArray.map((i: string) => Number(i));
    let ecNumberSum =
      (numArrayValue[0] +
        2 * numArrayValue[1] +
        3 * numArrayValue[2] +
        4 * numArrayValue[3] +
        5 * numArrayValue[4] +
        6 * numArrayValue[5]) %
      11;
    if (ecNumberSum === numArrayValue[6]) {
      return false;
    } else {
      return true;
    }
  };

  const ecNumberFormat = (ecnoData?: string) => {
    if (ecnoData === null) {
      return true;
    }
    let num = ecnoData?.replace(/\D/g, "");
    let numArray = num?.split("");
    return ecNumberFunction(numArray);
  };
  const caschecksumFunction = (numArray: any) => {
    let casNumberSum = 0;
    numArray.forEach((item: any, index: number) => {
      if (index !== 0) {
        casNumberSum = casNumberSum + parseInt(item) * index;
      }
    });
    casNumberSum = casNumberSum % 10;
    if (casNumberSum.toString() === numArray[0]) {
      return false;
    } else {
      return true;
    }
  };
  const casCheckSum = (casNum: string) => {
    if (casNum === null) {
      return true;
    }
    let num = casNum.replace(/\D/g, "");
    let numArray = num.split("").reverse();
    return caschecksumFunction(numArray);
  };
  return {
    ecNumberFormat,
    casCheckSum,
  };
};
