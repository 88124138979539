import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Box, Divider } from "@mui/material";

type Props = {
  title: string;
  show: boolean;
  handleClose: () => void;
  handleConfirmation: () => void;
  children?: React.ReactNode;
  saveButtonName: string;
  cancelButtonName: string;
  modalHeight?: string;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export const ConfirmationDeleteModal = ({
  title,
  show,
  handleClose,
  handleConfirmation,
  children,
  saveButtonName,
  cancelButtonName,
  modalHeight,
}: Props) => {
  return (
    <>
      <Dialog
        open={show}
        TransitionComponent={Transition}
        keepMounted
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
            },
          },
        }}
      >
        <DialogTitle>{title}</DialogTitle>
        <Divider />
        <DialogContent sx={{ height: modalHeight }}>{children}</DialogContent>
        <DialogActions>
          <Box sx={{ textAlign: "right", px: 2 }}>
            <Button onClick={handleClose} variant="outlined" type="submit" sx={{ m: "1rem 1rem" }}>
              {cancelButtonName}
            </Button>
            <Button onClick={handleConfirmation} variant="contained" type="submit" sx={{ m: "1rem 0rem" }}>
              {saveButtonName}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};
