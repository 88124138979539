import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { HeaderLinks } from "./HeaderLinks/HeaderLinks";
import { ProfileButton } from "./ProfileButton/ProfileButton";
import { LanguageSelector } from "./LanguageSelector/LanguageSelector";
import eBVDLogo from "../../assets/ebvd.png";
import { Help } from "./Help/Help";
import { useNavigate } from "react-router-dom";
import { AboutLink } from "./About/AboutLink";
import { useTranslation } from "../../helpers/useTranslation";
import { UnauthenticatedTemplate } from "@azure/msal-react";

export const Header = () => {
  const navigate = useNavigate();
  const { get } = useTranslation();

  return (
    <Container maxWidth={false} sx={{ p: 0, background: "white" }}>
      <Container maxWidth="xl">
        <AppBar position="static" sx={{ p: 2, background: "white", color: "black", boxShadow: 0 }}>
          <Toolbar disableGutters sx={{ marginTop: 3, marginBottom: 3 }}>
            <Typography variant="h6" color="black" noWrap component="div" sx={{ mr: 2, display: { xs: "flex" } }}>
              <Box
                src={eBVDLogo}
                component="img"
                onClick={() => navigate("/")}
                sx={{
                  height: "60px",
                  px: 2,
                  cursor: "pointer",
                }}
              />
            </Typography>
            <Box sx={{ flexGrow: 0, display: "flex" }}>
              <HeaderLinks />
            </Box>
            <Box sx={{ flex: 1, textAlign: "-webkit-center" }} mx="1rem">
              {/* <HeaderSearch /> */}
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              <AboutLink />
            </Box>
            <Box sx={{ flexGrow: 0 }} display="none">
              <Help />
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              <LanguageSelector />
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              <ProfileButton />
            </Box>
            <UnauthenticatedTemplate>
              <Box sx={{ flexGrow: 0 }} mx="1rem">
                <a href="/createaccount">
                  <Typography color="black">{get("headerLinkCreateAccount")}</Typography>
                </a>
              </Box>
            </UnauthenticatedTemplate>
          </Toolbar>
        </AppBar>
      </Container>
    </Container>
  );
};
