import {
  Alert,
  AlertTitle,
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { useTranslation } from "../../../../helpers/useTranslation";
import { CustomTextField } from "../../../../components/CustomTextField/CustomTextField";
import { useGetCountries } from "../../../../services/countryService";
import { Controller, useFormContext } from "react-hook-form";
import { useRef, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useUploadUserGroupLogo, useDeleteUserGroupLogo } from "../../../../services/userGroupService";
import { useParams } from "react-router";
type Props = {
  data?: UserGroup;
};
type Params = {
  id: string;
};
export const Company = ({ data }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { id } = useParams<Params>();
  const [file, setFile] = useState({ name: "Logo" } as any);
  const [islogoUpdated, setLogoUpdated] = useState(data?.LogoUrl ? "Logo" : "");
  const { mutateAsync: uploadLogo } = useUploadUserGroupLogo();
  const { mutateAsync: deleteLogo } = useDeleteUserGroupLogo();

  const { get } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useFormContext();
  const { data: countriesDataValues } = useGetCountries();

  const handleClick = (e: any) => {
    if (inputRef.current != null) {
      inputRef.current.click();
    }
  };

  const handleCloseClick = (e: any) => {
    deleteLogo({
      UserGroupId: id,
    }).then(() => {
      setLogoUpdated("");
      setFile("");
    });
  };
  function handleUpload(event: any) {
    setFile(event.target.files[0]);
    uploadLogo({
      UserGroupId: id,
      Files: event.target.files[0],
      Extension: event.target.files[0].name.split(".").pop(),
    }).then(() => {
      event.target.value = "";
      setLogoUpdated("newLogo");
    });
  }
  const ImageThumb = ({ image }: any) => {
    return (
      <>
        <Box
          sx={{
            width: "150px",
          }}
          component="img"
          alt="Logo"
          src={image}
        ></Box>
        <IconButton color="secondary" onClick={handleCloseClick} sx={{ position: "absolute" }}>
          <HighlightOffIcon />
        </IconButton>
      </>
    );
  };

  return (
    <form onSubmit={handleSubmit as any}>
      <Grid container rowSpacing={1} columnSpacing={{ md: 3 }}>
        <Grid item md={12} sx={{ mt: 3 }}>
          <CustomTextField
            name={"Name"}
            isDisabled={false}
            controlValue={control}
            defaultValue={data?.Name}
            label={get("fieldCompanyName")}
            errors={errors}
          />
        </Grid>
        <Grid item md={3} sx={{ mt: 3 }}>
          <CustomTextField
            name={"OrgNo"}
            isDisabled={true}
            controlValue={control}
            defaultValue={data?.OrgNo}
            label={get("fieldCorporateIDNO")}
          />
        </Grid>
        <Grid item md={3} sx={{ mt: 3 }}>
          <CustomTextField
            name={"VatNo"}
            isDisabled={true}
            controlValue={control}
            defaultValue={data?.VatNo}
            label={get("fieldVATNumber")}
          />
        </Grid>
        <Grid item md={3} sx={{ mt: 3 }}>
          <CustomTextField
            name={"GLN"}
            isDisabled={true}
            controlValue={control}
            defaultValue={data?.GLN}
            label={get("fieldGLN")}
          />
        </Grid>
        <Grid item md={3} sx={{ mt: 3 }}>
          <CustomTextField
            name={"DUNS"}
            isDisabled={true}
            controlValue={control}
            defaultValue={data?.DUNS}
            label={get("fieldDUNS")}
          />
        </Grid>
        <Grid item md={3} sx={{ mt: 3 }}>
          <CustomTextField
            name={"AreaCode"}
            isDisabled={false}
            controlValue={control}
            defaultValue={data?.AreaCode}
            label={get("fieldAreaCode")}
          />
        </Grid>
        <Grid item md={9} sx={{ mt: 3 }}>
          <CustomTextField
            name={"City"}
            isDisabled={false}
            controlValue={control}
            defaultValue={data?.City}
            label={get("fieldCity")}
          />
        </Grid>
        <Grid item md={6} sx={{ mt: 3 }}>
          <CustomTextField
            name={"Address"}
            isDisabled={false}
            controlValue={control}
            defaultValue={data?.Address}
            label={get("fieldAddress")}
          />
        </Grid>
        <Grid item md={6} sx={{ mt: 3 }}>
          <CustomTextField
            name={"HomePage"}
            isDisabled={false}
            controlValue={control}
            defaultValue={data?.HomePage}
            label={get("fieldWebsite")}
          />
        </Grid>
        <Grid item md={6} sx={{ mt: 3 }}>
          <CustomTextField
            name={"Reference"}
            isDisabled={false}
            controlValue={control}
            defaultValue={data?.Reference}
            label={get("fieldContact")}
            errors={errors}
          />
        </Grid>
        <Grid item md={3} sx={{ mt: 3 }}>
          <CustomTextField
            name={"Phone"}
            isDisabled={false}
            controlValue={control}
            defaultValue={data?.Phone}
            label={get("fieldTelephone")}
          />
        </Grid>
        <Grid item md={3} sx={{ mt: 3 }}>
          <CustomTextField
            name={"Email"}
            isDisabled={false}
            controlValue={control}
            defaultValue={data?.Email}
            label={get("fieldEMail")}
          />
        </Grid>
        <Grid item md={6} sx={{ mt: 3, mb: 3 }}>
          <Controller
            name="CountryId"
            control={control}
            defaultValue={data?.CountryId || ""}
            render={({ field: { onChange, value } }) => (
              <FormControl sx={{ width: "100%" }}>
                <InputLabel shrink>{get("rawMaterialModalCountryRawMaterialTitle")}</InputLabel>
                <Select
                  value={value || ""}
                  name="CountryId"
                  onChange={onChange}
                  input={<OutlinedInput notched label={get("rawMaterialModalCountryRawMaterialTitle")} />}
                >
                  {countriesDataValues?.Items?.map((item: Item) => (
                    <MenuItem key={item.CountryId} value={item.CountryId}>
                      {item.Name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Grid>
        <Grid container item sx={{ mb: 3 }}>
          <Grid item md={5}>
            <CustomTextField
              name={"Logo"}
              isDisabled={true}
              controlValue={control}
              defaultValue={get("fieldLogoValue")}
              label={get("fieldLogo")}
            />
          </Grid>
          <Grid item md={1} sx={{ display: "flex", height: "55px", justifyContent: "space-between" }}>
            <input
              ref={inputRef}
              style={{ display: "none" }}
              accept="image/x-png,image/gif,image/jpeg,image/jpe,image/jpg,.svg,.svgz"
              type="file"
              onChange={handleUpload}
            />
            <Button onClick={handleClick} variant="outlined">
              <EditIcon />
            </Button>
          </Grid>
          <Grid item md={5}>
            {islogoUpdated === "newLogo" && <ImageThumb image={URL.createObjectURL(file)} />}
            {islogoUpdated === "Logo" && <ImageThumb image={data?.LogoUrl} />}
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
