import { Typography } from "@mui/material";
import { FieldError, FieldErrors, Merge } from "react-hook-form";

type Props = {
  errorMessage: string | FieldError | Merge<FieldError, FieldErrors<any>> | undefined;
};

export const CustomErrorTypography = ({ errorMessage }: Props) => {
  return (
    <Typography variant="subtitle1" sx={{ color: "error.main", margin: "3px 12px 0px 12px" }}>
      {errorMessage}
    </Typography>
  );
};
