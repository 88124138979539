import { useParams } from "react-router";
import { PageLayout } from "../../../components/PageLayout/PageLayout";
import { useTranslation } from "../../../helpers/useTranslation";
import { CustomTab } from "../../../components/CustomTab/CustomTab";
import { Information } from "./Information/Information";
import { UserInvitation } from "./UserInviation/UserInvitation";
import { DigitalTransfer } from "./DigitalTransfer/DigitalTransfer";
import { PublicAccess } from "./PublicAccess/PublicAccess";
import { User } from "./User/User";
import { useGetUserGroup } from "../../../services/userGroupService";
import { Loader } from "../../../components/Loader/Loader";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

type Params = {
  id: string;
};
export const OrganizationDetail = () => {
  const { get } = useTranslation();
  const { id } = useParams<Params>();
  const { data: userGroup, isLoading } = useGetUserGroup(id);
  const appInsights = useAppInsightsContext();
  const pageView = {
    name: "OrganizationDetail",
  };
  appInsights.trackPageView(pageView);

  const tabs = [
    {
      labelKey: get("organizationSectionInformation"),
      component: <Information data={userGroup} />,
    },
    {
      labelKey: get("organizationSectionUser"),
      component: <User data={userGroup?.Users} />,
    },
    {
      labelKey: get("organizationSectionUserInvitation"),
      component: <UserInvitation data={userGroup?.UserInvitations} />,
    },
    {
      labelKey: get("organizationSectionDigitalTransfer"),
      component: <DigitalTransfer data={userGroup?.UserGroups} />,
    },
    {
      labelKey: get("organizationSectionPublicAcces"),
      component: <PublicAccess data={userGroup} />,
    },
  ];

  if (isLoading) return <Loader />;

  return (
    <PageLayout title={`Admin: ${userGroup?.Name}`}>
      <CustomTab tabs={tabs} />
    </PageLayout>
  );
};
