import { format as formatDateFns, parseISO } from "date-fns";

export const useDateFormat = () => {
  const formatString = "yyyy-MM-dd HH:mm";
  const formatDateString = "yyyy-MM-dd";

  const format = (date: Date | string | undefined, type?: string) => {
    if (!date) {
      return "";
    }
    switch (type) {
      case "formatDateString": {
        if (date instanceof Date) {
          return formatDateFns(date, formatDateString);
        } else {
          return formatDateFns(parseISO(date), formatDateString);
        }
      }
      default: {
        if (date instanceof Date) {
          return formatDateFns(date, formatString);
        } else {
          return formatDateFns(parseISO(date), formatString);
        }
      }
    }
  };

  return {
    format,
  };
};
