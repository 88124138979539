import { useState } from "react";
import { Button, Grid, Stack, Tooltip } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useTranslation } from "../../../../../helpers/useTranslation";
import { Loader } from "../../../../../components/Loader/Loader";
import { GetTransactionItems } from "../../../../../services/TransactionItemService";
import { TransactionItemTable } from "../../../../Import/ImportPage/TransactionItemTable/TransactionItemTable";

type Props = {
  data?: UserGroup;
};

export const PublishingTransactions = ({ data = {} as UserGroup }: Props) => {
  const { get } = useTranslation();
  const [transactionInProgress, setTransactionInProgress] = useState<boolean>(true);

  const { data: transactionItems, refetch } = GetTransactionItems({
    UserGroupIds: [data.UserGroupId],
    TransactionItemTypeIds: [2, 3],
  });

  if (!data) {
    return <Loader />;
  }

  return (
    <>
      <Grid container sx={{ mt: 0, mb: 2 }} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item md={11}>
          <TransactionItemTable
            transactionItems={transactionItems}
            refetch={refetch}
            transactionInProgress={transactionInProgress}
            setTransactionInProgress={setTransactionInProgress}
          ></TransactionItemTable>
        </Grid>
        <Grid item md={1}>
          <Stack direction="column" spacing={2} sx={{ float: "right" }}>
            <Tooltip title={get("importRefetchListTooltip")} placement="left">
              <Button
                variant="contained"
                size="small"
                sx={{ m: 1, width: "36px", minWidth: "0px" }}
                onClick={() => refetch()}
              >
                <RefreshIcon />
              </Button>
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
