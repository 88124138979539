import { Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { InformationAccess } from "../../../../../components/InformationAccess/InformationAccess";
type Props = {
  data?: UserGroupDigital;
};
export const DigitalTransferInformationAccess = ({ data }: Props) => {
  const { handleSubmit, setValue } = useFormContext<UserGroupDigital>();
  return (
    <form onSubmit={handleSubmit as any}>
      <Grid container sx={{ mt: 2 }} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item md={12}>
          <InformationAccess setValue={setValue} data={data?.InformationPermissions} />
        </Grid>
      </Grid>
    </form>
  );
};
