import { Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ReactNode } from "react";

type Props = {
  children?: ReactNode;
  title?: string;
  index: number;
  length: number;
};

export const ComponentLayout = ({ title, children, index, length }: Props) => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          gap: 1,
          py: 1,
        }}
      >
        <Typography
          variant="body1"
          color="black"
          sx={{
            my: "auto",
          }}
        >
          {index}/{length}
        </Typography>
        <Divider orientation="vertical" variant="fullWidth" flexItem />
        <Typography variant="h5" color="black">
          {title}
        </Typography>
      </Box>
      {children}
    </Box>
  );
};
