import { useForm, useFormContext } from "react-hook-form";
import { useState } from "react";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { yupResolver } from "@hookform/resolvers/yup";
import { ConfirmationModal } from "../../../../../../components/ConfirmationModal/ConfirmationModal";
import { AttachmentsForm } from "./AttachmentsForm";
import { useTranslation } from "../../../../../../helpers/useTranslation";
import { useFormValidation } from "../../../../../../helpers/useFormValidation";

type Props = {
  DocumentIsReadOnly: boolean;
};

export const Attachments = ({ DocumentIsReadOnly }: Props) => {
  const { attachmentValidationSchema } = useFormValidation();
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors: attachmentError },
  } = useForm<DocumentAttachments>({ resolver: yupResolver(attachmentValidationSchema) });
  const { setValue, getValues } = useFormContext();
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [attachmentId, setAttachmentId] = useState(0);
  const { get } = useTranslation();
  const [attachmentData, setAttachmentData] = useState<DocumentAttachments[]>(getValues("DocumentAttachments") || []);
  const [rowColor, setRowColorRequired] = useState(false);

  const handleConfirmation = (data: DocumentAttachments) => {
    setAttachmentData([...attachmentData, data]);
    setRowColorRequired(false);
    setShowAdd(false);
    setAttachmentId(0);
    setValue("DocumentAttachments", [...attachmentData, data], { shouldDirty: true });
    reset();
  };
  const handleUpdateConfirmation = (data: DocumentAttachments) => {
    attachmentData[attachmentId - 1] = data;
    setAttachmentData([...attachmentData]);
    setShowEdit(false);
    setValue("DocumentAttachments", [...attachmentData], { shouldDirty: true });
    setAttachmentId(0);
    reset();
  };
  const remove = (index: number) => {
    if (index !== 0) attachmentData.splice(index - 1, 1);
    setAttachmentData([...attachmentData]);
    setValue("DocumentAttachments", [...attachmentData], { shouldDirty: true });
    setAttachmentId(0);
  };
  const handleModalClose = () => {
    setShowAdd(false);
    setAttachmentId(0);
    setRowColorRequired(false);
    reset();
  };

  const handleEditModalClose = () => {
    setShowEdit(false);
    setAttachmentId(0);
    setRowColorRequired(false);
    reset();
  };

  const handleRowClick = (idx: number) => {
    setAttachmentId(idx + 1);
    setRowColorRequired(true);
  };

  const handleAdd = () => {
    setAttachmentId(-1);
    setShowAdd(true);
  };

  return (
    <Grid container sx={{ mt: 0, mb: 2 }} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item md={11}>
        <Paper sx={{ overflow: "hidden" }}>
          <TableContainer sx={{ height: 300, width: "100%" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="body1">
                      <b>{get("attachmentBlockTitle")}</b>
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {attachmentData?.map((item, idx) => (
                  <TableRow key={idx} onClick={() => handleRowClick(idx)}>
                    <TableCell
                      scope="row"
                      sx={{
                        backgroundColor: rowColor && idx + 1 === attachmentId ? "#b1b8b8" : "",
                        cursor: "pointer",
                      }}
                    >
                      {item.Attachment}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
      <Grid item md={1}>
        <Stack direction="column" spacing={2} sx={{ float: "right" }}>
          <Button
            variant="contained"
            disabled={DocumentIsReadOnly}
            onClick={handleAdd}
            size="small"
            sx={{ width: "36px", minWidth: "0px" }}
          >
            <AddIcon />
          </Button>
          <Button
            variant="contained"
            onClick={() => setShowEdit(true)}
            size="small"
            disabled={attachmentId === 0 || DocumentIsReadOnly}
            sx={{ m: 1, width: "36px", minWidth: "0px" }}
          >
            <EditIcon />
          </Button>
          <Button
            color="error"
            size="small"
            sx={{ m: 1, width: "36px", minWidth: "0px" }}
            disabled={attachmentId === 0 || DocumentIsReadOnly}
            onClick={() => remove(attachmentId)}
          >
            <DeleteIcon />
          </Button>
        </Stack>
      </Grid>

      <ConfirmationModal
        show={showAdd}
        handleClose={() => handleModalClose()}
        handleConfirmation={() => handleSubmit(handleConfirmation)()}
        title={get("addAttachment")}
        saveButtonName={get("saveButtonText")}
        cancelButtonName={get("cancelButtonText")}
      >
        <AttachmentsForm
          handleSubmit={handleSubmit}
          control={control}
          data={null}
          errors={attachmentError}
          reset={reset}
        />
      </ConfirmationModal>
      <ConfirmationModal
        show={showEdit}
        handleClose={() => handleEditModalClose()}
        handleConfirmation={() => handleSubmit(handleUpdateConfirmation)()}
        title={get("editAttachment")}
        saveButtonName={get("saveButtonText")}
        cancelButtonName={get("cancelButtonText")}
      >
        <AttachmentsForm
          handleSubmit={handleSubmit}
          control={control}
          data={attachmentData[attachmentId - 1]}
          errors={attachmentError}
          reset={reset}
        />
      </ConfirmationModal>
    </Grid>
  );
};
