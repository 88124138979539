import React, { useState, useMemo } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Paper,
  Checkbox,
  Button,
  TablePagination,
  Tooltip,
  useMediaQuery,
  Typography,
  Grid,
  Alert,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import { RowData } from "../../data";
import { useTranslation } from "../../../../helpers/useTranslation";
import { CreateNewArticleModal } from "../Modals/CreateNewArticleModal";
import { CreateNewResourceModal } from "../Modals/CreateNewResourceModal";
import { useTheme } from "@mui/material/styles";
import { UnauthenticatedTemplate, useIsAuthenticated } from "@azure/msal-react";

interface TableSectionProps {
  rows: RowData[];
  onRowClick?: (id: number) => void;
  columns?: Array<keyof RowData>;
  modalType?: "createArticle" | "createResource";
  test?: string;
  title?: string;
  alertTitle?: string;
}

interface SortConfig {
  key: keyof RowData;
  direction: "asc" | "desc";
}

export const TableSection: React.FC<TableSectionProps> = ({
  alertTitle,
  title,
  rows,
  onRowClick,
  columns,
  modalType,
}) => {
  const isAuth = useIsAuthenticated();
  const { get } = useTranslation();
  const [filter, setFilter] = useState({ productName: "", epdOwner: "" });
  const [sortConfig, setSortConfig] = useState<SortConfig>({ key: "productName", direction: "asc" });
  const [isModalOpen, setModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Theme and media query for responsive design
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleFilterChange = (field: keyof typeof filter) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setFilter((prev) => ({ ...prev, [field]: value }));
  };

  const handleSort = (key: keyof RowData) => {
    const direction = sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredRows = useMemo(() => {
    return rows.filter(
      (row) =>
        row?.productName?.toLowerCase().includes(filter.productName.toLowerCase()) &&
        row?.epdOwner?.toLowerCase().includes(filter.epdOwner.toLowerCase()),
    );
  }, [rows, filter]);

  const sortedRows = useMemo(() => {
    return [...filteredRows].sort((a, b) => {
      const aValue = a[sortConfig.key] ?? "";
      const bValue = b[sortConfig.key] ?? "";
      if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
      return 0;
    });
  }, [filteredRows, sortConfig]);

  const paginatedRows = useMemo(() => {
    const start = page * rowsPerPage;
    const end = start + rowsPerPage;
    return sortedRows.slice(start, end);
  }, [sortedRows, page, rowsPerPage]);

  const columnKeys = columns || Object.keys(rows[0] || {}).filter((key) => key !== "id");

  const handleDownloadTemplate = async () => {
    const response = await fetch("/api/downloadTemplate");
    if (response.ok) {
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "template.xlsx";
      link.click();
    } else {
      console.error("Failed to download template");
    }
  };

  return (
    <>
      <Box display="flex" mb={2} gap={1} justifyContent={"flex-end"}>
        {modalType === "createResource" && (
          <Tooltip title={get("importDownloadExcelTemplateTooltip")} placement="left">
            <Button
              onClick={handleDownloadTemplate}
              disabled={!isAuth}
              variant="contained"
              size="small"
              sx={{ width: isSmallScreen ? "100%" : "36px", minWidth: "0px", height: "37px" }}
            >
              <DownloadIcon />
            </Button>
          </Tooltip>
        )}
        <Button
          variant="contained"
          color="primary"
          disabled={!isAuth}
          endIcon={<AddIcon />}
          onClick={handleModalOpen}
          sx={{ height: "37px", width: isSmallScreen ? "100%" : "auto" }}
        >
          {modalType === "createResource" ? get("EpdCreateNewArticle") : get("EpdCreateNewResource")}
        </Button>
      </Box>
      <Paper sx={{ padding: "20px" }}>
        <Typography display={"flex"} variant="h4" gutterBottom>
          {title}
          <UnauthenticatedTemplate>
            <Grid sx={{ ml: 3 }}>
              <Alert severity="info">{alertTitle}</Alert>
            </Grid>
          </UnauthenticatedTemplate>
        </Typography>
        <Box role="tabpanel" sx={{ maxWidth: "100%", padding: "20px 0px" }}>
          <Box
            display="flex"
            justifyContent="space-between"
            gap={2}
            mb={2}
            flexDirection={isSmallScreen ? "column" : "row"}
          >
            <Box display="flex" gap={2} flexDirection={isSmallScreen ? "column" : "row"} mb={2}>
              <TextField
                label={get("EpdFilterName")}
                variant="outlined"
                size="small"
                value={filter.productName}
                onChange={handleFilterChange("productName")}
              />
              <TextField
                label={get("EpdFilterOwner")}
                variant="outlined"
                size="small"
                value={filter.epdOwner}
                onChange={handleFilterChange("epdOwner")}
              />
            </Box>
          </Box>

          {modalType === "createResource" ? (
            <CreateNewArticleModal isOpen={isModalOpen} onClose={handleModalClose} />
          ) : (
            <CreateNewResourceModal isOpen={isModalOpen} onClose={handleModalClose} />
          )}

          <TableContainer
            component={Paper}
            sx={{
              borderRadius: 2,
              boxShadow: 3,
              mt: 2,

              overflowX: "auto",
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow sx={{ bgcolor: "#f9f9f9" }}>
                  {columnKeys.map((key) => (
                    <TableCell
                      key={key}
                      sx={{
                        fontWeight: 600,
                        minWidth: isSmallScreen ? 100 : 200,
                        whiteSpace: "nowrap",
                      }}
                    >
                      <TableSortLabel
                        active={sortConfig.key === key}
                        direction={sortConfig.direction}
                        onClick={() => handleSort(key as keyof RowData)}
                      >
                        {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, " $1")}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedRows.map((row, index) => (
                  <TableRow
                    key={row.resourceId}
                    hover
                    sx={{
                      cursor: "pointer",
                      backgroundColor: index % 2 === 0 ? "background.paper" : "#fafafa",
                    }}
                    onClick={() => onRowClick && onRowClick(row.resourceId)}
                  >
                    {columnKeys.map((key) => (
                      <TableCell key={key} sx={{ minWidth: isSmallScreen ? 100 : 200 }}>
                        {key === "publicPrivate" ? (
                          <Checkbox checked={row[key] as boolean} disabled />
                        ) : (
                          row[key as keyof RowData] ?? "-"
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            component="div"
            count={sortedRows.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[1, 2, 5, 10]}
            labelRowsPerPage={get("EpdRowsPerPage")}
            sx={{
              ".MuiTablePagination-toolbar": {
                flexDirection: isSmallScreen ? "column" : "row",
              },
            }}
          />
        </Box>
      </Paper>
    </>
  );
};
