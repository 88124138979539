import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

type Props = {
  name: string;
  id: string;
};

export const RecentItem = ({ name, id }: Props) => {
  return (
    <div>
      <Typography variant="h6" color="black" sx={{ textDecoration: "none" }} component={Link} to={`/documents/${id}`}>
        {name}
      </Typography>
    </div>
  );
};
