import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Grid, Tooltip, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "../../../../helpers/useTranslation";
import { ConfirmationModal } from "../../../../components/ConfirmationModal/ConfirmationModal";
import { DigitalTransferDetail } from "./DigitalTransferDetail/DigitalTransferDetail";
import { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import {
  useGetDigitalTransfer,
  useUpdateUserGroups,
  useAddUserGroup,
  useDeleteUserGroupUserGroup,
  useGetUserGroup,
} from "../../../../services/userGroupService";
import { useParams } from "react-router";

type Props = {
  data?: UserGroupDigital[];
};
type Params = {
  id: string;
};
export const DigitalTransfer = ({ data }: Props) => {
  const { mutateAsync: updateUserDigital } = useUpdateUserGroups();
  const { mutateAsync: addUserGroup } = useAddUserGroup();
  const { mutateAsync: deleteUserGroup } = useDeleteUserGroupUserGroup();
  const methods = useForm<UserGroupDigital>();

  const { get } = useTranslation();
  const { id } = useParams<Params>();
  const [showUserDetail, setShowUserDetail] = useState(false);
  const [selectedDigitalId, setSelectedDigitalId] = useState(0);
  const [selectedDigitalIsEditableId, setSelectedDigitalIsEditableId] = useState(0);
  const { data: digitalData } = useGetDigitalTransfer(id, selectedDigitalIsEditableId);
  const { data: usergroupData } = useGetUserGroup(id);

  useEffect(() => {
    console.log("usergroupData", usergroupData);
  }, [usergroupData]);

  const handleRowClick = (userGroupId: number, isSelectableUserGroup: boolean) => {
    setSelectedDigitalId(() => userGroupId);
    if (isSelectableUserGroup) {
      setSelectedDigitalIsEditableId(userGroupId);
    }
  };
  const handleUserDetailModalClose = () => {
    setShowUserDetail(false);
  };
  const handleUserDigital = () => {
    methods.reset(digitalData);
    setShowUserDetail(true);
  };
  const handleUpdateConfirmation = (data: UserGroupDigital) => {
    updateUserDigital(data);
    setShowUserDetail(false);
  };
  const handleChange = (event: any, userGroupId: number) => {
    setSelectedDigitalId(() => userGroupId);
    if (event.target.checked) {
      let organizationId = id || "0";

      const data = {
        UserGroupId: parseInt(organizationId),
        WebApiConsumerUserGroupId: userGroupId,
      } as UserGroupDigital;

      addUserGroup(data);
    } else {
      deleteUserGroup({
        UserGroupId: id,
        WebApiConsumerUserGroupId: userGroupId,
      });
    }
  };
  return (
    <Grid container sx={{ mt: 0, mb: 2 }} spacing={3} rowSpacing={1} columnSpacing={7}>
      <Grid item xs={11} sm={11}>
        <Paper sx={{ overflow: "hidden" }}>
          <TableContainer sx={{ width: "100%" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {/* <TableCell /> */}
                  <TableCell>
                    <Typography variant="body1">
                      <b>{get("digitalTransferName")}</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      <b>{get("digitalTransferInformationAccess")}</b>
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data?.map((item) => (
                  <TableRow
                    key={item.UserGroupId}
                    onClick={() => handleRowClick(item.UserGroupId, item.IsSelected)}
                    sx={{
                      backgroundColor: selectedDigitalId === item.UserGroupId ? "#b1b8b8" : "",
                      cursor: "pointer",
                    }}
                  >
                    {/* <TableCell>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="ExistingArticleCheckboxItem"
                            size="small"
                            // onChange={(event) => handleChange(event, item.UserGroupId)}
                            checked={item.IsSelected}
                            disabled={true}
                          />
                        }
                        label={""}
                      />
                    </TableCell> */}
                    <TableCell>{item.UserGroupName}</TableCell>
                    <TableCell>{item.InformationPermissionsDescription}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
      <Grid item md={1}>
        <Stack direction="column" sx={{ float: "right" }}>
          <Tooltip title={get("tooltipEditDigitalTransfer")} placement="left">
            <Button
              variant="contained"
              size="small"
              sx={{ width: "36px", minWidth: "0px" }}
              disabled={!data?.some((x: UserGroupDigital) => x.UserGroupId === selectedDigitalId && x.IsSelected)}
              onClick={handleUserDigital}
            >
              <EditIcon />
            </Button>
          </Tooltip>
        </Stack>
      </Grid>
      <FormProvider {...methods}>
        <ConfirmationModal
          maxWidth="md"
          show={showUserDetail}
          handleClose={() => handleUserDetailModalClose()}
          handleConfirmation={() => methods.handleSubmit(handleUpdateConfirmation)()}
          title={digitalData ? digitalData?.UserGroupName + " : " + digitalData?.WebApiConsumerUserGroupName : ""}
          saveButtonName={get("saveButtonText")}
          cancelButtonName={get("cancelButtonText")}
        >
          <DigitalTransferDetail data={digitalData} />
        </ConfirmationModal>
      </FormProvider>
    </Grid>
  );
};
