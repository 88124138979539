import { useEffect } from "react";
import { Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "../../../../../../helpers/useTranslation";
import { CustomTextField } from "../../../../../../components/CustomTextField/CustomTextField";
import { CustomErrorTypography } from "../../../../../../components/CustomTypography/CustomErrorTypography";

type Props = {
  data?: ProductContentComponentForTree;
};

export const ArticleSubComponentTableRowForm = ({ data }: Props) => {
  const {
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useFormContext();
  const { get } = useTranslation();

  useEffect(() => {
    reset({
      ProductContentComponentId: data?.ProductContentComponentId,
      ProductContentComponentType: data?.ProductContentComponentType,
      Name: data?.Name,
    });
  }, [data, reset]);

  return (
    <>
      <form onSubmit={handleSubmit as any}>
        <Grid container sx={{ mt: 2 }} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6} sx={{ mb: 2 }}>
            <CustomTextField
              name="Name"
              isDisabled={false}
              controlValue={control}
              defaultValue={data?.Name || ""}
              label={get("name")}
              errors={errors}
            />
            <CustomErrorTypography errorMessage={errors?.Component && errors.Component.message} />
          </Grid>
        </Grid>
      </form>
    </>
  );
};
