import { useState } from "react";
import PropTypes from "prop-types";
import Cookies from "../Cookies";
import { CookieBannerContent } from "./CookieBannerContent";

const CONSENT_GIVEN = "rcl_consent_given";
const PREFERENCES_COOKIE = "rcl_preferences_consent";
const STATISTICS_COOKIE = "rcl_statistics_consent";
const MARKETING_COOKIE = "rcl_marketing_consent";
type Props = {
  message: string;
  wholeDomains: boolean;
  policyLink: string;
  privacyPolicyLinkText: string;
  necessaryOptionText: string;
  preferencesOptionText: string;
  statisticsOptionText: string;
  marketingOptionText: string;
  showMarketingOption: boolean;
  acceptButtonText: string;
  declineButtonText: string;
  managePreferencesButtonText: string;
  savePreferencesButtonText: string;
};
function useForceUpdate() {
  let [value, setState] = useState(true);
  return () => setState(!value);
}

export const CookieBanner = ({
  message,
  wholeDomains,
  policyLink,
  privacyPolicyLinkText,
  necessaryOptionText,
  preferencesOptionText,
  statisticsOptionText,
  marketingOptionText,
  showMarketingOption,
  acceptButtonText,
  declineButtonText,
  managePreferencesButtonText,
  savePreferencesButtonText,
}: Props) => {
  const [preferencesCookies, setPreferencesChecked] = useState(false);
  const [statisticsCookies, setStatisticsChecked] = useState(false);
  const [marketingCookies, setMarketingChecked] = useState(false);
  const cookies = new Cookies(wholeDomains || false);
  const handleForceupdateMethod = useForceUpdate();

  const onTogglePreferencesCookies = (value: boolean) => {
    setPreferencesChecked(value);
  };

  const onToggleStatisticsCookies = (value: boolean) => {
    setStatisticsChecked(value);
  };

  const onToggleMarketingCookies = (value: boolean) => {
    setMarketingChecked(value);
  };

  const onAcceptAll = () => {
    cookies.set(CONSENT_GIVEN);
    cookies.set(PREFERENCES_COOKIE);
    cookies.set(STATISTICS_COOKIE);
    cookies.set(MARKETING_COOKIE);
    handleForceupdateMethod();
  };

  const confirm = () => {
    cookies.set(CONSENT_GIVEN);

    if (preferencesCookies) {
      cookies.set(PREFERENCES_COOKIE);
    } else {
      cookies.remove(PREFERENCES_COOKIE);
    }

    if (statisticsCookies) {
      cookies.set(STATISTICS_COOKIE);
    } else {
      cookies.remove(STATISTICS_COOKIE);
    }

    if (marketingCookies) {
      cookies.set(MARKETING_COOKIE);
    } else {
      cookies.remove(MARKETING_COOKIE);
    }
    handleForceupdateMethod();
  };

  const decline = () => {
    cookies.set(CONSENT_GIVEN);
    cookies.remove(PREFERENCES_COOKIE);
    cookies.remove(STATISTICS_COOKIE);
    cookies.remove(MARKETING_COOKIE);
    handleForceupdateMethod();
  };

  if (cookies.get(CONSENT_GIVEN)) {
    //consetsCallback();
    return null;
  }

  const contentProps = {
    message,
    policyLink,
    privacyPolicyLinkText,
    necessaryOptionText,
    preferencesOptionText,
    statisticsOptionText,
    marketingOptionText,
    acceptButtonText,
    declineButtonText,
    managePreferencesButtonText,
    savePreferencesButtonText,
    showMarketingOption,
    preferencesDefaultChecked: true,
    statisticsDefaultChecked: false,
    marketingDefaultChecked: false,
    onTogglePreferencesCookies: onTogglePreferencesCookies,
    onToggleStatisticsCookies: onToggleStatisticsCookies,
    onToggleMarketingCookies: onToggleMarketingCookies,
    onDecline: decline,
    onConfirm: confirm,
    onAcceptAll: onAcceptAll,
  };

  return <CookieBannerContent styles={undefined} className={""} showDeclineButton={true} {...contentProps} />;
};

CookieBanner.protoTypes = {
  onAccept: PropTypes.func,
  onAcceptPreferences: PropTypes.func,
  onAcceptStatistics: PropTypes.func,
  onAcceptMarketing: PropTypes.func,
  onDeclinePreferences: PropTypes.func,
  onDeclineStatistics: PropTypes.func,
  onDeclineMarketing: PropTypes.func,
};
export default CookieBanner;
