import { useTranslation } from "../../../../../../helpers/useTranslation";
import { Grid } from "@mui/material";
import { CustomRadioButton } from "../../../../../../components/CustomRadioButton/CustomRadioButton";
import { CustomTextField } from "../../../../../../components/CustomTextField/CustomTextField";
import { useFormContext } from "react-hook-form";

type Props = {
  data?: ProductEnvironmentalImpact;
  DocumentIsReadOnly: boolean;
};

export const EnvironmentalImpactInformation = ({ data, DocumentIsReadOnly }: Props) => {
  const { get } = useTranslation();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <form onSubmit={handleSubmit as any}>
      <Grid container rowSpacing={1} columnSpacing={{ md: 3 }}>
        <Grid item xs={12}>
          <label>{get("environmentalImpactEN15804")}</label>
        </Grid>
        <Grid item xs={12}>
          <CustomRadioButton
            name={"ProductEnvironmentalImpact.EN15804"}
            controlValue={control}
            dataValue={data?.EN15804 || ""}
            isNA={false}
            yesValue={true}
            noValue={false}
            disabled={DocumentIsReadOnly}
            errorFieldMessage={errors?.EN15804?.message}
          />
        </Grid>
        <Grid item xs={12} md={8} sx={{ mt: 1 }}>
          <CustomTextField
            isDisabled={DocumentIsReadOnly}
            name={"ProductEnvironmentalImpact.PCR"}
            controlValue={control}
            defaultValue={data?.PCR || ""}
            isMultiLine={false}
            rowSpan={0}
            label={get("environmentalImpactPCR")}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} md={4} sx={{ mt: 1 }}>
          <CustomTextField
            isDisabled={DocumentIsReadOnly}
            name={"ProductEnvironmentalImpact.EPD"}
            controlValue={control}
            defaultValue={data?.EPD || ""}
            isMultiLine={false}
            rowSpan={0}
            label={get("environmentalImpactEPD")}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 3 }}>
          <CustomTextField
            isDisabled={DocumentIsReadOnly}
            name={"ProductEnvironmentalImpact.LifecyclePerspective"}
            controlValue={control}
            defaultValue={data?.LifecyclePerspective || ""}
            isMultiLine={true}
            rowSpan={6}
            label={get("environmentalLifecyclePerspective")}
            errors={errors}
          />
        </Grid>
      </Grid>
    </form>
  );
};
