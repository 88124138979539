import { Box, Grid } from "@mui/material";
import { ReactNode } from "react";
import { Footer } from "../Footer/Footer";
import { Header } from "../Header/Header";
import { NotificationSnackbar } from "../NotificationSnackbar/NotificationSnackbar";

type Props = {
  children?: ReactNode;
};

export const Layout = ({ children }: Props) => {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flex: 1,
      }}
    >
      <NotificationSnackbar />
      <Grid container direction="column">
        <Grid item>
          <Header />
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            flex: 1,
          }}
        >
          <Grid container item>
            {children}
          </Grid>
        </Grid>
        <Grid item>
          <Footer />
        </Grid>
      </Grid>
    </Box>
  );
};
