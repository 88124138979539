import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { PageLayout } from "../../../components/PageLayout/PageLayout";
import { useTranslation } from "../../../helpers/useTranslation";
import { useGetUser } from "../../../services/userService";
import { OrganizationListItem } from "./OrganizationListItem/OrganizationListItem";
import { Navigate } from "react-router";

export const OrganizationList = () => {
  const { get } = useTranslation();
  const { data } = useGetUser();

  if (data && data?.UserGroups.filter((x) => x.CanEditUserGroup || x.IsUserGroupAdmin).length > 1) {
    return (
      <PageLayout title="Admin">
        <Grid textAlign={"center"}>
          <Box sx={{ width: "50%", margin: "auto" }}>
            <Box
              sx={{
                display: "flex",
                gap: 1,
                py: 1,
                backgroundColor: "white",
                padding: 5,
                marginTop: 5,
              }}
            >
              <Grid container textAlign="center">
                <Grid item md={12} marginBottom={"30px"}>
                  <Typography variant="h5" color="black">
                    {get("organizationListChoice")}
                  </Typography>
                </Grid>
                {data?.UserGroups.map((organization) => (
                  <OrganizationListItem orgName={organization.UserGroupName} orgId={organization.UserGroupId} />
                ))}
              </Grid>
            </Box>
          </Box>
        </Grid>
      </PageLayout>
    );
  } else {
    return <Navigate to={`/admin/${data?.UserGroups[0].UserGroupId}`} />;
  }
};
