import { useTranslation } from "../../../../../helpers/useTranslation";
import { DemolitionInformation } from "./DemolitionInformation/DemolitionInformation";
import { ComponentLayout } from "../../../../../components/ComponentLayout/ComponentLayout";

type Props = {
  data?: EBVDDocument;
};

export const Demolition = ({ data }: Props) => {
  const { get } = useTranslation();

  const components = [
    {
      component: (
        <DemolitionInformation data={data?.ProductDisassembly} DocumentIsReadOnly={data?.DocumentIsReadOnly || false} />
      ),
      title: get("sectionDemolition"),
    },
  ];

  return (
    <>
      {components.map((comp, idx) => (
        <ComponentLayout key={idx} title={comp.title} index={idx + 1} length={components.length}>
          {comp.component}
        </ComponentLayout>
      ))}
    </>
  );
};
