export const DocumentIdSystem = [
  { key: 1, value: "A", label: "GLN" },
  { key: 2, value: "B", label: "DUNS" },
  { key: 3, value: "C", label: "VAT" },
];

export const DocumentIdSystemPattern = [
  { key: "A", value: "######-#####-#" },
  { key: "B", value: "#########" },
  { key: "C", value: "SE############" },
];
