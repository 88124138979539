export const api = {
  document: "document",
  productgroup: "productgroup",
  transactionitem: "transactionitem",
  wastecode: "wastecode",
  country: "country",
  comparisonoperator: "comparisonoperator",
  user: "user",
  productcontentcomponent: "productcontentcomponent",
  usergroup: "usergroup",
  userinvitationusergroup: "userinvitationusergroup",
  userinvitation: "userinvitation",
  hazardphrase: "hazardphrase",
  resource: "Resource",
};
