import { FormControlLabel } from "@mui/material";
import { Controller } from "react-hook-form";
import Checkbox from "@mui/material/Checkbox";

type Props = {
  name: string;
  controlValue: any;
  dataValue: any;
  label: string;
  disable?: boolean;
};

export const CustomCheckBox = ({ name, controlValue, dataValue, label, disable }: Props) => {
  return (
    <Controller
      name={name}
      defaultValue={dataValue}
      control={controlValue}
      render={({ field }) => (
        <FormControlLabel
          label={label}
          control={
            <Checkbox
              onChange={(e) => field.onChange(e.target.checked)}
              sx={{ p: 1 }}
              checked={field.value}
              disabled={disable}
            />
          }
        />
      )}
    />
  );
};
