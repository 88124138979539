import { DocumentIdSystemPattern } from "../constants/documentIdConstants";

export const useDocumentIdSystem = () => {
  const formatDocumentIDSystem = (documentID: any) => {
    let pattern = DocumentIdSystemPattern.find((x) => x.key === documentID);
    return pattern?.value;
  };

  const documentSystemCode = (systemId: string | undefined, userGroup: UserGroup): string => {
    if (systemId === "A") {
      return userGroup?.GLN;
    } else if (systemId === "B") {
      return userGroup?.DUNS;
    } else if (systemId === "C") {
      return userGroup?.VatNo;
    } else {
      return "";
    }
  };

  return {
    formatDocumentIDSystem,
    documentSystemCode,
  };
};
