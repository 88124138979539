import { IconButton, Menu, MenuItem } from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { localeAtom } from "../../../recoil/atom";
import { languages } from "../../../config/languages";
import { useTranslation } from "../../../helpers/useTranslation";

export const LanguageSelector = () => {
  const [language, setLanguage] = useRecoilState(localeAtom);
  const { get } = useTranslation();

  const [anchorElLang, setAnchorElLang] = useState<null | HTMLElement>(null);

  const handleOpenLanguageMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElLang(event.currentTarget);
  };

  const handleCloseLanguageMenu = () => {
    setAnchorElLang(null);
  };

  const handleLanguageChange = (language: string) => {
    setLanguage(language);
    handleCloseLanguageMenu();
  };

  return (
    <div>
      <IconButton onClick={handleOpenLanguageMenu} sx={{ p: 1 }}>
        <LanguageIcon />
      </IconButton>
      <Menu
        id="language-menu"
        keepMounted
        anchorEl={anchorElLang}
        open={Boolean(anchorElLang)}
        onClose={handleCloseLanguageMenu}
      >
        {languages.map((lang) => (
          <MenuItem
            key={lang.key}
            onClick={() => handleLanguageChange(lang.key)}
            sx={{ fontWeight: lang.key === language ? "bold" : "normal" }}
          >
            {get(lang.labelKey)}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
