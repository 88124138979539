import { useRequest } from "../helpers/useRequest";
import { api } from "../config/apiEndpoints";
import { queryEnums } from "../config/queryEnums";
import { useQuery } from "react-query";

const wasteCodeUrl = api.wastecode;

export const useGetWasteCodes = (filter: any) => {
  const { post } = useRequest();

  return useQuery<WasteCodes>(
    [queryEnums.wasteCodes, filter],
    () =>
      post(`${wasteCodeUrl}/GetWasteCodes`, {
        TextFilter: filter.TextFilter,
        CreateTree: false,
        SortType: 0,
        SortAscending: true,
      }),
    {
      enabled: Boolean(filter.TextFilter),
      suspense: false,
    },
  );
};
