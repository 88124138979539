import { ChemicalContentInformation } from "./ChemicalContentInformation/ChemicalContentInformation";
import { ArticleSubComponents } from "./ArticleSubComponents/ArticleSubComponents";
import { useTranslation } from "../../../../../helpers/useTranslation";
import { ComponentLayout } from "../../../../../components/ComponentLayout/ComponentLayout";
import { useFormContext } from "react-hook-form";

type Props = {
  data?: EBVDDocument;
  hPhrasesData?: HPhrases;
  existingArticleData?: ExistingProductContentComponent;
};

export const ChemicalContent = ({ data, hPhrasesData, existingArticleData }: Props) => {
  const { get } = useTranslation();
  const { handleSubmit } = useFormContext();

  const components = [
    {
      component: (
        <ChemicalContentInformation
          data={data?.ProductContent}
          DocumentIsReadOnly={data?.DocumentIsReadOnly || false}
        />
      ),
      title: get("sectionChemicalContent"),
    },
    {
      component: (
        <ArticleSubComponents
          data={data}
          DocumentIsReadOnly={data?.DocumentIsReadOnly || false}
          hPhrasesData={hPhrasesData}
          existingArticleData={existingArticleData}
        />
      ),
      title: get("chemicalContentArticle"),
    },
  ];

  return (
    <>
      <form onSubmit={handleSubmit as any}>
        {components.map((comp, idx) => (
          <ComponentLayout key={idx} title={comp.title} index={idx + 1} length={components.length}>
            {comp.component}
          </ComponentLayout>
        ))}
      </form>
    </>
  );
};
