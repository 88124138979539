import { Grid, TextField } from "@mui/material";
import { useTranslation } from "../../../../../../helpers/useTranslation";
import { CustomTextField } from "../../../../../../components/CustomTextField/CustomTextField";
import { CustomErrorTypography } from "../../../../../../components/CustomTypography/CustomErrorTypography";
import { Controller } from "react-hook-form";
import { useEffect } from "react";

type Props = {
  handleSubmit: any;
  control: any;
  data?: any;
  errors: any;
  reset: any;
};
export const AttachmentsForm = ({ handleSubmit, control, data, errors, reset }: Props) => {
  const { get } = useTranslation();
  useEffect(() => {
    if (data !== undefined) reset(data);
    else
      reset({
        DocumentAttachmentId: -1,
        Attachment: "",
      });
  }, [data, reset]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container sx={{ mt: 2 }} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12}>
            <CustomTextField
              name="Attachment"
              isDisabled={false}
              controlValue={control}
              defaultValue={data?.Attachment || ""}
              isMultiLine={false}
              rowSpan={0}
              label={get("fielddocumentAttachment") + " *"}
              errors={errors}
            />
            <CustomErrorTypography errorMessage={errors.Attachment && errors.Attachment.message} />
            <Controller
              render={({ field }) => <TextField {...field} variant="filled" sx={{ display: "none", width: "100%" }} />}
              name="DocumentAttachmentId"
              control={control}
              defaultValue={data?.DocumentAttachmentId || -1}
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
};
