import { FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "../../../helpers/useTranslation";
import { CustomTextField } from "../../../components/CustomTextField/CustomTextField";
import { useGetCountries } from "../../../services/countryService";
type Props = {
  data?: CreateAccount;
};
export const InvoiceInformation = ({ data }: Props) => {
  const { data: countriesDataValues } = useGetCountries();
  const { get } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useFormContext<CreateAccount>();

  return (
    <form onSubmit={handleSubmit as any}>
      <Grid container rowSpacing={1} columnSpacing={{ md: 3 }}>
        <Grid item md={6} sx={{ mt: 3, mb: 3 }}>
          <Controller
            name="InvoiceCountryId"
            control={control}
            defaultValue={data?.InvoiceCountryId || ""}
            render={({ field: { onChange, value } }) => (
              <FormControl sx={{ width: "100%" }}>
                <InputLabel shrink>{get("accountInvoiceCountry") + "*"}</InputLabel>
                <Select
                  value={value || ""}
                  name="InvoiceCountryId"
                  onChange={onChange}
                  error={!!errors?.InvoiceCountryId?.message}
                  input={<OutlinedInput notched label={get("accountInvoiceCountry")} />}
                >
                  {countriesDataValues?.Items?.map((item: Item) => (
                    <MenuItem key={item.CountryId} value={item.CountryId}>
                      {item.Name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item md={12} sx={{ mt: 3 }}>
          <CustomTextField
            name={"InvoiceAddress"}
            isDisabled={false}
            controlValue={control}
            defaultValue={data?.InvoiceAddress}
            label={get("accountInvoiceAddress") + "*"}
            errors={errors}
          />
        </Grid>
        <Grid item md={12} sx={{ mt: 3 }}>
          <CustomTextField
            name={"InvoiceAreaCode"}
            isDisabled={false}
            controlValue={control}
            defaultValue={data?.InvoiceAreaCode}
            label={get("accountPostCode") + "*"}
            errors={errors}
          />
        </Grid>
        <Grid item md={12} sx={{ mt: 3 }}>
          <CustomTextField
            name={"InvoiceCity"}
            isDisabled={false}
            controlValue={control}
            defaultValue={data?.InvoiceCity}
            label={get("fieldCity") + "*"}
            errors={errors}
          />
        </Grid>
        <Grid item md={12} sx={{ mt: 3 }}>
          <CustomTextField
            name={"InvoiceReference"}
            isDisabled={false}
            controlValue={control}
            defaultValue={data?.InvoiceReference}
            label={get("accountInvoiceReference") + "*"}
            errors={errors}
          />
        </Grid>
        <Grid item md={12} sx={{ mt: 3 }}>
          <CustomTextField
            name={"InvoiceEmail"}
            isDisabled={false}
            controlValue={control}
            defaultValue={data?.InvoiceEmail}
            label={get("accountInvoiceEmail") + "*"}
            errors={errors}
          />
        </Grid>
        <Grid item md={12} sx={{ mt: 3 }}>
          <CustomTextField
            name={"InvoiceExternalId"}
            isDisabled={false}
            controlValue={control}
            defaultValue={data?.InvoiceExternalId}
            label={get("accountInternalOrganizationID")}
          />
        </Grid>
      </Grid>
    </form>
  );
};
