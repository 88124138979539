import { Grid } from "@mui/material";
import { useTranslation } from "../../../../helpers/useTranslation";
import { CustomTextField } from "../../../../components/CustomTextField/CustomTextField";
import { CustomCheckBox } from "../../../../components/CustomCheckBox/CustomCheckBox";
import { CustomRadioButton } from "../../../../components/CustomRadioButton/CustomRadioButton";
import { useFormContext } from "react-hook-form";

type Props = {
  data?: UserGroup;
};

export const CompanyManagement = ({ data }: Props) => {
  const { get } = useTranslation();
  const { control, handleSubmit } = useFormContext<UserGroup>();

  return (
    <form onSubmit={handleSubmit as any}>
      <Grid container rowSpacing={1} px={1} columnSpacing={{ md: 3 }}>
        <Grid item xs={12} md={12} sx={{ mt: 3 }}>
          <label>{get("fieldCompanyResponsibility")}</label>
        </Grid>
        <Grid item md={12} sx={{ mt: 3 }}>
          <CustomCheckBox
            name="SocialSystemMapping"
            label={get("fieldCompanyMapping")}
            dataValue={data?.SocialSystemMapping}
            controlValue={control}
            disable={false}
          />
        </Grid>
        <Grid item md={12} sx={{ mt: 3 }}>
          <CustomCheckBox
            name="SocialSystemRiskAnalyses"
            label={get("fieldCompanyRisk")}
            dataValue={data?.SocialSystemRiskAnalyses}
            controlValue={control}
            disable={false}
          />
        </Grid>
        <Grid item md={12} sx={{ mt: 3 }}>
          <CustomCheckBox
            name="SocialSystemActionPlan"
            label={get("fieldCompanyAction")}
            dataValue={data?.SocialSystemActionPlan}
            controlValue={control}
            disable={false}
          />
        </Grid>
        <Grid item md={12} sx={{ mt: 3 }}>
          <CustomCheckBox
            name="SocialSystemFollowUpPlan"
            label={get("fieldCompanyFollowUpPlan")}
            dataValue={data?.SocialSystemFollowUpPlan}
            controlValue={control}
            disable={false}
          />
        </Grid>
        <Grid item xs={12} md={12} sx={{ mt: 3 }}>
          <label>{get("fieldCompanySustainability")}</label>
        </Grid>
        <Grid item xs={12} md={12}>
          <CustomRadioButton
            name={"HasSocialSustainabilityReporting"}
            controlValue={control}
            dataValue={data?.HasSocialSustainabilityReporting}
            isNA={false}
            yesValue={true}
            noValue={false}
          />
        </Grid>
        <Grid item md={12} sx={{ mt: 3, mb: 3 }}>
          <CustomTextField
            name={"SocialSustainabilityReporting"}
            isDisabled={false}
            controlValue={control}
            defaultValue={data?.SocialSustainabilityReporting}
            label={get("fieldCompanyAccounting")}
          />
        </Grid>
      </Grid>
    </form>
  );
};
