import { Alert, Box, Grid, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { initialRows, RowData } from "./data";
import { useGetResources } from "../../services/epdService";
import { TableSection } from "./components/Table/TableSection";
import { useTranslation } from "../../helpers/useTranslation";
import { UnauthenticatedTemplate } from "@azure/msal-react";

export const EpdLayout = () => {
  const navigate = useNavigate();
  const handleRowClick = (id: number) => {
    navigate(`/digitalepd/${id}`);
  };
  const { get } = useTranslation();
  const articleSystemColumns: Array<keyof RowData> = [
    "productName",
    "epdOwner",
    "locationCode",
    "resourceTypeName",
    "resourceSubTypeName",
    "epdNumber",
    "publicPrivate",
    "inventoryUnitGWP",
    "inventoryUnit",
    "declaredUnitGWP",
    "declaredUnit",
    "programOperator",
    "epdUniqueId",
    "registrationNumber",
    "sourceId",
    "pdfLink",
    "confidential",
  ];
  const { data } = useGetResources();

  if (data) {
    console.log("Fetched data:", data);
  }
  return (
    <Box
      role="tabpanel"
      sx={{
        width: { xs: "100%", sm: "100%", md: "900px", lg: "1200px", xl: "1400px" },
        margin: "0 auto",
      }}
    >
      <Box>
        <TableSection
          alertTitle={get("EpdAlert")}
          title={get("EpdTitle")}
          rows={initialRows}
          columns={articleSystemColumns}
          onRowClick={handleRowClick}
        />
      </Box>
    </Box>
  );
};
