import { FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import { useTranslation } from "../../../../../helpers/useTranslation";
import { CustomCheckBox } from "../../../../../components/CustomCheckBox/CustomCheckBox";
import { Controller, useFieldArray, useFormContext, useWatch } from "react-hook-form";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { AccessType } from "../../../../../helpers/usePublicAccessPresets";
import { translationRows } from "../../../../../constants/translationRows";

type Props = {
  data?: UserGroupUser;
};

export const UserInformationAccess = ({ data }: Props) => {
  const { get } = useTranslation();
  const { control, handleSubmit, setValue } = useFormContext<UserGroupUser>();
  const { fields } = useFieldArray({
    control,
    name: "InformationPermissions.DataItems",
  });

  const propertySelection = useWatch({
    name: "InformationPermissions.PropertySelection",
  });

  const setInformationPermissionAccess = (selectedProperty: string) => {
    switch (selectedProperty) {
      case "0": {
        setValue(
          "InformationPermissions.DataItems",
          fields.map((item) => {
            return {
              ...item,
              CanRead: true,
              CanEdit: true,
            };
          }),
        );
        break;
      }
      case "2": {
        setValue(
          "InformationPermissions.DataItems",
          fields.map((item) => {
            if (item.BusinessObjectPropertyGroupId === 1 || item.BusinessObjectPropertyGroupId === 2) {
              return {
                ...item,
                CanRead: true,
                CanEdit: false,
              };
            } else {
              return {
                ...item,
                CanRead: false,
                CanEdit: false,
              };
            }
          }),
        );
        break;
      }
      default:
        break;
    }
  };

  return (
    <form onSubmit={handleSubmit as any}>
      <Grid container sx={{ mt: 2 }} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <Controller
            render={({ field: { onChange, value } }) => (
              <RadioGroup
                name="RoleGroup"
                row={true}
                value={value}
                onChange={(event, value) => {
                  onChange(event, value);
                  setInformationPermissionAccess(value);
                }}
              >
                <FormControlLabel value={0} control={<Radio />} label={get("allInformation")} />
                <FormControlLabel value={2} control={<Radio />} label={get("minimumInformation")} />
                <FormControlLabel value={3} control={<Radio />} label={get("selectedInformation")} />
              </RadioGroup>
            )}
            name={"InformationPermissions.PropertySelection"}
            control={control}
            defaultValue={data?.InformationPermissions?.PropertySelection || 0}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 3, mb: 2 }} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item md={12}>
          <Paper sx={{ overflow: "hidden" }}>
            <TableContainer sx={{ width: "100%" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="body1">
                        <b>{get("userFieldGroup")}</b>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        <b>{get("userRoleRead")}</b>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        <b>{get("userRoleEdit")}</b>
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fields.map((item, idx: number) => (
                    <TableRow key={item.id}>
                      <TableCell>
                        {item.BusinessObjectPropertyGroupId}
                        {". "}
                        {get(translationRows[item.BusinessObjectPropertyGroupId as AccessType])}
                      </TableCell>
                      <TableCell>
                        <CustomCheckBox
                          name={"InformationPermissions.DataItems[" + idx + "].CanRead"}
                          label={""}
                          dataValue={item.CanRead}
                          controlValue={control}
                          disable={
                            String(propertySelection) !== "3" ||
                            item.BusinessObjectPropertyGroupId === 1 ||
                            item.BusinessObjectPropertyGroupId === 2
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <CustomCheckBox
                          name={"InformationPermissions.DataItems[" + idx + "].CanEdit"}
                          label={""}
                          dataValue={item.CanEdit}
                          controlValue={control}
                          disable={String(propertySelection) !== "3"}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
};
