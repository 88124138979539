import { Company } from "./Company";
import { useTranslation } from "../../../../../helpers/useTranslation";
import { ComponentLayout } from "../../../../../components/ComponentLayout/ComponentLayout";

type Props = {
  data?: UserGroup;
};

export const CompanyInformation = ({ data }: Props) => {
  const { get } = useTranslation();

  const components = [
    {
      component: <Company data={data} />,
      title: get("sectionCompanyTitle"),
    },
    // {
    //   component: <CompanyCertification data={data?.UserGroup} />,
    //   title: get("sectionCompanyCertificationTitle"),
    // },
    // {
    //   component: <CompanyPolicies data={data?.UserGroup} />,
    //   title: get("sectionCompanyPoliciesTitle"),
    // },
    // {
    //   component: <CompanyManagement data={data?.UserGroup} />,
    //   title: get("sectionCompanyManagementTitle"),
    // },
  ];

  return (
    <>
      {components.map((comp, idx) => (
        <ComponentLayout key={idx} title={comp.title} index={idx + 1} length={components.length}>
          {comp.component}
        </ComponentLayout>
      ))}
    </>
  );
};
