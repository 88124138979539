import { Button, Typography, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "../../helpers/useTranslation";
import { PageLayout } from "../PageLayout/PageLayout";

type Props = {
  error: any;
  resetErrorBoundary: () => void;
};

export const ErrorFallback = ({ error, resetErrorBoundary }: Props) => {
  const { get } = useTranslation();
  const location = useLocation();
  const [hasError, setHasError] = useState(null);

  useEffect(() => {
    if (error && !hasError) {
      setHasError(error);
    } else if (error && hasError) {
      setHasError(null);
      resetErrorBoundary();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <PageLayout>
      <Box>
        <Box
          sx={{
            marginBottom: "1rem",
          }}
        >
          <Typography variant="inherit">{get("errorMessage")}</Typography>
        </Box>
        <Box
          sx={{
            fontWeight: 500,
            bgcolor: "#f6f6f6",
            padding: "1rem",
          }}
        >
          <i>
            <Typography>{error.message}</Typography>
          </i>
        </Box>
        <Button
          onClick={resetErrorBoundary}
          variant="contained"
          sx={{
            marginTop: "1rem",
          }}
        >
          {get("errorTryAgainButton")}
        </Button>
      </Box>
    </PageLayout>
  );
};
