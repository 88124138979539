import { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useDebounce } from "use-debounce";
import InternalSearchAccordion from "./InternalSearchAccordion";
import { InternalSearchTextField } from "./InternalSearchTextField";
import { useTranslation } from "../../../../helpers/useTranslation";
import { useGetProductGroups } from "../../../../services/productGroupService";
import { InternalFilterShow } from "./InternalFilterShow";

type Props = {
  setFilter: any;
  filter: InternalSearchFilter;
};

export const InternalBK04Filter = ({ setFilter, filter }: Props) => {
  const [searchResults, setSearchResults] = useState<ProductGroup[]>([]);
  const [itemsToShow, setItemsToShow] = useState<number>(5);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { get } = useTranslation();
  const [filterProductGroup, setFilterProductGroup] = useState<ArticleFilterOptions>({
    TextFilter: " ",
    ProductGroupSystemId: "BK04",
  });
  const [debouncedFilter] = useDebounce(filterProductGroup, 500);

  const { data: productGroupDataValues } = useGetProductGroups(debouncedFilter);

  useEffect(() => {
    setSearchResults(productGroupDataValues?.ProductGroups || []);
  }, [searchTerm, productGroupDataValues?.ProductGroups]);

  const handleSearch = (e: any) => {
    setSearchTerm(e.target.value);
    setFilterProductGroup({ TextFilter: e.target.value !== "" ? e.target.value : " ", ProductGroupSystemId: "BK04" });
  };

  const handleChange = (event: any) => {
    if (event.target.checked) {
      setFilter({
        ...filter,
        BK04ClassificationCodeIds: [...(filter.BK04ClassificationCodeIds || []), event.target.value],
      });
    } else {
      setFilter({
        ...filter,
        BK04ClassificationCodeIds: filter.BK04ClassificationCodeIds?.filter((x) => x !== event.target.value),
      });
    }
  };

  return (
    <>
      <InternalSearchAccordion title={get("searchDocumentBK04")}>
        <InternalSearchTextField
          handleSearch={handleSearch}
          searchTerm={searchTerm}
          label={get("searchDocumentBK04")}
        />
        <FormGroup sx={{ marginTop: 2 }}>
          {searchResults.length > 0 ? (
            searchResults.slice(0, itemsToShow).map((items: ProductGroup) => (
              <div className="" key={items.ProductGroupId}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      value={items.Code}
                      onChange={handleChange}
                      checked={filter.BK04ClassificationCodeIds?.some((x) => x?.toString() === items.Code.toString())}
                    />
                  }
                  label={<Typography variant="body2">{items.Name}</Typography>}
                />
              </div>
            ))
          ) : (
            <div>
              {get("noResultText")}"{searchTerm}"
            </div>
          )}
        </FormGroup>
        <InternalFilterShow
          itemsToShow={itemsToShow}
          setItemsToShow={setItemsToShow}
          searchResult={searchResults.length}
        />
      </InternalSearchAccordion>
    </>
  );
};
