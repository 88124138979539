import { PageLayout } from "../../components/PageLayout/PageLayout";
import { Box } from "@mui/material";
import { RightSideContent } from "./RightSideContent/RightSideContent";

import { useTranslation } from "../../helpers/useTranslation";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { RecentlyViewed } from "../Index/RecentlyViewed/RecentlyViewed";

export const AboutPage = () => {
  const { get } = useTranslation();
  return (
    <PageLayout title={get("abouteBVDPageTitle")}>
      <Box sx={{ flexGrow: 1, display: "flex" }}>
        <AuthenticatedTemplate>
          <RecentlyViewed />
        </AuthenticatedTemplate>
        <RightSideContent />
      </Box>
    </PageLayout>
  );
};
