import { useRequest } from "../helpers/useRequest";
import { api } from "../config/apiEndpoints";
import { useMutation, useQueryClient } from "react-query";
import { queryEnums } from "../config/queryEnums";
import { useTranslation } from "../helpers/useTranslation";
import { useMessage } from "../helpers/useMessages";

const url = api.userinvitationusergroup;

export const useDeleteUserInvite = () => {
  const { post } = useRequest();
  const { get } = useTranslation();
  const { send } = useMessage();
  const qc = useQueryClient();

  return useMutation(
    (userInvite: UserInvite) =>
      post(`${url}/DeleteDataItem`, {
        UserInvitationId: userInvite.UserInvitationId,
        UserGroupId: userInvite.UserGroupId,
      }),
    {
      onSuccess: (_data, variables) => {
        qc.invalidateQueries([queryEnums.userGroup, String(variables.UserGroupId)]);
        send(`${get("toastDeleted")}.`);
      },
    },
  );
};
