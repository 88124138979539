import { Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTranslation } from "../../../../../../../helpers/useTranslation";
import { CustomTextField } from "../../../../../../../components/CustomTextField/CustomTextField";

type Props = {
  control: any;
  handleSubmit: any;
  data?: ProductEmission;
  errors: any;
  reset: any;
};
const IndoorEnvironmentTableForm = ({ control, handleSubmit, data, errors, reset }: Props) => {
  const { get } = useTranslation();

  useEffect(() => {
    if (data !== undefined) reset(data);
    else
      reset({
        ProductEmissionId: -1,
        EmissionType: "",
        Method1: "",
        Result1: "",
        Result1ComparisonOperatorId: null,
        Unit1: "",
        MeasurementTimeInterval1: "",
        MeasurementTimeInterval1Unit: "",
        Method2: "",
        Result2: "",
        Result2ComparisonOperatorId: null,
        Unit2: "",
        MeasurementTimeInterval2: "",
        MeasurementTimeInterval2Unit: "",
      });
  }, [data, reset]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container sx={{ mt: 1 }} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} sx={{ mb: 1 }}>
            <CustomTextField
              name={"EmissionType"}
              isDisabled={false}
              controlValue={control}
              defaultValue={data?.EmissionType || ""}
              isMultiLine={false}
              rowSpan={0}
              label={get("indoorModalEmissionTitle") + " *"}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sx={{ mb: 1 }}>
            <Typography sx={{ fontWeight: "bold", mb: 2 }}>{get("indoorModalMeasuringPoint1")}</Typography>
            <CustomTextField
              name={"Method1"}
              isDisabled={false}
              controlValue={control}
              defaultValue={data?.Method1 || ""}
              isMultiLine={false}
              rowSpan={0}
              label={get("indoorModalMeasuringMethodStandardTitle1") + " *"}
              errors={errors}
            />
          </Grid>
          <Grid item xs={4} sx={{ mt: 3 }}>
            <Controller
              name={"Result1ComparisonOperatorId"}
              control={control}
              defaultValue={data?.Result1ComparisonOperatorId || null}
              render={({ field: { onChange, value } }) => (
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel shrink>{get("indoorModalResult1")}</InputLabel>
                  <Select
                    value={value}
                    onChange={onChange}
                    label={get("documentModalOrganisation")}
                    labelId={get("documentModalOrganisation")}
                    error={errors.Result1ComparisonOperatorId}
                    input={<OutlinedInput notched label={get("documentModalOrganisation")} />}
                  >
                    <MenuItem value="1">=</MenuItem>
                    <MenuItem value="2">{"<"}</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={4} sx={{ mt: 3 }}>
            <CustomTextField
              name={"Result1"}
              isDisabled={false}
              controlValue={control}
              defaultValue={data?.Result1 || null}
              type="number"
              label={""}
              errors={errors}
            />
          </Grid>
          <Grid item xs={4} sx={{ mt: 3 }}>
            <CustomTextField
              name={"Unit1"}
              isDisabled={false}
              controlValue={control}
              defaultValue={data?.Unit1 || ""}
              isMultiLine={false}
              rowSpan={0}
              label={get("indoorModalUnit1")}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>{get("indoorTimeIntervalMeasurement1")}</Typography>
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              name={"MeasurementTimeInterval1"}
              isDisabled={false}
              controlValue={control}
              defaultValue={data?.MeasurementTimeInterval1 || null}
              type="number"
              label={""}
              errors={errors}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="MeasurementTimeInterval1Unit"
              control={control}
              defaultValue={data?.MeasurementTimeInterval1Unit || ""}
              render={({ field: { onChange, value } }) => (
                <FormControl sx={{ width: "100%" }}>
                  <Select value={value} onChange={onChange} error={errors.MeasurementTimeInterval1Unit}>
                    <MenuItem value="hour">{get("indoorHours")}</MenuItem>
                    <MenuItem value="day">{get("indoorDays")}</MenuItem>
                    <MenuItem value="week">{get("indoorWeeks")}</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>

          <Grid item xs={12} sx={{ mt: 2, mb: 1 }}>
            <Typography sx={{ fontWeight: "bold", mb: 2 }}>{get("indoorModalMeasuringPoint2")}</Typography>
            <CustomTextField
              name={"Method2"}
              isDisabled={false}
              controlValue={control}
              defaultValue={data?.Method2 || ""}
              isMultiLine={false}
              rowSpan={0}
              label={get("indoorModalMeasuringMethodStandardTitle2")}
            />
          </Grid>
          <Grid item xs={4} sx={{ mt: 3 }}>
            <Controller
              name="Result2ComparisonOperatorId"
              control={control}
              defaultValue={data?.Result2ComparisonOperatorId || null}
              render={({ field: { onChange, value } }) => (
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel shrink>{get("indoorModalResult2")}</InputLabel>
                  <Select
                    value={value}
                    onChange={onChange}
                    label={get("indoorModalResult2")}
                    labelId={get("documentModalOrganisation")}
                    error={errors.Result2ComparisonOperatorId}
                    input={<OutlinedInput notched label={get("indoorModalResult2")} />}
                  >
                    <MenuItem value="1">=</MenuItem>
                    <MenuItem value="2">{"<"}</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={4} sx={{ mt: 3 }}>
            <CustomTextField
              name={"Result2"}
              isDisabled={false}
              controlValue={control}
              defaultValue={data?.Result2 || null}
              type="number"
              label={""}
              errors={errors}
            />
          </Grid>
          <Grid item xs={4} sx={{ mt: 3 }}>
            <CustomTextField
              name={"Unit2"}
              isDisabled={false}
              controlValue={control}
              defaultValue={data?.Unit2 || ""}
              isMultiLine={false}
              rowSpan={0}
              label={get("indoorModalUnit2")}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>{get("indoorTimeIntervalMeasurement2")}</Typography>
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              name={"MeasurementTimeInterval2"}
              isDisabled={false}
              controlValue={control}
              defaultValue={data?.MeasurementTimeInterval2 || null}
              type="number"
              label={""}
              errors={errors}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="MeasurementTimeInterval2Unit"
              control={control}
              defaultValue={data?.MeasurementTimeInterval2Unit || ""}
              render={({ field: { onChange, value } }) => (
                <FormControl sx={{ width: "100%" }}>
                  <Select value={value} onChange={onChange} error={errors.MeasurementTimeInterval2Unit}>
                    <MenuItem value="hour">{get("indoorHours")}</MenuItem>
                    <MenuItem value="day">{get("indoorDays")}</MenuItem>
                    <MenuItem value="week">{get("indoorWeeks")}</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Controller
            render={({ field }) => <TextField {...field} variant="filled" sx={{ display: "none", width: "100%" }} />}
            name="ProductEmissionId"
            control={control}
            defaultValue={data?.ProductEmissionId || -1}
          />
        </Grid>
      </form>
    </>
  );
};

export default IndoorEnvironmentTableForm;
