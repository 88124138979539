import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useForm, useFormContext } from "react-hook-form";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "../../../../../../helpers/useTranslation";
import { useFormValidation } from "../../../../../../helpers/useFormValidation";
import { ConfirmationModal } from "../../../../../../components/ConfirmationModal/ConfirmationModal";
import { ReferencesForm } from "./ReferencesForm";

type Props = {
  DocumentIsReadOnly: boolean;
};

export const References = ({ DocumentIsReadOnly }: Props) => {
  const { referenceValidationSchema } = useFormValidation();
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors: referenceError },
    setValue: setReferenceValue,
  } = useForm<DocumentReferences>({ resolver: yupResolver(referenceValidationSchema) });
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [referenceId, setReferenceId] = useState(0);

  const { getValues, setValue } = useFormContext();

  const { get } = useTranslation();
  const [referenceData, setReferenceData] = useState<DocumentReferences[]>(getValues("DocumentReferences") || []);
  const [rowColor, setRowColorRequired] = useState(false);

  const handleConfirmation = (data: DocumentReferences) => {
    setReferenceData([...referenceData, data]);
    setRowColorRequired(false);
    setShowAdd(false);
    setReferenceId(0);
    setValue("DocumentReferences", [...referenceData, data], { shouldDirty: true });
    reset();
  };

  const handleUpdateConfirmation = (data: DocumentReferences) => {
    referenceData[referenceId - 1] = data;
    setReferenceData([...referenceData]);
    setShowEdit(false);
    setValue("DocumentReferences", [...referenceData], { shouldDirty: true });
    setReferenceId(0);
    reset();
  };
  const remove = (index: number) => {
    if (index !== 0) referenceData.splice(index - 1, 1);
    setReferenceData([...referenceData]);
    setValue("DocumentReferences", [...referenceData], { shouldDirty: true });
    setReferenceId(0);
  };

  const handleModalClose = () => {
    setShowAdd(false);
    setRowColorRequired(false);
    setReferenceId(0);
    reset();
  };

  const handleEditModalClose = () => {
    setShowEdit(false);
    setRowColorRequired(false);
    setReferenceId(0);
    reset();
  };

  const handleRowClick = (idx: number) => {
    setReferenceId(idx + 1);
    setRowColorRequired(true);
  };

  const handleAdd = () => {
    setReferenceId(-1);
    setShowAdd(true);
  };

  return (
    <Grid container sx={{ mt: 0, mb: 2 }} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item md={11}>
        <Paper sx={{ overflow: "hidden" }}>
          <TableContainer sx={{ height: 300, width: "100%" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {" "}
                    <Typography variant="body1">
                      <b>{get("documentReference")}</b>
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {referenceData?.map((item, idx) => (
                  <TableRow
                    key={idx}
                    onClick={() => {
                      handleRowClick(idx);
                    }}
                  >
                    <TableCell
                      scope="row"
                      sx={{
                        backgroundColor: rowColor && idx + 1 === referenceId ? "#b1b8b8" : "",
                        cursor: "pointer",
                      }}
                    >
                      {item.Reference}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
      <Grid item md={1}>
        <Stack direction="column" spacing={2} sx={{ float: "right" }}>
          <Button
            variant="contained"
            onClick={handleAdd}
            size="small"
            disabled={DocumentIsReadOnly}
            sx={{ width: "36px", minWidth: "0px" }}
          >
            <AddIcon />
          </Button>
          <Button
            variant="contained"
            onClick={() => setShowEdit(true)}
            size="small"
            disabled={referenceId === 0 || DocumentIsReadOnly}
            sx={{ m: 1, width: "36px", minWidth: "0px" }}
          >
            <EditIcon />
          </Button>
          <Button
            color="error"
            size="small"
            sx={{ m: 1, width: "36px", minWidth: "0px" }}
            disabled={referenceId === 0 || DocumentIsReadOnly}
            onClick={() => remove(referenceId)}
          >
            <DeleteIcon />
          </Button>
        </Stack>
      </Grid>

      <ConfirmationModal
        show={showAdd}
        handleClose={() => handleModalClose()}
        handleConfirmation={() => handleSubmit(handleConfirmation)()}
        title={get("addReference")}
        saveButtonName={get("saveButtonText")}
        cancelButtonName={get("cancelButtonText")}
      >
        <ReferencesForm
          handleSubmit={handleSubmit}
          control={control}
          data={null}
          errors={referenceError}
          setValue={setReferenceValue}
          reset={reset}
        />
      </ConfirmationModal>
      <ConfirmationModal
        show={showEdit}
        handleClose={() => handleEditModalClose()}
        handleConfirmation={() => handleSubmit(handleUpdateConfirmation)()}
        title={get("editReference")}
        saveButtonName={get("saveButtonText")}
        cancelButtonName={get("cancelButtonText")}
      >
        <ReferencesForm
          handleSubmit={handleSubmit}
          control={control}
          data={referenceData[referenceId - 1]}
          errors={referenceError}
          setValue={setReferenceValue}
          reset={reset}
        />
      </ConfirmationModal>
    </Grid>
  );
};
