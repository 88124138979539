import { PageLayout } from "../../components/PageLayout/PageLayout";
import { Box } from "@mui/material";
import { RightSideContent } from "./RightSideContent/RightSideContent";
import { RecentlyViewed } from "./RecentlyViewed/RecentlyViewed";
import { useTranslation } from "../../helpers/useTranslation";
import { AuthenticatedTemplate } from "@azure/msal-react";

export const IndexPage = () => {
  const { get } = useTranslation();
  return (
    <PageLayout title={get("indexPageTitle")}>
      <Box sx={{ flexGrow: 1, display: "flex" }}>
        <AuthenticatedTemplate>
          <RecentlyViewed />
        </AuthenticatedTemplate>
        <RightSideContent />
      </Box>
    </PageLayout>
  );
};
