import { DeliveredArticle } from "./DeliveredArticle/DeliveredArticle";
import { ComponentLayout } from "../../../../../components/ComponentLayout/ComponentLayout";
import { useTranslation } from "../../../../../helpers/useTranslation";

type Props = {
  data?: EBVDDocument;
};

export const WasteManagement = ({ data }: Props) => {
  const { get } = useTranslation();
  const components = [
    {
      component: <DeliveredArticle data={data} />,
      title: get("deliveredArticleSession"),
    },
  ];

  return (
    <>
      {components.map((comp, idx) => (
        <ComponentLayout key={idx} title={comp.title} index={idx + 1} length={components.length}>
          {comp.component}
        </ComponentLayout>
      ))}
    </>
  );
};
