import { Box, Button, Grid } from "@mui/material";
import { useTranslation } from "../../../../../../helpers/useTranslation";
import { useState } from "react";
import { ConfirmationDeleteModal } from "../../../../../../components/ConfirmationModal/ConfirmationDeleteModal";

type Props = {
  handleAdd: any;
  setShowExistingArticle: any;
  disableMaterialButton: boolean;
  setAddMaterial: any;
  disableSubstanceButton: boolean;
  setAddSubstance: any;
  handleEdit: any;
  disableEditButton: boolean;
  deleteItem: any;
  disableDeleteButton: boolean;
  DocumentIsReadOnly: boolean;
  setHideTable: any;
  hideTable: boolean;
};

export const ArticleSubComponentButton = ({
  handleAdd,
  setShowExistingArticle,
  disableMaterialButton,
  setAddMaterial,
  disableSubstanceButton,
  setAddSubstance,
  handleEdit,
  disableEditButton,
  deleteItem,
  disableDeleteButton,
  DocumentIsReadOnly,
  setHideTable,
  hideTable,
}: Props) => {
  const { get } = useTranslation();
  const [showDelete, setShowDelete] = useState(false);

  return (
    <>
      <Grid item md={12}>
        <ConfirmationDeleteModal
          show={showDelete}
          handleClose={() => setShowDelete(false)}
          handleConfirmation={() => {
            deleteItem();
            setShowDelete(false);
          }}
          title={get("deleteProductContentComponentTitle")}
          saveButtonName={get("radioButtonYes")}
          cancelButtonName={get("radioButtonNo")}
        >
          {get("deleteProductContentComponentMessage")}
        </ConfirmationDeleteModal>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Box
            sx={{
              padding: 0,
              marginBottom: 4,
              gap: 2,
              display: "inline-flex",
            }}
          >
            <Button variant="contained" onClick={handleAdd} disabled={DocumentIsReadOnly}>
              {get("articleSubComponentTableAddTitle")}
            </Button>

            <Button variant="contained" onClick={() => setShowExistingArticle(true)} disabled={DocumentIsReadOnly}>
              {get("articleSubComponentExistingArticleTableTitle")}
            </Button>

            <Button
              variant="contained"
              disabled={DocumentIsReadOnly === true ? true : disableMaterialButton}
              onClick={() => {
                setAddMaterial(true);
              }}
            >
              {get("articleSubComponentMaterialTableTitle")}
            </Button>
            <Button
              variant="contained"
              disabled={DocumentIsReadOnly === true ? true : disableSubstanceButton}
              onClick={() => {
                setAddSubstance(true);
              }}
            >
              {get("articleSubComponentTableSubstanceTitle")}
            </Button>
            <Button
              variant="contained"
              onClick={handleEdit}
              disabled={DocumentIsReadOnly === true ? true : disableEditButton}
            >
              {get("articleSubComponentTableEditTitle")}
            </Button>
            <Button
              variant="contained"
              // onClick={deleteItem}
              onClick={() => {
                setShowDelete(true);
              }}
              disabled={DocumentIsReadOnly === true ? true : disableDeleteButton}
            >
              {get("articleSubComponentTableDeleteTitle")}
            </Button>
            <Button variant="contained" onClick={() => setHideTable(!hideTable)}>
              {!hideTable ? get("articleSubComponentTableShow") : get("articleSubComponentTableHide")}
            </Button>
          </Box>
        </Box>
      </Grid>
    </>
  );
};
