import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { Typography } from "@mui/material";
import { useTranslation } from "../../../../../../helpers/useTranslation";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

type Props = {
  hideTable: boolean;
  isCollapsed: boolean;
  setIsCollapsed: any;
};
export const ArticleSubComponentTableHeader = ({ hideTable, isCollapsed, setIsCollapsed }: Props) => {

  const { get } = useTranslation();

  const handleCollapse = () => {
    setIsCollapsed(!isCollapsed); 
  };

  return (
    <>
      <TableHead>
        <TableRow>
          <>
            <TableCell />
            <TableCell />
            <TableCell>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={handleCollapse}
                  >
                    {isCollapsed ? <KeyboardArrowDownIcon /> : <KeyboardArrowRight />}
                  </IconButton>
              <Typography variant="body1">{/* <b>{get("chemicalComponentTableComponentTitle")}</b> */}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body1">
                <b>{get("chamicalComponentComponentMaterialSubstanceTitle")}</b>
              </Typography>
            </TableCell>
          </>
          {/*<TableCell>
             <Typography variant="body1">
              <b>{get("chemicalComponentSubstanceTableTitle")}</b>
            </Typography>
          </TableCell> */}
          <TableCell>
            <Typography variant="body1">
              <b>{get("chemicalComponentPhaseTableTitle")}</b>
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body1">
              <b>{get("chemicalComponentConcentrationTableTitle")}</b>
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body1">
              <b>{get("chemicalComponentCASTableTitle")}</b>
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body1">
              <b>{get("checmicalComponentECTableTitle")}</b>
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body1">
              <b>{get("checmicalComponentAlternateCodeTableTitle")}</b>
            </Typography>
          </TableCell>
          {hideTable && (
            <>
              <TableCell>
                <Typography variant="body1">
                  <b>{get("checmicalComponentHphraseTableTitle")}</b>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1">
                  <b>{get("chemicalComponentRouteofExposureTableTitle")}</b>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1">
                  <b>{get("chemicalContentCandidateListTableTitle")}</b>{" "}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1">
                  <b>{get("chemicalComponentPhasingTableTitle")}</b>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1">
                  <b>{get("chemicalContentCommentTableTitle")}</b>
                </Typography>
              </TableCell>
            </>
          )}
        </TableRow>
      </TableHead>
    </>
  );
};
