import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import { Loader } from "../../../../../components/Loader/Loader";
import { useTranslation } from "../../../../../helpers/useTranslation";
import { UserInformation } from "./UserInformation";
import { UserInformationAccess } from "./UserInformationAccess";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
type Props = {
  data?: UserGroupUser;
};

export const UserDetail = ({ data }: Props) => {
  const { get } = useTranslation();
  const [value, setTabValue] = useState(0);
  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box bgcolor="white" sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  if (!data) return <Loader />;

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Information" />
          <Tab label={get("userInformationAccess")} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <UserInformation data={data} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <UserInformationAccess data={data} />
      </TabPanel>
    </Box>
  );
};
