import { IPublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { QueryClientProvider, useQueryErrorResetBoundary } from "react-query";
import { useNavigate } from "react-router";
import { RecoilRoot } from "recoil";
import { AppRoutes } from "./components/AppRoutes/AppRoutes";
import { ErrorFallback } from "./components/ErrorFallback/ErrorFallback";
import { Layout } from "./components/Layout/Layout";
import { Loader } from "./components/Loader/Loader";
import { queryClient } from "./config/queryClient";
import { ReactQueryDevtools } from "react-query/devtools";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./AppInsights";
import { CookieAccept } from "./components/CookieAccept/CookieAccept";
import { CustomNavigationClient } from "./utils/NavigationClient";

type Props = {
  pca: IPublicClientApplication;
};

function App({ pca }: Props) {
  const { reset } = useQueryErrorResetBoundary();
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <MsalProvider instance={pca}>
        <RecoilRoot>
          <QueryClientProvider client={queryClient}>
            <Layout>
              <Suspense fallback={<Loader />}>
                <ErrorBoundary onReset={reset} FallbackComponent={ErrorFallback}>
                  <AppRoutes />
                </ErrorBoundary>
              </Suspense>
            </Layout>
            <ReactQueryDevtools initialIsOpen={false} />
                  </QueryClientProvider>
                  <CookieAccept />
        </RecoilRoot>
      </MsalProvider>
    </AppInsightsContext.Provider>
  );
}

export default App;
