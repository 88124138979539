import { useMsal } from "@azure/msal-react";
import { AccountCircle } from "@mui/icons-material";
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "../../../helpers/useTranslation";
import { useGetUser } from "../../../services/userService";

export const AuthenticatedProfileButton = () => {
  const { instance } = useMsal();
  const { get } = useTranslation();

  const logout = () => {
    instance.logoutRedirect();
  };

  const getLogout = () => {
    return {
      name: get("actionLogout"),
      action: logout,
    };
  };

  const settings = [getLogout()];
  const { data } = useGetUser();

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  return (
    <>
      <IconButton onClick={handleOpenUserMenu} sx={{ p: 1 }}>
        <Typography variant="body2" sx={{ mr: 1 }}>
          {data?.Name}
        </Typography>
        <AccountCircle />
      </IconButton>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {settings.map((setting) => (
          <MenuItem key={setting.name} onClick={handleCloseUserMenu}>
            <Typography textAlign="center" onClick={setting.action}>
              {setting.name}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
