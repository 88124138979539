import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "../../../../../../helpers/useTranslation";
import { useGetWasteCodes } from "../../../../../../services/wasteCodeService";

type Props = {
  handleSubmit: any;
  errors: any;
  setValue: any;
  reset: any;
  data?: any;
};

export const MountedArticleTableForm = ({ handleSubmit, errors, setValue, reset, data }: Props) => {
  const { get } = useTranslation();
  const [filter, setFilter] = useState<FilterOptions>({
    TextFilter: "",
  });
  const { data: wasteCodeDataValues } = useGetWasteCodes(filter);

  useEffect(() => {
    if (data !== undefined) reset(data);
    else
      reset({
        WasteCodeMountedId: 0,
      });
  }, [data, reset]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container rowSpacing={1} columnSpacing={{ md: 2.3 }}>
          <Grid item xs={12} sx={{ mt: 3, mb: 5 }}>
            <Autocomplete
              noOptionsText={get("noOptionsText")}
              filterOptions={(x) => x}
              disablePortal
              getOptionLabel={(option) => {
                return option.Code + " - " + option.Name;
              }}
              onChange={(_event, value) => {
                setValue("WasteCodeMountedId", value?.WasteCodeId, { shouldValidate: true });
              }}
              onInputChange={(_event, newInputValue) => {
                setFilter({
                  ...filter,
                  TextFilter: newInputValue,
                });
              }}
              options={wasteCodeDataValues?.Items || []}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={get("wasteManagementMountedTableWastecode")}
                  InputLabelProps={{ shrink: true }}
                  required={true}
                />
              )}
            />
            <Typography sx={{ color: "#ff1744", fontSize: "0.75rem", fontWeight: 400, margin: "3px 12px 0px 12px" }}>
              {errors.WasteCodeMountedId?.message}
            </Typography>
          </Grid>
        </Grid>
      </form>
    </>
  );
};
