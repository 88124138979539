import { AuthenticatedTemplate } from "@azure/msal-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal";
import { useFormValidation } from "../../../helpers/useFormValidation";
import { useTranslation } from "../../../helpers/useTranslation";
import { useImportEBVDDocument } from "../../../services/documentService";
import { ImportDocumentForm } from "../ImportDocumentForm/ImportDocumentForm";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  userGroup: UserGroup;
  refetch: () => void;
};

export const ImportDocumentModal = ({ userGroup, open, setOpen, refetch }: Props) => {
  const { get } = useTranslation();
  const [fileName, setFileName] = useState<string>(""); // Track the file name
  const { importDocumentValidationSchema } = useFormValidation();

  const { mutateAsync: importDocument } = useImportEBVDDocument();
  const methods = useForm<ImportDocument>({
    resolver: yupResolver(importDocumentValidationSchema),
  });

  const resetForm = () => {
    setOpen(false);
    methods.reset();
  };

  const handleConfirmation = (documentData: ImportDocument) => {
    importDocument(documentData).then((result: any) => {
      refetch();
    });
    resetForm();
    setFileName("");
  };

  const handleModalClose = () => {
    resetForm();
    setFileName("");
  };

  return (
    <FormProvider {...methods}>
      <ConfirmationModal
        show={open}
        handleClose={() => handleModalClose()}
        handleConfirmation={() => methods.handleSubmit(handleConfirmation)()}
        title={get("importDocumentsModalTitle")}
        saveButtonName={get("importDocuments")}
        cancelButtonName={get("documentCancel")}
      >
        <AuthenticatedTemplate>
          <ImportDocumentForm userGroup={userGroup} fileName={fileName} setFileName={setFileName} />
        </AuthenticatedTemplate>
      </ConfirmationModal>
    </FormProvider>
  );
};
