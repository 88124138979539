import { Button } from "@mui/material";
import { useNavigate } from "react-router";
import { useTranslation } from "../../../../helpers/useTranslation";

export const ImportButton = () => {
  const { get } = useTranslation();
  const navigate = useNavigate();

  return (
    <Button
      onClick={() => navigate("/import")}
      variant="contained"
      sx={{
        ml: 1,
        mt: 1,
      }}
    >
      {get("frontPageImportButton")}
    </Button>
  );
};
