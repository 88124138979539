import { useTranslation } from "./useTranslation";

export const usePhaseStatus = () => {
  const { get } = useTranslation();

  const productPhaseStatus = (productPhaseId?: number) => {
    if (productPhaseId === 1) return get("phaseDeliveryOrMounted");
    else if (productPhaseId === 2) return get("phaseDelivery");
    else if (productPhaseId === 3) return get("phaseMounted");
    else return "";
  };
  return {
    productPhaseStatus,
  };
};
