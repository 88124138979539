import { Button } from "@mui/material";
import { useNavigate } from "react-router";
import { useTranslation } from "../../../../helpers/useTranslation";

export const AdminButton = () => {
  const { get } = useTranslation();
  const navigate = useNavigate();

  return (
    <Button
      onClick={() => navigate("/admin")}
      variant="contained"
      sx={{
        mt: 1,
      }}
    >
      {get("frontPageAdminButton")}
    </Button>
  );
};
