import { useRequest } from "../helpers/useRequest";
import { api } from "../config/apiEndpoints";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";

const url = api.userinvitation;

export const AcceptUserInvitation = () => {
  const { post } = useRequest();
  const navigate = useNavigate();

  return useMutation(
    (data: AcceptUserInvite) => {
      return post(`${url}/AcceptUserInvitation`, data);
    },
    {
      onSuccess: () => {
        navigate("/registrationConfirmation");
      },
    },
  );
};

export const ReInviteUser = () => {
  const { post } = useRequest();
  return useMutation(
    (userInvitationId?: number) =>
      post(`${url}/ReinviteUser`, {
        UserInvitationId: userInvitationId,
      }),
    {},
  );
};
