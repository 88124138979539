import { Grid, Typography } from "@mui/material";
import { useTranslation } from "../../../../../../helpers/useTranslation";
import { CustomTextField } from "../../../../../../components/CustomTextField/CustomTextField";
import { CustomRadioButton } from "../../../../../../components/CustomRadioButton/CustomRadioButton";
import { useFormContext } from "react-hook-form";
import ErrorIcon from "@mui/icons-material/Error";
import { ErrorMessage } from "@hookform/error-message";

type Props = {
  data?: ProductConstruction;
  DocumentIsReadOnly: boolean;
};

export const ConstructionInformation = ({ data, DocumentIsReadOnly }: Props) => {
  const { get } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useFormContext();
  return (
    <form onSubmit={handleSubmit as any}>
      <Grid container rowSpacing={1} columnSpacing={{ md: 3 }}>
        <Grid item md={12}>
          <Typography>{get("hasStorageRequirements")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <CustomRadioButton
            name={"ProductConstruction.HasStorageRequirements"}
            controlValue={control}
            dataValue={data?.HasStorageRequirements}
            isNA={true}
            yesValue={1}
            noValue={2}
            disabled={DocumentIsReadOnly}
            errorFieldMessage={errors?.HasStorageRequirements?.message}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 1 }}>
          <CustomTextField
            name={"ProductConstruction.StorageRequirements"}
            isDisabled={DocumentIsReadOnly}
            controlValue={control}
            defaultValue={data?.StorageRequirements}
            isMultiLine={true}
            rowSpan={4}
            label={get("storageRequirements")}
            errors={errors}
          />
        </Grid>
        <Grid item md={0} sx={{ mt: 3 }}>
          <ErrorMessage
            message=""
            as={
              <ErrorIcon
                color="error"
                style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "center" }}
              />
            }
            errors={errors}
            name="ProductConstruction.StorageRequirements"
          />
        </Grid>
        <Grid item md={11} sx={{ mt: 3 }} style={{ padding: "0" }}>
          <ErrorMessage
            as={<Typography variant="subtitle1" sx={{ color: "#E95450", margin: "3px 12px 0px 12px" }} />}
            errors={errors}
            name="ProductConstruction.StorageRequirements"
          />
        </Grid>
        <Grid item md={12} sx={{ mt: 2 }}>
          <Typography>{get("hasRequirementsOnSurroundingBuildingProducts")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <CustomRadioButton
            name={"ProductConstruction.HasRequirementsOnSurroundingBuildingProducts"}
            controlValue={control}
            dataValue={data?.HasRequirementsOnSurroundingBuildingProducts}
            isNA={true}
            yesValue={1}
            noValue={2}
            disabled={DocumentIsReadOnly}
            errorFieldMessage={errors?.HasRequirementsOnSurroundingBuildingProducts?.message}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 1 }}>
          <CustomTextField
            name={"ProductConstruction.RequirementsOnSurroundingBuildingProducts"}
            isDisabled={DocumentIsReadOnly}
            controlValue={control}
            defaultValue={data?.RequirementsOnSurroundingBuildingProducts}
            isMultiLine={true}
            rowSpan={4}
            label={get("requirementsOnSurroundingBuildingProducts")}
            errors={errors}
          />
        </Grid>
        <Grid item md={0} sx={{ mt: 3 }}>
          <ErrorMessage
            message=""
            as={
              <ErrorIcon
                color="error"
                style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "center" }}
              />
            }
            errors={errors}
            name="ProductConstruction.RequirementsOnSurroundingBuildingProducts"
          />
        </Grid>
        <Grid item md={11} sx={{ mt: 3 }} style={{ padding: "0" }}>
          <ErrorMessage
            as={<Typography variant="subtitle1" sx={{ color: "#E95450", margin: "3px 12px 0px 12px" }} />}
            errors={errors}
            name="ProductConstruction.RequirementsOnSurroundingBuildingProducts"
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 3 }}>
          <CustomTextField
            name={"ProductConstruction.Comment"}
            isDisabled={DocumentIsReadOnly}
            controlValue={control}
            defaultValue={data?.Comment}
            isMultiLine={true}
            rowSpan={4}
            label={get("constructionPhaseComment")}
            errors={errors}
          />
        </Grid>
      </Grid>
    </form>
  );
};
