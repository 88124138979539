import { Typography, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "../../../helpers/useTranslation";
import { Box } from "@mui/system";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { useState } from "react";
import { CreateDocumentModal } from "../../Documents/CreateDocumentModal/CreateDocumentModal";
import { AdminAuthenticationTemplate } from "../../../components/AdminAuthenticationTemplate/AdminAuthenticationTemplate";
import { AdminButton } from "./AdminButton/AdminButton";
import { useGetUser } from "../../../services/userService";
import { ImportAuthenticationTemplate } from "../../../components/ImportAuthenticationTemplate/ImportAuthenticationTemplate";
import { ImportButton } from "./ImportButton/ImportButton";

export const WelcomeSection = () => {
  const { get } = useTranslation();
  const navigate = useNavigate();
  const [showAdd, setShowAdd] = useState<boolean>(false);
  const { data } = useGetUser();

  return (
    <>
      <CreateDocumentModal open={showAdd} setOpen={setShowAdd} />
      <Grid container sx={{ p: 3, padding: "30px", paddingBottom: "50px", paddingTop: "34px", paddingLeft: "50px" }}>
        <Grid item md={8}>
          <Typography variant="h5" color="black">
            {get("welcomeSectionTitle")}
          </Typography>

          <Typography variant="subtitle1" sx={{ mt: 2, mb: 3 }}>
            {get("welcomeSectionContent")}
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 2, mb: 2 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant="subtitle1" sx={{ textDecoration: "none" }}>
                {get("welcomeSectionContentToHomepage")}
              </Typography>
              <Typography
                variant="subtitle1"
                color="#44a4b7"
                sx={{ textDecoration: "none", marginLeft: "4px" }}
                component="a"
                href="https://www.ivl.se/ebvd"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.ivl.se/ebvd
              </Typography>{" "}
              <OpenInNewIcon
                sx={{
                  color: "#44a4b7",
                  ml: 2,
                  mr: 4,
                }}
              />
            </Box>
          </Typography>
          <Box>
            <Typography
              variant="subtitle1"
              color="#44a4b7"
              sx={{ textDecoration: "none" }}
              component={Link}
              to={`/about`}
            >
              {get("welcomeSectionContentAbouteBVD")}
            </Typography>
          </Box>
          <Box sx={{ mt: 2, mb: 2, display: "flex", alignItems: "center" }}>
            <Typography variant="subtitle1" sx={{ textDecoration: "none", mr: 1 }}>
              {get("welcomeSectionQuestionsAbouteBVD")}
            </Typography>
            <Typography
              variant="subtitle1"
              color="#44a4b7"
              sx={{ textDecoration: "none" }}
              component="a"
              href="mailto:eBVD@ivl.se"
            >
              eBVD@ivl.se
            </Typography>
          </Box>
        </Grid>
        <Grid item container justifyContent="flex-end" md={4} height="100%">
          {/* <Button
            onClick={() => navigate("/digitalepd")}
            variant="contained"
            sx={{
              mr: 1,
              mt: 1,
            }}
          >
            {get("digitalEPD")}
          </Button> */}
          <AdminAuthenticationTemplate>
            <AdminButton />
          </AdminAuthenticationTemplate>
          <AuthenticatedTemplate>
            <Button
              onClick={() => navigate("/documents")}
              variant="contained"
              sx={{
                ml: 1,
                mt: 1,
              }}
            >
              {get("manageeBVD")}
            </Button>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Button
              onClick={() => navigate("/documents")}
              variant="contained"
              sx={{
                ml: 1,
                mt: 1,
              }}
            >
              {get("searcheBVD")}
            </Button>
          </UnauthenticatedTemplate>
          <AuthenticatedTemplate>
            {data && data?.UserGroups.filter((x) => x.CanCreateNew).length > 0 && (
              <Button
                onClick={() => setShowAdd(true)}
                startIcon={<AddIcon />}
                variant="contained"
                sx={{
                  ml: 1,
                  mt: 1,
                }}
              >
                {get("frontPageNewDocumentButton")}
              </Button>
            )}
          </AuthenticatedTemplate>
          {/* Only shows button if import is not invalid for the user */}
          {data && !data.InvalidApplicationFeatureIds.includes(1) && (
            <ImportAuthenticationTemplate>
              <ImportButton />
            </ImportAuthenticationTemplate>
          )}
        </Grid>
      </Grid>
    </>
  );
};
