// All types for access, for type safety
export type AccessType =
  | 1001
  | 1002
  | 1003
  | 1100
  | 1101
  | 1102
  | 1103
  | 1004
  | 1200
  | 1201
  | 1202
  | 1203
  | 1005
  | 1006
  | 1007
  | 1008
  | 1009
  | 1010
  | 1011;

export type TypeKeys = Record<AccessType, string>;

export const AccessTypeKeys: TypeKeys = {
  1001: "CompanyInformation",
  1002: "ArticleInformation",
  1003: "ChemicalContent",
  1100: "CASnumber",
  1101: "ECnumber",
  1102: "AlternativeCode",
  1103: "Substance",
  1004: "RawMaterials",
  1200: "CountryOfRawMaterialExtraction",
  1201: "CityOfRawMaterialExtraction",
  1202: "CountryOfManufacture",
  1203: "CityOfManufacture",
  1005: "EnvironmentalImpact",
  1006: "Distribution",
  1007: "ConstructionPhase",
  1008: "UsagePhase",
  1009: "Demolition",
  1010: "WasteManagement",
  1011: "IndoorEnvironment",
};

// The props for each access type, canRead is required, canReadConfidential is optional
export interface AccessProps {
  canRead: boolean;
  canReadConfidential?: boolean;
}

// The public access preset, a record of access types and their props
export type IPublicAccessPreset = Record<AccessType, AccessProps>;

// A public access preset, an example with mocked access props
export const PublicAccessAllInformation: IPublicAccessPreset = {
  1001: { canRead: true },
  1002: { canRead: true },
  1003: { canRead: true },
  1100: { canRead: true, canReadConfidential: true },
  1101: { canRead: true, canReadConfidential: true },
  1102: { canRead: true, canReadConfidential: true },
  1103: { canRead: true, canReadConfidential: true },
  1004: { canRead: true },
  1200: { canRead: true, canReadConfidential: true },
  1201: { canRead: true, canReadConfidential: true },
  1202: { canRead: true, canReadConfidential: true },
  1203: { canRead: true, canReadConfidential: true },
  1005: { canRead: true },
  1006: { canRead: true },
  1007: { canRead: true },
  1008: { canRead: true },
  1009: { canRead: true },
  1010: { canRead: true },
  1011: { canRead: true },
};

export const PublicAccessNoConfidentialInformation: IPublicAccessPreset = {
  1001: { canRead: true },
  1002: { canRead: true },
  1003: { canRead: true },
  1100: { canRead: true, canReadConfidential: false },
  1101: { canRead: true, canReadConfidential: false },
  1102: { canRead: true, canReadConfidential: false },
  1103: { canRead: true, canReadConfidential: false },
  1004: { canRead: true },
  1200: { canRead: true, canReadConfidential: false },
  1201: { canRead: true, canReadConfidential: false },
  1202: { canRead: true, canReadConfidential: false },
  1203: { canRead: true, canReadConfidential: false },
  1005: { canRead: true },
  1006: { canRead: true },
  1007: { canRead: true },
  1008: { canRead: true },
  1009: { canRead: true },
  1010: { canRead: true },
  1011: { canRead: true },
};
export const PublicAccessMinimumInformation: IPublicAccessPreset = {
  1001: { canRead: true },
  1002: { canRead: true },
  1003: { canRead: false },
  1100: { canRead: false, canReadConfidential: false },
  1101: { canRead: false, canReadConfidential: false },
  1102: { canRead: false, canReadConfidential: false },
  1103: { canRead: false, canReadConfidential: false },
  1004: { canRead: false },
  1200: { canRead: false, canReadConfidential: false },
  1201: { canRead: false, canReadConfidential: false },
  1202: { canRead: false, canReadConfidential: false },
  1203: { canRead: false, canReadConfidential: false },
  1005: { canRead: false },
  1006: { canRead: false },
  1007: { canRead: false },
  1008: { canRead: false },
  1009: { canRead: false },
  1010: { canRead: false },
  1011: { canRead: false },
};

const parseDataItem = (item?: DataItem | ChildItem) => {
  if (item) {
    return {
      canRead: item.CanRead,
      canReadConfidential: !!item.ConfidentialityType,
    } as AccessProps;
  }
  return {
    canRead: false,
    canReadConfidential: false,
  };
};

export const PublicAccessSelectedInformation = (data?: InformationPermission) => {
  const PublicAccessSelectedInformation: IPublicAccessPreset = {
    1001: parseDataItem(data?.DataItems[0]),
    1002: parseDataItem(data?.DataItems[1]),

    1003: parseDataItem(data?.DataItems[2]),
    1100: parseDataItem(data?.DataItems[2].ChildItems ? data?.DataItems[2].ChildItems[0] : undefined),
    1101: parseDataItem(data?.DataItems[2].ChildItems ? data?.DataItems[2].ChildItems[1] : undefined),
    1102: parseDataItem(data?.DataItems[2].ChildItems ? data?.DataItems[2].ChildItems[2] : undefined),
    1103: parseDataItem(data?.DataItems[2].ChildItems ? data?.DataItems[2].ChildItems[3] : undefined),

    1004: parseDataItem(data?.DataItems[3]),
    1200: parseDataItem(data?.DataItems[3].ChildItems ? data?.DataItems[3].ChildItems[0] : undefined),
    1201: parseDataItem(data?.DataItems[3].ChildItems ? data?.DataItems[3].ChildItems[1] : undefined),
    1202: parseDataItem(data?.DataItems[3].ChildItems ? data?.DataItems[3].ChildItems[2] : undefined),
    1203: parseDataItem(data?.DataItems[3].ChildItems ? data?.DataItems[3].ChildItems[3] : undefined),

    1005: parseDataItem(data?.DataItems[4]),
    1006: parseDataItem(data?.DataItems[5]),
    1007: parseDataItem(data?.DataItems[6]),
    1008: parseDataItem(data?.DataItems[7]),
    1009: parseDataItem(data?.DataItems[8]),
    1010: parseDataItem(data?.DataItems[9]),
    1011: parseDataItem(data?.DataItems[10]),
  };

  return PublicAccessSelectedInformation;
};
