import { memo } from "react";
import { TableCell } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { useHPhraseDisplay } from "../../../../../../helpers/useHPhraseDisplay";
import React from "react";

type Props = {
  productContentComponent?: ProductContentComponent;
  productContentComponentForTree?: ProductContentComponentForTree;
  hideTable: boolean;
  hPhrasesData?: HPhrases;
};

export const ArticleSubComponentTableRowCellGroup = memo(
  ({ productContentComponent, productContentComponentForTree, hideTable, hPhrasesData }: Props) => {
    const { hPhraseDataDisplay } = useHPhraseDisplay();

    return (
      <>
        {productContentComponentForTree?.ProductContentComponentType === 0 ||
        productContentComponentForTree?.ProductContentComponentType === 1 ? (
          <>
            {productContentComponentForTree?.ExistsInDatabase ? (
              <TableCell>{productContentComponent?.WeightDescription}</TableCell>
            ) : (
              <TableCell />
            )}

            <TableCell />
            <TableCell />
            <TableCell />
            {hideTable && (
              <>
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                {productContentComponentForTree?.ExistsInDatabase ? (
                  <TableCell>{productContentComponent?.Comment}</TableCell>
                ) : (
                  <TableCell />
                )}
              </>
            )}
          </>
        ) : (
          <>
            <TableCell>{productContentComponent?.WeightDescription}</TableCell>
            <TableCell>{productContentComponent?.CASNumber}</TableCell>
            <TableCell>{productContentComponent?.EGNumber}</TableCell>
            <TableCell>{productContentComponent?.AlternativeCode}</TableCell>
            {hideTable && (
              <>
                <TableCell>
                  {productContentComponent?.ProductContentComponentHazardPhraseItems &&
                    hPhraseDataDisplay(productContentComponent?.ProductContentComponentHazardPhraseItems, hPhrasesData)}
                </TableCell>
                <TableCell>{productContentComponent?.HazardPhraseComment}</TableCell>
                <TableCell>
                  {productContentComponent?.SubstanceIsOnCandidateList ? (
                    <CheckBoxIcon
                      style={{
                        color: "green",
                        fontSize: "30px",
                        paddingLeft: "10px",
                        paddingTop: "10px",
                      }}
                    />
                  ) : (
                    <CheckBoxOutlineBlankIcon
                      style={{
                        color: "green",
                        fontSize: "30px",
                        paddingLeft: "10px",
                        paddingTop: "10px",
                      }}
                    />
                  )}
                </TableCell>
                <TableCell>
                  {productContentComponent?.SubstanceHasPhasingOutProperties ? (
                    <CheckBoxIcon
                      style={{
                        color: "green",
                        fontSize: "30px",
                        paddingLeft: "10px",
                        paddingTop: "10px",
                      }}
                    />
                  ) : (
                    <CheckBoxOutlineBlankIcon
                      style={{
                        color: "green",
                        fontSize: "30px",
                        paddingLeft: "10px",
                        paddingTop: "10px",
                      }}
                    />
                  )}
                </TableCell>
                <TableCell>{productContentComponent?.Comment}</TableCell>
              </>
            )}
          </>
        )}
      </>
    );
  },
);
