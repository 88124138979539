import { useRequest } from "../helpers/useRequest";
import { api } from "../config/apiEndpoints";
import { queryEnums } from "../config/queryEnums";
import { useQuery } from "react-query";

const transactionItemUrl = api.transactionitem;

export const GetTransactionItems = (filter: any) => {
  const { post } = useRequest();
  return useQuery<TransactionItems>(
    [queryEnums.transactionItems, filter.UserGroupIds],
    () =>
      post(`${transactionItemUrl}/GetTransactionItems`, {
        PageItem: {
          SortType: 0,
          SortAscending: false,
          RowCount: 20,
        },
        UserGroupIds: filter.UserGroupIds,
        TransactionItemTypeIds: filter.TransactionItemTypeIds,
      }),
    {
      enabled: Boolean(filter.UserGroupIds),
      suspense: false,
    },
  );
};
