import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Box, Divider } from "@mui/material";
import ListItem from "@mui/material/ListItem";

type Props = {
  errorMessageValue: any;
  title: string;
  show: boolean;
  handleClose: () => void;
  cancelButtonName: string;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ConfirmationErrorMessageModal = ({ errorMessageValue, title, show, handleClose, cancelButtonName }: Props) => {
  return (
    <>
      <Dialog
        open={show}
        TransitionComponent={Transition}
        keepMounted
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
            },
          },
        }}
      >
        <DialogTitle sx={{ color: "error.main" }}>{title}</DialogTitle>
        <Divider />
        <DialogContent>
          <ListItem sx={{ whiteSpace: "pre-wrap", padding: "0px", color: "error.main" }}>
            <Box>{errorMessageValue}</Box>
          </ListItem>
        </DialogContent>
        <DialogActions>
          <Box sx={{ textAlign: "right", px: 2 }}>
            <Button onClick={handleClose} variant="outlined" type="submit" sx={{ m: "1rem 1rem" }}>
              {cancelButtonName}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmationErrorMessageModal;
