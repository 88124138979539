import { Typography, Grid, CardContent } from "@mui/material";
import { useTranslation } from "../../../helpers/useTranslation";

export const NewsListSection = () => {
  const { get } = useTranslation();

  return (
    <>
      <Grid
        container
        sx={{
          p: 3,
          backgroundColor: "#f3f8f9",
          padding: "30px",
          paddingBottom: "50px",
          paddingLeft: "50px",
          paddingTop: "40px",
        }}
      >
        <Grid item md={12} sx={{ mb: 2 }}>
          <Typography variant="h5" color="black">
            {get("newslistSectionTitle")}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <CardContent sx={{ rowGap: 1, backgroundColor: "#f3f8f9" }}>
            <Typography sx={{ fontWeight: "bold", fontSize: "16px" }}>{get("newsTitle3")}</Typography>
            <Typography variant="body2">2023-11-28</Typography>
            <Typography variant="subtitle1">{get("newsDesc3")}</Typography>
          </CardContent>
        </Grid>
        <Grid item xs={4}>
          <CardContent sx={{ rowGap: 1, backgroundColor: "#f3f8f9" }}>
            <Typography sx={{ fontWeight: "bold", fontSize: "16px" }}>{get("newsTitle2")}</Typography>
            <Typography variant="body2">2023-11-06</Typography>
            <Typography variant="subtitle1">{get("newsDesc2")}</Typography>
          </CardContent>
        </Grid>

        <Grid item xs={4}>
          <CardContent sx={{ rowGap: 1, backgroundColor: "#f3f8f9" }}>
            <Typography sx={{ fontWeight: "bold", fontSize: "16px" }}>{get("newsTitle1")}</Typography>
            <Typography variant="body2">2023-10-17</Typography>
            <Typography variant="subtitle1">{get("newsDesc1")}</Typography>
          </CardContent>
        </Grid>
      </Grid>
    </>
  );
};
