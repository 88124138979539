import RawMaterialsTable from "./RawMaterialsTable/RawMaterialsTable";
import { RenewableMaterial } from "./RenewableMaterial/RenewableMaterial";
import { TotalRecycledMaterial } from "./TotalRecycledMaterial/TotalRecycledMaterial";
import { WoodenRawMaterial } from "./WoodenRawMaterial/WoodenRawMaterial";
import { OriginRawMaterial } from "./OriginRawMaterial/OriginRawMaterial";
import { useTranslation } from "../../../../../helpers/useTranslation";
import { ComponentLayout } from "../../../../../components/ComponentLayout/ComponentLayout";
import { useFormContext } from "react-hook-form";

type Props = {
  data?: EBVDDocument;
};

export const RawMaterial = ({ data }: Props) => {
  const { get } = useTranslation();
  const { handleSubmit } = useFormContext();

  const components = [
    {
      component: <RawMaterialsTable data={data} />,
      title: get("sectionRawMaterials"),
    },
    {
      component: <TotalRecycledMaterial data={data} />,
      title: get("sectionTotalRecycledMaterial"),
    },
    {
      component: (
        <RenewableMaterial data={data?.ProductRawMaterial} DocumentIsReadOnly={data?.DocumentIsReadOnly || false} />
      ),
      title: get("renewableMaterial"),
    },
    {
      component: (
        <OriginRawMaterial data={data?.ProductRawMaterial} DocumentIsReadOnly={data?.DocumentIsReadOnly || false} />
      ),
      title: get("originRawMaterial"),
    },
    {
      component: (
        <WoodenRawMaterial data={data?.ProductRawMaterial} DocumentIsReadOnly={data?.DocumentIsReadOnly || false} />
      ),
      title: get("woodenRawMaterial"),
    },
  ];

  return (
    <>
      <form onSubmit={handleSubmit as any}>
        {components.map((comp, idx) => (
          <ComponentLayout key={idx} title={comp.title} index={idx + 1} length={components.length}>
            {comp.component}
          </ComponentLayout>
        ))}
      </form>
    </>
  );
};
