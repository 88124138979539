import { Typography, Grid, Link } from "@mui/material";
import Button from "@mui/material/Button";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "../../../helpers/useTranslation";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { useState } from "react";
import { CreateDocumentModal } from "../../Documents/CreateDocumentModal/CreateDocumentModal";
import { AdminAuthenticationTemplate } from "../../../components/AdminAuthenticationTemplate/AdminAuthenticationTemplate";
import { useGetUser } from "../../../services/userService";
import { AdminButton } from "../../Index/RightSideContent/AdminButton/AdminButton";
import { Box } from "@mui/system";

export const AboutSection = () => {
  const { get, getCurrentLanguage } = useTranslation();
  const navigate = useNavigate();
  const [showAdd, setShowAdd] = useState<boolean>(false);
  const { data } = useGetUser();

  const openLink = (fileId: number) => {
    window.open(
      "https://ivl-ebvd.azurewebsites.net/api/V2/FileItem/GetDataItemAsStream/" + fileId,
      "_blank",
    );
  };

  return (
    <>
      <CreateDocumentModal open={showAdd} setOpen={setShowAdd} />
      <Grid container sx={{ p: 3, padding: "30px", paddingBottom: "50px", paddingTop: "34px", paddingLeft: "50px" }}>
        <Grid item md={8}>
          <Typography variant="h5" color="black">
            {get("aboutSectionTitle")}
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 2 }}>
            {get("aboutSectionContent1")}
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 2, mb: 4 }}>
            {get("aboutSectionContent2")}
            <a href="mailto:eBVD@ivl.se" style={{ color: "#44a4b7", textDecoration: "none" }}>
              eBVD@ivl.se
            </a>
          </Typography>
          <Grid item md={12} sx={{ mt: 2 }}>
            <Box
              sx={{
                display: "flex",
                color: "#44a4b7",
                alignItems: "center",
                marginBottom: 2,
              }}
            >
              <Link
                component="button"
                target="_blank"
                rel="noreferrer"
                underline="none"
                onClick={() => {
                  var fileId = 0;
                  if (getCurrentLanguage() === "sv") {
                    fileId = 3;
                  } else if (getCurrentLanguage() === "en") {
                    fileId = 4;
                  }
                  openLink(fileId);
                }}
              >
                <Typography variant="h5" color="#44a4b7">
                  {get("instructions")}
                </Typography>
              </Link>
              <OpenInNewIcon
                sx={{
                  ml: 2,
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                color: "#44a4b7",
                alignItems: "center",
                marginBottom: 2,
              }}
            >
              <Link
                component="button"
                target="_blank"
                rel="noreferrer"
                underline="none"
                onClick={() => {
                  var fileId = 0;
                  if (getCurrentLanguage() === "sv") {
                    fileId = 23;
                  } else if (getCurrentLanguage() === "en") {
                    fileId = 24;
                  }
                  openLink(fileId);
                }}
              >
                <Typography variant="h5" color="#44a4b7">
                  {get("instructionsImportTemplate")}
                </Typography>
              </Link>
              <OpenInNewIcon
                sx={{
                  ml: 2,
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                color: "#44a4b7",
                alignItems: "center",
                marginBottom: 2,
              }}
            >
              <Link
                component="button"
                target="_blank"
                rel="noreferrer"
                underline="none"
                onClick={() => {
                  var fileId = 0;
                  if (getCurrentLanguage() === "sv") {
                    fileId = 8;
                  } else if (getCurrentLanguage() === "en") {
                    fileId = 9;
                  }
                  openLink(fileId);
                }}
              >
                <Typography variant="h5" color="#44a4b7">
                  {get("FAQ")}
                </Typography>
              </Link>
              <OpenInNewIcon
                sx={{
                  ml: 2,
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                color: "#44a4b7",
                alignItems: "center",
                marginBottom: 2,
              }}
            >
              <Link
                component="button"
                target="_blank"
                rel="noreferrer"
                underline="none"
                onClick={() => {
                  var fileId = 0;
                  if (getCurrentLanguage() === "sv") {
                    fileId = 10;
                  } else if (getCurrentLanguage() === "en") {
                    fileId = 11;
                  }
                  openLink(fileId);
                }}
              >
                <Typography variant="h5" color="#44a4b7">
                  {get("userManual")}
                </Typography>
              </Link>
              <OpenInNewIcon
                sx={{
                  ml: 2,
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                color: "#44a4b7",
                alignItems: "center",
                marginBottom: 2,
              }}
            >
              <Link
                component="button"
                target="_blank"
                rel="noreferrer"
                underline="none"
                onClick={() => {
                  var fileId = 0;
                  if (getCurrentLanguage() === "sv") {
                    fileId = 12;
                  } else if (getCurrentLanguage() === "en") {
                    fileId = 13;
                  }
                  openLink(fileId);
                }}
              >
                <Typography variant="h5" color="#44a4b7">
                  {get("privacyPolicy")}
                </Typography>
              </Link>
              <OpenInNewIcon
                sx={{
                  ml: 2,
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                color: "#44a4b7",
                alignItems: "center",
                marginBottom: 2,
              }}
            >
              <Link
                component="button"
                target="_blank"
                rel="noreferrer"
                underline="none"
                onClick={() => {
                  var fileId = 0;
                  if (getCurrentLanguage() === "sv") {
                    fileId = 14;
                  } else if (getCurrentLanguage() === "en") {
                    fileId = 15;
                  }
                  openLink(fileId);
                }}
              >
                <Typography variant="h5" color="#44a4b7">
                  {get("userTerms")}
                </Typography>
              </Link>
              <OpenInNewIcon
                sx={{
                  ml: 2,
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                color: "#44a4b7",
                alignItems: "center",
                marginBottom: 2,
              }}
            >
              <Link
                component="button"
                target="_blank"
                rel="noreferrer"
                underline="none"
                onClick={() => {
                  var fileId = 0;
                  if (getCurrentLanguage() === "sv") {
                    fileId = 1;
                  } else if (getCurrentLanguage() === "en") {
                    fileId = 2;
                  }
                  openLink(fileId);
                }}
              >
                <Typography variant="h5" color="#44a4b7">
                  {get("fees")}
                </Typography>
              </Link>
              <OpenInNewIcon
                sx={{
                  ml: 2,
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item container justifyContent="flex-end" md={4} height="100%">
          <AdminAuthenticationTemplate>
            <AdminButton />
          </AdminAuthenticationTemplate>
          <AuthenticatedTemplate>
            <Button
              onClick={() => navigate("/documents")}
              variant="contained"
              sx={{
                ml: 1,
                mt: 1,
              }}
            >
              {get("manageeBVD")}
            </Button>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Button
              onClick={() => navigate("/documents")}
              variant="contained"
              sx={{
                ml: 1,
                mt: 1,
              }}
            >
              {get("searcheBVD")}
            </Button>
          </UnauthenticatedTemplate>
          <AuthenticatedTemplate>
            {data && data?.UserGroups.filter((x) => x.CanCreateNew).length > 0 && (
              <Button
                onClick={() => setShowAdd(true)}
                startIcon={<AddIcon />}
                variant="contained"
                sx={{
                  ml: 1,
                  mt: 1,
                }}
              >
                {get("frontPageNewDocumentButton")}
              </Button>
            )}
          </AuthenticatedTemplate>
        </Grid>
      </Grid>
    </>
  );
};
