import { WelcomeSection } from "./WelcomeSection";
import { NewsListSection } from "./NewsListSection";
import { ContactSection } from "./ContactSection";
import { Box } from "@mui/material";

export const RightSideContent = () => {
  return (
    <Box role="tabpanel" sx={{ width: "100%" }}>
      <Box bgcolor="white" sx={{ minHeight: "70vh" }}>
        <WelcomeSection />
        <NewsListSection />
        <ContactSection />
      </Box>
    </Box>
  );
};
