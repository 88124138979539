import { Controller, useFormContext, useWatch } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { Grid, Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTranslation } from "../../../helpers/useTranslation";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import NumberFormat from "react-number-format";
import { DocumentIdSystem } from "../../../constants/documentIdConstants";
import { useDocumentIdSystem } from "../../../helpers/useDocumentIdSystem";
import { CustomTextField } from "../../../components/CustomTextField/CustomTextField";
import { CustomCheckBox } from "../../../components/CustomCheckBox/CustomCheckBox";
import { useGetUser } from "../../../services/userService";
import { useGetUserGroup } from "../../../services/userGroupService";
import { useGetDateFnsLocale } from "../../../helpers/useGetDateFnsLocale";

type Props = {
  setDocumentID: any;
  documentID: any;
};

export const CreateDocumentForm = ({ documentID, setDocumentID }: Props) => {
  const { get } = useTranslation();
  const { formatDocumentIDSystem, documentSystemCode } = useDocumentIdSystem();
  const {
    control,
    handleSubmit,
    setValue,
    register,
    formState: { errors },
  } = useFormContext<CreateDocument>();
  const userGroupId = useWatch({
    control,
    name: "UserGroupId",
  });
  const { data: userGroup } = useGetUserGroup(userGroupId);
  const { data } = useGetUser();

  const documentFunction = (id: string) => {
    setDocumentID(id);
    setValue("DocumentSystemId", id);
    setValue("DocumentSystemCode", documentSystemCode(id, userGroup || ({} as UserGroup)));
  };

  const dateFnsLocale = useGetDateFnsLocale();

  return (
    <form onSubmit={handleSubmit as any}>
      <Grid container sx={{ mt: 2 }} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <CustomTextField
            name={"ProductName"}
            isDisabled={false}
            controlValue={control}
            defaultValue={""}
            isMultiLine={false}
            rowSpan={0}
            label={get("documentModalProductName") + " *"}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 1 }}>
          <Controller
            name="UserGroupId"
            control={control}
            render={({ field }) => (
              <FormControl variant="outlined" sx={{ width: "100%" }}>
                <InputLabel shrink>{get("documentModalOrganisation") + " *"}</InputLabel>
                <Select
                  {...field}
                  labelId={get("documentModalOrganisation")}
                  error={!!errors.UserGroupId}
                  input={<OutlinedInput notched label={get("documentModalOrganisation")} />}
                >
                  {data?.UserGroups.filter((x) => x.CanCreateNew).map((item: EBVDUserGroup) => (
                    <MenuItem key={item.UserGroupId} value={item.UserGroupId}>
                      {item.UserGroupName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
          <Typography sx={{ color: "#ff1744", fontSize: "0.75rem", fontWeight: 400, margin: "3px 12px 0px 12px" }}>
            {errors.UserGroupId?.message}
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ mt: 1 }}>
          <Controller
            name="DocumentSystemId"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={() => (
              <FormControl variant="outlined" sx={{ width: "100%" }}>
                <InputLabel shrink>{get("documentModalDocumentIDSystem") + " *"}</InputLabel>
                <Select
                  onChange={(e) => documentFunction(e.target.value)}
                  value={documentID}
                  error={!!errors.DocumentSystemId}
                  inputProps={register("DocumentSystemId")}
                  input={<OutlinedInput notched label={get("documentModalDocumentIDSystem")} />}
                >
                  {DocumentIdSystem.map((item: any) => (
                    <MenuItem key={item.key} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
          <Typography sx={{ color: "#ff1744", fontSize: "0.75rem", fontWeight: 400, margin: "3px 12px 0px 12px" }}>
            {errors.DocumentSystemId?.message}
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ mt: 1 }}>
          <Controller
            control={control}
            name="DocumentSystemCode"
            render={({ field }) => (
              <NumberFormat
                {...field}
                displayType="input"
                type="text"
                sx={{ width: "100%" }}
                customInput={TextField}
                spellCheck="false"
                disabled={documentID ? false : true}
                format={formatDocumentIDSystem(documentID)}
                error={!!errors.DocumentSystemCode}
                helperText={errors.DocumentSystemCode ? errors.DocumentSystemCode?.message : ""}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} sx={{ mt: 1 }}>
          <Controller
            name="DocumentValidFromDate"
            control={control}
            defaultValue={new Date()}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={dateFnsLocale}>
                <DesktopDatePicker
                  label={get("documentModalValidFrom")}
                  inputFormat="yyyy/MM/dd"
                  value={field.value}
                  onChange={field.onChange}
                  renderInput={(params) => <TextField {...params} sx={{ width: "100%" }} />}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>
        <Grid item xs={6} sx={{ mt: 2.5, p: 0 }}>
          <CustomCheckBox
            name="IsTemplate"
            label={get("documentModalTemplate")}
            dataValue={false}
            controlValue={control}
          />
        </Grid>
      </Grid>
    </form>
  );
};
