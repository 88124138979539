import { useRequest } from "../helpers/useRequest";
import { api } from "../config/apiEndpoints";
import { queryEnums } from "../config/queryEnums";
import { useQuery } from "react-query";

const comparisonOperatorUrl = api.comparisonoperator;

// export const useGetCountries = () => {
//   const { post } = useRequest();

//   return useQuery<Countries>(
//     [queryEnums.countries],
//     () =>
//       post(`${countryUrl}/GetDataItems`, {
//         SortType: 0,
//         SortAscending: true,
//       }),
//     {
//       suspense: false,
//       refetchOnWindowFocus: false,
//     },
//   );
// };

export const useGetComparisonOperatorDataItems = () => {
  const { post } = useRequest();
  return useQuery<ComparisonOperators>(
    [queryEnums.comparisonOperators],
    () =>
      post(`${comparisonOperatorUrl}/GetDataItems`, {
        PageItem: {
          SortType: 0,
          SortAscending: true,
        },
      }),
    {
      enabled: Boolean(true),
      suspense: false,
    },
  );
};
