import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { get } from "lodash";

type Props = {
  name: string;
  isDisabled: boolean;
  controlValue: any;
  defaultValue: any;
  isMultiLine?: boolean;
  rowSpan?: number;
  label: string;
  errors?: any;
  type?: string;
  onChange?: any;
};

export const CustomTextField = ({
  name,
  isDisabled,
  controlValue,
  defaultValue,
  isMultiLine,
  rowSpan,
  label,
  type = "text",
  errors = {},
  onChange,
}: Props) => {
  return (
    <>
      {!onChange && (
        <Controller
          render={({ field }) => (
            <TextField
              disabled={isDisabled}
              {...field}
              variant="outlined"
              multiline={isMultiLine}
              sx={{ width: "100%" }}
              rows={rowSpan}
              type={type}
              size="medium"
              label={label}
              error={get(errors, name, false)}
              InputLabelProps={{ shrink: true }}
            />
          )}
          name={name}
          control={controlValue}
          defaultValue={defaultValue}
        />
      )}
      {onChange && (
        <Controller
          render={({ field }) => (
            <TextField
              disabled={isDisabled}
              {...field}
              variant="outlined"
              multiline={isMultiLine}
              sx={{ width: "100%" }}
              rows={rowSpan}
              type={type}
              size="medium"
              label={label}
              error={get(errors, name, false)}
              InputLabelProps={{ shrink: true }}
              onChange={onChange}
            />
          )}
          name={name}
          control={controlValue}
          defaultValue={defaultValue}
        />
      )}
    </>
  );
};
