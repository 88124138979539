import { useSetRecoilState } from "recoil";
import { messageAtom } from "../recoil/atom";
import { useTranslation } from "./useTranslation";

export const useMessage = () => {
  const setMessage = useSetRecoilState(messageAtom);
  const { get } = useTranslation();

  const sendUpdated = (entity = "") => {
    setMessage(`${get("toastSaved")} ${entity}`);
  };
  const sendAdded = (entity = "") => {
    setMessage(`${get("toastSaved")} ${entity}`);
  };
  const sendRemoved = (entity = "") => {
    setMessage(`${get("toastDeleted")} ${entity}`);
  };
  const send = (message: string) => {
    setMessage(message);
  };

  return {
    sendUpdated,
    sendAdded,
    sendRemoved,
    send,
  };
};
