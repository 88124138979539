import React, { useState, useEffect } from "react";
import { Box, TextField, Button, Paper, Typography, Grid, Alert } from "@mui/material";
import { UnauthenticatedTemplate, useIsAuthenticated } from "@azure/msal-react";
import { useTranslation } from "../../../../helpers/useTranslation";
import { RowData } from "../../data";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import SaveIcon from "@mui/icons-material/Save";
import { ToolbarButton } from "../../../../components/DocumentToolbar/ToolbarButton/ToolbarButton";

interface GeneralInformationTabProps {
  data: RowData;
  onSave?: (updatedData: Partial<RowData>) => void;
}

export const GeneralInformationTab: React.FC<GeneralInformationTabProps> = ({ data, onSave }) => {
  const { get } = useTranslation();
  const isAuth = useIsAuthenticated();

  // State to manage editable fields
  const [editableData, setEditableData] = useState<Partial<RowData>>({
    registrationNumber: data.registrationNumber || "",
    sourceId: data.sourceId || "",
  });
  const [isDirty, setIsDirty] = useState(false);

  // Update state if `data` prop changes
  useEffect(() => {
    setEditableData({
      registrationNumber: data.registrationNumber || "",
      sourceId: data.sourceId || "",
    });
  }, [data]);

  // Update isDirty flag when editable fields change
  const handleFieldChange = (field: keyof RowData) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setEditableData((prev) => ({ ...prev, [field]: value }));
    setIsDirty(true);
  };

  const handleSave = () => {
    if (onSave && isDirty) {
      onSave(editableData);
      setIsDirty(false);
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: "2", mb: 2 }}>
        <Button
          startIcon={<PictureAsPdfIcon />}
          disabled={!isAuth}
          variant="contained"
          component="label"
          sx={{ mr: 2 }}
        >
          {get("EpdUploadPdf")}
          <input type="file" hidden />
        </Button>
        {isAuth && (
          <ToolbarButton
            status={1}
            icon={<SaveIcon />}
            text={get("toolBarSave")}
            onClick={handleSave}
            disabled={!isDirty}
          />
        )}
      </Box>
      <Paper sx={{ padding: "20px" }}>
        <Typography display={"flex"} variant="h4" gutterBottom>
          {get("EpdGeneralInformation")}
          <UnauthenticatedTemplate>
            <Grid sx={{ ml: 3 }}>
              <Alert severity="info">{get("EpdAlertGeneralInformation")}</Alert>
            </Grid>
          </UnauthenticatedTemplate>
        </Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: 2,
          }}
        >
          <TextField
            label="EPD Status"
            variant="outlined"
            fullWidth
            value={data.publicPrivate ? "Public" : "Private"}
            InputProps={{ readOnly: true }}
            sx={{ my: 2 }}
          />
          <TextField
            label="Created by"
            variant="outlined"
            fullWidth
            value={data.epdOwner}
            InputProps={{ readOnly: true }}
            sx={{ my: 2 }}
          />
          <TextField
            label="EPD Owner"
            variant="outlined"
            fullWidth
            value={data.epdOwner}
            InputProps={{ readOnly: true }}
            sx={{ my: 2 }}
          />
          <TextField
            label="Program Operator"
            variant="outlined"
            fullWidth
            value={data.programOperator || "/text/"}
            InputProps={{ readOnly: true }}
            sx={{ my: 2 }}
          />
          <TextField
            label="Location"
            variant="outlined"
            fullWidth
            value={data.locationCode}
            InputProps={{ readOnly: true }}
            sx={{ my: 2 }}
          />
          <TextField
            label="Product Name"
            variant="outlined"
            fullWidth
            value={data.productName}
            InputProps={{ readOnly: true }}
            sx={{ my: 2 }}
          />
          <TextField
            label="EPD Number"
            variant="outlined"
            fullWidth
            value={data.epdNumber}
            InputProps={{ readOnly: true }}
            sx={{ my: 2 }}
          />
          <TextField
            label="EPD Unique ID"
            variant="outlined"
            fullWidth
            value={data.epdUniqueId || "/UUID, GUID/"}
            InputProps={{ readOnly: true }}
            sx={{ my: 2 }}
          />
          <TextField
            label="EPD Type"
            variant="outlined"
            fullWidth
            value={data.resourceTypeName}
            InputProps={{ readOnly: true }}
            sx={{ my: 2 }}
          />
          <TextField
            label="EPD Sub-type"
            variant="outlined"
            fullWidth
            value={data.resourceSubTypeName}
            InputProps={{ readOnly: true }}
            sx={{ my: 2 }}
          />
          <TextField
            label="Upload PDF EPD"
            variant="outlined"
            fullWidth
            value={data.pdfLink || "/http/"}
            InputProps={{ readOnly: true }}
            sx={{ my: 2 }}
          />
          <TextField
            label="Registration Number"
            variant="outlined"
            fullWidth
            value={editableData.registrationNumber}
            onChange={handleFieldChange("registrationNumber")}
            InputProps={{ readOnly: !isAuth }}
            sx={{ my: 2 }}
          />
          <TextField
            label="Source ID"
            variant="outlined"
            fullWidth
            value={editableData.sourceId}
            onChange={handleFieldChange("sourceId")}
            InputProps={{ readOnly: !isAuth }}
            sx={{ my: 2 }}
          />
        </Box>
      </Paper>
    </>
  );
};
