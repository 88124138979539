import { memo, useCallback, useState, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Button, TableCell, TableRow } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { usePhaseStatus } from "../../../../../../helpers/usePhaseStatus";
import { ArticleSubComponentTableRowCellGroup } from "./ArticleSubComponentTableRowCellGroup";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import EditIcon from "@mui/icons-material/Edit";
import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import { ConfirmationModal } from "../../../../../../components/ConfirmationModal/ConfirmationModal";
import { useTranslation } from "../../../../../../helpers/useTranslation";
import { ArticleSubComponentTableRowForm } from "./ArticleSubComponentTableRowForm";

type Props = {
  productContentComponents: ProductContentComponent[] | undefined;
  productContentComponentForTree: ProductContentComponentForTree | undefined;
  renameContentComponents: any;
  id: string;
  data?: ProductContentComponent;
  hideTable: boolean;
  hPhrasesData?: HPhrases;
  isOpen: boolean;
  selected: string;
  setSelected: any;
  disableEditButton: boolean;
  DocumentIsReadOnly: boolean;
  handleItemClick: (
    id: string | undefined,
    productContentComponentForTree: ProductContentComponentForTree | undefined,
    productContentComponent: ProductContentComponent | undefined,
  ) => void;
  handleEdit: any;
  isCollapsed: boolean;
};

export const ArticleSubComponentTableRow = memo(
  ({
    productContentComponents,
    productContentComponentForTree,
    renameContentComponents,
    id,
    data,
    hideTable,
    hPhrasesData,
    isOpen,
    selected,
    setSelected,
    disableEditButton,
    DocumentIsReadOnly,
    handleItemClick,
    handleEdit,
    isCollapsed,
  }: Props) => {
    const { get } = useTranslation();
    const { productPhaseStatus } = usePhaseStatus();
    const [open, setOpen] = useState(true);
    const [showAdd, setShowAdd] = useState(false);
    const [, setSelectedContentComponentForTree] = useState(productContentComponentForTree);

    useEffect(() => {
      setOpen(isCollapsed);
    }, [isCollapsed]);

    const getProductContentComponent = (productContentComponentId: number | undefined) => {
      return productContentComponents?.find((x) => x.ProductContentComponentId === productContentComponentId);
    };

    const productContentComponentForTreeForm = useForm<RenameProductContentComponent>();

    const handleAdd = () => {
      setSelectedContentComponentForTree(productContentComponentForTree);
      setShowAdd(true);
    };

    const handleModalClose = () => {
      setShowAdd(false);
    };

    const handleConfirmation = useCallback(
      (data: RenameProductContentComponent) => {
        setShowAdd(false);
        console.log(data, "data");
        productContentComponentForTreeForm.reset();
        renameContentComponents(data);
      },

      [productContentComponentForTreeForm, renameContentComponents],
    );

    return (
      <>
        {isOpen && (
          <>
            <TableRow 
            className={`ProductIndividualRow${id}`}
              key={id}
              sx={{
                backgroundColor: selected === id ? "#b1b8b8" : "",
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell
                key={id}
                onClick={() => {
                  handleItemClick(
                    id,
                    productContentComponentForTree,
                    getProductContentComponent(productContentComponentForTree?.ProductContentComponentId),
                  );
                }}
                sx={{ color: "#4d4d4d" }}
              >
                {selected === id ? <RadioButtonChecked /> : <RadioButtonUnchecked />}
              </TableCell>
              <TableCell>
                {selected === id && (
                  <Button
                    sx={{ fontSize: "medium" }}
                    variant="contained"
                    onClick={handleEdit}
                    disabled={DocumentIsReadOnly === true ? true : disableEditButton}
                  >
                    {get("articleSubComponentTableEditTitle")}
                  </Button>
                )}
              </TableCell>
              <TableCell>
                {productContentComponentForTree?.ChildItems && productContentComponentForTree.ChildItems.length > 0 && (
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                    sx={{ ml: productContentComponentForTree.ProductContentComponentType * 3 }}
                  >
                    {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRight />}
                  </IconButton>
                )}
                {productContentComponentForTree?.ChildItems && productContentComponentForTree.ChildItems.length < 1 && (
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    disabled={true}
                    sx={{ ml: productContentComponentForTree.ProductContentComponentType * 3 }}
                  >
                    <KeyboardArrowRight />
                  </IconButton>
                )}
              </TableCell>
              <TableCell>
                <EditIcon sx={{ fontSize: "medium", color: "#4d4d4d", mr: 1, pt: 0.5 }} onClick={handleAdd} />

                <FormProvider {...productContentComponentForTreeForm}>
                  <ConfirmationModal
                    show={showAdd}
                    handleClose={() => handleModalClose()}
                    handleConfirmation={() => productContentComponentForTreeForm.handleSubmit(handleConfirmation)()}
                    title={get("rename")}
                    saveButtonName={get("saveButtonText")}
                    cancelButtonName={get("cancelButtonText")}
                  >
                    <ArticleSubComponentTableRowForm data={productContentComponentForTree} />
                  </ConfirmationModal>
                </FormProvider>

                {productContentComponentForTree?.Name}
              </TableCell>
              {/* <TableCell>{id}</TableCell> */}
              <TableCell>{productPhaseStatus(productContentComponentForTree?.ProductPhaseId)}</TableCell>
              <ArticleSubComponentTableRowCellGroup
                productContentComponent={getProductContentComponent(
                  productContentComponentForTree?.ProductContentComponentId,
                )}
                hideTable={hideTable}
                hPhrasesData={hPhrasesData}
                productContentComponentForTree={productContentComponentForTree}
              ></ArticleSubComponentTableRowCellGroup>
            </TableRow>
            <>
              {productContentComponentForTree?.ChildItems?.map((productContentMaterial, Idx) => (
                <ArticleSubComponentTableRow
                  key={
                    productContentMaterial?.ProductContentComponentId +
                    "-" +
                    productContentMaterial?.ProductContentComponentType
                  }
                  productContentComponents={productContentComponents}
                  productContentComponentForTree={productContentMaterial}
                  id={
                    productContentMaterial?.ProductContentComponentId +
                    "-" +
                    productContentMaterial?.ProductContentComponentType
                  }
                  renameContentComponents={renameContentComponents}
                  hideTable={hideTable}
                  hPhrasesData={hPhrasesData}
                  isOpen={open}
                  selected={selected}
                  setSelected={setSelected}
                  handleItemClick={handleItemClick}
                  handleEdit={handleEdit}
                  disableEditButton={disableEditButton}
                  DocumentIsReadOnly={DocumentIsReadOnly}
                  isCollapsed={isCollapsed}
                ></ArticleSubComponentTableRow>
              ))}
            </>
          </>
        )}
      </>
    );
  },
);
