import { InternalOrganisationSearchFilter } from "./InternalSearchFilters/InternalOrganisationSearchFilter";
import { InternalDocumentStatusSearchFilter } from "./InternalSearchFilters/InternalDocumentStatusSearchFilter";
import { InternalDocumentValidDateFilter } from "./InternalSearchFilters/InternalDocumentValidDateFilter";
import { InternalIncludeOldVersionFilter } from "./InternalSearchFilters/InternalIncludeOldVersionFilter";
import { InternalBSAB96Filter } from "./InternalSearchFilters/InternalBSAB96Filter";
import { InternalBK04Filter } from "./InternalSearchFilters/InternalBK04Filter";
import { AuthenticatedTemplate } from "@azure/msal-react";

type Props = {
  setFilter: any;
  filter: InternalSearchFilter;
};

const InternalSearchSubFilters = ({ setFilter, filter }: Props) => {
  return (
    <>
      <InternalOrganisationSearchFilter setFilter={setFilter} filter={filter} />
      <InternalBK04Filter setFilter={setFilter} filter={filter} />
      <InternalBSAB96Filter setFilter={setFilter} filter={filter} />
      <AuthenticatedTemplate>
        <InternalDocumentStatusSearchFilter setFilter={setFilter} filter={filter} />
      </AuthenticatedTemplate>
      <InternalDocumentValidDateFilter setFilter={setFilter} filter={filter} />
      <InternalIncludeOldVersionFilter setFilter={setFilter} filter={filter} />
    </>
  );
};

export default InternalSearchSubFilters;
