import { createTheme } from "@mui/material";
import CalibriRegular from "../fonts/Calibri/font.woff";
import CalibriBold from "../fonts/CalibriBold/font.woff";
import CalibriBoldItalic from "../fonts/CalibriBoldItalic/font.woff";
import CalibriItalic from "../fonts/CalibriItalic/font.woff";
import Yummo from "../fonts/Yummo/yummo.woff";
import { svSE } from "@mui/material/locale";

const theme = createTheme(
  {
    spacing: 5,
    typography: {
      fontFamily: ["Calibri, Yummo"].join(","),
      h1: {
        fontFamily: "Yummo",
        lineHeight: "112px",
        fontSize: "96px",
      },
      h2: {
        fontFamily: "Yummo",
        lineHeight: "72px",
        fontSize: "60px",
      },
      h3: {
        fontFamily: "Calibri",
        lineHeight: "56px",
        fontSize: "48px",
        fontWeight: "bold",
      },
      h4: {
        fontFamily: "Calibri",
        lineHeight: "42px",
        fontSize: "34px",
        fontWeight: "bold",
      },
      h5: {
        fontFamily: "Calibri",
        lineHeight: "32px",
        fontSize: "24px",
        fontWeight: "bold",
      },
      h6: {
        fontFamily: "Calibri",
        lineHeight: "32px",
        fontSize: "20px",
      },
      subtitle1: {
        fontSize: "16px",
        lineHeight: "28px",
        fontFamily: "Calibri",
      },
      subtitle2: {
        fontSize: "16px",
        lineHeight: "28px",
        fontWeight: "bold",
        fontFamily: "Calibri",
      },
      body1: {
        fontSize: "16px",
        lineHeight: "24px",
        fontFamily: "Calibri",
      },
      body2: {
        fontSize: "14px",
        lineHeight: "20px",
        fontFamily: "Calibri",
      },
      caption: {
        fontSize: "16px",
        lineHeight: "24px",
        fontStyle: "italic",
        fontFamily: "Calibri",
      },
      overline: {
        fontSize: "14px",
        lineHeight: "20px",
        fontFamily: "Calibri",
        textTransform: "uppercase",
      },
    },
    palette: {
      primary: {
        main: "#008387",
        light: "#e2efef",
        dark: "#008387",
      },
      secondary: {
        main: "#f18968",
        light: "#feeee4",
        dark: "#f18968",
      },
      error: {
        main: "#E95450",
      },
      warning: {
        main: "#E95450",
      },
      success: {
        main: "#47D088",
      },
      background: {
        default: "#f4f4f3",
      },
    },

    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 2,
            boxShadow: " 0 2px 4px 0 rgba(0,0,0,0.3)",
            fontSize: "16px",
            fontWeight: "bold",
            lineHeight: "26px",
            textTransform: "none",
            fontFamily: "Calibri",
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            borderRadius: 2,
          },
          colorPrimary: {
            backgroundColor: "#008387",
            color: "#ffffff",
            "&:hover": {
              backgroundColor: "#008387",
              boxShadow: " 0 2px 4px 0 rgba(0,0,0,0.3)",
            },
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: `
      @font-face {
        font-family: 'Yummo';
        src: url(${Yummo}) format('woff');
        font-weight: bold;
        font-style: normal;
        unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
      }
      @font-face {
        font-family: 'Calibri';
        src: url(${CalibriRegular}) format('woff');
        font-weight: normal;
        font-style: normal;
        unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
      }
      @font-face {
        font-family: 'Calibri';
        src: url(${CalibriBold}) format('woff');
        font-weight: bold;
        font-style: normal;
        unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
      }
      @font-face {
        font-family: 'Calibri';
        src: url(${CalibriItalic}) format('woff');
        font-weight: normal;
        font-style: italic;
        unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
      }
      @font-face {
        font-family: 'Calibri';
        src: url(${CalibriBoldItalic}) format('woff');
        font-weight: bold;
        font-style: italic;
        unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
      }
      html {
        overflow-y: scroll;
      }
      a {
        text-decoration: none;
      }
    `,
      },
    },
  },
  svSE,
);

export default theme;
