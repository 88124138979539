import InternalSearchAccordion from "./InternalSearchAccordion";
import { Grid } from "@mui/material";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import TextField from "@mui/material/TextField";
import { useTranslation } from "../../../../helpers/useTranslation";
import { useGetDateFnsLocale } from "../../../../helpers/useGetDateFnsLocale";

type Props = {
  setFilter: any;
  filter: InternalSearchFilter;
};

export const InternalDocumentValidDateFilter = ({ setFilter, filter }: Props) => {
  const { get } = useTranslation();

  return (
    <>
      <InternalSearchAccordion title={get("searchDocumentValidFilter")}>
        <Grid container>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={useGetDateFnsLocale()}>
            <Grid item xs={12}>
              <DesktopDatePicker
                label={get("documentValidFrom")}
                inputFormat="yyyy/MM/dd"
                value={filter.DocumentValidFromDate || null}
                onChange={(value) => {
                  setFilter({
                    ...filter,
                    DocumentValidFromDate: value,
                  });
                }}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 3, mb: 2 }}>
              <DesktopDatePicker
                label={get("documentValidTo")}
                inputFormat="yyyy/MM/dd"
                value={filter.DocumentValidToDate || null}
                onChange={(value) => {
                  setFilter({
                    ...filter,
                    DocumentValidToDate: value,
                  });
                }}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Grid>
          </LocalizationProvider>
        </Grid>
      </InternalSearchAccordion>
    </>
  );
};
