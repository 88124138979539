import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { Grid } from "@mui/material";

type Props = {
  label: string;
  defaultValue: any;
};

export const CompanyCheckIcon = ({ label, defaultValue }: Props) => {
  return (
    <>
      <Grid container direction="row" alignItems="center">
        <Grid item>
          {defaultValue ? (
            <CheckCircleIcon style={{ color: "green", fontSize: "30px", paddingRight: "10px", paddingTop: "10px" }} />
          ) : (
            <RadioButtonUncheckedIcon
              style={{ color: "green", fontSize: "30px", paddingRight: "10px", paddingTop: "10px" }}
            />
          )}
        </Grid>
        <Grid item>{label}</Grid>
      </Grid>
    </>
  );
};
