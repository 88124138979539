import { FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import { useTranslation } from "../../../../../helpers/useTranslation";
import { CustomTextField } from "../../../../../components/CustomTextField/CustomTextField";
import { Controller, useFormContext } from "react-hook-form";

export const InviteUser = () => {
  const { get } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useFormContext<InviteUser>();

  return (
    <>
      <form onSubmit={handleSubmit as any}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={12}>
            <Typography>{get("fieldUserRole")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              render={({ field: { onChange, value } }) => (
                <RadioGroup name="RoleGroup" row={true} value={value} onChange={onChange}>
                  <FormControlLabel value={1} control={<Radio />} label={get("radioButtonCoWorker")} />
                  <FormControlLabel value={2} control={<Radio />} label={get("radioButtonSubcontractor")} />
                  <FormControlLabel value={3} control={<Radio />} label={get("radioButtonAdministrator")} />
                </RadioGroup>
              )}
              name="UserRoleId"
              control={control}
              defaultValue={1}
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: 3 }}>
            <CustomTextField
              name={"Email"}
              isDisabled={false}
              controlValue={control}
              type={"email"}
              defaultValue={""}
              isMultiLine={false}
              rowSpan={0}
              label={get("fieldUserEmail")}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: 3 }}>
            <CustomTextField
              name={"Name"}
              isDisabled={false}
              controlValue={control}
              defaultValue={""}
              isMultiLine={false}
              rowSpan={0}
              label={get("fieldUserDisplayName")}
              errors={errors}
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
};
