import { useTranslation } from "../../../helpers/useTranslation";

type Props = {
  styles?: any;
  necessaryOptionText?: string;
  preferencesOptionText?: string;
  statisticsOptionText?: string;
  marketingOptionText?: string;
  showPreferencesOption: boolean;
  showStatisticsOption?: boolean;
  showMarketingOption?: boolean;
  preferencesDefaultChecked?: boolean;
  statisticsDefaultChecked?: boolean;
  marketingDefaultChecked?: boolean;
  onTogglePreferencesCookies?: any;
  onToggleStatisticsCookies?:any;
  onToggleMarketingCookies?:any;
};
export const CookieBannerPreferences = ({
  styles,
  necessaryOptionText,
  preferencesOptionText,
  statisticsOptionText,
  marketingOptionText,
  showPreferencesOption,
  showStatisticsOption,
  showMarketingOption,
  preferencesDefaultChecked,
  statisticsDefaultChecked,
  marketingDefaultChecked,
  onTogglePreferencesCookies,
  onToggleStatisticsCookies,
  onToggleMarketingCookies,
}: Props) => {
  const { get } = useTranslation();
  return (
    <div className="react-cookie-law-select-pane" style={{display:'table-cell',padding:'3px 0px 10px'}}>
        <div className="react-cookie-law-option-wrapper" >
        <input
          type="checkbox"
          id="check-required-cookies"
          className="react-cookie-law-option-checkbox"
          checked
          onChange={onTogglePreferencesCookies}
          disabled
        />
        <label htmlFor={"check-required-cookies"} >{get("cookieNecessaryOptionText")}</label>
      </div>

      {
        showPreferencesOption && (
          <div className="react-cookie-law-option-wrapper" >
          <input
            type="checkbox"
            id="check-preferences-cookies"
            className="react-cookie-law-option-checkbox"
            checked={preferencesDefaultChecked}
            onChange={onTogglePreferencesCookies}
          />
          <label htmlFor={"check-preferences-cookies"} >{get("cookiePreferenceOptionText")}</label>
        </div>
        )
      }

      {
        showStatisticsOption && (
          <div className="react-cookie-law-option-wrapper" >
          <input
            type="checkbox"
            id="check-statistics-cookies"
            className="react-cookie-law-option-checkbox"
            checked={statisticsDefaultChecked}
            onChange={onToggleStatisticsCookies}
          />
          <label htmlFor={"check-statistics-cookies"} >{get("cookieStatisticsOptionText")}</label>
        </div>
        )
      }

      {
        showMarketingOption && (
          <div className="react-cookie-law-option-wrapper" >
          <input
            type="checkbox"
            id="check-marketing-cookies"
            className="react-cookie-law-option-checkbox"
            checked={marketingDefaultChecked}
            onChange={onToggleMarketingCookies}
          />
          <label htmlFor={"check-marketing-cookies"} >{onToggleMarketingCookies}</label>
        </div>
        )
      }
    </div>
  );
};
