import { FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "../../../helpers/useTranslation";
import { CustomTextField } from "../../../components/CustomTextField/CustomTextField";
import { useGetCountries } from "../../../services/countryService";
type Props = {
  data?: CreateAccount;
};

export const ContactInformation = ({ data }: Props) => {
  const { data: countriesDataValues } = useGetCountries();
  const { get } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useFormContext<CreateAccount>();

  return (
    <form onSubmit={handleSubmit as any}>
      <Grid container rowSpacing={1} columnSpacing={{ md: 3 }}>
        <Grid item md={6} sx={{ mt: 3, mb: 3 }}>
          <Controller
            name="CountryId"
            control={control}
            defaultValue={data?.CountryId || ""}
            render={({ field: { onChange, value } }) => (
              <FormControl sx={{ width: "100%" }}>
                <InputLabel shrink>{get("fieldCountry") + "*"}</InputLabel>
                <Select
                  value={value || ""}
                  name="CountryId"
                  onChange={onChange}
                  error={!!errors.CountryId?.message}
                  input={<OutlinedInput notched label={get("fieldCountry")} />}
                >
                  {countriesDataValues?.Items?.map((item: Item) => (
                    <MenuItem key={item.CountryId} value={item.CountryId}>
                      {item.Name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item md={12} sx={{ mt: 3 }}>
          <CustomTextField
            name={"Address"}
            isDisabled={false}
            controlValue={control}
            defaultValue={data?.Address}
            label={get("accountVisitingAddress") + "*"}
            errors={errors}
          />
        </Grid>
        <Grid item md={12} sx={{ mt: 3 }}>
          <CustomTextField
            name={"AreaCode"}
            isDisabled={false}
            controlValue={control}
            defaultValue={data?.AreaCode}
            label={get("accountPostCode") + "*"}
            errors={errors}
          />
        </Grid>
        <Grid item md={12} sx={{ mt: 3 }}>
          <CustomTextField
            name={"City"}
            isDisabled={false}
            controlValue={control}
            defaultValue={data?.City}
            label={get("fieldCity") + "*"}
            errors={errors}
          />
        </Grid>
        <Grid item md={12} sx={{ mt: 3 }}>
          <CustomTextField
            name={"Reference"}
            isDisabled={false}
            controlValue={control}
            defaultValue={data?.Reference}
            label={get("accountReference") + "*"}
            errors={errors}
          />
        </Grid>
        <Grid item md={12} sx={{ mt: 3 }}>
          <CustomTextField
            name={"Email"}
            isDisabled={false}
            controlValue={control}
            defaultValue={data?.Email}
            label={get("accountEmail") + "*"}
            errors={errors}
          />
        </Grid>
        <Grid item md={12} sx={{ mt: 3 }}>
          <CustomTextField
            name={"Phone"}
            isDisabled={false}
            controlValue={control}
            defaultValue={data?.Phone}
            label={get("userPhoneNo") + "*"}
            errors={errors}
          />
        </Grid>
      </Grid>
    </form>
  );
};
