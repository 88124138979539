import React, { useState } from "react";
import { Button, Grid, Stack, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import DownloadIcon from "@mui/icons-material/Download";
import { useTranslation } from "../../../../helpers/useTranslation";
import { Loader } from "../../../../components/Loader/Loader";
import { useGetDocumentExcelTemplate } from "../../../../services/documentService";
import { ImportDocumentModal } from "../../../Documents/ImportDocumentModal/ImportDocumentModal";
import { GetTransactionItems } from "../../../../services/TransactionItemService";
import { TransactionItemTable } from "../TransactionItemTable/TransactionItemTable";

type Props = {
  data?: UserGroup;
};

export const ImportDocumentViaFile = ({ data = {} as UserGroup }: Props) => {
  const { get } = useTranslation();
  const [showImport, setShowImport] = useState<boolean>(false);
  const [transactionInProgress, setTransactionInProgress] = useState<boolean>(true);

  const { data: transactionItems, refetch } = GetTransactionItems({
    UserGroupIds: [data.UserGroupId],
    TransactionItemTypeIds: [1],
  });

  const { mutate: documentExcelTemplate } = useGetDocumentExcelTemplate();

  const getDocumentExcelTemplate = () => {
    documentExcelTemplate();
  };

  const closeShowImport = () => {
    setShowImport(false);
  };

  if (!data) {
    return <Loader />;
  }

  return (
    <>
      <Grid container sx={{ mt: 0, mb: 2 }} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item md={11}>
          <ImportDocumentModal userGroup={data} open={showImport} setOpen={closeShowImport} refetch={refetch} />
          <TransactionItemTable
            transactionItems={transactionItems}
            refetch={refetch}
            transactionInProgress={transactionInProgress}
            setTransactionInProgress={setTransactionInProgress}
          ></TransactionItemTable>
        </Grid>
        <Grid item md={1}>
          <Stack direction="column" spacing={2} sx={{ float: "right" }}>
            <Tooltip title={get("importDocumentsTooltip")} placement="left">
              <Button
                variant="contained"
                size="small"
                sx={{ width: "36px", minWidth: "0px" }}
                disabled={transactionInProgress}
                onClick={() => setShowImport(true)}
              >
                <AddIcon />
              </Button>
            </Tooltip>
            <Tooltip title={get("importDownloadExcelTemplateTooltip")} placement="left">
              <Button
                variant="contained"
                size="small"
                sx={{ m: 1, width: "36px", minWidth: "0px" }}
                onClick={() => getDocumentExcelTemplate()}
              >
                <DownloadIcon />
              </Button>
            </Tooltip>
            <Tooltip title={get("importRefetchListTooltip")} placement="left">
              <Button
                variant="contained"
                size="small"
                sx={{ m: 1, width: "36px", minWidth: "0px" }}
                onClick={() => refetch()}
              >
                <RefreshIcon />
              </Button>
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
