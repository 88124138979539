import React, { useState, useEffect } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import DownloadingIcon from "@mui/icons-material/Downloading";
import { useTranslation } from "../../../../helpers/useTranslation";
import { useDateFormat } from "../../../../helpers/useDateFormat";

type Props = {
  transactionItems: TransactionItems | undefined;
  refetch: any;
  transactionInProgress: boolean;
  setTransactionInProgress: any;
};

export const TransactionItemTable = ({
  transactionItems,
  refetch,
  transactionInProgress,
  setTransactionInProgress,
}: Props) => {
  const { get } = useTranslation();
  const [refetchCounter, setRefetchCounter] = useState<number>(0);
  const { format } = useDateFormat();

  useEffect(() => {
    if (transactionItems) {
      // console.log("transactionItems", transactionItems);
      if (
        transactionItems.TotalItemCount > 0 &&
        transactionItems.Items[0].TransactionItemStatus.TransactionItemStatusId === 0
      ) {
        setTransactionInProgress(true);
      } else {
        setTransactionInProgress(false);
      }
    } else {
      setTransactionInProgress(false);
    }
  }, [transactionItems]);

  useEffect(() => {
    // // Function to fetch transactionItems
    const fetchTransactionItems = () => {
      // console.log("refetching");
      refetch();
    };

    // Set up an interval to fetch transactionItems every 10 seconds
    const intervalId = setInterval(() => {
      if (transactionInProgress) {
        fetchTransactionItems();
        setRefetchCounter(1);
      } else if (refetchCounter === 1) {
        fetchTransactionItems();
        setRefetchCounter(0);
      }
    }, 10000); // 10000 milliseconds = 10 seconds

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [transactionInProgress]);

  return (
    <>
      <Paper sx={{ overflow: "hidden" }}>
        <TableContainer sx={{ width: "100%" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell width={137}>
                  <Typography variant="body1">
                    <b>{get("transactionCreated")}</b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">
                    <b>{get("transactionResultMessage")}</b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">
                    <b>{get("transactionStatus")}</b>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactionItems?.Items.map((item) => (
                <TableRow
                  key={item.TransactionItemId}
                  // onClick={() => handleRowClick(item.UserId)}
                  // sx={{
                  //   backgroundColor: selectedUserId === item.UserId ? "#b1b8b8" : "",
                  //   cursor: "pointer",
                  // }}
                >
                  <TableCell>{format(item.CreatedTime)}</TableCell>
                  {item.FirstErrorMessage ? (
                    <Tooltip title={item.FirstErrorMessage} placement="left">
                      <TableCell>{item.ResultMessage}</TableCell>
                    </Tooltip>
                  ) : (
                    <TableCell>{item.ResultMessage}</TableCell>
                  )}

                  <TableCell>
                    {item.TransactionItemStatus.TransactionItemStatusId === 1 ? (
                      <Tooltip title={item.TransactionItemStatus.Name} placement="left">
                        <CheckCircleIcon style={{ color: "green" }} />
                      </Tooltip>
                    ) : item.TransactionItemStatus.TransactionItemStatusId === 0 ? (
                      <Tooltip title={item.TransactionItemStatus.Name} placement="left">
                        <DownloadingIcon style={{ color: "blue" }} />
                      </Tooltip>
                    ) : item.TransactionItemStatus.TransactionItemStatusId === 3 ? (
                      <Tooltip title={item.TransactionItemStatus.Name} placement="left">
                        <DownloadingIcon style={{ color: "blue" }} />
                      </Tooltip>
                    ) : item.TransactionItemStatus.TransactionItemStatusId === 4 ? (
                      <Tooltip title={item.TransactionItemStatus.Name} placement="left">
                        <DownloadingIcon style={{ color: "blue" }} />
                      </Tooltip>
                    ) : (
                      <Tooltip title={item.TransactionItemStatus.Name} placement="left">
                        <CancelIcon style={{ color: "red" }} />
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};
