import { Grid, TextField } from "@mui/material";
import { useTranslation } from "../../../../../../helpers/useTranslation";
import { CustomTextField } from "../../../../../../components/CustomTextField/CustomTextField";
import { CustomErrorTypography } from "../../../../../../components/CustomTypography/CustomErrorTypography";
import { Controller } from "react-hook-form";
import { useEffect } from "react";

type Props = {
  handleSubmit: any;
  control: any;
  data: any;
  errors: any;
  setValue: any;
  reset: any;
};
export const ReferencesForm = ({ handleSubmit, control, data, errors, setValue, reset }: Props) => {
  const { get } = useTranslation();
  useEffect(() => {
    if (data !== undefined) reset(data);
    else
      reset({
        DocumentReferenceId: -1,
        Reference: "",
      });
  }, [data, reset]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container sx={{ mt: 2 }} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12}>
            <CustomTextField
              name={"Reference"}
              isDisabled={false}
              controlValue={control}
              defaultValue={data ? data.Reference : ""}
              isMultiLine={true}
              rowSpan={4}
              label={get("fielddocumentReference") + " *"}
              errors={errors}
            />
            <CustomErrorTypography errorMessage={errors.Reference && errors.Reference.message} />
            <Controller
              render={({ field }) => <TextField {...field} variant="filled" sx={{ display: "none", width: "100%" }} />}
              name="DocumentReferenceId"
              control={control}
              defaultValue={data?.DocumentReferenceId || -1}
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
};
