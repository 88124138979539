import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { PageLayout } from "../../../components/PageLayout/PageLayout";
import { useTranslation } from "../../../helpers/useTranslation";
import { useGetUser } from "../../../services/userService";
import { OrganizationListForImportItem } from "./OrganizationListItem/OrganizationListForImportItem";
import { Navigate } from "react-router";

export const OrganizationListForImport = () => {
  const { get } = useTranslation();
  const { data } = useGetUser();

  // returns empty page if user cant use import
  if (!data || data.InvalidApplicationFeatureIds.includes(1)) return <></>;
  else if (
    data &&
    data?.UserGroups.filter((x) => (x.CanCreateNew && x.CanEdit && x.CanCreateNewVersion) || x.IsUserGroupAdmin)
      .length > 1
  ) {
    return (
      <PageLayout title="Import">
        <Grid textAlign={"center"}>
          <Box sx={{ width: "50%", margin: "auto" }}>
            <Box
              sx={{
                display: "flex",
                gap: 1,
                py: 1,
                backgroundColor: "white",
                padding: 5,
                marginTop: 5,
              }}
            >
              <Grid container textAlign="center">
                <Grid item md={12} marginBottom={"30px"}>
                  <Typography variant="h5" color="black">
                    {get("organizationListforImportChoice")}
                  </Typography>
                </Grid>
                {data?.UserGroups.map((organization) => (
                  <OrganizationListForImportItem
                    orgName={organization.UserGroupName}
                    orgId={organization.UserGroupId}
                  />
                ))}
              </Grid>
            </Box>
          </Box>
        </Grid>
      </PageLayout>
    );
  } else {
    return <Navigate to={`/import/${data?.UserGroups[0].UserGroupId}`} />;
  }
};
