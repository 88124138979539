import { Typography } from "@mui/material";

type Props = {
  fieldName: string;
  fieldValue: any;
};

export const CustomTypography = ({ fieldName, fieldValue }: Props) => {
  return (
    <>
      <Typography variant="body2" sx={{ mb: 2 }}>
        {fieldName}
      </Typography>
      <Typography variant="body1">
        <b>{fieldValue}</b>
      </Typography>
    </>
  );
};
