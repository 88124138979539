import { Grid, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { CustomRadioButton } from "../../../../../../components/CustomRadioButton/CustomRadioButton";
import { CustomTextField } from "../../../../../../components/CustomTextField/CustomTextField";
import { useTranslation } from "../../../../../../helpers/useTranslation";
import ErrorIcon from "@mui/icons-material/Error";
import { ErrorMessage } from "@hookform/error-message";

type Props = {
  data?: ProductDistribution;
  DocumentIsReadOnly: boolean;
};

export const DistributionInformation = ({ data, DocumentIsReadOnly }: Props) => {
  const { get } = useTranslation();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useFormContext<EBVDDocument>();
  return (
    <form onSubmit={handleSubmit as any}>
      <Grid container rowSpacing={1} columnSpacing={{ md: 3 }}>
        <Grid item xs={12}>
          <Typography>{get("distributionReusablePackaging")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <CustomRadioButton
            name={"ProductDistribution.ReusablePackaging"}
            controlValue={control}
            dataValue={data?.ReusablePackaging}
            isNA={true}
            yesValue={1}
            noValue={2}
            disabled={DocumentIsReadOnly}
            errorFieldMessage={errors?.ProductDistribution?.ReusablePackaging?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>{get("distributionReclaimPackaging")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <CustomRadioButton
            name={"ProductDistribution.ReclaimPackaging"}
            controlValue={control}
            dataValue={data?.ReclaimPackaging}
            isNA={true}
            yesValue={1}
            noValue={2}
            disabled={DocumentIsReadOnly}
            errorFieldMessage={errors?.ProductDistribution?.ReclaimPackaging?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>{get("distributionHasPackagingSystem")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <CustomRadioButton
            name={"ProductDistribution.HasPackagingSystem"}
            controlValue={control}
            dataValue={data?.HasPackagingSystem}
            isNA={true}
            yesValue={1}
            noValue={2}
            disabled={DocumentIsReadOnly}
            errorFieldMessage={errors?.ProductDistribution?.HasPackagingSystem?.message}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 1 }}>
          <CustomTextField
            name={"ProductDistribution.PackagingSystem"}
            isDisabled={DocumentIsReadOnly}
            controlValue={control}
            defaultValue={data?.PackagingSystem}
            isMultiLine={false}
            rowSpan={0}
            label={get("distributionPackagingSystem")}
            errors={errors}
          />
        </Grid>
        <Grid item md={0} sx={{ mt: 3 }}>
          <ErrorMessage
            message=""
            as={
              <ErrorIcon
                color="error"
                style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "center" }}
              />
            }
            errors={errors}
            name="ProductDistribution.PackagingSystem"
          />
        </Grid>
        <Grid item md={11} sx={{ mt: 3 }} style={{ padding: "0" }}>
          <ErrorMessage
            as={<Typography variant="subtitle1" sx={{ color: "#E95450", margin: "3px 12px 0px 12px" }} />}
            errors={errors}
            name="ProductDistribution.PackagingSystem"
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Typography>{get("distributionCanPackagingBeReused")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <CustomRadioButton
            name={"ProductDistribution.CanPackagingBeReused"}
            controlValue={control}
            dataValue={data?.CanPackagingBeReused}
            isNA={true}
            yesValue={1}
            noValue={2}
            disabled={DocumentIsReadOnly}
            errorFieldMessage={errors?.ProductDistribution?.CanPackagingBeReused?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>{get("distributionCanPackagingBeMaterialRecycled")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <CustomRadioButton
            name={"ProductDistribution.CanPackagingBeMaterialRecycled"}
            controlValue={control}
            dataValue={data?.CanPackagingBeMaterialRecycled}
            isNA={true}
            yesValue={1}
            noValue={2}
            disabled={DocumentIsReadOnly}
            errorFieldMessage={errors?.ProductDistribution?.CanPackagingBeMaterialRecycled?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>{get("distributionCanPackagingBeEnergyRecycled")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <CustomRadioButton
            name={"ProductDistribution.CanPackagingBeEnergyRecycled"}
            controlValue={control}
            dataValue={data?.CanPackagingBeEnergyRecycled}
            isNA={true}
            yesValue={1}
            noValue={2}
            disabled={DocumentIsReadOnly}
            errorFieldMessage={errors?.ProductDistribution?.CanPackagingBeEnergyRecycled?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>{get("distributionReturnSystem")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <CustomRadioButton
            name={"ProductDistribution.ReturnSystem"}
            controlValue={control}
            dataValue={data?.ReturnSystem}
            isNA={true}
            yesValue={1}
            noValue={2}
            disabled={DocumentIsReadOnly}
            errorFieldMessage={errors?.ProductDistribution?.ReturnSystem?.message}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 1 }}>
          <CustomTextField
            name={"ProductDistribution.Comment"}
            isDisabled={DocumentIsReadOnly}
            controlValue={control}
            defaultValue={data?.Comment}
            isMultiLine={true}
            rowSpan={6}
            label={get("distributionComment")}
            errors={errors}
          />
        </Grid>
        {/* <Grid item md={0} sx={{ mt:3}} >
          <ErrorMessage message=""  as={<ErrorIcon color="error" style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }} />
        } errors={errors} name="ProductDistribution.Comment" />
        </Grid>
        <Grid item md={11} sx={{ mt: 3 }} style={{ padding:'0'}}>
        <ErrorMessage  as={<Typography variant="subtitle1" sx={{ color: "#E95450", margin: "3px 12px 0px 12px" }} />} errors={errors} name="ProductDistribution.Comment" />

      </Grid> */}
      </Grid>
    </form>
  );
};
