import { Grid} from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "../../../helpers/useTranslation";
import { CustomTextField } from "../../../components/CustomTextField/CustomTextField";
type Props = {
  data?: CreateAccount;
};

export const CompanyInformation = ({ data }: Props) => {
  const { get } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useFormContext();

  return (
    <form onSubmit={handleSubmit as any}>
      <Grid container rowSpacing={1} columnSpacing={{ md: 3 }}>
        <Grid item md={12} sx={{ mt: 3 }}>
          <CustomTextField
            name={"Name"}
            isDisabled={false}
            controlValue={control}
            defaultValue={data?.Name}
            label={get("fieldCompanyName")+"*"}
            errors={errors}
          />
        </Grid>
        <Grid item md={12} sx={{ mt: 3 }}>
          <CustomTextField
            name={"OrgNumber"}
            isDisabled={false}
            controlValue={control}
            defaultValue={data?.OrgNumber}
            label={get("fieldCorporateIDNO")+"*"}
            errors={errors}
          />
        </Grid>
        <Grid item md={12} sx={{ mt: 3 }}>
          <CustomTextField
            name={"VatNumber"}
            isDisabled={false}
            controlValue={control}
            defaultValue={data?.VatNumber}
            label={get("fieldVATNumber")+"*"}
            errors={errors}
          />
        </Grid>
        <Grid item md={12} sx={{ mt: 3 }}>
          <CustomTextField
            name={"GLN"}
            isDisabled={false}
            controlValue={control}
            defaultValue={data?.GLN}
            label={get("fieldGLN")}
            errors={errors}
          />
        </Grid>
      </Grid>
    </form>
  );
};
