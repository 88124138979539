// data.ts
export interface ResourceNode {
  id: number;
  label: string;
  level?: number;
  children?: ResourceNode[];
}
export interface RowData {
  calculatedGwpGhg?: number;
  resourceId: number;
  productName?: string;
  epdOwner?: string;
  coOwnerName?: string;
  confidentialityLevel?: number;
  locationCode?: string;
  resourceTypeName?: string;
  resourceSubTypeName?: string;
  epdNumber?: string;
  publicPrivate?: boolean;
  inventoryUnitGWP?: number;
  inventoryUnit?: string;
  declaredUnitGWP?: number;
  declaredUnit?: string;
  programOperator?: string;
  epdUniqueId?: string;
  registrationNumber?: string;
  sourceId?: string;
  pdfLink?: string;
  confidential?: string;
  articleName?: string;
  productionSite?: string;
  EPDFactor?: number;
  Longitude?: number;
  Latitude?: number;
  GMN?: string;
  GLN?: string;
  supplierArticleId?: string;
  GTIN?: string;
  resourceNodes?: ResourceNode[];
  gwpFossil?: number;
  gwpBiogenic?: number;
  gwpLuluc?: number;
  gwpTotal?: number;
  gwpGhg?: number;
  biogenicCarbonInProduct?: number;
  biogenicCarbonInPackaging?: number;
  conversionFactor?: number;
  resourceDensity?: number;
  selectedNode?: number | null;
  hubType?: string;
  hubTypeSuggestion?: string;
  resourceNodeSuggestion?: string;
}

export const initialRows: RowData[] = [
  {
    resourceId: 1,
    productName: "HDF Strängbetong 1427",
    epdOwner: "Strängbetong",
    locationCode: "GLO",
    coOwnerName: "CoOwner Example",
    confidentialityLevel: 1,
    resourceTypeName: "EPD",
    resourceSubTypeName: "Other dataset",
    epdNumber: "EPD-VSE-67.0",
    publicPrivate: true,
    inventoryUnitGWP: 0.157,
    inventoryUnit: "kg",
    declaredUnitGWP: 0.157,
    declaredUnit: "kg",
    programOperator: "Operator A",
    epdUniqueId: "UUID-12345",
    registrationNumber: "REG-0001",
    sourceId: "SRC-001",
    pdfLink: "https://example.com/pdf1.pdf",
    confidential: "false",
    articleName: "Regelisolering, lambda 0.037, 45 mm",
    productionSite: "Billesholm, Sweden",
    EPDFactor: 0.966,
    Longitude: 12.974833,
    Latitude: 56.059766,
    GMN: "3563054004",
    GLN: "356307004",
    supplierArticleId: "7392979216954",
    GTIN: "7392979216931",
    conversionFactor: 2.5,
    resourceDensity: 1600,
    hubType: "EPD company",
    resourceNodes: [
      {
        id: 1,
        label: "Vegetation (RR)",
        children: [
          { id: 2, label: "Green roof, extensive, sedum with biochar (RR)" },
          { id: 3, label: "Green roof system, sedum tray with biochar (RR)" },
          { id: 4, label: "Vegetation substrate for green roofs (RR)" },
          {
            id: 5,
            label: "Green roof systems (RR)",
            children: [
              { id: 6, label: "Foil for green roof (RR)" },
              { id: 7, label: "Green roof system, extensive including planting, 100 mm substrate (RR)" },
              {
                id: 8,
                label: "Green roof system including steel frame, extensive including planting, 100 mm substrate (RR)",
              },
              {
                id: 9,
                label: "Green roof system including steel frame, intensive including planting, 450 mm substrate (RR)",
              },
            ],
          },
          { id: 10, label: "Deforestation (RR)" },
        ],
      },
      {
        id: 11,
        label: "Energy wares, extraction to combustion (RR)",
        children: [
          {
            id: 12,
            label: "Petrol (RR)",
            children: [{ id: 13, label: "Petrol WtW, fossil (RR)" }],
          },
        ],
      },
    ],
    gwpFossil: 10.5,
    gwpBiogenic: 5.2,
    gwpLuluc: 1.8,
    gwpTotal: 17.5,
    gwpGhg: 15.3,
    biogenicCarbonInProduct: 2.4,
    biogenicCarbonInPackaging: 1.1,
  },
  {
    resourceId: 2,
    productName: "HDF Strängbetong 1427",
    epdOwner: "Strängbetong",
    coOwnerName: "CoOwner Example",
    confidentialityLevel: 2,
    locationCode: "GLO",
    resourceTypeName: "EPD",
    resourceSubTypeName: "Specific dataset",
    epdNumber: "EPD-VSP-67.0",
    publicPrivate: false,
    inventoryUnitGWP: 2.66,
    inventoryUnit: "kg",
    declaredUnitGWP: 2.66,
    declaredUnit: "kg",
    programOperator: "Operator B",
    epdUniqueId: "UUID-67890",
    registrationNumber: "REG-0002",
    sourceId: "SRC-002",
    pdfLink: "https://example.com/pdf2.pdf",
    confidential: "true",
    articleName: "Regelisolering, lambda 0.037, 45 mm",
    productionSite: "Billesholm, Sweden",
    EPDFactor: 0.966,
    Longitude: 12.974833,
    Latitude: 56.059766,
    GMN: "3563054004",
    GLN: "356307004",
    supplierArticleId: "7392979216954",
    GTIN: "7392979216931",
    conversionFactor: 2.8,
    resourceDensity: 1400,
    hubType: "EPD company",
    resourceNodes: [
      {
        id: 1,
        label: "Vegetation (RR)",
        children: [
          { id: 2, label: "Green roof, extensive, sedum with biochar (RR)" },
          { id: 3, label: "Green roof system, sedum tray with biochar (RR)" },
          { id: 4, label: "Vegetation substrate for green roofs (RR)" },
          {
            id: 5,
            label: "Green roof systems (RR)",
            children: [
              { id: 6, label: "Foil for green roof (RR)" },
              { id: 7, label: "Green roof system, extensive including planting, 100 mm substrate (RR)" },
              {
                id: 8,
                label: "Green roof system including steel frame, extensive including planting, 100 mm substrate (RR)",
              },
              {
                id: 9,
                label: "Green roof system including steel frame, intensive including planting, 450 mm substrate (RR)",
              },
            ],
          },
          { id: 10, label: "Deforestation (RR)" },
        ],
      },
      {
        id: 11,
        label: "Energy wares, extraction to combustion (RR)",
        children: [
          {
            id: 12,
            label: "Petrol (RR)",
            children: [{ id: 13, label: "Petrol WtW, fossil (RR)" }],
          },
        ],
      },
    ],
    gwpFossil: 10.5,
    gwpBiogenic: 5.2,
    gwpLuluc: 1.8,
    gwpTotal: 17.5,
    gwpGhg: 15.3,
    biogenicCarbonInProduct: 2.4,
    biogenicCarbonInPackaging: 1.1,
  },
  {
    resourceId: 3,
    calculatedGwpGhg: 17.5,
    productName: "Connecting technology",
    epdOwner: "Vega",
    coOwnerName: "CoOwner Example",
    confidentialityLevel: 2,
    locationCode: "GLO",
    resourceTypeName: "EPD",
    resourceSubTypeName: "Specific dataset",
    epdNumber: "EPD-VSP-67.0",
    publicPrivate: false,
    inventoryUnitGWP: 0.572,
    inventoryUnit: "kg",
    declaredUnitGWP: 0.572,
    declaredUnit: "kg",
    programOperator: "Operator C",
    epdUniqueId: "UUID-24680",
    registrationNumber: "REG-0003",
    sourceId: "SRC-003",
    pdfLink: "https://example.com/pdf3.pdf",
    confidential: "true",
    articleName: "Regelisolering, lambda 0.037, 45 mm",
    productionSite: "Billesholm, Sweden",
    EPDFactor: 0.966,
    Longitude: 12.974833,
    Latitude: 56.059766,
    GMN: "3563054004",
    GLN: "356307004",
    supplierArticleId: "7392979216954",
    GTIN: "7392979216931",
    conversionFactor: 2.1,
    resourceDensity: 1100,
    hubType: "EPD company",
    resourceNodes: [
      {
        id: 1,
        label: "Vegetation (RR)",
        children: [
          { id: 2, label: "Green roof, extensive, sedum with biochar (RR)" },
          { id: 3, label: "Green roof system, sedum tray with biochar (RR)" },
          { id: 4, label: "Vegetation substrate for green roofs (RR)" },
          {
            id: 5,
            label: "Green roof systems (RR)",
            children: [
              { id: 6, label: "Foil for green roof (RR)" },
              { id: 7, label: "Green roof system, extensive including planting, 100 mm substrate (RR)" },
              {
                id: 8,
                label: "Green roof system including steel frame, extensive including planting, 100 mm substrate (RR)",
              },
              {
                id: 9,
                label: "Green roof system including steel frame, intensive including planting, 450 mm substrate (RR)",
              },
            ],
          },
          { id: 10, label: "Deforestation (RR)" },
        ],
      },
      {
        id: 11,
        label: "Energy wares, extraction to combustion (RR)",
        children: [
          {
            id: 12,
            label: "Petrol (RR)",
            children: [{ id: 13, label: "Petrol WtW, fossil (RR)" }],
          },
        ],
      },
    ],
    gwpFossil: 10.5,
    gwpBiogenic: 5.2,
    gwpLuluc: 1.8,
    gwpTotal: 17.5,
    gwpGhg: 15.3,
    biogenicCarbonInProduct: 2.4,
    biogenicCarbonInPackaging: 1.1,
  },
  {
    resourceId: 4,
    productName: "technology",
    epdOwner: "Vega",
    coOwnerName: "CoOwner Example",
    confidentialityLevel: 2,
    locationCode: "GLO",
    resourceTypeName: "EPD",
    resourceSubTypeName: "Specific dataset",
    epdNumber: "EPD-VSP-67.0",
    publicPrivate: false,
    inventoryUnitGWP: 0.572,
    inventoryUnit: "kg",
    declaredUnitGWP: 0.572,
    declaredUnit: "kg",
    programOperator: "Operator C",
    epdUniqueId: "UUID-24680",
    registrationNumber: "REG-0003",
    sourceId: "SRC-003",
    pdfLink: "https://example.com/pdf3.pdf",
    confidential: "true",
    articleName: "Regelisolering, lambda 0.037, 45 mm",
    productionSite: "Billesholm, Sweden",
    EPDFactor: 0.966,
    Longitude: 12.974833,
    Latitude: 56.059766,
    GMN: "3563054004",
    GLN: "356307004",
    supplierArticleId: "7392979216954",
    GTIN: "7392979216931",
    conversionFactor: 2.3,
    resourceDensity: 1200,
    hubType: "EPD company",
    resourceNodes: [
      {
        id: 1,
        label: "Vegetation (RR)",
        children: [
          { id: 2, label: "Green roof, extensive, sedum with biochar (RR)" },
          { id: 3, label: "Green roof system, sedum tray with biochar (RR)" },
          { id: 4, label: "Vegetation substrate for green roofs (RR)" },
          {
            id: 5,
            label: "Green roof systems (RR)",
            children: [
              { id: 6, label: "Foil for green roof (RR)" },
              { id: 7, label: "Green roof system, extensive including planting, 100 mm substrate (RR)" },
              {
                id: 8,
                label: "Green roof system including steel frame, extensive including planting, 100 mm substrate (RR)",
              },
              {
                id: 9,
                label: "Green roof system including steel frame, intensive including planting, 450 mm substrate (RR)",
              },
            ],
          },
          { id: 10, label: "Deforestation (RR)" },
        ],
      },
      {
        id: 11,
        label: "Energy wares, extraction to combustion (RR)",
        children: [
          {
            id: 12,
            label: "Petrol (RR)",
            children: [{ id: 13, label: "Petrol WtW, fossil (RR)" }],
          },
        ],
      },
    ],
    gwpFossil: 10.5,
    gwpBiogenic: 5.2,
    gwpLuluc: 1.8,
    gwpTotal: 17.5,
    gwpGhg: 15.3,
    biogenicCarbonInProduct: 2.4,
    biogenicCarbonInPackaging: 1.1,
  },
];
