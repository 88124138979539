import { Box } from "@mui/system";

type Props = {
  children?: React.ReactNode;
  index: number;
  value: number;
};

export const DocumentTabPanel = ({ children, index, value }: Props) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      sx={{ width: "100%" }}
    >
      {value === index && (
        <Box bgcolor="white" sx={{ p: 3, minHeight: "70vh", padding: "30px", paddingBottom: "40px" }}>
          {children}
        </Box>
      )}
    </Box>
  );
};
