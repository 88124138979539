import { AuthenticatedTemplate } from "@azure/msal-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal";
import { useFormValidation } from "../../../helpers/useFormValidation";
import { useTranslation } from "../../../helpers/useTranslation";
import { useCreateEBVDDocument } from "../../../services/documentService";
import { CreateDocumentForm } from "../CreateDocumentForm/CreateDocumentForm";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

export const CreateDocumentModal = ({ open, setOpen }: Props) => {
  const { get } = useTranslation();
  const navigate = useNavigate();
  const { createDocumentValidationSchema } = useFormValidation();

  const [documentID, setDocumentID] = useState("");
  const { mutateAsync: addDocument } = useCreateEBVDDocument();
  const methods = useForm<CreateDocument>({
    resolver: yupResolver(createDocumentValidationSchema),
  });

  const resetForm = () => {
    setDocumentID("");
    setOpen(false);
    methods.reset();
  };

  const handleConfirmation = (documentData: CreateDocument) => {
    addDocument(documentData).then((result: EBVDDocument) => {
      navigate(`/documents/${result.DocumentId}`);
    });
    resetForm();
  };

  const handleModalClose = () => {
    resetForm();
  };

  return (
    <FormProvider {...methods}>
      <ConfirmationModal
        show={open}
        handleClose={() => handleModalClose()}
        handleConfirmation={() => methods.handleSubmit(handleConfirmation)()}
        title={get("documentModalTitle")}
        saveButtonName={get("documentCreateNeweBVD")}
        cancelButtonName={get("documentCancel")}
      >
        <AuthenticatedTemplate>
          <CreateDocumentForm setDocumentID={setDocumentID} documentID={documentID} />
        </AuthenticatedTemplate>
      </ConfirmationModal>
    </FormProvider>
  );
};
