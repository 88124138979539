import { useRecoilValue } from "recoil";
import { localeAtom } from "../recoil/atom";
import { translations } from "../translation";
import { Languages } from "../translation/languages";
import { TranslatedKey } from "../translation/translatedKey";

export const useTranslation = () => {
  const locale = useRecoilValue(localeAtom);

  const get = (key: TranslatedKey) => {
    return translations[locale as keyof Languages][key];
  };

  const getByLocale = (key: TranslatedKey, locale: string) => {
    return translations[locale as keyof Languages][key];
  };

  const getCurrentLanguage = () => {
    return locale;
  };

  return {
    get,
    getByLocale,
    getCurrentLanguage,
  };
};
