import { useTranslation } from "./useTranslation";

export const useEmissionMeasureUnit = () => {
  const { get } = useTranslation();

  const emissionMeasureUnit = (measureUnit: string) => {
    if (measureUnit === "hour") return get("indoorHours");
    else if (measureUnit === "day") return get("indoorDays");
    else if (measureUnit === "week") return get("indoorWeeks");
    else return "";
  };
  return {
    emissionMeasureUnit,
  };
};
