import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { Controller } from "react-hook-form";
import { Typography } from "@mui/material";
import { useTranslation } from "../../helpers/useTranslation";

type Props = {
  name: string;
  controlValue: any;
  dataValue: any;
  isNA: boolean;
  yesValue: any;
  noValue: any;
  disabled?: boolean;
  errorFieldMessage?: any;
};

export const CustomRadioButton = ({
  name,
  controlValue,
  dataValue,
  isNA,
  yesValue,
  noValue,
  errorFieldMessage,
  disabled,
}: Props) => {
  const { get } = useTranslation();
  return (
    <>
      <Controller
        render={({ field: { onChange, value } }) => (
          <RadioGroup aria-labelledby="YesNoNAGroup" name="YesNoNAGroup" row={true} value={value} onChange={onChange}>
            <FormControlLabel value={yesValue} control={<Radio />} label={get("radioButtonYes")} disabled={disabled} />
            <FormControlLabel value={noValue} control={<Radio />} label={get("radioButtonNo")} disabled={disabled} />
            {isNA && (
              <FormControlLabel
                value={0}
                control={<Radio />}
                label={get("radioButtonNotApplicable")}
                disabled={disabled}
              />
            )}
          </RadioGroup>
        )}
        name={name}
        control={controlValue}
        defaultValue={dataValue}
      />
      {errorFieldMessage && (
        <Typography variant="subtitle1" sx={{ color: "#E95450", margin: "3px 12px 0px 12px" }}>
          {errorFieldMessage?.message}
        </Typography>
      )}
    </>
  );
};
