import { Grid, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { CustomRadioButton } from "../../../../../../components/CustomRadioButton/CustomRadioButton";
import { CustomTextField } from "../../../../../../components/CustomTextField/CustomTextField";
import { useTranslation } from "../../../../../../helpers/useTranslation";
import ErrorIcon from "@mui/icons-material/Error";
import { ErrorMessage } from "@hookform/error-message";

type Props = {
  data?: ProductDisassembly;
  DocumentIsReadOnly: boolean;
};

export const DemolitionInformation = ({ data, DocumentIsReadOnly }: Props) => {
  const { get } = useTranslation();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useFormContext<EBVDDocument>();

  return (
    <form onSubmit={handleSubmit as any}>
      <Grid container rowSpacing={1} columnSpacing={{ md: 3 }}>
        <Grid item xs={12}>
          <Typography>{get("demolitionPrepared")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <CustomRadioButton
            name={"ProductDisassembly.Prepared"}
            controlValue={control}
            dataValue={data?.Prepared}
            isNA={true}
            yesValue={1}
            noValue={2}
            disabled={DocumentIsReadOnly}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>{get("demolitionCanBeSeparated")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <CustomRadioButton
            name={"ProductDisassembly.CanBeSeparated"}
            controlValue={control}
            dataValue={data?.CanBeSeparated}
            isNA={true}
            yesValue={1}
            noValue={2}
            disabled={DocumentIsReadOnly}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <CustomTextField
            isDisabled={DocumentIsReadOnly}
            name={"ProductDisassembly.PreparedComment"}
            controlValue={control}
            defaultValue={data?.PreparedComment}
            isMultiLine={true}
            rowSpan={5}
            label={get("demolitionPreparedComment")}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Typography>{get("demolitionSafety")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <CustomRadioButton
            name={"ProductDisassembly.Safety"}
            controlValue={control}
            dataValue={data?.Safety}
            isNA={true}
            yesValue={1}
            noValue={2}
            disabled={DocumentIsReadOnly}
            errorFieldMessage={errors?.ProductDisassembly?.Safety?.message}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <CustomTextField
            isDisabled={DocumentIsReadOnly}
            name={"ProductDisassembly.SafetyComment"}
            controlValue={control}
            defaultValue={data?.SafetyComment}
            isMultiLine={true}
            rowSpan={5}
            label={get("demolitionSafetyComment")}
            errors={errors}
          />
        </Grid>
        <Grid item md={0} sx={{ mt: 3 }}>
          <ErrorMessage
            message=""
            as={
              <ErrorIcon
                color="error"
                style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "center" }}
              />
            }
            errors={errors}
            name="ProductDisassembly.SafetyComment"
          />
        </Grid>
        <Grid item md={11} sx={{ mt: 3 }} style={{ padding: "0" }}>
          <ErrorMessage
            as={<Typography variant="subtitle1" sx={{ color: "#E95450", margin: "3px 12px 0px 12px" }} />}
            errors={errors}
            name="ProductDisassembly.SafetyComment"
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 3 }}>
          <CustomTextField
            isDisabled={DocumentIsReadOnly}
            name={"ProductDisassembly.Comment"}
            controlValue={control}
            defaultValue={data?.Comment}
            isMultiLine={true}
            rowSpan={6}
            label={get("demolitionComment")}
          />
        </Grid>
      </Grid>
    </form>
  );
};
