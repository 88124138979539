import { Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useTranslation } from "../../../../../helpers/useTranslation";
import { useDateFormat } from "../../../../../helpers/useDateFormat";
import { CustomTypography } from "../../../../../components/CustomTypography/CustomTypography";

type Props = {
  data?: UserGroup;
};

export const Company = ({ data }: Props) => {
  const { get } = useTranslation();

  const { format } = useDateFormat();

  return (
    <form>
      <Grid container rowSpacing={10} columnSpacing={{ md: 3 }}>
        <Grid item xs={12} md={12} sx={{ display: "flex", mt: 2 }}>
          <Typography sx={{ mr: 2 }}>{get("fieldsavedDate")}</Typography>
          <Typography>{format(data?.UpdatedTime)}</Typography>
        </Grid>
        <Grid item xs={12} md={12} sx={{ mb: 2 }}>
          <CustomTypography fieldName={get("fieldCompanyName")} fieldValue={data?.Name} />
        </Grid>
        <Grid item xs={12} md={3}>
          <CustomTypography fieldName={get("fieldCorporateIDNO")} fieldValue={data?.OrgNo} />
        </Grid>
        <Grid item xs={12} md={3}>
          <CustomTypography fieldName={get("fieldVATNumber")} fieldValue={data?.VatNo} />
        </Grid>
        <Grid item xs={12} md={3}>
          <CustomTypography fieldName={get("fieldGLN")} fieldValue={data?.GLN} />
        </Grid>
        <Grid item xs={12} md={3}>
          <CustomTypography fieldName={get("fieldDUNS")} fieldValue={data?.DUNS} />
        </Grid>
        <Grid item xs={12} md={3}>
          <CustomTypography fieldName={get("fieldAddress")} fieldValue={data?.Address} />
        </Grid>
        <Grid item xs={12} md={3}>
          <CustomTypography fieldName={get("fieldAreaCode")} fieldValue={data?.AreaCode} />
        </Grid>
        <Grid item xs={12} md={3}>
          <CustomTypography fieldName={get("fieldCity")} fieldValue={data?.City} />
        </Grid>
        <Grid item xs={12} md={3}>
          <CustomTypography fieldName={get("fieldCountry")} fieldValue={data?.CountryName} />
        </Grid>

        <Grid item xs={12} md={3}>
          <CustomTypography fieldName={get("fieldContact")} fieldValue={data?.Reference} />
        </Grid>
        <Grid item xs={12} md={3}>
          <CustomTypography fieldName={get("fieldTelephone")} fieldValue={data?.Phone} />
        </Grid>
        <Grid item xs={12} md={3}>
          <CustomTypography fieldName={get("fieldEMail")} fieldValue={data?.Email} />
        </Grid>
        <Grid item xs={12} md={3}>
          <CustomTypography fieldName={get("fieldWebsite")} fieldValue={data?.HomePage} />
        </Grid>
        <Grid item xs={3} md={3}>
          <CustomTypography fieldName={get("fieldLogo")} fieldValue={data?.LogoUrl} />
          <Typography sx={{ mt: 3, mb: 3 }}>
            <Box
              component="img"
              sx={{
                height: "auto",
                width: "100%",
              }}
              alt="Logo"
              src={data?.LogoUrl}
            />
          </Typography>
        </Grid>
      </Grid>
    </form>
  );
};
