import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Box, Grid, Typography, FormControlLabel, Checkbox, Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { Link, useLocation } from "react-router-dom";
import { PageLayout } from "../../components/PageLayout/PageLayout";
import { RegistrationForm } from "./RegistrationForm";
import { AcceptUserInvitation } from "../../services/userInvitationService";
import { useFormValidation } from "../../helpers/useFormValidation";
import { useTranslation } from "../../helpers/useTranslation";

export const RegistrationPage = () => {
  const { get, getCurrentLanguage } = useTranslation();
  const { userRegistrationValidationSchema } = useFormValidation();
  const methods = useForm<AcceptUserInvite>({
    resolver: yupResolver(userRegistrationValidationSchema),
  });
  const { mutateAsync: acceptInviteUser } = AcceptUserInvitation();
  const query = new URLSearchParams(useLocation().search);
  const securityStamp = query.get("securitystamp");

  const [acceptTerms, setAcceptTerms] = useState(false);

  const handleUpdateConfirmation = (data: AcceptUserInvite) => {
    data.SecurityStamp = securityStamp?.toString();
    acceptInviteUser(data);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAcceptTerms(event.target.checked);
  };

  const openLink = () => {
    const fileId = getCurrentLanguage() === "sv" ? 12 : 13;
    window.open(`https://ivl-ebvd.azurewebsites.net/api/V2/FileItem/GetDataItemAsStream/${fileId}`, "_blank");
  };

  return (
    <PageLayout title={get("indexPageTitle")}>
      <FormProvider {...methods}>
        <Box sx={{ flexGrow: 1, display: "flex" }}>
          <Grid
            container
            sx={{ p: 3, padding: "30px", paddingBottom: "50px", paddingTop: "34px", paddingLeft: "50px" }}
          >
            <Grid item md={12} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Typography variant="h5" color="black">
                {get("registrationTitle")}
              </Typography>
              <RegistrationForm />
            </Grid>

            <Grid item md={12} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <FormControlLabel
                control={<Checkbox checked={acceptTerms} onChange={handleCheckboxChange} color="primary" />}
                label={
                  <span>
                    {get("iHaveReadIntegrityPolicy")}{" "}
                    <Link onClick={openLink} style={{ color: "#44a4b7" }} to={""}>
                      {get("integrityPolicy")}
                    </Link>
                  </span>
                }
              />
              <Button
                onClick={() => methods.handleSubmit(handleUpdateConfirmation)()}
                variant="contained"
                type="submit"
                sx={{ m: "1rem 0rem" }}
                startIcon={<SaveIcon />}
                disabled={!acceptTerms}
              >
                {get("register")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </FormProvider>
    </PageLayout>
  );
};
