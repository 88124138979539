import { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useTranslation } from "../../../../../../../helpers/useTranslation";
import { ArticleProductIdSystem } from "../../../../../../../constants/articleIDConstants";
import { useGetProductGroups } from "../../../../../../../services/productGroupService";
import { CustomTextField } from "../../../../../../../components/CustomTextField/CustomTextField";
import { CustomErrorTypography } from "../../../../../../../components/CustomTypography/CustomErrorTypography";

type Props = {
  handleSubmit: any;
  control: any;
  setValue: any;
  articleItemID: string;
  setArticleItemId: any;
  errors: any;
  register: any;
  reset: any;
};

export const ArticleProductGroupForm = ({
  handleSubmit,
  control,
  setValue,
  articleItemID,
  setArticleItemId,
  errors,
  register,
  reset,
}: Props) => {
  const { get } = useTranslation();

  const [filter, setFilter] = useState<ArticleFilterOptions>({
    TextFilter: "",
    ProductGroupSystemId: "",
  });

  const { data: productGroupDataValues } = useGetProductGroups(filter);

  const handleChange = (event: SelectChangeEvent) => {
    reset({
      ProductGroupCode: "",
    });
    setArticleItemId(event.target.value);
    setFilter({
      ...filter,
      ProductGroupSystemId: event.target.value,
    });
    setValue("ProductGroupSystemId", event.target.value);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container rowSpacing={1} columnSpacing={{ md: 2.3 }}>
          <Grid item xs={12} sx={{ marginBottom: "1rem" }}>
            <label>{get("articleSelectIdSystemNumber") + " *"}</label>
          </Grid>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <Controller
              name="ProductGroupSystemId"
              control={control}
              defaultValue=""
              render={() => (
                <FormControl variant="outlined" sx={{ width: "100%" }}>
                  <InputLabel shrink>{get("articleSelectIdSystemNumber")}</InputLabel>
                  <Select
                    onChange={handleChange}
                    value={articleItemID}
                    error={errors.ProductGroupSystemId}
                    inputProps={register("ProductGroupSystemId")}
                    input={<OutlinedInput notched label={get("articleSelectIdSystemNumber")} />}
                  >
                    {ArticleProductIdSystem.map((item, idx) => (
                      <MenuItem value={item.value} key={idx}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            <Typography sx={{ color: "#ff1744", fontSize: "0.75rem", fontWeight: 400, margin: "3px 12px 0px 12px" }}>
              {errors.ProductGroupSystemId?.message}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ mt: 5, mb: 5 }}>
            {(articleItemID === "BK04" || articleItemID === "BSAB96") && (
              <>
                <Autocomplete
                  noOptionsText={get("noOptionsText")}
                  filterOptions={(x) => x}
                  disablePortal
                  getOptionLabel={(option) => {
                    return option.FullName;
                  }}
                  onChange={(_event, value) => {
                    setValue("ProductGroupCode", value?.Code, { shouldValidate: true });
                  }}
                  onInputChange={(_event, newInputValue) => {
                    setFilter({
                      ...filter,
                      TextFilter: newInputValue,
                    });
                  }}
                  options={productGroupDataValues?.ProductGroups || []}
                  renderInput={(params) => <TextField {...params} label={articleItemID} name="ProductGroupCode" />}
                />
                {(articleItemID === "BK04" || articleItemID === "BSAB96") && errors.ProductGroupCode && (
                  <CustomErrorTypography errorMessage={errors.ProductGroupCode && errors.ProductGroupCode.message} />
                )}
              </>
            )}
            {(articleItemID === "ETIM" || articleItemID === "UNSPSC") && (
              <CustomTextField
                isDisabled={false}
                name={"ProductGroupCode"}
                controlValue={control}
                defaultValue={""}
                label={articleItemID}
                errors={errors}
              />
            )}
            {(articleItemID === "ETIM" || articleItemID === "UNSPSC") &&
              errors.ProductGroupCode &&
              errors.ProductGroupCode.type === "required" && (
                <CustomErrorTypography errorMessage={errors.ProductGroupCode && errors.ProductGroupCode.message} />
              )}
            {(articleItemID === "SNI" || articleItemID === "KN") && (
              <CustomTextField
                isDisabled={false}
                type="number"
                name={"ProductGroupCode"}
                controlValue={control}
                defaultValue={""}
                label={articleItemID}
                errors={errors}
              />
            )}
            {(articleItemID === "SNI" || articleItemID === "KN") && errors.ProductGroupCode && (
              <CustomErrorTypography errorMessage={errors.ProductGroupCode && errors.ProductGroupCode.message} />
            )}
          </Grid>
        </Grid>
      </form>
    </>
  );
};
