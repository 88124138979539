import { Button, Grid } from "@mui/material";
import { useNavigate } from "react-router";
import { ArrowForward } from "@mui/icons-material";

type Props = {
  orgName: string;
  orgId: number;
};

export const OrganizationListItem = ({ orgName, orgId }: Props) => {
  const navigate = useNavigate();

  return (
    <Grid item md={6} margin={"auto"} mb={2}>
      <Button
        onClick={() => navigate(`/admin/${orgId}`)}
        variant="contained"
        sx={{
          ml: "auto",
        }}
      >
        {orgName}
        <ArrowForward />
      </Button>
    </Grid>
  );
};
