import { AccessType } from "../helpers/usePublicAccessPresets";
import { TranslatedKey } from "../translation/translatedKey";

type UserInformationTypes = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

type TranslationTypes = AccessType | UserInformationTypes;

export const translationRows = {
  1: "sectionCompanyInformation",
  2: "sectionArticleInformation",
  3: "sectionChemicalContent",
  4: "sectionRawMaterials",
  5: "sectionEnvironmentalImpact",
  6: "sectionDistribution",
  7: "sectionConstructionPhase",
  8: "sectionUsagePhase",
  9: "sectionDemolition",
  10: "sectionWasteManagement",
  11: "sectionIndoorEnvironment",
  1001: "sectionCompanyInformation",
  1002: "sectionArticleInformation",
  1003: "sectionChemicalContent",
  1100: "fieldCASNum",
  1101: "fieldECNum",
  1102: "fieldAlternativeCode",
  1103: "chemicalComponentSubstanceTableTitle",
  1004: "sectionRawMaterials",
  1200: "rawMaterialModalCountryRawMaterialTitle",
  1201: "rawMaterialModalCityRawMaterialTitle",
  1202: "rawMaterialModalCountryOfManufacturerTitle",
  1203: "rawMaterialModalCityOfManufacturerTitle",
  1005: "sectionEnvironmentalImpact",
  1006: "sectionDistribution",
  1007: "sectionConstructionPhase",
  1008: "sectionUsagePhase",
  1009: "sectionDemolition",
  1010: "sectionWasteManagement",
  1011: "sectionIndoorEnvironment",
} as Record<TranslationTypes, TranslatedKey>;
