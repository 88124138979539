import React, { useState, useEffect } from "react";
import { Grid, Button, Tooltip, Alert } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import PublishOutlinedIcon from "@mui/icons-material/PublishOutlined";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";
import UnpublishedOutlinedIcon from "@mui/icons-material/UnpublishedOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { styled, alpha } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { KeyboardArrowUp } from "@mui/icons-material";
import { useTranslation } from "../../../helpers/useTranslation";
import { ConfirmationDeleteModal } from "../../../components/ConfirmationModal/ConfirmationDeleteModal";
import {
  useDeleteDocumentById,
  useGetDocumentExcelFromDocumentIds,
  useGetDocumentPdfFromDocumentId,
  useGetDocumentXMLFromDocumentId,
  usePublishEBVDDocuments,
  useUnpublishEBVDDocuments,
} from "../../../services/documentService";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { PublishDocumentsModal } from "../PublishDocumentsModal/PublishDocumentsModal";
import { useNavigate } from "react-router";
import { useGetUser } from "../../../services/userService";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
      paddingBottom: "0px",
    },

    "& .MuiMenuItem-root": {
      "&.MuiMenuItem-gutters": {
        width: "220px!important",
        whiteSpace: "break-spaces",
      },
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
        paddingBottom: "0px",
      },
      "&:active": {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

type Props = {
  setFilter: any;
  filter: InternalSearchFilter;
  documentList: EBVDListDocument[];
  documentIds: number[];
  setDocumentIds: any;
};

export const InternalSearchSubToolBar = ({ setFilter, filter, documentList, documentIds, setDocumentIds }: Props) => {
  const [sortValue, setSortValue] = useState("");
  const { get } = useTranslation();
  const navigate = useNavigate();
  const { data } = useGetUser();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { mutate: deleteDocument } = useDeleteDocumentById();
  const { mutate: documentPdf } = useGetDocumentPdfFromDocumentId();
  const { mutate: documentXML } = useGetDocumentXMLFromDocumentId();
  const { mutate: documentExcel } = useGetDocumentExcelFromDocumentIds();
  const [showDelete, setShowDelete] = useState(false);
  const [showUnpublish, setShowUnpublish] = useState(false);
  const [showPublishDocumentsModal, setShowPublishDocumentsModal] = useState(false);
  const [documentsToPublish, setDocumentsToPublish] = useState<EBVDListDocument[] | undefined>();
  const [documentsToUnpublish, setDocumentsToUnpublish] = useState<EBVDListDocument[] | undefined>();
  const [unPublishMessage, setUnPublishMessage] = useState("");
  const [unPublishAvailable, setUnPublishAvailable] = useState(false);

  const { mutateAsync: publishEBVDDocuments } = usePublishEBVDDocuments();
  const { mutateAsync: unpublishEBVDDocuments } = useUnpublishEBVDDocuments();

  useEffect(() => {
    if (filter.SortType === 0 && filter.SortAscending === true) setSortValue("0");
    else if (filter.SortType === 0 && filter.SortAscending === false) setSortValue("1");
    else if (filter.SortType === 1 && filter.SortAscending === true) setSortValue("2");
    else if (filter.SortType === 1 && filter.SortAscending === false) setSortValue("3");
    else if (filter.SortType === 3 && filter.SortAscending === true) setSortValue("5");
    else if (filter.SortType === 3 && filter.SortAscending === false) setSortValue("6");
    else setSortValue("4");
  }, [filter]);

  useEffect(() => {
    let documents: EBVDListDocument[] = [];

    documentIds?.forEach((documentId) => {
      documents.push(documentList[documentId]);
    });

    setDocumentsToPublish(documents.filter((x) => x.DocumentPermissions.CanPublish === true));
    setDocumentsToUnpublish(documents.filter((x) => x.DocumentPermissions.CanUnPublish === true));
    if (documents.filter((x) => x.DocumentPermissions.CanUnPublish === true)?.length > 0) {
      setUnPublishAvailable(true);
      if (documents.filter((x) => x.DocumentPermissions.CanUnPublish === true).length === 1) {
        setUnPublishMessage(
          get("unpublishDocumentsMessage") +
            documents.filter((x) => x.DocumentPermissions.CanUnPublish === true)[0].ProductName,
        );
      } else {
        setUnPublishMessage(
          get("unpublishDocumentsMessage") +
            documents.filter((x) => x.DocumentPermissions.CanUnPublish === true).length +
            " " +
            get("documents"),
        );
      }
    } else {
      setUnPublishAvailable(false);
    }
  }, [documentIds, documentList]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleExportPdf = () => {
    documentIds?.forEach((documentId) => {
      documentPdf({
        versionNumber: documentList[documentId]?.DocumentVersion,
        documentId: documentList[documentId]?.InternalDocumentId,
        name: documentList[documentId]?.ProductName,
      });
    });
    setAnchorEl(null);
    setDocumentIds([]);
  };
  const handleExportXml = () => {
    documentIds?.forEach((documentId) => {
      documentXML({
        versionNumber: documentList[documentId]?.DocumentVersion,
        documentId: documentList[documentId]?.InternalDocumentId,
        name: documentList[documentId]?.ProductName,
      });
    });
    setAnchorEl(null);
    setDocumentIds([]);
  };
  const handleExportExcel = () => {
    let internalDocumentIds: number[] = [];
    documentIds?.forEach((documentId) => {
      internalDocumentIds.push(documentList[documentId]?.InternalDocumentId);
    });
    documentExcel({
      DocumentIds: internalDocumentIds,
    });

    setAnchorEl(null);
    setDocumentIds([]);
  };

  const publishDocuments = () => {
    if (documentsToPublish) {
      const documentIdsList: DocumentIdsList = {
        DocumentIds: documentsToPublish.map((document) => document.InternalDocumentId),
      };
      const userGroupId = documentsToPublish[0].CompanyId;
      publishEBVDDocuments(documentIdsList).then((result: any) => {
        navigate("/transactions/" + userGroupId);
      });
    }

    setShowPublishDocumentsModal(false);
  };

  const unPublishDocuments = () => {
    if (documentsToUnpublish) {
      const documentIdsList: DocumentIdsList = {
        DocumentIds: documentsToUnpublish.map((document) => document.InternalDocumentId),
      };
      const userGroupId = documentsToUnpublish[0].CompanyId;
      unpublishEBVDDocuments(documentIdsList).then((result: any) => {
        navigate("/transactions/" + userGroupId);
      });
    }
  };

  const goToTransactionPage = () => {
    navigate("/transactions");
  };

  const handleDelete = () => {
    documentIds?.forEach((documentId) => {
      deleteDocument(documentList[documentId]?.InternalDocumentId.toString());
    });
    setShowDelete(false);
    setDocumentIds([]);
  };

  const handleChange = (event: any) => {
    setSortValue(event.target.value);
    switch (event.target.value) {
      case "1":
        setFilter({
          ...filter,
          SortType: 0,
          SortAscending: false,
        });
        break;
      case "2":
        setFilter({
          ...filter,
          SortType: 1,
          SortAscending: true,
        });
        break;
      case "3":
        setFilter({
          ...filter,
          SortType: 1,
          SortAscending: false,
        });
        break;
      case "4":
        setFilter({
          ...filter,
          SortType: 2,
          SortAscending: true,
        });
        break;
      case "5":
        setFilter({
          ...filter,
          SortType: 3,
          SortAscending: true,
        });
        break;
      case "6":
        setFilter({
          ...filter,
          SortType: 3,
          SortAscending: false,
        });
        break;
      default:
        setFilter({
          ...filter,
          SortType: 0,
          SortAscending: true,
        });
        break;
    }
  };

  return (
    <Grid container sx={{ marginTop: 3, marginBottom: 3 }}>
      <AuthenticatedTemplate>
        <Grid item md={2} sx={{ mr: 3 }}>
          <ConfirmationDeleteModal
            show={showDelete}
            handleClose={() => setShowDelete(false)}
            handleConfirmation={() => handleDelete()}
            title={get("deleteDocumentTitle")}
            saveButtonName={get("radioButtonYes")}
            cancelButtonName={get("radioButtonNo")}
          >
            {get("deleteDocumentMessage")}
          </ConfirmationDeleteModal>

          <ConfirmationDeleteModal
            show={showUnpublish}
            handleClose={() => setShowUnpublish(false)}
            handleConfirmation={() => unPublishDocuments()}
            title={get("unpublishDocumentTitle")}
            saveButtonName={get("radioButtonYes")}
            cancelButtonName={get("radioButtonNo")}
          >
            {unPublishMessage}
          </ConfirmationDeleteModal>
          <PublishDocumentsModal
            title={get("deleteDocumentTitle")}
            show={showPublishDocumentsModal}
            handleClose={() => setShowPublishDocumentsModal(false)}
            handleConfirmation={() => publishDocuments()}
            saveButtonName={get("toolBarPublish")}
            cancelButtonName={get("cancelButtonText")}
            data={documentsToPublish}
          />

          <div>
            <Tooltip title={get("tooltipBulkManagement")} placement="right">
              <Button
                id="bulkManagement-arrowbtn"
                aria-controls={open ? "bulkManagement-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                sx={{ marginLeft: "2px" }}
                endIcon={anchorEl ? <KeyboardArrowUp /> : <KeyboardArrowDownIcon />}
              >
                {get("bulkManagementTitle")}
              </Button>
            </Tooltip>
            <StyledMenu
              id="bulkManagement-menu"
              MenuListProps={{
                "aria-labelledby": "bulkManagement-arrowbtn",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handleExportPdf} disableRipple disabled={!(documentIds.length > 0)}>
                <PublishOutlinedIcon />
                {get("bulkManagementExportPdfTitle")}
              </MenuItem>
              <MenuItem onClick={handleExportXml} disableRipple disabled={!(documentIds.length > 0)}>
                <PublishOutlinedIcon />
                {get("bulkManagementExportXMLTitle")}
              </MenuItem>
              <MenuItem onClick={handleExportExcel} disableRipple disabled={!(documentIds.length > 0)}>
                <PublishOutlinedIcon />
                {get("bulkManagementExportExcelTitle")}
              </MenuItem>
              {data && !data.InvalidApplicationFeatureIds.includes(2) && (
                <>
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      setShowPublishDocumentsModal(true);
                    }}
                    disableRipple
                    disabled={!(documentIds.length > 0)}
                  >
                    <PublishOutlinedIcon />
                    {get("bulkManagementPublishTitle")}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      setShowUnpublish(true);
                    }}
                    disableRipple
                    disabled={!(documentIds.length > 0 && unPublishAvailable)}
                  >
                    <UnpublishedOutlinedIcon />
                    {get("bulkManagementUnpublishTitle")}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      goToTransactionPage();
                    }}
                    disableRipple
                  >
                    <OpenInBrowserIcon />
                    {get("bulkManagementHistoryTitle")}
                  </MenuItem>
                </>
              )}
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  setShowDelete(true);
                }}
                disabled={!(documentIds.length > 0)}
                disableRipple
                sx={{ color: "#E95450" }}
              >
                <DeleteOutlinedIcon style={{ color: "red" }} />
                {get("bulkManagementDeleteTitle")}
              </MenuItem>
            </StyledMenu>
          </div>
        </Grid>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Grid item md={8} sx={{ mr: 3 }}>
          <Alert severity="info">{get("documentAlert")}</Alert>
        </Grid>
      </UnauthenticatedTemplate>
      <Grid item md={3} marginLeft="auto">
        <FormControl sx={{ width: "100%" }}>
          <InputLabel shrink>{get("sortFilterTitle")}</InputLabel>
          <Select
            value={sortValue}
            label="Sortera efter"
            onChange={handleChange}
            input={<OutlinedInput notched label={get("sortFilterTitle")} />}
          >
            <MenuItem value="0">{get("sortFilterProductNameAsc")}</MenuItem>
            <MenuItem value="1">{get("sortFilterProductNameDesc")}</MenuItem>
            <MenuItem value="2">{get("sortFilterDocumentUpdatedTimeAsc")}</MenuItem>
            <MenuItem value="3">{get("sortFilterDocumentUpdatedTimeDesc")}</MenuItem>
            <MenuItem value="4">{get("sortFilterDocumentVersion")}</MenuItem>
            <MenuItem value="5">{get("sortFilterDocumentIdAsc")}</MenuItem>
            <MenuItem value="6">{get("sortFilterDocumentIdDesc")}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};
