import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { PageLayout } from "../../../../../components/PageLayout/PageLayout";
import { useTranslation } from "../../../../../helpers/useTranslation";
import { useGetUser } from "../../../../../services/userService";
import { OrganizationListForTransactionItem } from "./OrganizationListItem/OrganizationListForTransactionItem";
import { Navigate } from "react-router";

export const OrganizationListForTransaction = () => {
  const { get } = useTranslation();
  const { data } = useGetUser();

  // returns empty page if user cant use import
  if (!data || data.InvalidApplicationFeatureIds.includes(2)) return <></>;
  else if (
    data &&
    data?.UserGroups.filter((x) => (x.CanCreateNew && x.CanEdit && x.CanCreateNewVersion) || x.IsUserGroupAdmin)
      .length > 1
  ) {
    return (
      <PageLayout title={get("transactionPageTitle")}>
        <Grid textAlign={"center"}>
          <Box sx={{ width: "50%", margin: "auto" }}>
            <Box
              sx={{
                display: "flex",
                gap: 1,
                py: 1,
                backgroundColor: "white",
                padding: 5,
                marginTop: 5,
              }}
            >
              <Grid container textAlign="center">
                <Grid item md={12} marginBottom={"30px"}>
                  <Typography variant="h5" color="black">
                    {get("organizationListforTransactionChoice")}
                  </Typography>
                </Grid>
                {data?.UserGroups.map((organization) => (
                  <OrganizationListForTransactionItem
                    orgName={organization.UserGroupName}
                    orgId={organization.UserGroupId}
                  />
                ))}
              </Grid>
            </Box>
          </Box>
        </Grid>
      </PageLayout>
    );
  } else {
    return <Navigate to={`/transactions/${data?.UserGroups[0].UserGroupId}`} />;
  }
};
