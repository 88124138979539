import { Grid, Link, Typography } from "@mui/material";
import { useTranslation } from "../../../../../../helpers/useTranslation";
import { CustomTypography } from "../../../../../../components/CustomTypography/CustomTypography";
import { DocumentIdSystem } from "../../../../../../constants/documentIdConstants";
import { useDateFormat } from "../../../../../../helpers/useDateFormat";
import { useDocumentStatus } from "../../../../../../helpers/useDocumentStatus";
import { CustomTextField } from "../../../../../../components/CustomTextField/CustomTextField";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ErrorIcon from "@mui/icons-material/Error";
import { ErrorMessage } from "@hookform/error-message";
import { format as formatDateFn } from "date-fns";

type Props = {
  data?: EBVDDocument;
};

export const DocumentData = ({ data }: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { get } = useTranslation();
  const navigate = useNavigate();
  const { format } = useDateFormat();

  const showVersionLink = data?.LatestVersionNumber !== data?.VersionNumber;

  const displayVersion = (LatestVersionDocumentId: number | undefined) => {
    if (LatestVersionDocumentId) {
      navigate(`/documents/${LatestVersionDocumentId}`);
    }
  };

  return (
    <Grid container rowSpacing={10} columnSpacing={{ md: 4 }} sx={{ mb: 2 }}>
      <Grid item xs={12} md={3} sx={{ mt: 2 }}>
        <CustomTypography
          fieldName={get("fieldDocDataSystemID")}
          fieldValue={DocumentIdSystem.find((x) => x.value === data?.DocumentSystemId)?.label}
        />
      </Grid>
      <Grid item xs={12} md={3} sx={{ mt: 2 }}>
        <CustomTypography fieldName={get("fieldDocDataSystemCode")} fieldValue={data?.DocumentSystemCode} />
      </Grid>
      <Grid item xs={12} md={3} sx={{ mt: 2 }}>
        <CustomTypography fieldName={get("fieldDocDataSerialNumber")} fieldValue={data?.SerialNumber} />
      </Grid>
      <Grid item xs={12} md={3} sx={{ mt: 2 }}>
        <CustomTypography
          fieldName={get("fieldDocDataVersionNumber")}
          fieldValue={data?.VersionNumber + " (" + get("of") + " " + data?.LatestVersionNumber + ")"}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <CustomTypography fieldName={get("fieldDocDataStatus")} fieldValue={data?.DocumentStatusName} />
      </Grid>
      <Grid item xs={12} md={3}>
        <CustomTypography fieldName={get("fieldDocDataCreated")} fieldValue={format(data?.DocumentCreatedTime)} />
      </Grid>
      <Grid item xs={12} md={3}>
        <CustomTypography fieldName={get("fieldDocDataSaved")} fieldValue={format(data?.DocumentUpdatedTime)} />
      </Grid>
      <Grid item xs={12} md={3}>
        <CustomTypography
          fieldName={get("fieldDocDataValidFrom")}
          fieldValue={
            data?.DocumentValidFromDate ? formatDateFn(new Date(data.DocumentValidFromDate), "yyyy-MM-dd") : ""
          }
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <CustomTypography
          fieldName={get("fieldDocDataValidTo")}
          fieldValue={data?.DocumentValidToDate ? formatDateFn(new Date(data.DocumentValidToDate), "yyyy-MM-dd") : ""}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <CustomTypography
          fieldName={get("fieldDocDataPublished")}
          fieldValue={format(data?.DocumentStatusUpdatedTime)}
        />
      </Grid>
      <Grid item xs={12} md={3} hidden={!showVersionLink}>
        <CustomTypography
          fieldName={get("fieldLinkToLatestVersion")}
          fieldValue={
            <Link
              component="button"
              underline="none"
              onClick={() => {
                displayVersion(data?.LatestVersionDocumentId);
              }}
            >
              {get("fieldDocDataVersionNumber") + " " + data?.LatestVersionNumber}
            </Link>
          }
        />
      </Grid>
      <Grid item xs={12} md={12} hidden={data?.VersionNumber !== 1 ? false : true}>
        <CustomTextField
          name={"ChangeDescription"}
          isDisabled={data?.DocumentIsReadOnly || false}
          controlValue={control}
          defaultValue={data?.ChangeDescription}
          label={get("fieldDocDataChangeDesc") + " *"}
          errors={errors}
        />
      </Grid>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ mb: 3 }}>
        <Grid item md={0} sx={{ mt: 3, ml: 4 }}>
          <ErrorMessage
            message=""
            as={
              <ErrorIcon
                color="error"
                style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "center" }}
              />
            }
            errors={errors}
            name="ChangeDescription"
          />
        </Grid>
        <Grid item md={10} sx={{ mt: 3 }} style={{ padding: "0" }}>
          <ErrorMessage
            as={<Typography variant="subtitle1" sx={{ color: "#E95450", margin: "3px 12px 0px 12px" }} />}
            errors={errors}
            name="ChangeDescription"
          />
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} sx={{ mb: 2 }}>
        <CustomTypography
          fieldName={get("fieldDocDataStatusComment")}
          fieldValue={data?.DocumentStatusDescription}
        />
      </Grid>
    </Grid>
  );
};
