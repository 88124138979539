import { useFormContext } from "react-hook-form";
import { Grid, Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "../../../helpers/useTranslation";
import { useGetUser } from "../../../services/userService";

import { useEffect } from "react";

type Props = {
  userGroup: UserGroup;
  fileName: string;
  setFileName: any;
};

export const ImportDocumentForm = ({ userGroup, fileName, setFileName }: Props) => {
  const { get } = useTranslation();
  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useFormContext<ImportDocument>();

  useGetUser();

  useEffect(() => {
    setValue("UserGroupId", userGroup.UserGroupId);
  }, [userGroup, fileName]);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: ".xlsx", // You can specify the file types you want to accept
    onDrop: (acceptedFiles) => {
      // Handle the dropped files as needed
      if (acceptedFiles && acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        setFileName(file.name); // Set the file name
        readFileContentsAsBase64(file)
          .then((fileContents) => {
            // You now have the file contents as a string
            setValue("FileBuffer", fileContents);
          })
          .catch((error) => {
            console.error("Error reading file:", error);
          });
      }
    },
  });

  function readFileContentsAsBase64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        if (event.target && event.target.result) {
          const result = event.target.result as ArrayBuffer;
          const byteArray = new Uint8Array(result);
          const base64String = arrayBufferToBase64(byteArray);
          resolve(base64String);
        } else {
          reject(new Error("Failed to read the file."));
        }
      };

      reader.onerror = (event) => {
        reject(event.target?.error || new Error("Error reading the file."));
      };

      reader.readAsArrayBuffer(file);
    });
  }

  function arrayBufferToBase64(buffer: Uint8Array): string {
    let binary = "";
    buffer.forEach((byte) => {
      binary += String.fromCharCode(byte);
    });
    return btoa(binary);
  }

  const dropzoneStyle: React.CSSProperties = {
    border: "2px dashed #d9d9d9",
    borderRadius: "4px",
    backgroundColor: "#fafafa",
    textAlign: "center",
    padding: "20px",
    cursor: "pointer",
  };

  return (
    <form onSubmit={handleSubmit as any}>
      <Grid container sx={{ mt: 2 }} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {/* <Grid item xs={12} sx={{ mt: 1 }}>
          <Controller
            name="UserGroupId"
            control={control}
            render={({ field }) => (
              <FormControl variant="outlined" sx={{ width: "100%" }}>
                <InputLabel shrink>{get("documentModalOrganisation")}</InputLabel>
                <Select
                  {...field}
                  labelId={get("documentModalOrganisation")}
                  error={!!errors.UserGroupId}
                  input={<OutlinedInput notched label={get("documentModalOrganisation")} />}
                >
                  {data?.UserGroups.filter((x) => x.UserGroupId === userGroup.UserGroupId).map(
                    (item: EBVDUserGroup) => (
                      <MenuItem key={item.UserGroupId} value={item.UserGroupId} selected={true}>
                        {item.UserGroupName}
                      </MenuItem>
                    ),
                  )}
                </Select>
              </FormControl>
            )}
          />
          <Typography sx={{ color: "#ff1744", fontSize: "0.75rem", fontWeight: 400, margin: "3px 12px 0px 12px" }}>
            {errors.UserGroupId?.message}
          </Typography>
        </Grid> */}

        <Grid item xs={12} sx={{ mt: 1 }}>
          <Typography variant="h6">{get("importUploadFile")}</Typography>

          {/* File Drop Zone */}
          <div {...getRootProps()} style={dropzoneStyle}>
            <input {...getInputProps()} />
            <p>{get("importDragAndDrop")} </p>
          </div>

          {/* Display accepted files */}
          {fileName && acceptedFiles.length > 0 && (
            <Typography variant="body2">{acceptedFiles.map((file) => file.name).join(", ")}</Typography>
          )}
          <Typography sx={{ color: "#ff1744", fontSize: "0.75rem", fontWeight: 400, margin: "3px 12px 0px 12px" }}>
            {errors.FileBuffer?.message}
          </Typography>

          {/* Hidden input field to store the file name */}
          <input
            type="hidden"
            name="fileName"
            value={fileName} // Use the file name here
          />
        </Grid>
      </Grid>
    </form>
  );
};
