import { TextField } from "@mui/material";

type Props = {
  searchTerm: string;
  handleSearch: any;
  label: any;
};

export const InternalSearchTextField = ({ handleSearch, searchTerm, label }: Props) => {
  return (
    <>
      <TextField
        id="standard-bare"
        variant="outlined"
        onChange={handleSearch}
        value={searchTerm}
        sx={{ width: "100%" }}
        label={label}
        InputLabelProps={{ shrink: true }}
      />
    </>
  );
};
