import { Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import byggLogo from "../../assets/bygg.jpg";
import ivlLogo from "../../assets/ivl.png";
import { useTranslation } from "../../helpers/useTranslation";

export const Footer = () => {
  const { get } = useTranslation();
  return (
    <Box
      sx={{
        backgroundColor: "transparent",
        color: "black",
        padding: "1rem",
      }}
    >
      <Container maxWidth="xl">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "30px",
          }}
        >
          <Typography sx={{ display: "flex", alignSelf: "center" }} variant="overline">
            {get("footerText")}
          </Typography>
          <Box component="img" src={byggLogo} sx={{ height: "25px", display: "flex", alignSelf: "center" }}></Box>
          <Box component="img" src={ivlLogo} sx={{ height: "50px", display: "flex", alignSelf: "center" }}></Box>
        </Box>
      </Container>
    </Box>
  );
};
