import { useRequest } from "../helpers/useRequest";
import { api } from "../config/apiEndpoints";
import { queryEnums } from "../config/queryEnums";
import { useQuery } from "react-query";

const countryUrl = api.country;

export const useGetCountries = () => {
  const { post } = useRequest();

  return useQuery<Countries>(
    [queryEnums.countries],
    () =>
      post(`${countryUrl}/GetCountries`, {
        SortType: 0,
        SortAscending: true,
      }),
    {
      suspense: false,
      refetchOnWindowFocus: false,
    },
  );
};
