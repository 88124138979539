import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import { useFormValidation } from "../../../helpers/useFormValidation";
import { useCreateAccount } from "../../../services/userGroupService";
import { useTranslation } from "../../../helpers/useTranslation";
import { ComponentLayout } from "../../../components/ComponentLayout/ComponentLayout";
import { CompanyInformation } from "./CompanyInformation";
import { InvoiceInformation } from "./InvoiceInformation";
import { ContactInformation } from "./ContactInformation";
import { OtherInformation } from "./OtherInformation";
import { PageLayout } from "../../../components/PageLayout/PageLayout";
import { CreateAccountToolBar } from "../OrganizationDetail/CreateAccountToolBar/CreateAccountToolBar";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

export const CreateAccount = () => {
  const { createAccountValidationSchema } = useFormValidation();
  const { mutateAsync: createAccountInformation } = useCreateAccount();
  const { get } = useTranslation();
  const appInsights = useAppInsightsContext();
  const pageView = {
    name: "CreateAccount",
  };
  appInsights.trackPageView(pageView);

  const methods = useForm<CreateAccount>({
    resolver: yupResolver(createAccountValidationSchema),
  });

  const isDirty = methods.formState.isDirty;

  const components = [
    {
      component: <CompanyInformation />,
      title: get("sectionCompanyInformation"),
    },
    {
      component: <InvoiceInformation />,
      title: get("accountInvoiceInformation"),
    },
    {
      component: <ContactInformation />,
      title: get("accountContactInformation"),
    },
    {
      component: <OtherInformation />,
      title: get("articleOtherInformation"),
    },
  ];

  const upCreateAccountData = (userGroupInfo: CreateAccount) => {
    createAccountInformation(userGroupInfo);
    methods.reset(userGroupInfo);
  };
  return (
    <PageLayout title={get("CreateAccount")}>
      <FormProvider {...methods}>
        <Grid container>
          <Grid item md={1}></Grid>
          <Grid item md={10}>
            <CreateAccountToolBar
              isDirty={isDirty}
              updateInformation={() => methods.handleSubmit(upCreateAccountData)()}
            ></CreateAccountToolBar>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={1}></Grid>
          <Grid item md={10}>
            {components.map((comp, idx) => (
              <ComponentLayout key={idx} title={comp.title} index={idx + 1} length={components.length}>
                {comp.component}
              </ComponentLayout>
            ))}
          </Grid>
        </Grid>
      </FormProvider>
    </PageLayout>
  );
};
