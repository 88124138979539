import { useParams } from "react-router";
import { PageLayout } from "../../../components/PageLayout/PageLayout";
import { DocumentSections } from "./DocumentSections/DocumentSections";
import { useGetDocumentByDocumentId } from "../../../services/documentService";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useRecoilState } from "recoil";
import { localeAtom } from "../../../recoil/atom";
import { useEffect } from "react";

type Params = {
  id: string;
};

export const DocumentDetailPage = () => {
  const appInsights = useAppInsightsContext();
  const pageView = {
    name: "DocumentDetailsPage",
  };
  appInsights.trackPageView(pageView);
  const [language] = useRecoilState(localeAtom);

  const { id } = useParams<Params>();
  const { data, refetch } = useGetDocumentByDocumentId(id);

  useEffect(() => {
    refetch();
  }, [language, refetch]);

  return (
    <PageLayout title={`eBVD: ${data?.Product.Name}`} key={id}>
      <DocumentSections data={data} />
    </PageLayout>
  );
};
