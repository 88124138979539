import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "../../../../../../../helpers/useTranslation";
import { ArticleProductGroupForm } from "./ArticleProductGroupForm";
import { ConfirmationModal } from "../../../../../../../components/ConfirmationModal/ConfirmationModal";
import { useFormValidation } from "../../../../../../../helpers/useFormValidation";
import ErrorIcon from "@mui/icons-material/Error";

type Props = {
  errors: any;
  setValue: any;
  getValues: any;
  DocumentIsReadOnly: boolean;
};

export const ArticleProductGroup = ({ errors, setValue, getValues, DocumentIsReadOnly }: Props) => {
  const [showAdd, setShowAdd] = useState(false);
  const [articleItemID, setArticleItemId] = useState<string>("");
  const [rowColor, setRowColorRequired] = useState(false);
  const [productGroupDataId, setProductGroupDataId] = useState(0);
  const { get } = useTranslation();
  const { documentProductGroupValidation } = useFormValidation();

  const {
    control,
    reset,
    handleSubmit,
    register,
    formState: { errors: errorsProductGroupSystem },
    setValue: setDocumentProductGroupValue,
  } = useForm<DocumentProductGroupSystem>({
    defaultValues: {
      ProductGroupSystemId: "",
      ProductGroupCode: "",
    },
    resolver: yupResolver(documentProductGroupValidation),
  });

  const [articleProductGroupData, setArticleProductGroupData] = useState<DocumentProductGroupSystem[]>(
    getValues("DocumentProductGroupSystems") || [],
  );

  const handleConfirmation = (data: DocumentProductGroupSystem) => {
    setArticleProductGroupData([...articleProductGroupData, data]);
    setRowColorRequired(false);
    setArticleItemId("");
    setShowAdd(false);
    setProductGroupDataId(0);
    setValue("DocumentProductGroupSystems", [...articleProductGroupData, data], { shouldDirty: true });
    reset();
  };

  const remove = (index: number) => {
    if (index !== 0) articleProductGroupData.splice(index - 1, 1);
    setArticleProductGroupData([...articleProductGroupData]);
    setValue("DocumentProductGroupSystems", [...articleProductGroupData], { shouldDirty: true });
    setProductGroupDataId(0);
  };

  const handleModalClose = () => {
    setArticleItemId("");
    setShowAdd(false);
    setRowColorRequired(false);
    setProductGroupDataId(0);
    reset();
  };

  const handleRowClick = (idx: number) => {
    setProductGroupDataId(idx + 1);
    setRowColorRequired(true);
  };

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ mb: 2 }}>
        <Grid item md={12} sx={{ mt: 2 }}>
          <Typography color="text.secondary" gutterBottom>
            {get("articleProductGroup") + " *"}
          </Typography>
        </Grid>
        <Grid item md={11}>
          <Paper
            sx={{
              overflow: "hidden",
              border: errors?.DocumentProductGroupSystems?.message ? "1px solid red!important" : "",
            }}
          >
            <TableContainer sx={{ height: 300, width: "100%" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="body1">
                        <b>{get("articleProductGroupSystem")}</b>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        <b>{get("articleProductGroupID")}</b>
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {articleProductGroupData?.map((item, idx) => (
                    <TableRow
                      key={idx}
                      onClick={() => handleRowClick(idx)}
                      sx={{
                        backgroundColor: rowColor && idx + 1 === productGroupDataId ? "#b1b8b8" : "",
                        cursor: "pointer",
                      }}
                    >
                      <TableCell>{item.ProductGroupSystemId}</TableCell>
                      <TableCell>{item.ProductGroupCode}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>

        <Grid item md={1}>
          <Stack direction="column" spacing={2} sx={{ float: "right" }}>
            <Button
              variant="contained"
              onClick={() => setShowAdd(true)}
              size="small"
              disabled={DocumentIsReadOnly}
              sx={{ width: "36px", minWidth: "0px" }}
            >
              <AddIcon />
            </Button>
            <Button
              color="error"
              size="small"
              sx={{ m: 1, width: "36px", minWidth: "0px" }}
              disabled={productGroupDataId === 0 || DocumentIsReadOnly}
              onClick={() => remove(productGroupDataId)}
            >
              <DeleteIcon />
            </Button>
          </Stack>
        </Grid>
        <Grid item md={0} sx={{ mt: 3 }}>
          {errors?.DocumentProductGroupSystems && (
            <ErrorIcon
              color="error"
              style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "center" }}
            />
          )}
        </Grid>
        <Grid item md={11} sx={{ mt: 3 }} style={{ padding: "0" }}>
          {errors?.DocumentProductGroupSystems && (
            <Typography variant="subtitle1" sx={{ color: "#E95450", margin: "3px 12px 0px 12px" }}>
              {errors.DocumentProductGroupSystems?.message}
            </Typography>
          )}
        </Grid>
        <ConfirmationModal
          show={showAdd}
          handleClose={() => handleModalClose()}
          handleConfirmation={() => handleSubmit(handleConfirmation)()}
          title={get("articleAddProductGroup")}
          saveButtonName={get("saveButtonText")}
          cancelButtonName={get("cancelButtonText")}
          modalHeight="600px"
        >
          <ArticleProductGroupForm
            handleSubmit={handleSubmit}
            control={control}
            setValue={setDocumentProductGroupValue}
            articleItemID={articleItemID}
            setArticleItemId={setArticleItemId}
            errors={errorsProductGroupSystem}
            register={register}
            reset={reset}
          />
        </ConfirmationModal>
      </Grid>
    </>
  );
};
