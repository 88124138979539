import { useTranslation } from "../helpers/useTranslation";

export const useDocumentStatus = () => {
  const { get } = useTranslation();

  const documentStatus = (statusId: number | undefined ) => {
    if (statusId === undefined) {
      return "";
    }

    if (statusId === 0) {
      return get("documentStatusSaved");
    } else if(statusId === 1) {
      return get("documentStatusPublish");
    }else if(statusId === 99) {
      return get("searchDocumentStatusDeleted");
    }
  };

  const documentStatusComment = (statusId: number | undefined) => {
    if (statusId === undefined) {
      return "";
    }

    if (statusId === 0) {
      return get("documentCommentSaved");
    } else if (statusId === 1) {
      return get("documentCommentPublish");
    }
  };

  return {
    documentStatus,
    documentStatusComment
  };
};
