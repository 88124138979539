import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { Grid, Typography } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "../../../../../../helpers/useTranslation";
import { CustomErrorTypography } from "../../../../../../components/CustomTypography/CustomErrorTypography";

type Props = {
  existingArticleIdx: number[];
  setExistingArticleIdx: any;
  data?: ExistingProductContentComponent;
  componentFilterItems: ComponentFilterItem[];
  setComponentFilterItems: any;
};

export const ExistingArticleForm = ({
  existingArticleIdx,
  setExistingArticleIdx,
  data,
  componentFilterItems,
  setComponentFilterItems,
}: Props) => {
  const {
    formState: { errors },
    handleSubmit,
    setValue,
  } = useFormContext();
  const { get } = useTranslation();
  const handleChange = (event: any) => {
    if (event.target.checked) {
      setExistingArticleIdx([...(existingArticleIdx || []), event.target.value]);
      setValue("ExistingArticleCheckboxItem", [...(existingArticleIdx || []), event.target.value], {
        shouldValidate: true,
      });
      setComponentFilterItems([
        ...componentFilterItems,
        {
          DocumentId: data?.Components[event.target.value]?.DocumentId,
          Component: data?.Components[event.target.value]?.Component,
          ProductPhaseId: data?.Components[event.target.value]?.ProductPhaseId,
        },
      ]);
    } else {
      setExistingArticleIdx(existingArticleIdx.filter((x) => x !== event.target.value));
      setValue(
        "ExistingArticleCheckboxItem",
        existingArticleIdx.filter((x) => x !== event.target.value),
        {
          shouldValidate: true,
        },
      );
      componentFilterItems.splice(event.target.value, 1);
      setComponentFilterItems([...componentFilterItems]);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit as any}>
        <Grid item md={12}>
          <Paper
            sx={{
              overflow: "hidden",
            }}
          >
            <TableContainer sx={{ height: 325, width: "100%" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="body1">
                        <b>{get("chemicalExistingSelected")} </b>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        <b>{get("chemicalExistingTradeName")}</b>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        <b>{get("chemicalExistingArticle")}</b>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        <b>{get("chemicalExistingPhase")}</b>
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {data?.Components.map((item: any, idx: any) => (
                    <TableRow key={idx}>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="ExistingArticleCheckboxItem"
                              size="small"
                              value={idx}
                              onChange={handleChange}
                              checked={existingArticleIdx.some((x: any) => x?.toString() === idx.toString())}
                            />
                          }
                          label={""}
                        />
                      </TableCell>
                      <TableCell>{item.DocumentName}</TableCell>
                      <TableCell>{item.Component}</TableCell>
                      <TableCell>{item.ProductPhaseName}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <CustomErrorTypography
            errorMessage={errors?.ExistingArticleCheckboxItem && errors.ExistingArticleCheckboxItem?.message}
          />
        </Grid>
      </form>
    </>
  );
};
