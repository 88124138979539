import { useRequest } from "../helpers/useRequest";
import { api } from "../config/apiEndpoints";
import { queryEnums } from "../config/queryEnums";
import { queryClient } from "../config/queryClient";
import { useQuery, useMutation } from "react-query";
import { useMessage } from "../helpers/useMessages";
import { useTranslation } from "../helpers/useTranslation";

const productContentComponentUrl = api.productcontentcomponent;

export const useGetProductContentInfoItem = (CompanyId: number | undefined) => {
  const { post } = useRequest();

  return useQuery<ExistingProductContentComponent>([queryEnums.productContentComponent, CompanyId], () =>
    post(`${productContentComponentUrl}/GetProductContentInfoItem`, {
      CompanyIds: [CompanyId],
    }),
  );
};

export const useGetProductContentComponentItems = () => {
  const { post } = useRequest();

  return useMutation((filter: ComponentFilterItem[]) => {
    const data = {
      ComponentFilterItems: filter,
      LoadProductContentComponentHazardPhrases: true,
      LoadProductContentComponentProductFeatures: true,
    };
    return post(`${productContentComponentUrl}/GetProductContentComponentItems`, data);
  });
};

export const useGetProductContentComponents = (id: number, key: string) => {
  const { post } = useRequest();
  const filter = {
    DocumentId: id,
    LoadProductContentComponentHazardPhrases: true,
    LoadProductContentComponentProductFeatures: true,
  } as ProductContentComponentsFilterItem;

  var resultDataIem = useQuery<ProductContentComponents>(
    [key, filter],
    () => post(`${productContentComponentUrl}/GetDocumentProductContentComponents`, filter),
    {
      suspense: false,
      enabled: false,
      refetchOnWindowFocus: false,
    },
  );

  return resultDataIem;
};

export const useUpdateProductContentComponent = () => {
  const { post } = useRequest();
  const { send } = useMessage();
  const { get } = useTranslation();

  return useMutation(
    (productContentComponent: ProductContentComponent) => {
      return post(`${productContentComponentUrl}/UpdateProductContentComponent`, productContentComponent);
    },
    {
      onSuccess: (productContentComponentResult: ProductContentComponent) => {
        queryClient.invalidateQueries(queryEnums.updateProductContentComponent);
        send(`eBVD ProductContentComponent: ${productContentComponentResult.Component} ${get("toastSaved")}.`);
      },
      onError: (productContentComponentResult: any) => {
        send(`Error on updating:  ${productContentComponentResult}`);
      },
    },
  );
};

export const useCreateProductContentComponent = () => {
  const { post } = useRequest();
  const { send } = useMessage();
  const { get } = useTranslation();

  return useMutation(
    (productContentComponent: ProductContentComponent) => {
      return post(`${productContentComponentUrl}/CreateProductContentComponent`, productContentComponent);
    },
    {
      onSuccess: (productContentComponentResult: ProductContentComponent) => {
        queryClient.invalidateQueries(queryEnums.createProductContentComponent);
        send(`eBVD ProductContentComponent: ${productContentComponentResult.Component} ${get("toastSaved")}.`);
      },
      onError: (productContentComponentResult: any) => {
        send(`Error on updating:  ${productContentComponentResult}`);
      },
    },
  );
};

export const useDeleteProductContentComponent = () => {
  const { post } = useRequest();
  const { send } = useMessage();

  return useMutation(
    (deleteProductContentComponent: DeleteProductContentComponent) => {
      return post(`${productContentComponentUrl}/DeleteProductContentComponents`, deleteProductContentComponent);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryEnums.deleteProductContentComponent);
        send(`eBVD ProductContentComponent: Removed.`);
      },
      onError: () => {
        send(`Error on updating:  Could not remove item`);
      },
    },
  );
};

export const useCopyProductContentComponents = () => {
  const { post } = useRequest();
  const { send } = useMessage();

  return useMutation(
    (copyProductContentComponents: CopyProductContentComponents) => {
      return post(`${productContentComponentUrl}/CopyProductContentComponents`, copyProductContentComponents);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryEnums.copyProductContentComponents);
        send(`eBVD ProductContentComponents: copied.`);
      },
      onError: () => {
        send(`Error on updating:  Could not copy items`);
      },
    },
  );
};

export const useRenameProductContentComponents = () => {
  const { post } = useRequest();
  const { send } = useMessage();

  return useMutation(
    (renameProductContentComponent: RenameProductContentComponent) => {
      return post(`${productContentComponentUrl}/RenameProductContentComponents`, renameProductContentComponent);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryEnums.renameProductContentComponents);
        send(`eBVD ProductContentComponents: renamed.`);
      },
      onError: () => {
        send(`Error on updating:  Could not rename item`);
      },
    },
  );
};
