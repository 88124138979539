import React, { useState } from "react";
import { Box, Typography, Tabs, Tab, Grid, useTheme, useMediaQuery } from "@mui/material";
import { useParams } from "react-router-dom";
import { PageLayout } from "../../../components/PageLayout/PageLayout";
import { useTranslation } from "../../../helpers/useTranslation";

import { EnvironmentalIndicatorsTab } from "./components/EnvironmentalIndicatorsTab";
import { ArticleSystemsTab } from "./components/ArticleSystemsTab";
import { GeneralInformationTab } from "./components/GeneralInformationTab";
import { UnitTab } from "./components/UnitTab";
import { initialRows } from "../data";

export const DetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { get } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(0);
  const rowData = initialRows.find((row) => row.resourceId === Number(id));

  // Using theme breakpoints to determine layout changes
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));

  if (!rowData) {
    return <Typography variant="h6">Data not found.</Typography>;
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <PageLayout title={get("routeDigitalEpdDetail")}>
      <Box sx={{ padding: "20px" }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={3}
            md={isSmallScreen ? 2 : 2.5}
            sx={{
              borderRight: { sm: "1px solid #e0e0e0" },
            }}
          >
            <Tabs
              orientation="vertical"
              variant="fullWidth"
              value={selectedTab}
              onChange={handleTabChange}
              visibleScrollbar
              aria-label="section-tabs"
              sx={{
                pl: 2,
                ".MuiTabs-indicator": {
                  left: 0,
                  top: "10px",
                  width: "5px",
                  marginTop: "9px",
                  maxHeight: "30px",
                  borderRadius: "2.5px",
                },
                ".MuiTabs-flexContainerVertical": {
                  gap: 1,
                  pr: 4,
                },
              }}
            >
              <Tab
                label={get("EpdGeneralInformation")}
                sx={{
                  textTransform: "none",
                  alignItems: "start",
                  typography: isSmallScreen ? "p" : "h6",
                  textAlign: "left",
                  "&.Mui-disabled": {
                    color: "black",
                  },
                }}
              />
              <Tab
                label={get("EpdUnit")}
                sx={{
                  textTransform: "none",
                  alignItems: "start",
                  typography: isSmallScreen ? "p" : "h6",
                  textAlign: "left",
                  "&.Mui-disabled": {
                    color: "black",
                  },
                }}
              />
              <Tab
                label={get("EpdEnvironmentalIndicators")}
                sx={{
                  textTransform: "none",
                  alignItems: "start",
                  typography: isSmallScreen ? "p" : "h6",
                  textAlign: "left",
                  "&.Mui-disabled": {
                    color: "black",
                  },
                }}
              />
              <Tab
                label={get("EpdAtricleSystem")}
                sx={{
                  textTransform: "none",
                  alignItems: "start",
                  typography: isSmallScreen ? "p" : "h6",
                  textAlign: "left",
                  "&.Mui-disabled": {
                    color: "black",
                  },
                }}
              />
            </Tabs>
          </Grid>

          <Grid item xs={12} sm={9} md={9}>
            <Box sx={{ padding: { xs: "0px 10px", sm: "0px 20px" } }}>
              {selectedTab === 0 && <GeneralInformationTab data={rowData} />}
              {selectedTab === 1 && <UnitTab data={rowData} />}
              {selectedTab === 2 && <EnvironmentalIndicatorsTab data={rowData} />}
              {selectedTab === 3 && <ArticleSystemsTab rowData={rowData} />}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </PageLayout>
  );
};
