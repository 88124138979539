import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Box, Checkbox, Divider, FormControlLabel, Typography } from "@mui/material";
import PublishIcon from "@mui/icons-material/Publish";
import { Grid } from "@mui/material";
import { useTranslation } from "../../../helpers/useTranslation";

type Props = {
  title: string;
  show: boolean;
  handleClose: () => void;
  handleConfirmation: () => void;
  children?: React.ReactNode;
  saveButtonName: string;
  cancelButtonName: string;
  modalHeight?: string;
  errorMessage?: string;
  maxWidth?: DialogProps["maxWidth"];
  data?: EBVDDocument;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});
// TODO make this generic like the other Modalss
export const PublishDocumentModal = ({
  data,
  show,
  handleClose,
  handleConfirmation,
  // children,
  saveButtonName,
  cancelButtonName,
  modalHeight,
  maxWidth = "sm",
}: Props) => {
  const { get } = useTranslation();
  const [confirmPublishFirstEUA, setconfirmPublishFirstEUA] = useState(false);
  const [confirmPublishSecondEUA, setconfirmPublishSecondEUA] = useState(false);
  const current = new Date();
  const date = `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`;

  function activatePublishButton() {
    if (confirmPublishFirstEUA && confirmPublishSecondEUA) {
      return true;
    } else {
      return false;
    }
  }

  function toggle(value: any) {
    return !value;
  }

  return (
    <>
      <Dialog
        maxWidth={maxWidth}
        open={show}
        TransitionComponent={Transition}
        keepMounted
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
            },
          },
        }}
      >
        <DialogTitle>{get("documentConfirmPublishing")}</DialogTitle>
        <Divider />
        <DialogContent sx={{ height: modalHeight }}>
          <Typography sx={{ fontWeight: "bold" }}>
            {get("documentAboutToPublish")} {data?.Product.Name} {get("documentVersionLowercase")}
            {data?.VersionNumber}
          </Typography>
          <Typography sx={{ fontWeight: "bold", mt: "1rem" }}>{get("documentPublishNoMoreChanges")}</Typography>
          <Box sx={{ mt: "1rem" }}>
            <FormControlLabel
              control={<Checkbox />}
              onChange={() => setconfirmPublishFirstEUA(toggle)}
              label={get("documentPublishAgreement1")}
            />
            <FormControlLabel
              sx={{ mt: "1rem" }}
              control={<Checkbox />}
              onChange={() => setconfirmPublishSecondEUA(toggle)}
              label={
                get("documentPublishAgreement2") +
                (data?.ProductContent.CandidateListEdition ? data?.ProductContent.CandidateListEdition : date) +
                "."
              }
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid container>
            <Grid item md={12}>
              <Box sx={{ textAlign: "right", px: 2 }}>
                <Button onClick={handleClose} variant="outlined" type="submit" sx={{ m: "1rem 1rem" }}>
                  {cancelButtonName}
                </Button>
                <Button
                  onClick={handleConfirmation}
                  disabled={!activatePublishButton()}
                  variant="contained"
                  type="submit"
                  sx={{ m: "1rem 0rem" }}
                  startIcon={<PublishIcon />}
                >
                  {saveButtonName}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};
