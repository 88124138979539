import { References } from "./References/References";
import { Attachments } from "./Attachments/Attachments";
import { ArticleNameInformation } from "./ArticleNameInformation/ArticleNameInformation";
import { DocumentData } from "./DocumentData/DocumentData";
import { SystemInformation } from "./SystemInformation/SystemInformation";
import { useTranslation } from "../../../../../helpers/useTranslation";
import { ComponentLayout } from "../../../../../components/ComponentLayout/ComponentLayout";
import { useFormContext } from "react-hook-form";

type Props = {
  data?: EBVDDocument;
};
export const ArticleInformation = ({ data }: Props) => {
  const { get } = useTranslation();
  const { getValues, handleSubmit } = useFormContext();

  const components = [
    {
      component: <DocumentData data={data} />,
      title: get("sectionDocumentData"),
    },
    {
      component: <ArticleNameInformation data={data} />,
      title: getValues("Product.Name"),
    },
    {
      component: <References DocumentIsReadOnly={data?.DocumentIsReadOnly || false} />,
      title: get("articleReferenceTitle"),
    },
    {
      component: <Attachments DocumentIsReadOnly={data?.DocumentIsReadOnly || false} />,
      title: get("articleAttachmentTitle"),
    },
    {
      component: <SystemInformation data={data} />,
      title: get("sectionSystemInformation"),
    },
  ];

  return (
    <>
      <form onSubmit={handleSubmit as any}>
        {components.map((comp, idx) => (
          <ComponentLayout key={idx} title={comp.title} index={idx + 1} length={components.length}>
            {comp.component}
          </ComponentLayout>
        ))}
      </form>
    </>
  );
};
