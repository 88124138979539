import React from "react";
import CookieBanner from "../CookieLaw/components/CookieBanner";
import { useTranslation } from "../../helpers/useTranslation";
import "./CookieAccept.scss";

export const CookieAccept = () => {
  const { get } = useTranslation();

  return (
    <CookieBanner
      message={get("cookieMessage")}
      policyLink={get("cookieLink")}
      privacyPolicyLinkText={get("cookiePolicyLinkText")}
      necessaryOptionText={get("cookieNecessaryOptionText")}
      preferencesOptionText={get("cookiePreferenceOptionText")}
      statisticsOptionText={get("cookieStatisticsOptionText")}
      marketingOptionText={get("cookieMarketingOptionText")}
      showMarketingOption={false}
      managePreferencesButtonText={get("cookieManageCookieButton")}
      savePreferencesButtonText={get("cookieSaveCookiePref")}
      acceptButtonText={get("cookieAcceptButton")}
      declineButtonText={get("cookieDeclineButton")}
      wholeDomains
    />
  );
};
