import { Grid, Typography } from "@mui/material";
import { useTranslation } from "../../../../../../helpers/useTranslation";
import { CustomTextField } from "../../../../../../components/CustomTextField/CustomTextField";
import { CustomRadioButton } from "../../../../../../components/CustomRadioButton/CustomRadioButton";
import { CustomCheckBox } from "../../../../../../components/CustomCheckBox/CustomCheckBox";
import { useFormContext } from "react-hook-form";
import ErrorIcon from '@mui/icons-material/Error';
import { ErrorMessage } from '@hookform/error-message';

type Props = {
  data?: ProductRawMaterial;
  DocumentIsReadOnly: boolean;
};

export const WoodenRawMaterial = ({ data, DocumentIsReadOnly }: Props) => {
  const { get } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ mb: 3 }}>
        <Grid item md={12} sx={{ mt: 1, ml: 0, mb: 0, pt: "0px!important" }}>
          <CustomCheckBox
            name="ProductRawMaterial.ContainsWood"
            label={get("containsWood")}
            dataValue={data?.ContainsWood}
            controlValue={control}
            disable={DocumentIsReadOnly}
          />
        </Grid>
        <Grid item md={12}>
          <Typography>{get("woodCertified")}</Typography>
        </Grid>
        <Grid item md={12}>
          <CustomRadioButton
            name={"ProductRawMaterial.WoodCertified"}
            controlValue={control}
            dataValue={data?.WoodCertified}
            isNA={false}
            yesValue={true}
            noValue={false}
            disabled={DocumentIsReadOnly}
            errorFieldMessage={errors?.WoodCertified?.message}
          />
        </Grid>
        <Grid item md={4} sx={{ mt: 2 }}>
          <CustomTextField
            name={"ProductRawMaterial.WoodCertifiedPart"}
            isDisabled={DocumentIsReadOnly}
            controlValue={control}
            defaultValue={data?.WoodCertifiedPart || ""}
            type="number"
            label={get("woodCertifiedPart")}
            errors={errors}
          />
        </Grid>
        <Grid item md={12} sx={{ mt: 3, mb: 2 }}>
          <CustomTextField
            name={"ProductRawMaterial.WoodCertifiedSystem"}
            isDisabled={DocumentIsReadOnly}
            controlValue={control}
            defaultValue={data?.WoodCertifiedSystem || ""}
            isMultiLine={false}
            rowSpan={0}
            label={get("woodCertifiedSystem")}
            errors={errors}
          />
        </Grid>
        <Grid item md={0} sx={{ mt:3}} >
          <ErrorMessage message=""  as={<ErrorIcon color="error" style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }} />
        } errors={errors} name="ProductRawMaterial.WoodCertifiedSystem" />
        </Grid>
        <Grid item md={11} sx={{ mt: 3 }} style={{ padding:'0'}}>
        <ErrorMessage  as={<Typography variant="subtitle1" sx={{ color: "#E95450", margin: "3px 12px 0px 12px" }} />} errors={errors} name="ProductRawMaterial.WoodCertifiedSystem" />
      </Grid>
        <Grid item md={12} sx={{ mt: 3, mb: 2 }}>
          <CustomTextField
            name={"ProductRawMaterial.WoodCertifiedSystemReference"}
            isDisabled={DocumentIsReadOnly}
            controlValue={control}
            defaultValue={data?.WoodCertifiedSystemReference || ""}
            isMultiLine={false}
            rowSpan={0}
            label={get("woodCertifiedSystemReference")}
            errors={errors}
          />
        </Grid>
        <Grid item md={0} sx={{ mt:3}} >
          <ErrorMessage message=""  as={<ErrorIcon color="error" style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }} />
        } errors={errors} name="ProductRawMaterial.WoodCertifiedSystemReference" />
        </Grid>
        <Grid item md={11} sx={{ mt: 3 }} style={{ padding:'0'}}>
        <ErrorMessage  as={<Typography variant="subtitle1" sx={{ color: "#E95450", margin: "3px 12px 0px 12px" }} />} errors={errors} name="ProductRawMaterial.WoodCertifiedSystemReference" />
      </Grid>
        <Grid item md={12}>
          <Typography>{get("woodLoggingCountry")}</Typography>
        </Grid>
        <Grid item md={12} sx={{ mt: 1 }}>
          <CustomTextField
            name={"ProductRawMaterial.LoggingCountry"}
            isDisabled={DocumentIsReadOnly}
            controlValue={control}
            defaultValue={data?.LoggingCountry || ""}
            isMultiLine={false}
            rowSpan={0}
            label={get("loggingCountry")}
            errors={errors}
          />
        </Grid>
        <Grid container item sx={{ ml: 0, mt: 0 }}>
          <CustomCheckBox
            name="ProductRawMaterial.NotWoodCITESAppendix"
            label={get("notWoodCITESAppendix")}
            dataValue={data?.NotWoodCITESAppendix}
            controlValue={control}
            disable={DocumentIsReadOnly}
          />
        </Grid>
        <Grid item md={12} sx={{ mt: 3 }}>
          <CustomTextField
            name={"ProductRawMaterial.NotWoodCITESAppendixVersion"}
            isDisabled={DocumentIsReadOnly}
            controlValue={control}
            defaultValue={data?.NotWoodCITESAppendixVersion || ""}
            isMultiLine={false}
            rowSpan={0}
            label={get("notWoodCITESAppendixVersion")}
            errors={errors}
          />
        </Grid>
        <Grid item md={12} sx={{ ml: 0, mt: 0 }}>
          <CustomCheckBox
            name="ProductRawMaterial.LoggingLegal"
            label={get("loggingLegal")}
            dataValue={data?.LoggingLegal}
            controlValue={control}
            disable={DocumentIsReadOnly}
          />
        </Grid>
      </Grid>
    </>
  );
};
