import { useParams } from "react-router";
import { PageLayout } from "../../../components/PageLayout/PageLayout";
import { useTranslation } from "../../../helpers/useTranslation";
import { CustomTab } from "../../../components/CustomTab/CustomTab";
import { useGetUserGroup } from "../../../services/userGroupService";
import { Loader } from "../../../components/Loader/Loader";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { ImportDocumentViaFile } from "./ImportDocumentViaFile/ImportDocumentViaFile";
import { useGetUser } from "../../../services/userService";

type Params = {
  id: string;
};
export const ImportPage = () => {
  const { get } = useTranslation();
  const { id } = useParams<Params>();
  const { data: userGroup, isLoading } = useGetUserGroup(id);
  const { data: user } = useGetUser();
  const appInsights = useAppInsightsContext();
  const pageView = {
    name: "ImportPage",
  };
  appInsights.trackPageView(pageView);

  const tabs = [
    {
      labelKey: get("importViaFile"),
      component: <ImportDocumentViaFile data={userGroup} />,
    },
  ];

  if (isLoading) return <Loader />;

  // returns empty page if user cant use import
  if (!user || user.InvalidApplicationFeatureIds.includes(1)) return <></>;

  return (
    <PageLayout title={`Import: ${userGroup?.Name}`}>
      <CustomTab tabs={tabs} alertText={get("importViaFileInfoText")} />
    </PageLayout>
  );
};
