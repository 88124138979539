import { useState } from "react";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { Grid, Tooltip, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useParams } from "react-router";
import { useTranslation } from "../../../../helpers/useTranslation";
import { ConfirmationModal } from "../../../../components/ConfirmationModal/ConfirmationModal";
import { useFormValidation } from "../../../../helpers/useFormValidation";
import {
  useInviteUser,
  useUpdateUserGroupUser,
  useDeleteUserGroupUser,
  useGetUserGroupUser,
} from "../../../../services/userGroupService";
import { InviteUser } from "./InviteUser/InviteUser";
import { UserDetail } from "./UserDetail/UserDetail";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { propertySelection } from "../../../../constants/propertySelection";
import { ConfirmationDeleteModal } from "../../../../components/ConfirmationModal/ConfirmationDeleteModal";

type Props = {
  data?: UserGroupUser[];
};
type Params = {
  id: string;
};
export const User = ({ data }: Props) => {
  const { get } = useTranslation();
  const { id } = useParams<Params>();
  const [showAdd, setShowAdd] = useState(false);
  const [showUserDetail, setShowUserDetail] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [, setAnchorEl] = useState<null | HTMLElement>(null);
  const { createUserValidationSchema } = useFormValidation();
  const methods = useForm<InviteUser>({
    resolver: yupResolver(createUserValidationSchema),
  });
  const updateMethods = useForm<UserGroupUser>();

  const { mutateAsync: inviteUser } = useInviteUser();
  const { mutateAsync: updateUser } = useUpdateUserGroupUser();
  const { mutateAsync: deleteUser } = useDeleteUserGroupUser();

  const [selectedUserId, setSelectedUserId] = useState(0);
  const { data: userGroupUser } = useGetUserGroupUser(id, selectedUserId);

  const handleModalClose = () => {
    setShowAdd(false);
  };

  const inviteUserConfirmation = (data: InviteUser) => {
    let userGroupId = id || "0";
    data.UserGroupId = parseInt(userGroupId);
    inviteUser(data);
    methods.reset();
    setShowAdd(false);
  };

  const handleAdd = () => {
    setShowAdd(true);
  };
  const handleUserDetailModalClose = () => {
    setShowUserDetail(false);
  };
  const handleUserDetail = () => {
    setShowUserDetail(true);
    updateMethods.reset(userGroupUser);
  };
  const handleRowClick = (userId: number) => {
    setSelectedUserId(() => userId);
  };
  const handleDelete = () => {
    if (selectedUserId !== 0) {
      deleteUser({
        UserGroupId: id,
        UserId: selectedUserId,
      });
      setShowDelete(false);
      setSelectedUserId(0);
    }
  };
  const handleUpdateConfirmation = (data: UserGroupUser) => {
    updateUser(data);
    setShowUserDetail(false);
  };
  return (
    <Grid container sx={{ mt: 0, mb: 2 }} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item md={11}>
        <ConfirmationDeleteModal
          show={showDelete}
          handleClose={() => setShowDelete(false)}
          handleConfirmation={() => handleDelete()}
          title={get("deleteUserTitle")}
          saveButtonName={get("radioButtonYes")}
          cancelButtonName={get("radioButtonNo")}
        >
          {get("deleteUserMessage") + userGroupUser?.UserName + "?"}
        </ConfirmationDeleteModal>
        <Paper sx={{ overflow: "hidden" }}>
          <TableContainer sx={{ width: "100%" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="body1">
                      <b>{get("userName")}</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      <b>{get("userEMail")}</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      <b>{get("userPhoneNo")}</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      <b>{get("userRoles")}</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      <b>{get("userInformationAccess")}</b>
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((item) => (
                  <TableRow
                    key={item.UserId}
                    onClick={() => handleRowClick(item.UserId)}
                    sx={{
                      backgroundColor: selectedUserId === item.UserId ? "#b1b8b8" : "",
                      cursor: "pointer",
                    }}
                  >
                    <TableCell>{item.UserName}</TableCell>
                    <TableCell>{item.UserEmail}</TableCell>
                    <TableCell>{item.UserPhoneNumber}</TableCell>
                    <TableCell>{item.UserRoleName}</TableCell>
                    <TableCell>{get(propertySelection[item.InformationPermissions?.PropertySelection || 0])}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
      <Grid item md={1}>
        <Stack direction="column" spacing={2} sx={{ float: "right" }}>
          <Tooltip title={get("tooltipInviteUser")} placement="left">
            <Button variant="contained" size="small" sx={{ width: "36px", minWidth: "0px" }} onClick={handleAdd}>
              <AddIcon />
            </Button>
          </Tooltip>
          <Tooltip title={get("tooltipEditUser")} placement="left">
            <Button
              variant="contained"
              size="small"
              sx={{ m: 1, width: "36px", minWidth: "0px" }}
              disabled={selectedUserId === 0}
              onClick={handleUserDetail}
            >
              <EditIcon />
            </Button>
          </Tooltip>
          <Tooltip title={get("tooltipDeleteUser")} placement="left">
            <Button
              color="error"
              size="small"
              sx={{ m: 1, width: "36px", minWidth: "0px" }}
              disabled={selectedUserId === 0}
              onClick={() => {
                setAnchorEl(null);
                setShowDelete(true);
              }}
            >
              <DeleteIcon />
            </Button>
          </Tooltip>
        </Stack>
      </Grid>
      <ConfirmationModal
        show={showAdd}
        maxWidth="md"
        handleClose={() => handleModalClose()}
        handleConfirmation={() => methods.handleSubmit(inviteUserConfirmation)()}
        title={get("userAdd")}
        saveButtonName={get("saveButtonText")}
        cancelButtonName={get("cancelButtonText")}
      >
        <FormProvider {...methods}>
          <InviteUser />
        </FormProvider>
      </ConfirmationModal>
      <ConfirmationModal
        maxWidth="md"
        show={showUserDetail}
        handleClose={() => handleUserDetailModalClose()}
        handleConfirmation={() => updateMethods.handleSubmit(handleUpdateConfirmation)()}
        title={userGroupUser ? userGroupUser?.UserGroupName + " : " + userGroupUser?.UserName : ""}
        saveButtonName={get("saveButtonText")}
        cancelButtonName={get("cancelButtonText")}
      >
        <FormProvider {...updateMethods}>
          <UserDetail data={userGroupUser} />
        </FormProvider>
      </ConfirmationModal>
    </Grid>
  );
};
