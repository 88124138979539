import React, { useState, useEffect } from "react";
import {
  Paper,
  Typography,
  TextField,
  MenuItem,
  Autocomplete,
  IconButton,
  Button,
  Grid,
  Alert,
  Box,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { UnauthenticatedTemplate, useIsAuthenticated } from "@azure/msal-react";
import { useTranslation } from "../../../../helpers/useTranslation";
import { ResourceNode, RowData } from "../../data";
import SaveIcon from "@mui/icons-material/Save";
import { ToolbarButton } from "../../../../components/DocumentToolbar/ToolbarButton/ToolbarButton";

interface UnitTabProps {
  data: RowData;
  onSave?: (updatedData: Partial<RowData>) => void;
}

interface FlattenedResourceNode extends ResourceNode {
  level: number;
}

export const UnitTab: React.FC<UnitTabProps> = ({ data, onSave }) => {
  const { get } = useTranslation();
  const isAuth = useIsAuthenticated();
  const [expandedNodes, setExpandedNodes] = useState<number[]>([]);
  const [selectedNode, setSelectedNode] = useState<FlattenedResourceNode | null>(null);

  const [editableData, setEditableData] = useState<Partial<RowData>>({
    conversionFactor: data.conversionFactor ?? undefined,
    resourceDensity: data.resourceDensity ?? undefined,
    declaredUnit: data.declaredUnit ?? undefined,
  });
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    setEditableData({
      conversionFactor: data.conversionFactor ?? undefined,
      resourceDensity: data.resourceDensity ?? undefined,
      declaredUnit: data.declaredUnit ?? undefined,
    });
    setSelectedNode(null);
  }, [data]);

  const handleFieldChange = (field: keyof RowData) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setEditableData((prev) => ({
      ...prev,
      [field]: field === "declaredUnit" ? value : parseFloat(value) || undefined,
    }));
    setIsDirty(true);
  };

  const handleToggleExpand = (id: number) => {
    setExpandedNodes((prev) => (prev.includes(id) ? prev.filter((nodeId) => nodeId !== id) : [...prev, id]));
  };

  const isNodeExpanded = (id: number) => expandedNodes.includes(id);

  const flattenResourceNodes = (nodes: ResourceNode[], level: number = 0): FlattenedResourceNode[] => {
    return nodes.flatMap((node) => {
      const flattenedNode: FlattenedResourceNode[] = [{ ...node, level }];
      if (isNodeExpanded(node.id) && node.children) {
        flattenedNode.push(...flattenResourceNodes(node.children, level + 1));
      }
      return flattenedNode;
    });
  };

  const flattenedOptions: FlattenedResourceNode[] = data.resourceNodes ? flattenResourceNodes(data.resourceNodes) : [];

  const handleNodeSelect = (event: React.ChangeEvent<{}>, value: FlattenedResourceNode | null) => {
    setSelectedNode(value);
    setIsDirty(true);
  };

  const handleSave = () => {
    if (onSave && isDirty) {
      onSave({
        ...editableData,
        selectedNode: selectedNode?.id || null,
      });
      setIsDirty(false);
    }
  };

  return (
    <>
      <Box display={"flex"} justifyContent={"flex-end"} mb={2}>
        {isAuth && (
          <ToolbarButton
            status={0}
            icon={<SaveIcon />}
            text={get("toolBarSave")}
            onClick={handleSave}
            disabled={!isDirty}
          />
        )}{" "}
      </Box>
      <Paper sx={{ padding: "20px" }}>
        <Typography display={"flex"} variant="h4" gutterBottom>
          {get("EpdUnit")}
          <UnauthenticatedTemplate>
            <Grid sx={{ ml: 3 }}>
              <Alert severity="info">{get("EpdAlertToEdit")}</Alert>
            </Grid>
          </UnauthenticatedTemplate>
        </Typography>

        <TextField
          label="Declared/functional unit in the EPD"
          fullWidth
          placeholder="kg"
          variant="outlined"
          margin="dense"
          disabled={!isAuth}
          value={editableData.declaredUnit ?? ""}
          onChange={handleFieldChange("declaredUnit")}
          sx={{ my: 2 }}
        />

        <TextField
          label="Conversion factor to (kg)"
          fullWidth
          placeholder="1200"
          variant="outlined"
          type="number"
          margin="dense"
          disabled={true}
          value={editableData.conversionFactor ?? ""}
          onChange={handleFieldChange("conversionFactor")}
          sx={{ my: 2 }}
        />

        <TextField
          label="Resource density (kg/m³)"
          fullWidth
          placeholder="/number/"
          variant="outlined"
          type="number"
          margin="dense"
          disabled={!isAuth}
          value={editableData.resourceDensity ?? ""}
          onChange={handleFieldChange("resourceDensity")}
          sx={{ my: 2 }}
        />

        <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>
          Product type / Node information in RH
        </Typography>

        {/* <Typography variant="subtitle1" gutterBottom sx={{ marginTop: 2 }}>
        Hub type
      </Typography>
      <TextField fullWidth select variant="outlined" margin="dense" defaultValue="">
        <MenuItem value="EPD company">EnvHub_SE</MenuItem>
      </TextField> */}

        {/* <Typography variant="subtitle1" gutterBottom sx={{ marginTop: 2 }}>
        Hub Type Suggestion
      </Typography>
      <TextField fullWidth placeholder="Enter Hub Type Suggestion" variant="outlined" margin="dense" disabled={true} /> */}

        <Typography variant="subtitle1" gutterBottom sx={{ marginTop: 2 }}>
          Resource node
        </Typography>
        <Autocomplete
          options={flattenedOptions}
          getOptionLabel={(option) => option.label}
          value={selectedNode}
          onChange={handleNodeSelect}
          renderOption={(props, option) => {
            const hasChildren = option.children && option.children.length > 0;
            const isExpanded = isNodeExpanded(option.id);

            return (
              <li
                {...props}
                style={{ paddingLeft: `${(option.level ?? 0) * 20}px`, display: "flex", alignItems: "center" }}
              >
                {hasChildren && (
                  <IconButton
                    size="small"
                    onClick={(event) => {
                      event.stopPropagation();
                      handleToggleExpand(option.id);
                    }}
                  >
                    {isExpanded ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                )}
                {option.label}
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField {...params} placeholder="Search Resource Node" variant="outlined" margin="dense" />
          )}
        />

        {/* <Typography variant="subtitle1" gutterBottom sx={{ marginTop: 2 }}>
        Resource Node Suggestion
      </Typography>
      <TextField
        fullWidth
        placeholder="Enter Resource Node Suggestion"
        variant="outlined"
        margin="dense"
        disabled={true}
      /> */}

        <Typography variant="body2" color="textSecondary" sx={{ marginTop: 4 }}>
          Note: Resource information is visible in the BM resource registry but data are not visible from the
          workspace/domain unless created as type "ID+m EPD workspace". RH can make these public similarly to Boverket's
          area to reflect the workspace with all public ID+m EPD.
        </Typography>
      </Paper>
    </>
  );
};
