import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { Route, Routes } from "react-router";
import { loginRequest } from "../../auth/authConfig";
import { routes } from "../../config/routes";
import { useTranslation } from "../../helpers/useTranslation";
import { Loader } from "../Loader/Loader";

export const AppRoutes = () => {
  const { get } = useTranslation();

  return (
    <Routes>
      {Object.entries(routes).map(([key, value]) => {
        return (
          <Route
            key={`route_${key}`}
            path={value.path}
            element={
              value.protected ? (
                <MsalAuthenticationTemplate
                  interactionType={InteractionType.Redirect}
                  authenticationRequest={loginRequest}
                  loadingComponent={() => <Loader message={get("loginMessage")} />}
                >
                  <value.component />
                </MsalAuthenticationTemplate>
              ) : (
                <value.component />
              )
            }
          />
        );
      })}
    </Routes>
  );
};
