import { Grid, Typography } from "@mui/material";
import { useTranslation } from "../../../../../../helpers/useTranslation";
import { CustomTextField } from "../../../../../../components/CustomTextField/CustomTextField";
import { CustomRadioButton } from "../../../../../../components/CustomRadioButton/CustomRadioButton";
import { useFormContext } from "react-hook-form";
import ErrorIcon from "@mui/icons-material/Error";
import { ErrorMessage } from "@hookform/error-message";
import { useEffect } from "react";

type Props = {
  data?: ProductRawMaterial;
  DocumentIsReadOnly: boolean;
};

export const OriginRawMaterial = ({ data, DocumentIsReadOnly }: Props) => {
  const { get } = useTranslation();
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<EBVDDocument>();

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ mb: 3 }}>
        <Grid item md={12}>
          <Typography>{get("hasRawMaterialOriginFossil")}</Typography>
        </Grid>
        <Grid item md={12}>
          <CustomRadioButton
            name={"ProductRawMaterial.HasRawMaterialOriginFossil"}
            controlValue={control}
            dataValue={data?.HasRawMaterialOriginFossil || false}
            isNA={false}
            yesValue={true}
            noValue={false}
            disabled={DocumentIsReadOnly}
            errorFieldMessage={errors?.ProductRawMaterial?.HasRawMaterialOriginFossil?.message}
          />
        </Grid>
        <Grid item md={12} sx={{ mt: 2 }}>
          <CustomTextField
            name={"ProductRawMaterial.RawMaterialOriginFossilPart"}
            isDisabled={DocumentIsReadOnly}
            controlValue={control}
            defaultValue={data?.RawMaterialOriginFossilPart || undefined}
            isMultiLine={false}
            rowSpan={0}
            type="number"
            label={get("rawMaterialOriginFossilPart")}
            errors={errors}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const value = e.target.value === "" ? undefined : parseFloat(e.target.value);
              setValue("ProductRawMaterial.RawMaterialOriginFossilPart", value);
            }}
          />
        </Grid>
        <Grid item md={0} sx={{ mt: 3 }}>
          <ErrorMessage
            message=""
            as={
              <ErrorIcon
                color="error"
                style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "center" }}
              />
            }
            errors={errors}
            name="ProductRawMaterial.RawMaterialOriginFossilPart"
          />
        </Grid>
        <Grid item md={11} sx={{ mt: 3 }} style={{ padding: "0" }}>
          <ErrorMessage
            as={<Typography variant="subtitle1" sx={{ color: "#E95450", margin: "3px 12px 0px 12px" }} />}
            errors={errors}
            name="ProductRawMaterial.RawMaterialOriginFossilPart"
          />
        </Grid>
      </Grid>
    </>
  );
};
