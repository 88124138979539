import { Grid, Typography } from "@mui/material";
import { useForm, useFormContext } from "react-hook-form";
import { useState } from "react";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "../../../../../../helpers/useTranslation";
import { ConfirmationModal } from "../../../../../../components/ConfirmationModal/ConfirmationModal";
import { TotalRecycledMaterialForm } from "./TotalRecycledMaterialForm";
import { CustomCheckBox } from "../../../../../../components/CustomCheckBox/CustomCheckBox";
import { useFormValidation } from "../../../../../../helpers/useFormValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import ErrorIcon from "@mui/icons-material/Error";
import { ErrorMessage } from "@hookform/error-message";

type Props = {
  data?: EBVDDocument;
};

export const TotalRecycledMaterial = ({ data }: Props) => {
  const { get } = useTranslation();
  const { rawRecycledTableValidationSchema } = useFormValidation();
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors: recycledError },
  } = useForm<ProductRecycledMaterial>({ resolver: yupResolver(rawRecycledTableValidationSchema) });

  const { getValues, setValue, control: controlValue } = useFormContext();

  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [productRecycleMaterialRecordId, setProductRecycleMaterialRecordId] = useState(0);
  const [productRecycleMaterialData, setProductRecycleMaterialData] = useState<ProductRecycledMaterial[]>(
    getValues("ProductRecycledMaterials") || [],
  );
  const [rowColor, setRowColorRequired] = useState(false);

  const handleConfirmation = (data: ProductRecycledMaterial) => {
    setProductRecycleMaterialData([...productRecycleMaterialData, data]);
    setRowColorRequired(false);
    setShowAdd(false);
    setProductRecycleMaterialRecordId(0);
    setValue("ProductRecycledMaterials", [...productRecycleMaterialData, data], { shouldDirty: true });
    reset();
  };
  const handleUpdateConfirmation = (data: ProductRecycledMaterial) => {
    productRecycleMaterialData[productRecycleMaterialRecordId - 1] = data;
    setProductRecycleMaterialData([...productRecycleMaterialData]);
    setShowEdit(false);
    setValue("ProductRecycledMaterials", [...productRecycleMaterialData], { shouldDirty: true });
    setProductRecycleMaterialRecordId(0);
  };
  const remove = (index: number) => {
    if (index !== 0) productRecycleMaterialData.splice(index - 1, 1);
    setProductRecycleMaterialData([...productRecycleMaterialData]);
    setValue("ProductRecycledMaterials", [...productRecycleMaterialData], { shouldDirty: true });
    setProductRecycleMaterialRecordId(0);
  };

  const handleModalClose = () => {
    setShowAdd(false);
    setRowColorRequired(false);
    setProductRecycleMaterialRecordId(0);
    reset();
  };

  const handleEditModalClose = () => {
    setShowEdit(false);
    setRowColorRequired(false);
    setProductRecycleMaterialRecordId(0);
    reset();
  };

  const handleRowClick = (idx: number) => {
    setProductRecycleMaterialRecordId(idx + 1);
    setRowColorRequired(true);
  };

  const handleAdd = () => {
    setProductRecycleMaterialRecordId(-1);
    setShowAdd(true);
  };

  return (
    <>
      <Grid container sx={{ mb: 3 }} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item md={12}>
          <CustomCheckBox
            name="ProductRawMaterial.HasRecycledMaterial"
            label={get("totalRecycleMaterialArticle")}
            dataValue={data?.ProductRawMaterial.HasRecycledMaterial}
            controlValue={controlValue}
            disable={data?.DocumentIsReadOnly || false}
          />
        </Grid>
        <Grid item md={12}>
          <Typography variant="body1">
            <b>{get("totalRecycleMaterialTableTitle")}</b>
          </Typography>
        </Grid>
        <Grid item md={11}>
          <Paper sx={{ overflow: "hidden" }}>
            <TableContainer sx={{ height: 300, width: "100%" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="body1">
                        <b>{get("totalRecycleMaterialTableMaterialHeading")}</b>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        <b>{get("totalRecycleMaterialTableProportionBefore")}</b>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        <b>{get("totalRecycleMaterialTableProportionOfWaste")}</b>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        <b>{get("totalRecycleMaterialTableProportionAfter")}</b>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        <b>{get("totalRecycleMateialTableRecycle")}</b>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        <b>{get("totalRecycleMaterialWeight")}</b>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        <b>{get("totalRecycleMaterialComment")}</b>
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {productRecycleMaterialData &&
                    productRecycleMaterialData?.map((item, idx) => (
                      <TableRow key={idx} onClick={() => handleRowClick(idx)}>
                        <TableCell
                          scope="row"
                          sx={{
                            backgroundColor: rowColor && idx + 1 === productRecycleMaterialRecordId ? "#b1b8b8" : "",
                            cursor: "pointer",
                          }}
                        >
                          {item.Material}
                        </TableCell>
                        <TableCell
                          scope="row"
                          sx={{
                            backgroundColor: rowColor && idx + 1 === productRecycleMaterialRecordId ? "#b1b8b8" : "",
                            cursor: "pointer",
                          }}
                        >
                          {item.PreconsumerPart}
                        </TableCell>
                        <TableCell
                          scope="row"
                          sx={{
                            backgroundColor: rowColor && idx + 1 === productRecycleMaterialRecordId ? "#b1b8b8" : "",
                            cursor: "pointer",
                          }}
                        >
                          {item.ExternalProductionWastePart}
                        </TableCell>
                        <TableCell
                          scope="row"
                          sx={{
                            backgroundColor: rowColor && idx + 1 === productRecycleMaterialRecordId ? "#b1b8b8" : "",
                            cursor: "pointer",
                          }}
                        >
                          {item.PostconsumerPart}
                        </TableCell>
                        <TableCell
                          scope="row"
                          sx={{
                            backgroundColor: rowColor && idx + 1 === productRecycleMaterialRecordId ? "#b1b8b8" : "",
                            cursor: "pointer",
                          }}
                        >
                          {item.ReusedMaterialPart}
                        </TableCell>
                        <TableCell
                          scope="row"
                          sx={{
                            backgroundColor: rowColor && idx + 1 === productRecycleMaterialRecordId ? "#b1b8b8" : "",
                            cursor: "pointer",
                          }}
                        >
                          {item.WeightOrWeightPercentage}
                          {item.WeightIsPercentage ? " %" : " g"}
                        </TableCell>
                        <TableCell
                          scope="row"
                          sx={{
                            backgroundColor: rowColor && idx + 1 === productRecycleMaterialRecordId ? "#b1b8b8" : "",
                            cursor: "pointer",
                          }}
                        >
                          {item.Comment}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid item md={1}>
          <Stack direction="column" spacing={2} sx={{ float: "right" }}>
            <Button
              variant="contained"
              onClick={handleAdd}
              disabled={data?.DocumentIsReadOnly || false}
              size="small"
              sx={{ width: "36px", minWidth: "0px" }}
            >
              <AddIcon />
            </Button>
            <Button
              variant="contained"
              onClick={() => setShowEdit(true)}
              size="small"
              disabled={productRecycleMaterialRecordId === 0 || data?.DocumentIsReadOnly}
              sx={{ m: 1, width: "36px", minWidth: "0px" }}
            >
              <EditIcon />
            </Button>
            <Button
              color="error"
              size="small"
              sx={{ m: 1, width: "36px", minWidth: "0px" }}
              disabled={productRecycleMaterialRecordId === 0 || data?.DocumentIsReadOnly}
              onClick={() => remove(productRecycleMaterialRecordId)}
            >
              <DeleteIcon />
            </Button>
          </Stack>
        </Grid>
        <Grid item md={0} sx={{ mt: 3 }}>
          <ErrorMessage
            message=""
            as={
              <ErrorIcon
                color="error"
                style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "center" }}
              />
            }
            // errors={errors}
            name="ProductRecycledMaterials"
          />
        </Grid>
        <Grid item md={11} sx={{ mt: 3 }} style={{ padding: "0" }}>
          <ErrorMessage
            as={<Typography variant="subtitle1" sx={{ color: "#E95450", margin: "3px 12px 0px 12px" }} />}
            // errors={errors}
            name="ProductRecycledMaterials"
          />
        </Grid>
      </Grid>
      <ConfirmationModal
        show={showAdd}
        handleClose={() => handleModalClose()}
        handleConfirmation={() => handleSubmit(handleConfirmation)()}
        title={get("sectionTotalRecycledMaterial")}
        saveButtonName={get("saveButtonText")}
        cancelButtonName={get("cancelButtonText")}
      >
        <TotalRecycledMaterialForm handleSubmit={handleSubmit} control={control} errors={recycledError} reset={reset} />
      </ConfirmationModal>
      <ConfirmationModal
        show={showEdit}
        handleClose={() => handleEditModalClose()}
        handleConfirmation={() => handleSubmit(handleUpdateConfirmation)()}
        title={get("totalRecycleEditAttachment")}
        saveButtonName={get("saveButtonText")}
        cancelButtonName={get("cancelButtonText")}
      >
        <TotalRecycledMaterialForm
          handleSubmit={handleSubmit}
          control={control}
          data={productRecycleMaterialData[productRecycleMaterialRecordId - 1]}
          errors={recycledError}
          reset={reset}
        />
      </ConfirmationModal>
    </>
  );
};
