import { useState } from "react";
import { CookieBannerPreferences } from "./CookieBannerPreferences";

type Props = {
  styles: any;
  className: string;
  message: string;
  policyLink: string;
  privacyPolicyLinkText: string;
  acceptButtonText: string;
  managePreferencesButtonText: string;
  savePreferencesButtonText: string;
  onConfirm: any;
  onAcceptAll: any;
  onDecline: any;
  showDeclineButton: boolean;
};
export const CookieBannerContent = ({
  styles,
  message,
  className,
  policyLink,
  privacyPolicyLinkText,
  onConfirm,
  onAcceptAll,
  showDeclineButton,
  onDecline,
  acceptButtonText,
  managePreferencesButtonText,
  savePreferencesButtonText,
}: Props) => {
  const [showPreferences, showPreferencesChecked] = useState(false);

  return (
    <div
      className={`react-cookie-law-dialog cookie-consent ${className}`}
      style={{
        position: "fixed",
        top: "unset",
        left: "0px",
        right: "0px",
        zIndex: "100000",
        color: "#fff",
        bottom: "0px",
        backgroundColor: "#495057",
        padding: "10px",
      }}
    >
      <div
        className="react-cookie-law-container"
        style={{ maxWidth: "1410px", fontSize: "1rem", marginLeft: "auto", marginRight: "auto", overflow: "hidden" }}
      >
        <div
          className="react-cookie-law-msg"
          style={{
            minHeight: "32px",
            display: "flex",
            fontWeight: "400px",
            lineHeight: "130%",
            padding: "10px 0px",
            justifyContent: "space-between",
          }}
        >
          {message}
        </div>

        {showPreferences && (
          <CookieBannerPreferences showPreferencesOption={showPreferences} showStatisticsOption={showPreferences} />
        )}

        {
          <a href="/about" className="react-cookie-law-policy">
            <u>{privacyPolicyLinkText}</u>
          </a>
        }

        <div className="react-cookie-law-button-wrapper" style={{ float: "right" }}>
          {showPreferences ? (
            <button type="button" className="react-cookie-law-manage-btn" onClick={() => onConfirm()}>
              <span>{savePreferencesButtonText}</span>
            </button>
          ) : (
            <button type="button" className="react-cookie-law-accept-btn" onClick={() => showPreferencesChecked(true)}>
              <span>{managePreferencesButtonText}</span>
            </button>
          )}
          <button type="button" className="react-cookie-law-accept-btn" onClick={() => onAcceptAll()}>
            <span>{acceptButtonText}</span>
          </button>
        </div>
      </div>
    </div>
  );
};
