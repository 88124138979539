import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { TextField, Grid } from "@mui/material";
import { Controller } from "react-hook-form";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useTranslation } from "../../../../../../helpers/useTranslation";
import { CustomTextField } from "../../../../../../components/CustomTextField/CustomTextField";
import { CustomCheckBox } from "../../../../../../components/CustomCheckBox/CustomCheckBox";
import { CustomErrorTypography } from "../../../../../../components/CustomTypography/CustomErrorTypography";
import { useGetCountries } from "../../../../../../services/countryService";
import { useEffect } from "react";

type Props = {
  handleSubmit: any;
  control: any;
  data?: ProductRawMaterialComponent;
  errors: any;
  reset: any;
};
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 225,
      width: 250,
    },
  },
};

export const RawMaterialsTableForm = ({ handleSubmit, control, data, errors, reset }: Props) => {
  const { get } = useTranslation();
  const { data: countriesDataValues } = useGetCountries();

  useEffect(() => {
    if (data !== undefined) reset(data);
    else
      reset({
        Component: "",
        Comment: "",
        Material: "",
        PrimaryProductionCity: "",
        PrimaryProductionCityIsConfidential: false,
        PrimaryProductionCountryId: 0,
        PrimaryProductionCountryIdIsConfidential: false,
        ProductRawMaterialComponentId: -1,
        ProductionCity: "",
        ProductionCityIsConfidential: false,
        ProductionCountryId: null,
        ProductionCountryIdIsConfidential: false,
        TransportType: "",
      });
  }, [data, reset]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container rowSpacing={1} columnSpacing={{ md: 2.3 }}>
          <Grid item xs={4}>
            <CustomTextField
              name={"Component"}
              isDisabled={false}
              controlValue={control}
              defaultValue={data?.Component || ""}
              isMultiLine={false}
              rowSpan={0}
              label={get("rawMaterialModalArticleSubTitle")}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomTextField
              name={"Material"}
              isDisabled={false}
              controlValue={control}
              defaultValue={data?.Material || ""}
              isMultiLine={false}
              rowSpan={0}
              label={get("rawMaterialModalMaterialTitle")}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomTextField
              name={"TransportType"}
              isDisabled={false}
              controlValue={control}
              defaultValue={data?.TransportType || ""}
              isMultiLine={false}
              rowSpan={0}
              label={get("rawMaterialModalTransportTypeTitle")}
            />
          </Grid>
          <Grid item md={6} sx={{ mt: 1.5 }}>
            <Controller
              name="PrimaryProductionCountryId"
              control={control}
              defaultValue={data?.PrimaryProductionCountryId || ""}
              render={({ field: { onChange, value } }) => (
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel shrink>{get("rawMaterialModalCountryRawMaterialTitle") + " *"}</InputLabel>
                  <Select
                    value={value || ""}
                    name="PrimaryProductionCountryId"
                    error={errors.PrimaryProductionCountryId}
                    onChange={onChange}
                    MenuProps={MenuProps}
                    input={<OutlinedInput notched label={get("rawMaterialModalCountryRawMaterialTitle")} />}
                  >
                    {countriesDataValues?.Items?.map((item: Item) => (
                      <MenuItem key={item.CountryId} value={item.CountryId}>
                        {item.Name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            <CustomErrorTypography
              errorMessage={errors.PrimaryProductionCountryId && errors.PrimaryProductionCountryId.message}
            />
            <CustomCheckBox
              name="PrimaryProductionCountryIdIsConfidential"
              label={get("rawMaterialModalConfidentialTitle")}
              dataValue={data?.PrimaryProductionCountryIdIsConfidential || false}
              controlValue={control}
            />
          </Grid>
          <Grid item md={6} sx={{ mt: 1.5 }}>
            <CustomTextField
              name={"PrimaryProductionCity"}
              isDisabled={false}
              controlValue={control}
              defaultValue={data?.PrimaryProductionCity || ""}
              isMultiLine={false}
              rowSpan={0}
              label={get("rawMaterialModalCityRawMaterialTitle") + " *"}
              errors={errors}
            />
            <CustomErrorTypography
              errorMessage={errors.PrimaryProductionCity && errors.PrimaryProductionCity.message}
            />
            <CustomCheckBox
              name="PrimaryProductionCityIsConfidential"
              label={get("rawMaterialModalConfidentialTitle")}
              dataValue={data?.PrimaryProductionCityIsConfidential || false}
              controlValue={control}
            />
          </Grid>
          <Grid item md={6} sx={{ mt: 1.5 }}>
            <Controller
              name="ProductionCountryId"
              control={control}
              defaultValue={data?.ProductionCountryId || ""}
              render={({ field: { onChange, value } }) => (
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel shrink>{get("rawMaterialModalCountryOfManufacturerTitle")}</InputLabel>
                  <Select
                    value={value || ""}
                    onChange={onChange}
                    MenuProps={MenuProps}
                    input={<OutlinedInput notched label={get("rawMaterialModalCountryOfManufacturerTitle")} />}
                  >
                    {countriesDataValues?.Items?.map((item: Item) => (
                      <MenuItem key={item.CountryId} value={item.CountryId}>
                        {item.Name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            <CustomCheckBox
              name="ProductionCountryIdIsConfidential"
              label={get("rawMaterialModalConfidentialTitle")}
              dataValue={data?.ProductionCountryIdIsConfidential || false}
              controlValue={control}
            />
          </Grid>
          <Grid item md={6} sx={{ mt: 1.5 }}>
            <CustomTextField
              name={"ProductionCity"}
              isDisabled={false}
              controlValue={control}
              defaultValue={data?.ProductionCity || ""}
              isMultiLine={false}
              rowSpan={0}
              label={get("rawMaterialModalCityOfManufacturerTitle")}
            />
            <CustomCheckBox
              name="ProductionCityIsConfidential"
              label={get("rawMaterialModalConfidentialTitle")}
              dataValue={data?.ProductionCityIsConfidential || false}
              controlValue={control}
            />
          </Grid>
          <Grid item md={12} sx={{ mb: 1 }}>
            <CustomTextField
              name={"Comment"}
              isDisabled={false}
              controlValue={control}
              defaultValue={data?.Comment || ""}
              isMultiLine={true}
              rowSpan={4}
              label={get("rawMaterialModalCommentTitle")}
            />
          </Grid>
          <Controller
            render={({ field }) => <TextField {...field} variant="filled" sx={{ display: "none", width: "100%" }} />}
            name="ProductRawMaterialComponentId"
            control={control}
            defaultValue={data?.ProductRawMaterialComponentId || -1}
          />
        </Grid>
      </form>
    </>
  );
};
