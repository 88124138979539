import { Typography, Grid } from "@mui/material";
import { useTranslation } from "../../../helpers/useTranslation";

export const ContactSection = () => {
  const { get } = useTranslation();

  return (
    <Grid
      container
      sx={{
        p: 3,
        padding: "30px",
        paddingBottom: "54px",
        paddingTop: "54px",
        paddingLeft: "50px",
        margin: "auto",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid item md={5} sx={{ display: "grid", justifyContent: "center", alignItems: "center" }}>
        <Typography sx={{ fontWeight: "bold", fontSize: "16px" }}>{get("contactSectionAddressTitle")}</Typography>
        <Typography variant="body1">{get("contactSectionAddress1")}</Typography>
        <Typography variant="body1">{get("contactSectionAddress2")}</Typography>
      </Grid>
      <Grid item md={5} sx={{ display: "grid", justifyContent: "center", alignItems: "center" }}>
        <Typography sx={{ fontWeight: "bold", fontSize: "16px" }}>{get("contactSectionAddressTitleIvl")}</Typography>
        <Typography variant="body1">{get("contactSectionAddressIvl")}</Typography>
        <Typography variant="body1">{get("contactSectionContactIvl")}</Typography>
      </Grid>
      <Grid item md={12} sx={{ display: "grid", justifyContent: "center", alignItems: "center", marginTop: "40px" }}>
        <Typography
          sx={{
            fontStyle: "italic",
            textAlign: "center",
            width: "100%",
          }}
          variant="body1"
        >
          {get("contactSectionInfoIvl1")}{" "}
          <Typography
            variant="subtitle1"
            color="#44a4b7"
            sx={{ textDecoration: "none", fontStyle: "italic" }}
            component="a"
            href="https://www.ivl.se"
            target="_blank"
            rel="noopener noreferrer"
          >
            {get("contactSectionInfoIvlLink")}
          </Typography>{" "}
          {get("contactSectionInfoIvl2")}
        </Typography>
      </Grid>
    </Grid>
  );
};
