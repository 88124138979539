import { useTranslation } from "../../../../../helpers/useTranslation";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { useParams } from "react-router";
import SearchIcon from "@mui/icons-material/Search";
import PublishIcon from "@mui/icons-material/Publish";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import {
  useGetDocumentVersionsFromDocumentId,
  usePublishEBVDDocument,
  useUnPublishEBVDDocument,
} from "../../../../../services/documentService";
import { CompanyCheckIcon } from "../CompanyInformation/CompanyCheckIcon/CompanyCheckIcon";
import { useDocumentStatus } from "../../../../../helpers/useDocumentStatus";
import { useDateFormat } from "../../../../../helpers/useDateFormat";
import { useNavigate } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { useEffect, useState } from "react";
import { Restricted } from "../../../../../components/Restricted/Restricted";

type Params = {
  id: string;
};
type Props = {
  userGroupId?: number;
  setTabValue: any;
  // setDisablePublish: any;
};

export const DocumentVersion = ({ setTabValue, userGroupId }: Props) => {
  const { get } = useTranslation();
  const { id } = useParams<Params>();
  const { data, refetch } = useGetDocumentVersionsFromDocumentId(id);
  const { documentStatus } = useDocumentStatus();
  const { format } = useDateFormat();
  const { mutateAsync: publishEBVDDocument } = usePublishEBVDDocument();
  const { mutateAsync: unPublishEBVDDocument } = useUnPublishEBVDDocument();
  const navigate = useNavigate();
  const { reset } = useFormContext();
  const [disablePublishButtons, setDisablePublishButtons] = useState(false);

  const publishDocument = (item: EBVDVersionDocumentResult) => {
    if (item.DocumentId !== undefined) {
      setDisablePublishButtons(true);
      publishEBVDDocument({
        DocumentId: item.InternalDocumentId,
        VersionNumber: item.DocumentVersion,
      } as DocumentVersion).then((res) => {
        refetch();
        reset(data);
      });
    }
  };

  const unPublishDocument = (item: EBVDVersionDocumentResult) => {
    if (item.DocumentId !== undefined) setDisablePublishButtons(true);
    unPublishEBVDDocument({
      DocumentId: item.InternalDocumentId,
      VersionNumber: item.DocumentVersion,
    } as DocumentVersion).then((res) => {
      refetch();
      reset(data);
    });
  };

  useEffect(() => {
    if (disablePublishButtons) {
      setDisablePublishButtons(false);
    }
  }, [data, disablePublishButtons]);

  const handleClick = (item: EBVDVersionDocumentResult) => {
    if (item?.DocumentStatusId === 0) publishDocument(item);
    else unPublishDocument(item);
  };

  const displayVersion = (item: EBVDVersionDocumentResult) => {
    navigate(`/documents/${item.InternalDocumentId}`);
    setTabValue(1);
  };

  return (
    <Grid container sx={{ mt: 0, mb: 2 }} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item md={12}>
        <Paper sx={{ overflow: "hidden" }}>
          <TableContainer sx={{ height: 300, width: "100%" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="body1">
                      <b>{get("documentVersion")}</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      <b>{get("documentStatus")}</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      <b>{get("documentValidFrom")}</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      <b>{get("documentValidTo")}</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      <b>{get("documentCreated")}</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      <b>{get("documentLastsaved")}</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      <b>{get("documentStatusChanged")}</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      <b>{get("documentLatestPublished")}</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      <b>{get("documentLatestVersion")}</b>
                    </Typography>
                  </TableCell>
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((item, idx) => (
                  <TableRow key={idx}>
                    <TableCell scope="row">{item.DocumentVersion}</TableCell>
                    <TableCell scope="row">{documentStatus(item.DocumentStatusId)}</TableCell>
                    <TableCell scope="row">{format(item.DocumentValidFromDate, "formatDateString")}</TableCell>
                    <TableCell scope="row">{format(item.DocumentValidToDate)}</TableCell>
                    <TableCell scope="row">{format(item.DocumentCreatedTime)}</TableCell>
                    <TableCell scope="row">{format(item.DocumentUpdatedTime)}</TableCell>
                    <TableCell scope="row">{format(item.DocumentStatusUpdatedTime)}</TableCell>
                    <TableCell scope="row" align="center">
                      <CompanyCheckIcon label="" defaultValue={item.IsLatestPublished} />
                    </TableCell>
                    <TableCell scope="row" align="center">
                      <CompanyCheckIcon label="" defaultValue={item.IsLatestVersion} />
                    </TableCell>
                    <TableCell scope="row" align="center">
                      <Tooltip title={get("toolTipGoToDocument")}>
                        <SearchIcon onClick={() => displayVersion(item)}></SearchIcon>
                      </Tooltip>
                    </TableCell>
                    <TableCell scope="row" align="center">
                      {item?.DocumentStatusId !== 1 ? (
                        <Restricted userGroupId={userGroupId} permission="CanPublish">
                          <Tooltip title={get("toolTipPublish")}>
                            <IconButton
                              disabled={
                                disablePublishButtons ||
                                item.IsTemplate ||
                                !item.DocumentPermissions.CanPublish ||
                                item.DocumentStatusId === 99
                              }
                            >
                              <PublishIcon onClick={() => handleClick(item)}></PublishIcon>
                            </IconButton>
                          </Tooltip>
                        </Restricted>
                      ) : (
                        <Tooltip title={get("toolTipUnPublish")}>
                          <IconButton
                            disabled={
                              disablePublishButtons || item.IsTemplate || !item.DocumentPermissions.CanUnPublish
                            }
                          >
                            <UnpublishedIcon onClick={() => handleClick(item)}></UnpublishedIcon>
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </Grid>
  );
};
