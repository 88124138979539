import { Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Sticky from "react-stickynode";
import { useState } from "react";
import { ToolbarButton } from "../../../components/DocumentToolbar/ToolbarButton/ToolbarButton";
import { useTranslation } from "../../../helpers/useTranslation";

type Props = {
  handleCreateEBVDForm: () => void;
};
export const InternalSearchToolBar = ({ handleCreateEBVDForm }: Props) => {
  const [status, setStatus] = useState<number>(Sticky.STATUS_ORIGINAL);
  const { get } = useTranslation();

  return (
    <Sticky innerZ={5} onStateChange={(status) => setStatus(status.status)}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Box
          sx={{
            padding: "10px 0px 10px 10px",
            gap: 2,
            display: "inline-flex",
          }}
        >
          <ToolbarButton
            icon={<AddIcon />}
            text={get("createEbvdButton")}
            status={status}
            onClick={handleCreateEBVDForm}
          />
        </Box>
      </Box>
    </Sticky>
  );
};
