// ArticleSystems.tsx
import React from "react";
import { Alert, Box, Grid, Paper, Typography } from "@mui/material";
import { useTranslation } from "../../../../helpers/useTranslation";
import { RowData } from "../../data";
import { TableSection } from "../../components/Table/TableSection";
import { UnauthenticatedTemplate } from "@azure/msal-react";

interface ArticleSystemsTabProps {
  rowData: RowData;
}

export const ArticleSystemsTab: React.FC<ArticleSystemsTabProps> = ({ rowData }) => {
  const { get } = useTranslation();
  const articleSystemColumns: Array<keyof RowData> = [
    "articleName",
    "productionSite",
    "EPDFactor",
    "Longitude",
    "Latitude",
    "GMN",
    "GLN",
    "supplierArticleId",
    "GTIN",
  ];

  return (
    <Box
      sx={{
        maxWidth: { padding: "20px", xs: "100%", sm: "100%", md: "900px", lg: "1200px", xl: "1400px" },
        margin: "0 auto",
      }}
    >
      <TableSection
        alertTitle={get("EpdAlertArticle")}
        title={get("EpdAtricleSystem")}
        rows={[rowData]}
        columns={articleSystemColumns}
        modalType="createResource"
      />
    </Box>
  );
};
