import { useEffect, useState } from "react";
import { Grid, Typography, TextField, Button } from "@mui/material";
import { Controller } from "react-hook-form";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import Autocomplete from "@mui/material/Autocomplete";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "../../../../../../helpers/useTranslation";
import { CustomTextField } from "../../../../../../components/CustomTextField/CustomTextField";
import { CustomErrorTypography } from "../../../../../../components/CustomTypography/CustomErrorTypography";
import { CustomCheckBox } from "../../../../../../components/CustomCheckBox/CustomCheckBox";
import { useECNumberFormat } from "../../../../../../helpers/useECNumberFormat";
import { PRODUCT_CONTENT_TYPE } from "../../../../../../constants/productContentTypeConstants";
import { useGetComparisonOperatorDataItems } from "../../../../../../services/comparisonOperatorService";

type Props = {
  eCAndCASdata?: ExistingProductContentComponent;
  data?: ProductContentComponent;
  addMaterial: boolean;
  addSubstance: boolean;
  editComponent: boolean;
  editMaterial: boolean;
  editSubstance: boolean;
  hPhrasesData?: HPhrases;
  setVerifyECValue: any;
  setVerifyCASValue: any;
  verifyECValue: boolean;
  verifyCASValue: boolean;
  hazardPhraseIds: string[];
  setHazardPhraseIds: any;
  productContentType: any;
  setShowErrorMessage: any;
  showSubstanceSpecific: any;
  setShowSubstanceSpecific: any;
};

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 150,
      width: 250,
    },
  },
};
export const AddArticleSubComponentForm = ({
  eCAndCASdata,
  data,
  addMaterial,
  addSubstance,
  editComponent,
  editMaterial,
  editSubstance,
  hPhrasesData,
  setVerifyECValue,
  setVerifyCASValue,
  verifyECValue,
  verifyCASValue,
  hazardPhraseIds,
  setHazardPhraseIds,
  productContentType,
  setShowErrorMessage,
  showSubstanceSpecific,
  setShowSubstanceSpecific,
}: Props) => {
  const {
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
    control,
    reset,
    clearErrors,
  } = useFormContext();
  const { get } = useTranslation();
  // setShowErrorMessage(errors.length !== 0 ? true : false);
  const { data: comparisonOperators } = useGetComparisonOperatorDataItems();

  const { ecNumberFormat, casCheckSum } = useECNumberFormat();
  useEffect(() => {
    const errorMessages = Object.entries(errors).map((k) => k[1]);
    setShowErrorMessage(errorMessages.length !== 0 ? true : false);
  }, [errors, setShowErrorMessage]);
  const handleChange = (event: SelectChangeEvent<typeof hazardPhraseIds>) => {
    const {
      target: { value },
    } = event;
    setHazardPhraseIds(typeof value === "string" ? value.split(",") : value);
    let phraseId = typeof value === "string" ? value.split(",") : value;
    let setPhraseValue = phraseId.map((item) => ({ HazardPhraseId: parseInt(item) }));
    setValue("ProductContentComponentHazardPhraseItems", setPhraseValue);
  };
  const [egValue, setEGValue] = useState("");
  const [casValue, setCASValue] = useState("");

  const handleCASValueChange = (data: string) => {
    if (eCAndCASdata?.CASNumbers.find((x: CASNumber) => x.Name === data && x.HazardPhrases !== null)) {
      const HazardPhrases = eCAndCASdata?.CASNumbers?.find((x: CASNumber) => x.Name === data)?.HazardPhrases.map(
        (item: HazardPhrase) => item.HazardPhraseId,
      );
      if (HazardPhrases) {
        setHazardPhraseIds(HazardPhrases);
        let setPhraseValue = HazardPhrases.map((item) => ({ HazardPhraseId: item }));
        setValue("ProductContentComponentHazardPhraseItems", setPhraseValue);
      }
    } else {
      setHazardPhraseIds([]);
      setValue("ProductContentComponentHazardPhraseItems", null);
    }
  };

  useEffect(() => {
    reset({
      ProductContentComponentId: -1,
      ProductContentComponentHazardPhraseItems: null,
      ProductPhaseId: "",
      Component: "",
      Material: "",
      Substance: "",
      SubstanceIsConfidential: false,
      SubstanceHasPhasingOutProperties: false,
      SubstanceIsOnCandidateList: false,
      SubstanceIsOnAuthorisationList: false,
      SubstanceIsOnTheLimitationList: false,
      SubstanceIsEndocrineDisruptor: false,
      SubstanceIsPBT: false,
      SubstanceIsvPvB: false,
      SubstanceIsPbCompound: false,
      SubstanceIsHgCompound: false,
      SubstanceIsCdCompound: false,
      WeightComparisonOperatorId: "",
      MinWeightPercentage: undefined,
      MaxWeightPercentage: undefined,
      EGNumber: "",
      EGNumberIsConfidential: false,
      CASNumber: "",
      CASNumberIsConfidential: false,
      AlternativeCode: "",
      AlternativeCodeIsConfidential: false,
      IdentityNumberComment: null,
      HazardPhraseComment: "",
      Comment: "",
    });

    setCASValue("");
    setEGValue("");
    setHazardPhraseIds([]);
    if (data !== undefined) {
      if (!data.MaxWeightPercentage) data.MaxWeightPercentage = undefined;
      if (!data.MinWeightPercentage) data.MinWeightPercentage = undefined;

      if (addMaterial) {
        setValue("Component", data.Component);
      } else if (addSubstance) {
        setValue("Component", data.Component);
        setValue("Material", data.Material);
      } else if (editComponent || editMaterial) {
        reset(data);
      } else {
        reset(data);
        const HazardPhrases = data?.ProductContentComponentHazardPhraseItems?.map(
          (item: ProductContentComponentHazardPhraseItem) => item.HazardPhraseId,
        );
        if (HazardPhrases) {
          setHazardPhraseIds(HazardPhrases);
        }
        setCASValue(data.CASNumber);
        setEGValue("");
      }
    }
  }, [
    data,
    reset,
    addMaterial,
    addSubstance,
    setValue,
    setHazardPhraseIds,
    editMaterial,
    editSubstance,
    editComponent,
  ]);

  const comparisonOperatorFunction = (operatorId: number) => {
    setValue("WeightComparisonOperatorId", operatorId);
    if (operatorId === 2 || operatorId === 3) {
      setError("MaxWeightPercentage", {});
      clearErrors("MinWeightPercentage");
    } else if (operatorId === 1 || operatorId === 4 || operatorId === 7) {
      setError("MinWeightPercentage", {});
      clearErrors("MaxWeightPercentage");
    } else {
      clearErrors("MinWeightPercentage");
      clearErrors("MaxWeightPercentage");
    }
  };

  useEffect(() => {
    if (data?.Substance) {
      setShowSubstanceSpecific(true);
    } else {
      setShowSubstanceSpecific(false);
    }
  }, [data?.Substance]);

  const substanceSpecificFields = (e: { target: { value: any } }) => {
    setValue("Substance", e.target.value);
    if (e.target.value) {
      setShowSubstanceSpecific(true);
    } else {
      setShowSubstanceSpecific(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit as any}>
        <Grid container sx={{ mt: 2 }} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6} sx={{ mb: 2 }}>
            <CustomTextField
              name="Component"
              isDisabled={addSubstance || addMaterial || editComponent || editMaterial || editSubstance}
              controlValue={control}
              defaultValue={data?.Component || ""}
              label={get("fieldComponent") + " *"}
              errors={errors}
            />
            <CustomErrorTypography errorMessage={errors?.Component && errors.Component.message} />
          </Grid>

          <Grid item xs={6} sx={{ mb: 2 }}>
            {!editComponent && (
              <CustomTextField
                name="Material"
                isDisabled={(addSubstance && data?.Material !== "") || editComponent || editMaterial || editSubstance}
                controlValue={control}
                defaultValue={data?.Material || ""}
                label={get("fieldMaterial")}
              />
            )}
          </Grid>
          {!addMaterial && !editMaterial && !editComponent && (
            <>
              <Grid item xs={6} sx={{ mb: 2 }}>
                <CustomTextField
                  name="Substance"
                  isDisabled={addMaterial || editSubstance}
                  controlValue={control}
                  defaultValue={data?.Substance || ""}
                  label={get("fieldSubstance")}
                  onChange={substanceSpecificFields}
                />
              </Grid>
              <Grid item xs={6} sx={{ mb: 2 }}>
                <CustomCheckBox
                  name="SubstanceIsConfidential"
                  label={get("rawMaterialModalConfidentialTitle")}
                  dataValue={data?.SubstanceIsConfidential || false}
                  controlValue={control}
                />
              </Grid>
            </>
          )}
          <Grid item xs={6} sx={{ mb: 2 }}>
            <Controller
              name="ProductPhaseId"
              control={control}
              defaultValue={data?.ProductPhaseId || ""}
              render={({ field: { onChange, value } }) => (
                <FormControl variant="outlined" sx={{ width: "100%" }}>
                  <InputLabel shrink>{get("fieldPhase") + " *"}</InputLabel>
                  <Select
                    value={value}
                    onChange={onChange}
                    labelId={get("fieldPhase")}
                    error={!!errors.ProductPhaseId}
                    input={<OutlinedInput notched label={get("fieldPhase")} />}
                  >
                    <MenuItem value="1">{get("phaseDeliveryOrMounted")}</MenuItem>
                    <MenuItem value="2">{get("phaseDelivery")}</MenuItem>
                    <MenuItem value="3">{get("phaseMounted")}</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
            <CustomErrorTypography errorMessage={errors?.ProductPhaseId && errors.ProductPhaseId.message} />
          </Grid>
          <Grid item xs={6} sx={{ mb: 2 }}>
            <Controller
              name="WeightComparisonOperatorId"
              control={control}
              defaultValue={data?.WeightComparisonOperatorId || ""}
              render={({ field: { value } }) => (
                <FormControl variant="outlined" sx={{ width: "100%" }}>
                  <InputLabel shrink>{get("fieldConcentrationRange") + " *"}</InputLabel>
                  <Select
                    value={value}
                    onChange={(e) => comparisonOperatorFunction(e.target.value)}
                    labelId={get("fieldConcentrationRange")}
                    error={!!errors.WeightComparisonOperatorId}
                    input={<OutlinedInput notched label={get("fieldConcentrationRange")} />}
                  >
                    {comparisonOperators?.Items.map((item) => (
                      <MenuItem key={item.ComparisonOperatorId} value={item.ComparisonOperatorId}>
                        {item.Name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            <CustomErrorTypography
              errorMessage={errors?.WeightComparisonOperatorId && errors.WeightComparisonOperatorId.message}
            />
          </Grid>
          <Grid item xs={12} sx={{ mb: 2 }}>
            {productContentType === PRODUCT_CONTENT_TYPE.CHEMICAL_PRODUCT && (
              <>
                <Typography variant="body1">{get("productContentTypeChemicalProduct")}</Typography>
              </>
            )}
            {productContentType === PRODUCT_CONTENT_TYPE.PRODUCT && (
              <>
                <Typography variant="body1">{get("productContentTypeProductText1")}</Typography>
                <Typography variant="body1">{get("productContentTypeProductText2")}</Typography>
                <Typography variant="body1">{get("productContentTypeProductText3")}</Typography>
              </>
            )}
          </Grid>
          <Grid item xs={6} sx={{ mb: 2 }}>
            <CustomTextField
              name="MinWeightPercentage"
              isDisabled={false}
              controlValue={control}
              defaultValue={data?.MinWeightPercentage || ""}
              type="number"
              label={get("fieldMinWeight")}
              errors={errors}
            />
            <CustomErrorTypography errorMessage={errors?.MinWeightPercentage && errors.MinWeightPercentage.message} />
          </Grid>
          <Grid item xs={6} sx={{ mb: 2 }}>
            <CustomTextField
              name="MaxWeightPercentage"
              isDisabled={false}
              controlValue={control}
              defaultValue={data?.MaxWeightPercentage || ""}
              type="number"
              label={get("fieldMaxWeight")}
              errors={errors}
            />
            <CustomErrorTypography errorMessage={errors?.MaxWeightPercentage && errors.MaxWeightPercentage.message} />
          </Grid>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <CustomTextField
              name="Comment"
              isDisabled={false}
              controlValue={control}
              defaultValue={data?.Comment || ""}
              isMultiLine={true}
              rowSpan={2}
              label={get("fieldComment")}
            />
          </Grid>
          {!addMaterial && !editMaterial && !editComponent && (
            <>
              <Grid item xs={12}>
                <Typography color="text.secondary" gutterBottom>
                  {get("sessionTitleSubstanceProperties")}
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ mb: 2 }}>
                <CustomCheckBox
                  name="SubstanceIsOnCandidateList"
                  label={get("fieldCandidateList")}
                  dataValue={data?.SubstanceIsOnCandidateList || false}
                  controlValue={control}
                  disable={!showSubstanceSpecific}
                />
              </Grid>
              <Grid item xs={6} sx={{ mb: 2 }}>
                <CustomCheckBox
                  name="SubstanceHasPhasingOutProperties"
                  label={get("fieldPhasingOutProperties")}
                  dataValue={data?.SubstanceHasPhasingOutProperties || false}
                  controlValue={control}
                  disable={!showSubstanceSpecific}
                />
              </Grid>
              <Grid item xs={6} sx={{ mb: 2 }}>
                <CustomCheckBox
                  name="SubstanceIsOnAuthorisationList"
                  label={get("fieldAuthorisationList")}
                  dataValue={data?.SubstanceIsOnAuthorisationList || false}
                  controlValue={control}
                  disable={!showSubstanceSpecific}
                />
              </Grid>
              <Grid item xs={6} sx={{ mb: 2 }}>
                <CustomCheckBox
                  name="SubstanceIsOnTheLimitationList"
                  label={get("fieldLimitationList")}
                  dataValue={data?.SubstanceIsOnTheLimitationList || false}
                  controlValue={control}
                  disable={!showSubstanceSpecific}
                />
              </Grid>
              <Grid item xs={6} sx={{ mb: 2 }}>
                <CustomCheckBox
                  name="SubstanceIsEndocrineDisruptor"
                  label={get("fieldEndocrineDisruptor")}
                  dataValue={data?.SubstanceIsEndocrineDisruptor || false}
                  controlValue={control}
                  disable={!showSubstanceSpecific}
                />
              </Grid>
              <Grid item xs={6} sx={{ mb: 2 }}>
                <CustomCheckBox
                  name="SubstanceIsPBT"
                  label={get("fieldPBT")}
                  dataValue={data?.SubstanceIsPBT || false}
                  controlValue={control}
                  disable={!showSubstanceSpecific}
                />
              </Grid>
              <Grid item xs={6} sx={{ mb: 2 }}>
                <CustomCheckBox
                  name="SubstanceIsvPvB"
                  label={get("fieldvPvB")}
                  dataValue={data?.SubstanceIsvPvB || false}
                  controlValue={control}
                  disable={!showSubstanceSpecific}
                />
              </Grid>
              <Grid item xs={6} sx={{ mb: 2 }}>
                <CustomCheckBox
                  name="SubstanceIsPbCompound"
                  label={get("fieldLead")}
                  dataValue={data?.SubstanceIsPbCompound || false}
                  controlValue={control}
                  disable={!showSubstanceSpecific}
                />
              </Grid>
              <Grid item xs={6} sx={{ mb: 2 }}>
                <CustomCheckBox
                  name="SubstanceIsHgCompound"
                  label={get("fieldMercury")}
                  dataValue={data?.SubstanceIsHgCompound || false}
                  controlValue={control}
                  disable={!showSubstanceSpecific}
                />
              </Grid>
              <Grid item xs={6} sx={{ mb: 2 }}>
                <CustomCheckBox
                  name="SubstanceIsCdCompound"
                  label={get("fieldCadmium")}
                  dataValue={data?.SubstanceIsCdCompound || false}
                  controlValue={control}
                  disable={!showSubstanceSpecific}
                />
              </Grid>
              <Grid item xs={6} sx={{ mb: 2 }}>
                <Controller
                  name="EGNumber"
                  defaultValue={data?.EGNumber || ""}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value } }) => (
                    <FormControl variant="outlined" sx={{ width: "100%" }}>
                      <Autocomplete
                        disabled={!showSubstanceSpecific}
                        value={value}
                        getOptionLabel={(option) => option || ""}
                        onChange={(_event, value) => {
                          if (value === null) {
                            value = "";
                            setVerifyECValue(false);
                          }
                          setValue("EGNumber", value, { shouldValidate: true });
                          setEGValue(value);
                        }}
                        onInputChange={(_event, newInputValue) => {
                          setValue("EGNumber", newInputValue, { shouldValidate: true });
                          setEGValue(newInputValue);
                        }}
                        freeSolo
                        options={eCAndCASdata?.EGNumbers.map((eg) => eg.Name) || []}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={get("fieldECNum")}
                            name="EGNumber"
                            helperText={verifyECValue ? get("ecCheckSumErrorMessage") : ""}
                            error={!!errors.EGNumber}
                            disabled={!showSubstanceSpecific}
                          />
                        )}
                      />
                    </FormControl>
                  )}
                />
                <CustomErrorTypography errorMessage={errors?.EGNumber && errors.EGNumber.message} />
                <Button
                  sx={{ mt: 3 }}
                  onClick={() => setVerifyECValue(ecNumberFormat(egValue))}
                  disabled={!showSubstanceSpecific}
                >
                  {get("buttonVerify")}
                </Button>
              </Grid>
              <Grid item xs={6} sx={{ mb: 2 }}>
                <CustomCheckBox
                  name="EGNumberIsConfidential"
                  label={get("rawMaterialModalConfidentialTitle")}
                  dataValue={data?.EGNumberIsConfidential || false}
                  controlValue={control}
                  disable={!showSubstanceSpecific}
                />
              </Grid>
              <Grid item xs={6} sx={{ mb: 2 }}>
                <Controller
                  name="CASNumber"
                  defaultValue={data?.CASNumber || ""}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value } }) => (
                    <FormControl variant="outlined" sx={{ width: "100%" }}>
                      <Autocomplete
                        disabled={!showSubstanceSpecific}
                        value={value}
                        getOptionLabel={(option) => option || ""}
                        onChange={(_event, value) => {
                          if (value === null) {
                            value = "";
                            setVerifyCASValue(false);
                          }
                          setValue("CASNumber", value, { shouldValidate: true });
                          setCASValue(value);
                          handleCASValueChange(value);
                        }}
                        onInputChange={(_event, newInputValue) => {
                          setValue("CASNumber", newInputValue, { shouldValidate: true });
                          setCASValue(newInputValue);
                        }}
                        freeSolo
                        options={eCAndCASdata?.CASNumbers.map((eg) => eg.Name) || []}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={get("fieldCASNum")}
                            name="CASNumber"
                            helperText={verifyCASValue ? get("casCheckSumErrorMessage") : ""}
                            error={!!errors.CASNumber}
                            disabled={!showSubstanceSpecific}
                          />
                        )}
                      />
                    </FormControl>
                  )}
                />
                <CustomErrorTypography errorMessage={errors?.CASNumber && errors.CASNumber.message} />
                <Button
                  sx={{ mt: 3 }}
                  onClick={() => setVerifyCASValue(casCheckSum(casValue))}
                  disabled={!showSubstanceSpecific}
                >
                  {get("buttonVerify")}
                </Button>
              </Grid>
              <Grid item xs={6} sx={{ mb: 2 }}>
                <CustomCheckBox
                  name="CASNumberIsConfidential"
                  label={get("rawMaterialModalConfidentialTitle")}
                  dataValue={data?.CASNumberIsConfidential || false}
                  controlValue={control}
                  disable={!showSubstanceSpecific}
                />
              </Grid>
              <Grid item xs={6} sx={{ mb: 2 }}>
                <CustomTextField
                  name="AlternativeCode"
                  controlValue={control}
                  defaultValue={data?.AlternativeCode || ""}
                  label={get("fieldAlternativeCode")}
                  errors={errors}
                  isDisabled={!showSubstanceSpecific}
                />
                <CustomErrorTypography errorMessage={errors?.AlternativeCode && errors.AlternativeCode.message} />
              </Grid>
              <Grid item xs={6} sx={{ mb: 2 }}>
                <CustomCheckBox
                  name="AlternativeCodeIsConfidential"
                  label={get("rawMaterialModalConfidentialTitle")}
                  dataValue={data?.AlternativeCodeIsConfidential || false}
                  controlValue={control}
                  disable={!showSubstanceSpecific}
                />
              </Grid>
              <Grid item xs={12} sx={{ mb: 2 }}>
                <CustomTextField
                  name="IdentityNumberComment"
                  controlValue={control}
                  defaultValue={data?.IdentityNumberComment || null}
                  isMultiLine={true}
                  rowSpan={2}
                  label={get("fieldNOECNumorCASNum")}
                  errors={errors}
                  isDisabled={!showSubstanceSpecific}
                />
                <CustomErrorTypography
                  errorMessage={errors?.IdentityNumberComment && errors.IdentityNumberComment.message}
                />
              </Grid>
              <Grid item xs={12} sx={{ mb: 2 }}>
                <Controller
                  name="ProductContentComponentHazardPhraseItems"
                  control={control}
                  defaultValue={hazardPhraseIds}
                  render={() => (
                    <FormControl variant="outlined" sx={{ width: "100%" }}>
                      <InputLabel shrink>{get("fieldHPhase")}</InputLabel>
                      <Select
                        multiple
                        value={hazardPhraseIds}
                        onChange={handleChange}
                        MenuProps={MenuProps}
                        labelId={get("fieldHPhase")}
                        input={<OutlinedInput notched label={get("fieldHPhase")} />}
                        disabled={!showSubstanceSpecific}
                      >
                        {hPhrasesData?.Items?.map((item: item) => (
                          <MenuItem key={item.HazardPhraseId} value={item.HazardPhraseId}>
                            {item.Name + " - "}
                            {item.Abbreviation + " ( "}
                            {item.Description + " )"}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} sx={{ mb: 2 }}>
                <CustomTextField
                  name="HazardPhraseComment"
                  controlValue={control}
                  defaultValue={data?.HazardPhraseComment || ""}
                  isMultiLine={true}
                  rowSpan={2}
                  label={get("fieldRoute")}
                  isDisabled={!showSubstanceSpecific}
                />
                <Controller
                  render={({ field }) => (
                    <TextField {...field} variant="filled" sx={{ display: "none", width: "100%" }} />
                  )}
                  name="ProductContentComponentId"
                  control={control}
                  defaultValue={-1}
                />
              </Grid>
            </>
          )}
        </Grid>
      </form>
    </>
  );
};
