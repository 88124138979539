import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";

type Props = {
  label?: string;
  handleDelete: any;
};

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export const ChipTemplate = ({ label, handleDelete }: Props) => {
  return (
    <ListItem>
      <Chip
        label={<Typography variant="body2">{label}</Typography>}
        deleteIcon={<CloseIcon style={{ fontSize: "10px", color: "#000000", marginTop: "2px" }} />}
        size="small"
        sx={{ mr: 2, backgroundColor: "#E2EFEF", borderRadius: "2px!important" }}
        onDelete={handleDelete}
      />
    </ListItem>
  );
};
