import { Transition } from "history";
import { useCallback } from "react";
import { useBlocker } from "./useBlocker";

export const usePrompt = (message: string, when = true) => {
  const blocker = useCallback(
    (tx: Transition) => {
      if (window.confirm(message)) tx.retry();
    },
    [message],
  );

  useBlocker(blocker, when);
};
