import { CircularProgress, Box, Container, Typography } from "@mui/material";

type Props = {
    message?: string;
};

export const Loader = ({ message }: Props) => {
    return (
        <Container>
            <Box
                sx={{
                    margin: "auto",
                    display: "flex",
                    height: "50vh",
                    flexDirection: "column",
                }}
            >
                <CircularProgress
                    sx={{
                        margin: "auto",
                    }}
                />
                <Typography variant="inherit" m="auto">
                    {message}
                </Typography>
            </Box>
        </Container>
    );
};
