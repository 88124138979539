import React, { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography, Divider } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "../../../../helpers/useTranslation";
import { useCreateNewArticle } from "../../../../services/epdService";
import { fileToByteArray } from "../../../../helpers/fileToByteArray";

interface ArticleSystemModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const CreateNewArticleModal: React.FC<ArticleSystemModalProps> = ({ isOpen, onClose }) => {
  const { get } = useTranslation();
  const [file, setFile] = useState<File | null>(null);

  const createArticleMutation = useCreateNewArticle();

  // Initialize dropzone with accept for .xlsx and .xls files
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept: [".xlsx", ".xls"],
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        setFile(acceptedFiles[0]);
      }
    },
  });

  const handleUpload = async () => {
    if (!file) return;
    try {
      // Convert file to byte array
      const fileByteArray = await fileToByteArray(file);

      // Create the payload with the byte array
      const newArticleData = {
        fileBytes: fileByteArray,
        fileName: file.name, // Include the file name if needed by the backend
      };

      // Send the byte array data to the backend
      createArticleMutation.mutate(newArticleData, {
        onSuccess: () => {
          console.log("Excel file uploaded successfully as byte array");
          onClose();
        },
        onError: (error) => {
          console.error("Upload failed:", error);
        },
      });
    } catch (error) {
      console.error("Error converting file to byte array:", error);
    }
  };

  const dropzoneStyle: React.CSSProperties = {
    border: "2px dashed #d9d9d9",
    borderRadius: "4px",
    backgroundColor: "#fafafa",
    textAlign: "center",
    padding: "20px",
    cursor: "pointer",
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{get("EpdCreateNewArticle")}</DialogTitle>
      <Divider />
      <DialogContent style={{ minWidth: 400 }}>
        {/* Drag-and-Drop Zone */}
        <div {...getRootProps()} style={dropzoneStyle}>
          <input {...getInputProps()} />
          <p>{file ? file.name : get("importDragAndDrop")}</p>
        </div>

        {/* Display file name if selected */}
        {file && (
          <Typography variant="body2" sx={{ mt: 1 }}>
            {file.name}
          </Typography>
        )}
        {!file && (
          <Typography variant="body2" sx={{ color: "#888", mt: 1 }}>
            {get("EpdUploadExcelHelperText")}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {get("EpdClose")}
        </Button>
        <Button
          onClick={handleUpload}
          color="primary"
          variant="contained"
          disabled={!file || createArticleMutation.isLoading}
          startIcon={<SaveIcon />}
        >
          {createArticleMutation.isLoading ? get("EpdUploading") : get("EpdUpload")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
