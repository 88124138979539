import { DigitalTransferInformation } from "./DigitalTransferInformation";
import { DigitalTransferInformationAccess } from "./DigitalTransferInformationAccess";
import { useTranslation } from "../../../../../helpers/useTranslation";
import { Loader } from "../../../../../components/Loader/Loader";
import { CustomTab } from "../../../../../components/CustomTab/CustomTab";

type Props = {
  data?: UserGroupDigital;
};
export const DigitalTransferDetail = ({ data }: Props) => {
  const { get } = useTranslation();
  const tabs = [
    {
      labelKey: get("organizationSectionInformation"),
      component: <DigitalTransferInformation data={data} />,
    },
    {
      labelKey: get("userInformationAccess"),
      component: <DigitalTransferInformationAccess data={data} />,
    },
  ];
  if (!data) return <Loader />;
  return <CustomTab tabs={tabs} />;
};
