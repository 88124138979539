export const useHPhraseDisplay = () => {
  const hPhraseDataDisplay = (
    hPhrase: ProductContentComponentHazardPhraseItem[] | undefined,
    hPhrasedata: HPhrases | undefined,
  ) => {
    let hphraseValue = hPhrase?.map((x: ProductContentComponentHazardPhraseItem) =>
      hPhrasedata?.Items.find((y: item) => y.HazardPhraseId === x.HazardPhraseId),
    );
    let phraseValue = hphraseValue?.map((x) => x?.Name + " ");

    return phraseValue;
  };

  return {
    hPhraseDataDisplay,
  };
};
