import { IconButton } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";

export const Help = () => {
    
    return (
      <>
        <div>
          <IconButton sx={{ p: 1 }}>
            <HelpIcon />
          </IconButton>
        </div>
      </>
    );
  };
  