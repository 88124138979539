import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { Grid, Tooltip, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "../../../../helpers/useTranslation";
import MailIcon from "@mui/icons-material/Mail";
import { useState } from "react";
import { useDeleteUserInvite } from "../../../../services/userInvitationUserGroupService";
import { ReInviteUser } from "../../../../services/userInvitationService";
import { useDateFormat } from "../../../../helpers/useDateFormat";
import { useParams } from "react-router";

type Props = {
  data?: UserInvitations[];
};
type Params = {
  id: string;
};

export const UserInvitation = ({ data }: Props) => {
  const { get } = useTranslation();
  const { id } = useParams<Params>();
  const { format } = useDateFormat();
  const { mutateAsync: deleteUserInvite } = useDeleteUserInvite();
  const { mutateAsync: reInviteUser } = ReInviteUser();
  const [userInvitationId, setUserInvitationId] = useState(0);
  const handleRowClick = (userInvitationId: number) => {
    setUserInvitationId(userInvitationId);
  };
  const remove = () => {
    if (userInvitationId !== 0) {
      deleteUserInvite({
        UserGroupId: id,
        UserInvitationId: userInvitationId,
      });
      setUserInvitationId(0);
    }
  };
  const reInvite = () => {
    if (userInvitationId !== 0) {
      reInviteUser(userInvitationId);
      setUserInvitationId(0);
    }
  };

  return (
    <Grid container sx={{ mt: 0, mb: 2 }} spacing={3} rowSpacing={1} columnSpacing={7}>
      <Grid item xs={11} sm={11}>
        <Paper sx={{ overflow: "hidden" }}>
          <TableContainer sx={{ width: "100%" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="body1">
                      <b>{get("userName")}</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      <b>{get("userEMail")}</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      <b>{get("userStatus")}</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      <b>{get("userRole")}</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      <b>{get("userCreated")}</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      <b>{get("userSent")}</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      <b>{get("userAccepted")}</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      <b>{get("userNotified")}</b>
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data?.map((item) => (
                  <TableRow
                    key={item.UserInvitationId}
                    onClick={() => handleRowClick(item.UserInvitationId)}
                    sx={{
                      backgroundColor: item.UserInvitationId === userInvitationId ? "#b1b8b8" : "",
                      cursor: "pointer",
                    }}
                  >
                    <TableCell>{item.Name}</TableCell>
                    <TableCell>{item.Email}</TableCell>
                    <TableCell>{item.UserInvitationStatusName}</TableCell>
                    <TableCell>{item.UserInvitationUserRoleName}</TableCell>
                    <TableCell>{format(item.CreatedTime, "formatString")}</TableCell>
                    <TableCell>{format(item.SentTime, "formatString")}</TableCell>
                    <TableCell>{format(item.AcceptedTime, "formatString")}</TableCell>
                    <TableCell>{format(item.NotifiedTime, "formatString")}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
      <Grid item xs={1} sm={1}>
        <Stack direction="column" spacing={2} sx={{ float: "right" }}>
          <Tooltip title={get("tooltipDeleteUserInvitation")} placement="left">
            <Button
              size="small"
              sx={{ width: "36px", minWidth: "0px" }}
              disabled={userInvitationId === 0}
              onClick={() => remove()}
            >
              <DeleteIcon />
            </Button>
          </Tooltip>
          <Tooltip title={get("tooltipReinviteUser")} placement="left">
            <Button
              size="small"
              sx={{ m: 1, width: "36px", minWidth: "0px" }}
              disabled={userInvitationId === 0}
              onClick={() => reInvite()}
            >
              <MailIcon />
            </Button>
          </Tooltip>
        </Stack>
      </Grid>
    </Grid>
  );
};
