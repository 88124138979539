import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { AuthenticatedProfileButton } from "./AuthenticatedProfileButton";
import { UnauthenticatedProfileButton } from "./UnauthenticatedProfileButton";

export const ProfileButton = () => {
  return (
    <>
      <AuthenticatedTemplate>
        <AuthenticatedProfileButton />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <UnauthenticatedProfileButton />
      </UnauthenticatedTemplate>
    </>
  );
};
