import { Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "../../../helpers/useTranslation";
import { CustomAccountRadioButton } from "../../../components/CustomRadioButton/CustomAccountRadioButton";

type Props = {
  data?: CreateAccount;
};
export const OtherInformation = ({ data }: Props) => {
  const { get } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useFormContext<CreateAccount>();

  return (
    <form onSubmit={handleSubmit as any}>
      <Grid container rowSpacing={1} px={1} columnSpacing={{ md: 3 }}>
        <Grid item xs={12} md={12} sx={{ mt: 3 }}>
          <label style={{ fontWeight: "bold" }}>{get("accountTurnover") + "*"}</label>
        </Grid>
        <Grid item xs={12} md={12}>
          <CustomAccountRadioButton
            name={"TurnOver"}
            controlValue={control}
            errorFieldMessage={errors?.TurnOver?.message}
            dataValue={data?.TurnOver}
          />
        </Grid>
      </Grid>
    </form>
  );
};
