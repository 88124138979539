import InternalSearchAccordion from "./InternalSearchAccordion";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useTranslation } from "../../../../helpers/useTranslation";

type Props = {
  setFilter: any;
  filter: InternalSearchFilter;
};

export const InternalIncludeOldVersionFilter = ({ setFilter, filter }: Props) => {
  const { get } = useTranslation();
  const handleChange = (event: any) => {
    if (event.target.checked) {
      setFilter({
        ...filter,
        IncludeOldVersions: true,
      });
    } else {
      setFilter({
        ...filter,
        IncludeOldVersions: false,
      });
    }
  };
  return (
    <>
      <InternalSearchAccordion title={get("searchIncludeOldVersionTitle")}>
        <FormControl>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox onChange={handleChange} checked={filter.IncludeOldVersions} size="small" />}
              label={<Typography variant="body2">{get("searchIncludeOldVersion")}</Typography>}
            />
          </FormGroup>
        </FormControl>
      </InternalSearchAccordion>
    </>
  );
};
