import { AccountInfo } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import ky, { Options } from "ky";
import { useRecoilState } from "recoil";
import { loginRequest } from "../auth/authConfig";
import { localeAtom } from "../recoil/atom";

type ResponseType = "json" | "text" | "form" | "blob" | "arrayBuffer" | "stream";

export const useRequest = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [language] = useRecoilState(localeAtom);

  const request = (method: any) => {
    return (url: string, body: any, responseType: ResponseType = "json") => {
      const requestOptions = getToken().then((token) => {
        return {
          method,
          headers: { Authorization: `Bearer ${token}`, "Accept-Language": language },
          hooks: {
            afterResponse: [
              async (_request, options: any, response: any) => {
                if (response.status === 401) {
                  // TODO: Handle 401 case, options - logout? Retry? Token should always be fresh tho, expecting API to return 403 on forbidden content
                  return ky(_request, options);
                }
                if (response.status === 500) {
                  const body = await response.json();
                  throw new Error(body.Message);
                }
              },
            ],
          },
        } as Options;
      });

      return requestOptions.then((options) => {
        if (body) {
          options.json = body;
        }
        return _fetch(url, options, responseType);
      });
    };
  };

  const getToken = async (): Promise<string> => {
    if (isAuthenticated) {
      const resp = await instance.acquireTokenSilent({
        ...loginRequest,
        account: instance.getActiveAccount() as AccountInfo,
      });

      return resp.accessToken;
    }
    return "";
  };

  const _fetch = async (url: string, requestOptions: Options, responseType: ResponseType) => {
    switch (responseType) {
      case "blob":
        return ky(process.env.REACT_APP_API_URL + url, requestOptions).blob() as Promise<any>;
      case "arrayBuffer":
        return ky(process.env.REACT_APP_API_URL + url, requestOptions).arrayBuffer() as Promise<any>;
      case "text":
        return ky(process.env.REACT_APP_API_URL + url, requestOptions).text() as Promise<any>;
      default:
        return ky(process.env.REACT_APP_API_URL + url, requestOptions).json() as Promise<any>;
    }
  };

  return {
    get: request("GET"),
    post: request("POST"),
    put: request("PUT"),
    _delete: request("DELETE"),
  };
};
