import { Box, Breadcrumbs, Link } from "@mui/material";
import { NavLink } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { routesArray } from "../../config/routes";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export const CustomBreadcrumbs = () => {
  const breadcrumbs = useBreadcrumbs(routesArray as any);
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator={
        <ChevronRightIcon
          sx={{
            color: "#fff",
          }}
        />
      }
    >
      {breadcrumbs.map(({ match, breadcrumb }) => (
        <Box key={match.pathname}>
          <Link
            variant="body2"
            key={match.pathname}
            component={NavLink}
            to={match.pathname}
            sx={{
              display: "block",
              color: "white",
            }}
          >
            {breadcrumb}
          </Link>
        </Box>
      ))}
    </Breadcrumbs>
  );
};
