import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type Props = {
  title: string;
  children: any;
};
const InternalSearchAccordion = ({ title, children }: Props) => {
  return (
    <>
      <Accordion
        defaultExpanded={true}
        sx={{
          backgroundColor: "transparent!important",
          boxShadow: "none",
          paddingLeft: "7px",
          marginTop: "10px",
          paddingRight: "30px",
          border: "0px",
          position: "unset",
          "& .MuiAccordionSummary-content": {
            marginBottom: "5px",
          },
          "&.Mui-expanded": {
            margin: 0,
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ minHeight: "0px!important", marginBottom: "0px!important" }}
        >
          <Typography
            variant="body1"
            sx={{ textTransform: "uppercase", fontWeight: 550, lineHeight: "20px!important" }}
          >
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingBottom: "12px", paddingTop: 0, borderBottom: "1px solid #E2EFEF" }}>
          {children}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default InternalSearchAccordion;
