import { sv } from "date-fns/locale";
import { useRecoilValue } from "recoil";
import { localeAtom } from "../recoil/atom";

export const useGetDateFnsLocale = () => {
  const locale = useRecoilValue(localeAtom);
  if (locale === "sv") {
    return sv;
  }
  // Replace this in the future for other locales, IE if en requires different, for now just return sv
  else {
    return sv;
  }
};
