import { useCallback, useEffect } from "react";

const SAVE_LOCATION = "recentlyViewedDocuments";
const MAX_LENGTH = 15;

type RecentlyViewed = {
  id: string;
  name: string;
};

export const useRecentlyViewed = (id?: string, name?: string) => {
  const add = useCallback((recent: RecentlyViewed) => {
    localStorage.setItem(
      SAVE_LOCATION,
      JSON.stringify([recent, ...get().filter((item) => item.id !== recent.id)].slice(0, MAX_LENGTH)),
    );
  }, []);

  const get = (): RecentlyViewed[] => {
    const data = localStorage.getItem(SAVE_LOCATION);
    if (data) {
      return JSON.parse(data);
    }
    return [];
  };

  const clear = () => {
    localStorage.removeItem(SAVE_LOCATION);
  };

  useEffect(() => {
    if (id && name) {
      add({
        id,
        name,
      });
    }
  }, [id, name, add]);

  const recentlyViewed = get();

  return {
    add,
    get,
    clear,
    recentlyViewed,
  };
};
