import { useMsal } from "@azure/msal-react";
import { Link } from "@mui/material";
import { loginRequest } from "../../../auth/authConfig";
import { useTranslation } from "../../../helpers/useTranslation";

export const UnauthenticatedProfileButton = () => {
  const { instance } = useMsal();
  const { get } = useTranslation();

  const login = () => {
    instance.loginRedirect(loginRequest);
  };
  return (
    <Link
      onClick={login}
      underline="none"
      color="inherit"
      sx={{
        cursor: "pointer",
      }}
    >
      {get("actionLogin")}
    </Link>
  );
};
