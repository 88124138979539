import InternalSearchAccordion from "./InternalSearchAccordion";
import { useTranslation } from "../../../../helpers/useTranslation";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";

type Props = {
  setFilter: any;
  filter: InternalSearchFilter;
};

export const InternalDocumentStatusSearchFilter = ({ setFilter, filter }: Props) => {
  const { get } = useTranslation();

  const handleChange = (event: any) => {
    if (event.target.checked) {
      setFilter({
        ...filter,
        DocumentStatusIds: [...(filter.DocumentStatusIds || []), parseInt(event.target.value)],
      });
    } else {
      setFilter({
        ...filter,
        DocumentStatusIds: filter.DocumentStatusIds?.filter((x) => x !== parseInt(event.target.value)),
      });
    }
  };
  return (
    <>
      <InternalSearchAccordion title={get("searchDocumentStatus")}>
        <FormControl>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  onChange={handleChange}
                  value={0}
                  checked={filter.DocumentStatusIds?.some((x) => x?.toString() === "0")}
                />
              }
              label={<Typography variant="body2">{get("searchDocumentStatusCreated")}</Typography>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  onChange={handleChange}
                  value={1}
                  checked={filter.DocumentStatusIds?.some((x) => x?.toString() === "1")}
                />
              }
              label={<Typography variant="body2">{get("searchDocumentStatusPublished")}</Typography>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  onChange={handleChange}
                  value={99}
                  checked={filter.DocumentStatusIds?.some((x) => x?.toString() === "99")}
                />
              }
              label={<Typography variant="body2">{get("searchDocumentStatusDeleted")}</Typography>}
            />
          </FormGroup>
        </FormControl>
      </InternalSearchAccordion>
    </>
  );
};
