import { useTranslation } from "../../../../../helpers/useTranslation";
import { ComponentLayout } from "../../../../../components/ComponentLayout/ComponentLayout";
import { IndoorEnvironmentComponent } from "./IndoorEnvironmentComponent/IndoorEnvironmentComponent";
import { IndoorEmissionTable } from "./IndoorEnvironmentComponent/IndoorEmissionTable/IndoorEmissionTable";
import { useFormContext } from "react-hook-form";

type Props = {
  data?: EBVDDocument;
};

export const IndoorEnvironment = ({ data }: Props) => {
  const { get } = useTranslation();
  const { handleSubmit } = useFormContext();

  const components = [
    {
      component: (
        <IndoorEnvironmentComponent
          data={data?.ProductIndoorEnvironment}
          DocumentIsReadOnly={data?.DocumentIsReadOnly || false}
        />
      ),
      title: get("sectionIndoorEnvironment"),
    },
    {
      component: <IndoorEmissionTable data={data} />,
      title: get("indoorEnvironmentEmissioner"),
    },
  ];

  return (
    <>
      <form onSubmit={handleSubmit as any}>
        {components.map((comp, idx) => (
          <ComponentLayout key={idx} title={comp.title} index={idx + 1} length={components.length}>
            {comp.component}
          </ComponentLayout>
        ))}
      </form>
    </>
  );
};
