import { Company } from "./Company";
import { CompanyCertification } from "./CompanyCertification";
import { CompanyPolicies } from "./CompanyPolicies";
import { CompanyManagement } from "./CompanyManagement";
import { useTranslation } from "../../../../helpers/useTranslation";
import { ComponentLayout } from "../../../../components/ComponentLayout/ComponentLayout";
import { FormProvider, useForm } from "react-hook-form";
import { AdminToolbar } from "../AdminToolbar/AdminToolbar";
import { useUpdateDataItem } from "../../../../services/userGroupService";
import { useFormValidation } from "../../../../helpers/useFormValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";

type Props = {
  data?: UserGroup;
};

export const Information = ({ data }: Props) => {
  const { organizationInformationValidationSchema } = useFormValidation();
  const { mutateAsync: updateUserInformation } = useUpdateDataItem();
  const { get } = useTranslation();
  const methods = useForm<UserGroup>({
    defaultValues: data,
    resolver: yupResolver(organizationInformationValidationSchema),
  });

  const isDirty = methods.formState.isDirty;

  const components = [
    {
      component: <Company data={data} />,
      title: get("sectionCompanyTitle"),
    },
    {
      component: <CompanyCertification data={data} />,
      title: get("sectionCompanyCertificationTitle"),
    },
    {
      component: <CompanyPolicies data={data} />,
      title: get("sectionCompanyPoliciesTitle"),
    },
    {
      component: <CompanyManagement data={data} />,
      title: get("sectionCompanyManagementTitle"),
    },
  ];

  const updateInformationData = (userGroupInfo: UserGroup) => {
    updateUserInformation(userGroupInfo);
    methods.reset(userGroupInfo);
  };
  return (
    <FormProvider {...methods}>
      <Grid container>
        <Grid item md={11}>
          {components.map((comp, idx) => (
            <ComponentLayout key={idx} title={comp.title} index={idx + 1} length={components.length}>
              {comp.component}
            </ComponentLayout>
          ))}
        </Grid>
        <Grid item md={1}>
          <AdminToolbar
            isDirty={isDirty}
            updateInformation={() => methods.handleSubmit(updateInformationData)()}
          ></AdminToolbar>
        </Grid>
      </Grid>
    </FormProvider>
  );
};
