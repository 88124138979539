import { Grid } from "@mui/material";
import { useTranslation } from "../../../../../../helpers/useTranslation";
import { CustomTextField } from "../../../../../../components/CustomTextField/CustomTextField";
import { CustomCheckBox } from "../../../../../../components/CustomCheckBox/CustomCheckBox";
import { useFormContext } from "react-hook-form";

type Props = {
  data?: ProductRawMaterial;
  DocumentIsReadOnly: boolean;
};

export const RenewableMaterial = ({ data, DocumentIsReadOnly }: Props) => {
  const { get } = useTranslation();
  const { control } = useFormContext();
  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ mb: 3 }}>
        <Grid item md={4} sx={{ mt: 2 }}>
          <CustomTextField
            name={"ProductRawMaterial.RenewableMaterialShortPart"}
            isDisabled={DocumentIsReadOnly}
            controlValue={control}
            defaultValue={data?.RenewableMaterialShortPart || ""}
            type="number"
            label={get("renewableMaterialShortPart")}
          />
        </Grid>
        <Grid item md={12} sx={{ ml: 0, mt: 0 }}>
          <CustomCheckBox
            name="ProductRawMaterial.D6866"
            label={get("d6866")}
            dataValue={data?.D6866 || false}
            controlValue={control}
            disable={DocumentIsReadOnly}
          />
        </Grid>
      </Grid>
    </>
  );
};
