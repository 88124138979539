import { Box } from "@mui/system";

type Props = {
  children?: React.ReactNode;
};

export const InternalSearchPanel = ({ children }: Props) => {
  return (
    <Box role="tabpanel" sx={{ width: "100%" }}>
      <Box bgcolor="white" sx={{ p: 3, minHeight: "70vh", padding: "30px", paddingBottom: "40px" }}>
        {children}
      </Box>
    </Box>
  );
};
