export const usagePhaseEnergyLabelOneOptions = [
  {
    id: 1,
    label: "A+++",
    value: 1,
  },
  {
    id: 2,
    label: "A++",
    value: 2,
  },
  {
    id: 3,
    label: "A+",
    value: 3,
  },
  {
    id: 4,
    label: "A",
    value: 4,
  },
  {
    id: 5,
    label: "B",
    value: 5,
  },
  {
    id: 6,
    label: "C",
    value: 6,
  },
  {
    id: 7,
    label: "D",
    value: 7,
  },
  {
    id: 8,
    label: "E",
    value: 8,
  },
  {
    id: 9,
    label: "F",
    value: 9,
  },
  {
    id: 10,
    label: "G",
    value: 10,
  },
];

export const usagePhaseEnergyLabelTwoOptions = [
  {
    id: 4,
    label: "A",
    value: 4,
  },
  {
    id: 5,
    label: "B",
    value: 5,
  },
  {
    id: 6,
    label: "C",
    value: 6,
  },
  {
    id: 7,
    label: "D",
    value: 7,
  },
  {
    id: 8,
    label: "E",
    value: 8,
  },
  {
    id: 9,
    label: "F",
    value: 9,
  },
  {
    id: 10,
    label: "G",
    value: 10,
  },
];
