import { Configuration, RedirectRequest } from "@azure/msal-browser";

export const msalConfig: Configuration = {
  auth: {
    clientId: `${process.env.REACT_APP_AAD_APP_CLIENT_ID}`,
    authority: "https://ebvdad.b2clogin.com/ebvdad.onmicrosoft.com/B2C_1_signin",
    knownAuthorities: ["ebvdad.b2clogin.com"],
    redirectUri: `${process.env.REACT_APP_AAD_APP_REDIRECT_URI}`,
    postLogoutRedirectUri: `${process.env.REACT_APP_AAD_APP_REDIRECT_URI}`,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};

export const loginRequest: RedirectRequest = {
  scopes: ["openid", "https://ebvdad.onmicrosoft.com/api2/read", "profile"],
};

export const apiRequest = {
  scopes: ["openid", "https://ebvdad.onmicrosoft.com/api2/read", "profile"],
  forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
};

export const silentRequest = {
  scopes: ["openid", "https://ebvdad.onmicrosoft.com/api2/read", "profile"],
  forceRefresh: true,
};

export const b2cPolicies = {
  names: {
    signUpSignIn: "B2C_1_signin",
    forgotPassword: "B2C_1_resetpassword",
  },
  authorities: {
    signUpSignIn: {
      authority: `https://ebvdad.b2clogin.com/tfp/ebvdad.onmicrosoft.com/B2C_1_signin/ `,
    },
    forgotPassword: {
      authority: "https://ebvdad.b2clogin.com/tfp/ebvdad.onmicrosoft.com/B2C_1_resetpassword/",
    },
  },
};
