// /services/epdService.ts
import { useMutation, useQuery } from "react-query";
import { api } from "../config/apiEndpoints";
import { useRequestNew } from "../helpers/useRequestNew";
import { queryEnums } from "../config/queryEnums";

const resourceUrl = api.resource;

export const useGetResources = () => {
  const { post } = useRequestNew();

  return useQuery<GetResourcesParams>(
    [queryEnums.resources],
    () =>
      post(
        `/${resourceUrl}/GetResources`,
        { SortType: 0, SortAscending: true },
        "json",
        process.env.REACT_APP_API_URL_V1,
      ),
    {
      suspense: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );
};

export const useGetResourceById = (resourceId: string | number) => {
  const { get } = useRequestNew();

  return useQuery<GetSingleResourceParams>(
    [queryEnums.resource, resourceId],
    () => get(`${resourceUrl}/GetResource/${resourceId}`, null),
    {
      enabled: !!resourceId,
      suspense: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );
};

export const useCreateNewResource = () => {
  const { post } = useRequestNew();

  return useMutation(
    (createResource: CreateResource) =>
      post(`${resourceUrl}/CreateResource`, createResource, "json", process.env.REACT_APP_API_URL_V1),
    {},
  );
};

export const useCreateNewArticle = () => {
  const { post } = useRequestNew();

  return useMutation(
    (createArticle: CreateArticle) =>
      post(`${resourceUrl}/CreateResource`, createArticle, "json", process.env.REACT_APP_API_URL_V1),
    {},
  );
};
