import { atom } from "recoil";

const debugAtom = atom({
  key: "debug",
  default: false,
});

const messageAtom = atom({
  key: "toast",
  default: "",
});

const localeAtom = atom({
  key: "locale",
  //TODO: get from localstorage/cookies
  default: "sv",
});

const internalSearchFilterAtom = atom({
  key: "internalSearchFilter",
  default: {
    TextFilter: "",
    CompanyIds: [],
    BK04ClassificationCodeIds: [],
    BSABClassificationCodeIds: [],
    DocumentStatusIds: [0, 1],
    DocumentValidFromDate: "",
    DocumentValidToDate: "",
    SortType: 1,
    SortAscending: false,
    IncludeOldVersions: true,
    RowCount: 20,
    UserGroupRowCount: 0,
    RowStartIndex: 0,
  } as InternalSearchFilter,
});

export { messageAtom, localeAtom, debugAtom, internalSearchFilterAtom };
