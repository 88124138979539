import { useParams } from "react-router";
import { PageLayout } from "../../../../components/PageLayout/PageLayout";
import { useTranslation } from "../../../../helpers/useTranslation";
import { CustomTab } from "../../../../components/CustomTab/CustomTab";
import { useGetUserGroup } from "../../../../services/userGroupService";
import { Loader } from "../../../../components/Loader/Loader";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { PublishingTransactions } from "./PublishingTransactions/PublishingTransactions";
import { useGetUser } from "../../../../services/userService";

type Params = {
  id: string;
};
export const TransactionPage = () => {
  const { get } = useTranslation();
  const { id } = useParams<Params>();
  const { data: userGroup, isLoading } = useGetUserGroup(id);
  const { data: user } = useGetUser();
  const appInsights = useAppInsightsContext();
  const pageView = {
    name: "TransactionPage",
  };
  appInsights.trackPageView(pageView);

  const tabs = [
    {
      labelKey: get("transactions"),
      component: <PublishingTransactions data={userGroup} />,
    },
  ];

  if (isLoading) return <Loader />;

  // returns empty page if user cant use import
  if (!user || user.InvalidApplicationFeatureIds.includes(2)) return <></>;

  return (
    <PageLayout title={get("transactionPageTitle") + ": " + userGroup?.Name}>
      <CustomTab tabs={tabs} />
    </PageLayout>
  );
};
