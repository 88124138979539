import { Grid, Typography } from "@mui/material";
import { useTranslation } from "../../../../helpers/useTranslation";

type Props = {
  itemsToShow: number;
  setItemsToShow: any;
  searchResult: number;
};

export const InternalFilterShow = ({ setItemsToShow, itemsToShow, searchResult }: Props) => {
  const { get } = useTranslation();
  const handleShowMore = () => {
    setItemsToShow(itemsToShow + 5);
  };
  const handleShowLess = () => {
    setItemsToShow(itemsToShow - 5);
  };
  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          {searchResult - itemsToShow + 5 >= 5 && (
            <Typography
              variant="body1"
              onClick={handleShowMore}
              sx={{ cursor: "pointer", color: "primary.main", fontWeight: "bold" }}
            >
              {get("filterShowMore")}
            </Typography>
          )}
        </Grid>
        {itemsToShow !== 5 && searchResult >= 5 && (
          <Grid item xs={6}>
            <Typography
              variant="body1"
              onClick={handleShowLess}
              sx={{ cursor: "pointer", color: "primary.main", fontWeight: "bold" }}
            >
              {get("filterShowLess")}
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};
