import { PageLayout } from "../../components/PageLayout/PageLayout";
import { Typography, Grid, Box, Button, Link } from "@mui/material";
import { useTranslation } from "../../helpers/useTranslation";
import { useNavigate } from "react-router";

export const RegistrationSuccess = () => {
  const navigate = useNavigate();
  const { get } = useTranslation();

  return (
    <PageLayout title={get("registrationSuccessTitle")}>
      <Box sx={{ flexGrow: 1, display: "flex" }}>
        <Grid container sx={{ p: 3, padding: "30px", paddingBottom: "50px", paddingTop: "34px", paddingLeft: "50px" }}>
          <Grid item md={12}>
            <Typography variant="h5" color="black" sx={{ mb: 2 }}>
              {get("registrationSuccessTitle2")}
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {get("registrationSuccessText1")} <Link href="mailto:ebvd@ivl.se">ebvd@ivl.se</Link>
            </Typography>

            <Typography variant="body1" sx={{ mb: 2 }}>
              {get("registrationSuccessText2")}
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {get("registrationSuccessText3")}
              <Link href="mailto:eBVD@ivl.se">eBVD@ivl.se</Link>
            </Typography>
          </Grid>
          <Grid item md={12}>
            <Button onClick={() => navigate("/")} variant="contained" type="submit" sx={{ m: "1rem 0rem" }}>
              {get("toHomePage")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </PageLayout>
  );
};
