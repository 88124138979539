import Paper from "@mui/material/Paper";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "../../helpers/useTranslation";
import { useDateFormat } from "../../helpers/useDateFormat";
import { useGetProductGroups } from "../../services/productGroupService";
import { useGetDocuments } from "../../services/documentService";
import { useDocumentStatus } from "../../helpers/useDocumentStatus";
import { ChipTemplate } from "./ChipTemplate";

type Props = {
  filter: InternalSearchFilter;
  setFilter: any;
  orgData?: EBVDListResults;
};
type FilterType = "Status" | "BK04" | "BSAB96" | "Companies" | "ValidDateFrom" | "ValidDateTo";

export const CustomChips = ({ filter, setFilter }: Props) => {
  const { get } = useTranslation();
  const { format } = useDateFormat();
  const { documentStatus } = useDocumentStatus();
  const { data } = useGetDocuments();

  // Default statuses should hide the chips
  const defaultDocumentStatusIds: number[] = [0, 1];
  const hideDocumentStatusFilter: boolean = filter?.DocumentStatusIds.every(
    (value, index) => value === defaultDocumentStatusIds[index],
  );

  const companies = data?.Companies;
  const handleClear = () => {
    setFilter({
      ...filter,
      CompanyIds: [],
      BK04ClassificationCodeIds: [],
      BSABClassificationCodeIds: [],
      DocumentStatusIds: [0, 1],
      DocumentValidFromDate: "",
      DocumentValidToDate: "",
    });
  };
  const { data: productGroupBK04Values } = useGetProductGroups({
    TextFilter: " ",
    ProductGroupSystemId: "BK04",
  });
  const { data: productGroupBSAB96Values } = useGetProductGroups({
    TextFilter: " ",
    ProductGroupSystemId: "BSAB96",
  });
  const handleDelete = (chipToDelete: any, type: FilterType) => {
    switch (type) {
      case "Companies": {
        setFilter({
          ...filter,
          CompanyIds: filter.CompanyIds?.filter((x: number) => x !== chipToDelete),
        });
        break;
      }
      case "Status": {
        setFilter({
          ...filter,
          DocumentStatusIds: filter.DocumentStatusIds?.filter((x: number) => x !== chipToDelete),
        });
        break;
      }
      case "BK04": {
        setFilter({
          ...filter,
          BK04ClassificationCodeIds: filter.BK04ClassificationCodeIds?.filter((x: string) => x !== chipToDelete),
        });
        break;
      }
      case "BSAB96": {
        setFilter({
          ...filter,
          BSABClassificationCodeIds: filter.BSABClassificationCodeIds?.filter((x: string) => x !== chipToDelete),
        });
        break;
      }
      case "ValidDateFrom": {
        setFilter({
          ...filter,
          DocumentValidFromDate: "",
        });
        break;
      }
      case "ValidDateTo": {
        setFilter({
          ...filter,
          DocumentValidToDate: "",
        });
        break;
      }
    }
  };

  return (
    <Paper
      sx={{
        display: "flex",
        justifyContent: "left",
        flexWrap: "wrap",
        listStyle: "none",
        p: "3px 3px 3px 0px",
        m: 0,
        boxShadow: "none",
      }}
      component="ul"
      className="justify-content-start"
    >
      {filter?.CompanyIds?.map(
        (data: number) =>
          companies?.find((x) => x.CompanyId === data) !== undefined && (
            <ChipTemplate
              key={data}
              label={get("supplierFilterChip") + companies?.find((x) => x.CompanyId === data)?.Name}
              handleDelete={() => handleDelete(data, "Companies")}
            ></ChipTemplate>
          ),
      )}
      {!hideDocumentStatusFilter &&
        filter?.DocumentStatusIds?.map((data: number) => (
          <ChipTemplate
            key={data}
            label={get("documentStatusFilterChip") + documentStatus(data)}
            handleDelete={() => handleDelete(data, "Status")}
          ></ChipTemplate>
        ))}

      {filter?.BK04ClassificationCodeIds?.map((data: string) => (
        <ChipTemplate
          key={data}
          label={
            get("searchDocumentBK04") +
            productGroupBK04Values?.ProductGroups.find((x: ProductGroup) => x.Code === data)?.Name
          }
          handleDelete={() => handleDelete(data, "BK04")}
        ></ChipTemplate>
      ))}
      {filter?.BSABClassificationCodeIds?.map((data: string) => (
        <ChipTemplate
          key={data}
          label={
            get("searchDocumentBSAB96") +
            productGroupBSAB96Values?.ProductGroups.find((x: ProductGroup) => x.Code === data)?.Name.toString()
          }
          handleDelete={() => handleDelete(data, "BSAB96")}
        ></ChipTemplate>
      ))}
      {filter?.DocumentValidFromDate && (
        <ChipTemplate
          label={get("documentValidFrom") + ": " + format(filter?.DocumentValidFromDate)}
          handleDelete={() => handleDelete(filter?.DocumentValidFromDate, "ValidDateFrom")}
        ></ChipTemplate>
      )}
      {filter?.DocumentValidToDate && (
        <ChipTemplate
          label={get("documentValidTo") + ": " + format(filter?.DocumentValidToDate)}
          handleDelete={() => handleDelete(filter?.DocumentValidToDate, "ValidDateTo")}
        ></ChipTemplate>
      )}

      {(filter?.CompanyIds?.length > 0 ||
        (filter?.DocumentStatusIds?.length > 0 && !hideDocumentStatusFilter) ||
        filter?.BK04ClassificationCodeIds?.length > 0 ||
        filter?.BSABClassificationCodeIds?.length > 0 ||
        filter?.DocumentValidFromDate !== "" ||
        filter?.DocumentValidToDate !== "") && (
        <Button onClick={handleClear} size="small" sx={{ boxShadow: "none", marginTop: "2px" }}>
          <CloseIcon style={{ fontSize: "16px", marginRight: "5px", color: "#000000" }} />
          <Typography
            sx={{ fontSize: "16px", lineHeight: "22px", color: "#008387", fontFamily: "Calibri", fontWeight: "bold" }}
          >
            {get("clearFilterText")}
          </Typography>
        </Button>
      )}
    </Paper>
  );
};
