import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { Controller } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import { usagePhaseEnergyLabelOneOptions, usagePhaseEnergyLabelTwoOptions } from "../../constants/radioOptionConstants";
import { useTranslation } from "../../helpers/useTranslation";

type Props = {
  name: string;
  controlValue: any;
  dataValue: any;
  isReferenceServiceRequired: boolean;
  isEnergyHasLabel: boolean;
  isEnergyHasLabelTwo: boolean;
  clearSelectedValue: any;
  errors: any;
  DocumentIsReadOnly: boolean;
  setValue?: any;
};

export const CustomUsagePhaseRadioButton = ({
  name,
  controlValue,
  dataValue,
  isReferenceServiceRequired,
  isEnergyHasLabel,
  isEnergyHasLabelTwo,
  clearSelectedValue,
  errors,
  DocumentIsReadOnly,
  setValue,
}: Props) => {
  const { get } = useTranslation();
  const handleChange = (value: string) => {
    setValue("ProductUsage.LongevityMax", value !== "50" && value !== "0" ? value : "", { shouldDirty: true });
    setValue("ProductUsage.LongevityMin", value !== "0" ? value : "", { shouldDirty: true });
    setValue("ProductUsage.Longevitysets", value);
  };
  return (
    <>
      {isReferenceServiceRequired && (
        <Controller
          render={({ field: { onChange, value } }) => (
            <RadioGroup row={true} value={value} onChange={(e) => handleChange(e.target.value)}>
              <FormControlLabel
                value={5}
                control={<Radio />}
                label={get("radioButtonUsageFiveYears")}
                disabled={DocumentIsReadOnly}
              />
              <FormControlLabel
                value={10}
                control={<Radio />}
                label={get("radioButtonUsageTenYears")}
                disabled={DocumentIsReadOnly}
              />
              <FormControlLabel
                value={15}
                control={<Radio />}
                label={get("radioButtonUsageFifteenYears")}
                disabled={DocumentIsReadOnly}
              />
              <FormControlLabel
                value={25}
                control={<Radio />}
                label={get("radioButtonUsageTwentyFiveYears")}
                disabled={DocumentIsReadOnly}
              />
              <FormControlLabel
                value={50}
                control={<Radio />}
                label={get("radioButtonUsageGreaterthanFiftyYears")}
                disabled={DocumentIsReadOnly}
              />
              <FormControlLabel
                value={0}
                control={<Radio />}
                label={get("radioButtonUsageNotAvailable")}
                disabled={DocumentIsReadOnly}
              />
            </RadioGroup>
          )}
          name={name}
          control={controlValue}
          defaultValue={dataValue}
        />
      )}
      {isEnergyHasLabel ? (
        <Controller
          control={controlValue}
          name="ProductUsage.EnergyLabelId"
          defaultValue={dataValue}
          render={({ field }) => {
            return (
              <>
                <RadioGroup {...field} row>
                  {usagePhaseEnergyLabelOneOptions.map((item: any, idx) => (
                    <FormControlLabel
                      key={idx}
                      value={item.value}
                      control={<Radio />}
                      label={item.label}
                      disabled={DocumentIsReadOnly}
                    />
                  ))}

                  <Button
                    variant="contained"
                    size="small"
                    disabled={DocumentIsReadOnly}
                    sx={{ m: 1, width: "36px", minWidth: "0px" }}
                    onClick={clearSelectedValue}
                  >
                    <CloseIcon />
                  </Button>
                </RadioGroup>
                {errors.ProductUsage?.EnergyLabelId && (
                  <Typography variant="subtitle1" sx={{ color: "#E95450", margin: "3px 12px 0px 12px" }}>
                    {errors.ProductUsage?.EnergyLabelId?.message}
                  </Typography>
                )}
              </>
            );
          }}
        />
      ) : (
        ""
      )}

      {isEnergyHasLabelTwo ? (
        <Controller
          rules={{ required: true }}
          control={controlValue}
          name="ProductUsage.EnergyLabel2Id"
          defaultValue={dataValue}
          render={({ field }) => {
            return (
              <>
                <RadioGroup {...field} row>
                  {usagePhaseEnergyLabelTwoOptions.map((item: any, idx) => (
                    <FormControlLabel
                      key={idx}
                      value={item.value}
                      control={<Radio />}
                      label={item.label}
                      disabled={DocumentIsReadOnly}
                    />
                  ))}
                  <Button
                    variant="contained"
                    size="small"
                    disabled={DocumentIsReadOnly}
                    sx={{ m: 1, width: "36px", minWidth: "0px" }}
                    onClick={clearSelectedValue}
                  >
                    <CloseIcon />
                  </Button>
                </RadioGroup>
                {errors.ProductUsage?.EnergyLabel2Id && (
                  <Typography variant="subtitle1" sx={{ color: "#E95450", margin: "3px 12px 0px 12px" }}>
                    {errors.ProductUsage?.EnergyLabel2Id?.message}
                  </Typography>
                )}
              </>
            );
          }}
        />
      ) : (
        ""
      )}
    </>
  );
};
