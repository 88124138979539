import { PageLayout } from "../../components/PageLayout/PageLayout";
import { useTranslation } from "../../helpers/useTranslation";
import { Box } from "@mui/material";
import { EpdLayout } from "./EpdLayout";

export const EpdPage = () => {
  const { get } = useTranslation();
  return (
    <PageLayout title={get("routeDigitalEpd")}>
      <Box sx={{ flexGrow: 1, display: "flex" }}>
        <EpdLayout />
      </Box>
    </PageLayout>
  );
};
