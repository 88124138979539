import { PageLayout } from "../../components/PageLayout/PageLayout";
import { Typography, Grid, Box, Button } from "@mui/material";
import { useTranslation } from "../../helpers/useTranslation";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../auth/authConfig";
import { UnauthenticatedTemplate } from "@azure/msal-react";

export const RegistrationConfirmation = () => {
  const { get } = useTranslation();
  const { instance } = useMsal();
  const login = () => {
    instance.loginRedirect(loginRequest);
  };
  return (
    <PageLayout title={get("indexPageTitle")}>
      <Box sx={{ flexGrow: 1, display: "flex" }}>
        <Grid container sx={{ p: 3, padding: "30px", paddingBottom: "50px", paddingTop: "34px", paddingLeft: "50px" }}>
          <Grid item md={12}>
            <Typography variant="h5" color="black" sx={{ mb: 2 }}>
              {get("registrationConfirmationTitle")}
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {get("registrationConfirmationSubTitle")}
            </Typography>
            <Typography variant="body1">{get("registrationConfirmationMessage")}</Typography>
          </Grid>
          <Grid item md={12}>
            <UnauthenticatedTemplate>
              <Button onClick={login} variant="contained" type="submit" sx={{ m: "1rem 0rem" }}>
                {get("actionLogin")}
              </Button>
            </UnauthenticatedTemplate>
          </Grid>
        </Grid>
      </Box>
    </PageLayout>
  );
};
