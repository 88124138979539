import { useRequest } from "../helpers/useRequest";
import { api } from "../config/apiEndpoints";
import { queryEnums } from "../config/queryEnums";
import { useQuery } from "react-query";

const hPhraseUrl = api.hazardphrase;

export const useGetHPhrases = (filter: any) => {
  const { post } = useRequest();

  return useQuery<HPhrases>(
    [queryEnums.hPhrases, filter],
    () =>
      post(`${hPhraseUrl}/GetHazardPhrases`, {
        TextFilter: filter.TextFilter,
        SortType: 0,
        SortAscending: true,
      }),
    {
      enabled: Boolean(filter.TextFilter),
    },
  );
};
