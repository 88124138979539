import { useIsAuthenticated } from "@azure/msal-react";
import { Typography, Link } from "@mui/material";
import { useTranslation } from "../../../helpers/useTranslation";
import { Link as RouterLink } from "react-router-dom";
import { Route } from "../../../config/routes";

const pages: Route[] = [];

export const HeaderLinks = () => {
  const { get } = useTranslation();
  const isAuth = useIsAuthenticated();

  const linkItem = (page: Route) => {
    const item = (
      <Typography color="black" variant="inherit">
        {get(page.nameKey)}
      </Typography>
    );

    if (isAuth) {
      return item;
    } else {
      if (!page.protected) return item;
    }
  };

  return (
    <>
      {pages.map((page) => (
        <Link
          key={page.path}
          px="1rem"
          component={RouterLink}
          to={page.path}
          sx={{
            display: "block",
            color: "white",
          }}
        >
          {linkItem(page)}
        </Link>
      ))}
    </>
  );
};
