import { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InternalSearchAccordion from "./InternalSearchAccordion";
import { InternalSearchTextField } from "./InternalSearchTextField";
import { useTranslation } from "../../../../helpers/useTranslation";
import { useGetDocuments } from "../../../../services/documentService";
import { InternalFilterShow } from "./InternalFilterShow";

type Props = {
  setFilter: any;
  filter: InternalSearchFilter;
};

export const InternalOrganisationSearchFilter = ({ setFilter, filter }: Props) => {
  const [searchResults, setSearchResults] = useState<Company[]>([]);
  const [itemsToShow, setItemsToShow] = useState<number>(5);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { data } = useGetDocuments();
  const { get } = useTranslation();

  useEffect(() => {
    const results = data?.Companies.filter((x) => x.Name.toLowerCase().includes(searchTerm.toLowerCase()));
    setSearchResults(results || []);
  }, [searchTerm, data?.Companies]);

  const handleSearch = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const handleChange = (event: any) => {
    if (event.target.checked) {
      setFilter({
        ...filter,
        CompanyIds: [...(filter.CompanyIds || []), parseInt(event.target.value)],
      });
    } else {
      setFilter({
        ...filter,
        CompanyIds: filter.CompanyIds?.filter((x) => x !== parseInt(event.target.value)),
      });
    }
  };

  return (
    <>
      <InternalSearchAccordion title={get("supplierFilterTitle")}>
        <InternalSearchTextField
          handleSearch={handleSearch}
          searchTerm={searchTerm}
          label={get("tempSupplierSearchLabel")}
        />
        <FormGroup sx={{ marginTop: 2 }}>
          {searchResults.length > 0 ? (
            searchResults.slice(0, itemsToShow).map((items: Company) => (
              <div className="" key={items.CompanyId}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      value={items.CompanyId}
                      onChange={handleChange}
                      checked={filter.CompanyIds?.some((x) => x?.toString() === items.CompanyId.toString())}
                    />
                  }
                  label={<Typography variant="body2">{items.Name}</Typography>}
                />
              </div>
            ))
          ) : (
            <div>
              {get("noResultText")}"{searchTerm}"
            </div>
          )}
        </FormGroup>

        <InternalFilterShow
          itemsToShow={itemsToShow}
          setItemsToShow={setItemsToShow}
          searchResult={searchResults.length}
        />
      </InternalSearchAccordion>
    </>
  );
};
