import { useGetUser } from "../../services/userService";

type Permission =
  | "CanCreateNew"
  | "CanCreateNewVersion"
  | "CanDelete"
  | "CanEdit"
  | "CanEditUserGroup"
  | "CanPublish"
  | "CanRead"
  | "CanUnPublish"
  | "IsUserGroupAdmin";

type Props = {
  permission: Permission;
  children: React.ReactNode;
  userGroupId?: number;
};

export const Restricted = ({ permission, children, userGroupId }: Props) => {
  const { data } = useGetUser();

  if (userGroupId) {
    if (data?.UserGroups.find((ug) => ug.UserGroupId === userGroupId)?.[permission]) {
      return <>{children}</>;
    }
  } else if (data?.UserGroups.length === 1) {
    if (data?.UserGroups[0][permission]) {
      return <>{children}</>;
    }
  }

  return <></>;
};
