import { useForm, useFormContext, FormProvider, useWatch } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "../../../../../../helpers/useTranslation";
import { ConfirmationModal } from "../../../../../../components/ConfirmationModal/ConfirmationModal";
import { ExistingArticleForm } from "./ExistingArticleForm";
import { AddArticleSubComponentForm } from "./AddArticleSubComponentForm";
import { useFormValidation } from "../../../../../../helpers/useFormValidation";
import {
  useCopyProductContentComponents,
  useRenameProductContentComponents,
  useCreateProductContentComponent,
  useDeleteProductContentComponent,
  useGetProductContentComponentItems,
  useGetProductContentComponents,
  useUpdateProductContentComponent,
} from "../../../../../../services/productContentComponentService";
import { useECNumberFormat } from "../../../../../../helpers/useECNumberFormat";
import { ArticleSubComponentButton } from "./ArticleSubComponentButton";
import { ArticleSubComponentTableHeader } from "./ArticleSubComponentTableHeader";
import { ArticleSubComponentTableBody } from "./ArticleSubComponentTableBody";
import ErrorIcon from "@mui/icons-material/Error";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import _ from "lodash";
type Props = {
  data?: EBVDDocument;
  hPhrasesData?: HPhrases;
  existingArticleData?: ExistingProductContentComponent;
  DocumentIsReadOnly: boolean;
};
export const ArticleSubComponentTable = ({ hPhrasesData, existingArticleData, DocumentIsReadOnly }: Props) => {
  const { get } = useTranslation();

  const {
    formState: { errors },
    getValues,
    setValue,
    control,
  } = useFormContext<EBVDDocument>();

  const [articleData] = useState<ProductContentComponent[]>(getValues("ProductContentComponents") || []);

  const [documentId] = useState<number>(getValues("DocumentId"));

  let { data: ProductContentComponentsResultData, refetch } = useGetProductContentComponents(
    documentId,
    "ProductContentComponentsResultDataKey",
  );

  useEffect(() => {
    refetch();
  }, [documentId, refetch]);

  const { articleSubComponentValidationSchema, existingArticleSubComponentValidationSchema } = useFormValidation();

  const articleMethods = useForm<ProductContentComponent>({
    resolver: yupResolver(articleSubComponentValidationSchema),
  });

  const existingArticleMethods = useForm<any>({
    resolver: yupResolver(existingArticleSubComponentValidationSchema),
  });

  const { mutateAsync: updateProductContentComponent } = useUpdateProductContentComponent();
  const { mutateAsync: createProductContentComponent } = useCreateProductContentComponent();
  const { mutateAsync: deleteProductContentComponent } = useDeleteProductContentComponent();
  const { mutateAsync: copyProductContentComponents } = useCopyProductContentComponents();
  const { mutateAsync: renameProductContentComponents } = useRenameProductContentComponents();
  const [showExistingArticle, setShowExistingArticle] = useState(false);
  const [disableEditButton, setDisableEditButton] = useState(true);
  const [disableDeleteButton, setDisableDeleteButton] = useState(true);
  const [disableSubstanceButton, setDisableSubstanceButton] = useState(true);
  const [disableMaterialButton, setDisableMaterialButton] = useState(true);
  const [editcomponentData, setEditcomponentData] = useState<ProductContentComponent>();
  const [showEdit, setShowEdit] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [addMaterial, setAddMaterial] = useState(false);
  const [addSubstance, setAddSubstance] = useState(false);
  const [editComponent, setEditComponent] = useState(false);
  const [editMaterial, setEditMaterial] = useState(false);
  const [editSubstance, setEditSubstance] = useState(false);
  const [verifyECValue, setVerifyECValue] = useState<boolean>(false);
  const [verifyCASValue, setVerifyCASValue] = useState<boolean>(false);
  const [hazardPhraseIds, setHazardPhraseIds] = useState<string[]>([]);
  const { ecNumberFormat, casCheckSum } = useECNumberFormat();
  const [existingArticleIdx, setExistingArticleIdx] = useState<number[]>([]);
  const [componentFilterItems, setComponentFilterItems] = useState<SourceProductContentComponentItem[]>([]);
  useGetProductContentComponentItems();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showSubstanceSpecific, setShowSubstanceSpecific] = useState(false);
  const [selected, setSelected] = useState("-1");
  const [selectedProductContentComponentForTree, setSelectedProductContentComponentForTree] =
    useState<ProductContentComponentForTree | null>();

  const productContentType = useWatch({
    control,
    name: "ProductContent.ProductContentTypeId",
  });
  const [hideTable, setHideTable] = useState(false);

  const handleConfirmation = () => {
    // first remove duplicates
    const uniqueSourceProductContentComponentItems = componentFilterItems.reduce(
      (accumulator: any, currentObject: any) => {
        if (
          !accumulator.find(
            (existingObject: SourceProductContentComponentItem) =>
              existingObject.DocumentId === currentObject.DocumentId &&
              existingObject.Component === currentObject.Component &&
              existingObject.ProductPhaseId === currentObject.ProductPhaseId,
          )
        ) {
          accumulator.push(currentObject);
        }
        return accumulator;
      },
      [],
    );
    const copyProductContentComponents = {
      TargetDocumentId: documentId,
      SourceProductContentComponentItems: uniqueSourceProductContentComponentItems,
    } as CopyProductContentComponents;
    copyContentComponent(copyProductContentComponents);

    setExistingArticleIdx([]);
    existingArticleMethods.reset({
      ExistingArticleCheckboxItem: [],
    });
    setComponentFilterItems([]);
    setShowExistingArticle(false);
    resetButton();
  };

  const copyContentComponent = (cProductContentComponents: CopyProductContentComponents) => {
    copyProductContentComponents(cProductContentComponents).then(() => {
      refetch();
    });
  };

  const handleUpdateConfirmation = (data: ProductContentComponent) => {
    if (!editMaterial && !editComponent) {
      setVerifyCASValue(data.CASNumber !== "" && data.CASNumber !== null ? casCheckSum(data.CASNumber) : false);
      setVerifyECValue(data.EGNumber !== "" && data.EGNumber !== null ? ecNumberFormat(data.EGNumber) : false);
    }
    if (
      editMaterial ||
      editComponent ||
      (!(data.CASNumber !== "" && data.CASNumber !== null && casCheckSum(data.CASNumber)) &&
        !(data.EGNumber !== "" && data.EGNumber !== null && ecNumberFormat(data.EGNumber)))
    ) {
      // nedan kommentar kvar så vi kan dubbelkolla om det är ett problem med ny kod
      // // check if component name is changed. If so rename component name on all with same original name in articleData. BUG: 2048
      // const originComponentName = articleData[articleData.findIndex((x) => x === editcomponentData)].Component;
      // if (originComponentName !== data.Component) {
      //   articleData
      //     .filter((x) => x.Component === originComponentName)
      //     .forEach((dataItem) => {
      //       dataItem.Component = data.Component;
      //     });
      // }

      setShowEdit(false);
      disableButton();
      resetButton();
      articleMethods.reset({
        CASNumber: "",
      });
      if (editMaterial || editComponent) {
        data.ProductContentComponentHazardPhraseItems = undefined;
      }
      updateContentComponent(data);
    }
  };

  const updateContentComponent = (productContentComponent: ProductContentComponent) => {
    updateProductContentComponent(productContentComponent).then(() => {
      refetch();
    });
  };

  const renameContentComponents = (renameProductContentComponent: RenameProductContentComponent) => {
    renameProductContentComponents(renameProductContentComponent).then(() => {
      refetch();
    });
  };

  const handleModalClose = () => {
    existingArticleMethods.reset({
      ExistingArticleCheckboxItem: [],
    });
    setShowExistingArticle(false);
    resetButton();
    disableButton();
  };

  const deleteItem = () => {
    let deletingComponent = {
      ProductContentComponentId: selectedProductContentComponentForTree?.ProductContentComponentId,
      ProductContentComponentType: selectedProductContentComponentForTree?.ProductContentComponentType,
    } as DeleteProductContentComponent;
    deleteContentComponent(deletingComponent);
  };

  const deleteContentComponent = (deletingComponent: DeleteProductContentComponent) => {
    deleteProductContentComponent(deletingComponent).then(() => {
      refetch();
    });
  };

  const handleEditModalClose = () => {
    setShowEdit(false);
    resetButton();
    disableButton();

    let originalHazardPhraseIds: string[] = [];
    if (editcomponentData?.ProductContentComponentHazardPhraseItems) {
      editcomponentData?.ProductContentComponentHazardPhraseItems.forEach((HazardPhraseItem) => {
        originalHazardPhraseIds.push(HazardPhraseItem.HazardPhraseId.toString());
      });
    }
    setHazardPhraseIds(originalHazardPhraseIds);

    articleMethods.reset();
  };

  const handleAddConfirmation = useCallback(
    (data: ProductContentComponent) => {
      if (!editMaterial && !editComponent) {
        setVerifyCASValue(data.CASNumber !== "" ? casCheckSum(data.CASNumber) : false);
        setVerifyECValue(data.EGNumber !== "" ? ecNumberFormat(data.EGNumber) : false);
      }

      if (
        editMaterial ||
        editComponent ||
        (!(data.CASNumber !== "" && casCheckSum(data.CASNumber)) &&
          !(data.EGNumber !== "" && ecNumberFormat(data.EGNumber)))
      ) {
        setShowAdd(false);
        setHazardPhraseIds([]);
        disableButton();
        articleMethods.reset();
        resetButton();
        data.DocumentId = documentId;
        createContentComponent(data);
      }
    },
    [articleData, articleMethods, casCheckSum, ecNumberFormat, setValue],
  );

  const GetComponentType = (newContentComponentTree: ProductContentComponentForTree) => {
    let componentType = "0";
    if (newContentComponentTree.ChildItems && newContentComponentTree.ChildItems.length > 0) {
        componentType = "1";
        let childItem = newContentComponentTree.ChildItems.at(0);
      if (childItem?.ChildItems && childItem.ChildItems.length > 0) {
        componentType = "2";
      }
    }
    return componentType;
  }
  const ScrollToSelected = (component: string) => {
    const element = document.querySelector(
      `.ProductIndividualRow${component}`
    );
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }
  }

  const createContentComponent = (productContentComponent: ProductContentComponent) => {
    createProductContentComponent(productContentComponent).then((newContentComponent) => {
      refetch().then((response)=>{
        let newContentComponentTree = response.data?.ProductContentComponentTree?.find(a => a.ProductContentComponentId === newContentComponent.ProductContentComponentId);
        //set the new component as selected and adjust the edit options
        if (newContentComponent && newContentComponentTree) {
          let componentType = GetComponentType(newContentComponentTree);
          setSelected(newContentComponentTree?.ProductContentComponentId + "-" + componentType);
          ScrollToSelected(newContentComponentTree?.ProductContentComponentId + "-" + componentType)
          setSelectedProductContentComponentForTree(newContentComponentTree);
        }
        if (!newContentComponentTree && selectedProductContentComponentForTree) {
          let selectedComponent = response.data?.ProductContentComponentTree?.find(a => a.ProductContentComponentId === selectedProductContentComponentForTree.ProductContentComponentId);
          if (selectedComponent === undefined) {
            response.data?.ProductContentComponentTree?.forEach(x => {
            let childItem = x.ChildItems?.find(a => a.ProductContentComponentId === selectedProductContentComponentForTree.ProductContentComponentId)
              selectedComponent = childItem;
              return;
          });
          }
          newContentComponentTree = selectedComponent?.ChildItems?.find(m => m.ProductContentComponentId === newContentComponent.ProductContentComponentId);
          if (!newContentComponentTree) {
            let childItems: ProductContentMaterial[] = [];
            selectedComponent?.ChildItems?.forEach(element => {
              if (element.ChildItems && element.ChildItems?.length > 0) {
                childItems.push(...element.ChildItems);    
              }
            });
            newContentComponentTree = childItems.find(p => p.ProductContentComponentId === newContentComponent.ProductContentComponentId);
          }
        }
        setSelected(newContentComponentTree?.ProductContentComponentId + "-" + newContentComponentTree?.ProductContentComponentType);
        ScrollToSelected(newContentComponentTree?.ProductContentComponentId + "-" + newContentComponentTree?.ProductContentComponentType);
        setDisableEditButton(false);
        setDisableDeleteButton(false);
        if (newContentComponentTree?.CanAddMaterial) {
          setDisableMaterialButton(false);
        }
        if (newContentComponentTree?.CanAddSubstance) {
          setDisableSubstanceButton(false);
        }
      }
      );
    });
  };

  const handleEdit = () => {
    if (selectedProductContentComponentForTree) {
      if (selectedProductContentComponentForTree.ExistsInDatabase) {
        if (selectedProductContentComponentForTree.ProductContentComponentType === 0) {
          setEditComponent(true);
        } else if (selectedProductContentComponentForTree.ProductContentComponentType === 1) {
          setEditMaterial(true);
        } else if (selectedProductContentComponentForTree.ProductContentComponentType === 2) {
          setEditSubstance(true);
        }
        setShowEdit(true);
      } else {
        if (selectedProductContentComponentForTree.ProductContentComponentType === 0) {
          setEditcomponentData({ Component: selectedProductContentComponentForTree.Name } as ProductContentComponent);
          setEditComponent(true);
        } else if (selectedProductContentComponentForTree.ProductContentComponentType === 1) {
          let componentName = ProductContentComponentsResultData?.ProductContentComponentTree?.find(
            (x) =>
              x.ProductContentComponentId === selectedProductContentComponentForTree.ProductContentComponentId &&
              x.ProductContentComponentType === 0,
          )?.Name;
          // if treenode is does not have the same id as the parent
          if (!componentName) {
            componentName = ProductContentComponentsResultData?.ProductContentComponentTree?.find(
              (x) =>
                x.ChildItems?.some(
                  (ci) =>
                    ci.ProductContentComponentId === selectedProductContentComponentForTree.ProductContentComponentId,
                ) && x.ProductContentComponentType === 0,
            )?.Name;
          }
          setEditcomponentData({
            Component: componentName,
            Material: selectedProductContentComponentForTree.Name,
          } as ProductContentComponent);
          setEditMaterial(true);
        }
        setShowAdd(true);
      }
    }
  };

  const handleItemClick = (
    id: string | undefined,
    productContentComponentForTree: ProductContentComponentForTree | undefined,
    productContentComponent: ProductContentComponent | undefined,
  ) => {
    if (id !== undefined && productContentComponent !== undefined && productContentComponentForTree !== undefined) {
      if (id === selected) {
        setSelected("-1");
        setSelectedProductContentComponentForTree(null);
        disableButton();
        setEditcomponentData(undefined);
      } else {
        setSelected(id);
        setEditcomponentData(productContentComponent);
        setSelectedProductContentComponentForTree(productContentComponentForTree);
        setDisableMaterialButton(!productContentComponentForTree.CanAddMaterial);
        setDisableSubstanceButton(!productContentComponentForTree.CanAddSubstance);
        setDisableEditButton(false);
        setDisableDeleteButton(false);
      }
    }
  };

  const disableButton = () => {
    setDisableEditButton(true);
    setDisableDeleteButton(true);
    setDisableMaterialButton(true);
    setDisableSubstanceButton(true);
  };

  const resetButton = () => {
    setAddMaterial(false);
    setAddSubstance(false);
    setEditComponent(false);
    setEditMaterial(false);
    setEditSubstance(false);
    setSelectedProductContentComponentForTree(null);
    setSelected("-1");
  };
  const handleAdd = () => {
    articleMethods.reset({
      IdentityNumberComment: "",
    });
    setHazardPhraseIds([]);
    setShowAdd(true);
  };
  const handleAddModalClose = () => {
    setShowAdd(false);
    resetButton();
    disableButton();
    setHazardPhraseIds([]);
    setShowSubstanceSpecific(false);
    articleMethods.reset({
      IdentityNumberComment: "",
    });
  };

  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <>
      <Grid item md={12}>
        <Typography>{get("articleSubComponentTableTitle") + " *"}</Typography>
      </Grid>
      <ArticleSubComponentButton
        handleAdd={handleAdd}
        setShowExistingArticle={setShowExistingArticle}
        disableMaterialButton={disableMaterialButton}
        setAddMaterial={setAddMaterial}
        disableSubstanceButton={disableSubstanceButton}
        setAddSubstance={setAddSubstance}
        handleEdit={handleEdit}
        disableEditButton={disableEditButton}
        deleteItem={deleteItem}
        disableDeleteButton={disableDeleteButton}
        DocumentIsReadOnly={DocumentIsReadOnly}
        setHideTable={setHideTable}
        hideTable={hideTable}
      />

      <Grid container>
        <Grid item md={12} sx={{ ml: 3 }}>
          <Paper
            sx={{
              overflow: "hidden",
              border: errors?.ProductContentComponents?.message ? "1px solid red!important" : "",
            }}
          >
            {/* <TableContainer component={Paper} sx={{ width: chemicalContentTableCssProps }}> */}
            <TableContainer component={Paper}>
              <Table aria-label="table">
                <ArticleSubComponentTableHeader hideTable={hideTable} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
                <TableBody>
                  <ArticleSubComponentTableBody
                    productContentComponents={ProductContentComponentsResultData?.ProductContentComponents}
                    productContentComponentTree={ProductContentComponentsResultData?.ProductContentComponentTree}
                    renameContentComponents={renameContentComponents}
                    hideTable={hideTable}
                    productContentType={productContentType}
                    hPhrasesData={hPhrasesData}
                    selected={selected}
                    setSelected={setSelected}
                    handleItemClick={handleItemClick}
                    handleEdit={handleEdit}
                    disableEditButton={disableEditButton}
                    DocumentIsReadOnly={DocumentIsReadOnly}
                    isCollapsed={isCollapsed}
                  />
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid item md={0} sx={{ mt: 3 }}>
          {errors?.ProductContentComponents && (
            <ErrorIcon
              color="error"
              style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "center" }}
            />
          )}
        </Grid>
        <Grid item md={11} sx={{ mt: 3 }} style={{ padding: "0" }}>
          {errors?.ProductContentComponents && (
            <Typography variant="subtitle1" sx={{ color: "#E95450", margin: "3px 12px 0px 12px" }}>
              {errors.ProductContentComponents?.message}
            </Typography>
          )}
        </Grid>
      </Grid>
      <FormProvider {...existingArticleMethods}>
        <ConfirmationModal
          show={showExistingArticle}
          handleClose={() => handleModalClose()}
          handleConfirmation={() => existingArticleMethods.handleSubmit(handleConfirmation)()}
          title={get("chemicalExistingDialogTitle")}
          saveButtonName={get("chemicalExistingButtonName")}
          cancelButtonName={get("cancelButtonText")}
        >
          <ExistingArticleForm
            data={existingArticleData}
            existingArticleIdx={existingArticleIdx}
            setExistingArticleIdx={setExistingArticleIdx}
            setComponentFilterItems={setComponentFilterItems}
            componentFilterItems={componentFilterItems}
          />
        </ConfirmationModal>
      </FormProvider>
      {/* lägg till */}
      <FormProvider {...articleMethods}>
        <ConfirmationModal
          show={showAdd}
          handleClose={() => handleAddModalClose()}
          handleConfirmation={() => articleMethods.handleSubmit(handleAddConfirmation)()}
          title={get("articleAddSubComponentTitle")}
          saveButtonName={get("saveButtonText")}
          cancelButtonName={get("cancelButtonText")}
          errorMessage={showErrorMessage ? get("articleAddSubComponentError") : ""}
        >
          <AddArticleSubComponentForm
            eCAndCASdata={existingArticleData}
            addMaterial={addMaterial}
            addSubstance={addSubstance}
            editComponent={editComponent}
            editMaterial={editMaterial}
            editSubstance={editSubstance}
            hPhrasesData={hPhrasesData}
            setVerifyECValue={setVerifyECValue}
            setVerifyCASValue={setVerifyCASValue}
            verifyECValue={verifyECValue}
            verifyCASValue={verifyCASValue}
            setHazardPhraseIds={setHazardPhraseIds}
            hazardPhraseIds={hazardPhraseIds}
            productContentType={productContentType}
            setShowErrorMessage={setShowErrorMessage}
            showSubstanceSpecific={showSubstanceSpecific}
            setShowSubstanceSpecific={setShowSubstanceSpecific}
          />
        </ConfirmationModal>
        <ConfirmationModal
          show={showEdit}
          handleClose={() => handleEditModalClose()}
          handleConfirmation={() => articleMethods.handleSubmit(handleUpdateConfirmation)()}
          title={get("articleEditSubComponentTitle")}
          saveButtonName={get("saveButtonText")}
          cancelButtonName={get("cancelButtonText")}
          errorMessage={showErrorMessage ? get("articleAddSubComponentError") : ""}
        >
          <AddArticleSubComponentForm
            eCAndCASdata={existingArticleData}
            data={editcomponentData}
            addMaterial={false}
            addSubstance={false}
            editComponent={editComponent}
            editMaterial={editMaterial}
            editSubstance={editSubstance}
            hPhrasesData={hPhrasesData}
            setVerifyECValue={setVerifyECValue}
            setVerifyCASValue={setVerifyCASValue}
            verifyECValue={verifyECValue}
            verifyCASValue={verifyCASValue}
            setHazardPhraseIds={setHazardPhraseIds}
            hazardPhraseIds={hazardPhraseIds}
            productContentType={productContentType}
            setShowErrorMessage={setShowErrorMessage}
            showSubstanceSpecific={showSubstanceSpecific}
            setShowSubstanceSpecific={setShowSubstanceSpecific}
          />
        </ConfirmationModal>
        <ConfirmationModal
          show={addMaterial}
          handleClose={() => handleAddModalClose()}
          handleConfirmation={() => articleMethods.handleSubmit(handleAddConfirmation)()}
          title={get("articleAddSubComponentTitle")}
          saveButtonName={get("saveButtonText")}
          cancelButtonName={get("cancelButtonText")}
          errorMessage={showErrorMessage ? get("articleAddSubComponentError") : ""}
        >
          <AddArticleSubComponentForm
            eCAndCASdata={existingArticleData}
            data={editcomponentData}
            addMaterial={addMaterial}
            addSubstance={false}
            editComponent={editComponent}
            editMaterial={editMaterial}
            editSubstance={editSubstance}
            hPhrasesData={hPhrasesData}
            setVerifyECValue={setVerifyECValue}
            setVerifyCASValue={setVerifyCASValue}
            verifyECValue={verifyECValue}
            verifyCASValue={verifyCASValue}
            setHazardPhraseIds={setHazardPhraseIds}
            hazardPhraseIds={hazardPhraseIds}
            productContentType={productContentType}
            setShowErrorMessage={setShowErrorMessage}
            showSubstanceSpecific={showSubstanceSpecific}
            setShowSubstanceSpecific={setShowSubstanceSpecific}
          />
        </ConfirmationModal>
        <ConfirmationModal
          show={addSubstance}
          handleClose={() => handleAddModalClose()}
          handleConfirmation={() => articleMethods.handleSubmit(handleAddConfirmation)()}
          title={get("articleAddSubComponentTitle")}
          saveButtonName={get("saveButtonText")}
          cancelButtonName={get("cancelButtonText")}
          errorMessage={showErrorMessage ? get("articleAddSubComponentError") : ""}
        >
          <AddArticleSubComponentForm
            eCAndCASdata={existingArticleData}
            data={editcomponentData}
            addMaterial={false}
            addSubstance={addSubstance}
            editComponent={editComponent}
            editMaterial={editMaterial}
            editSubstance={editSubstance}
            hPhrasesData={hPhrasesData}
            setVerifyECValue={setVerifyECValue}
            setVerifyCASValue={setVerifyCASValue}
            verifyECValue={verifyECValue}
            verifyCASValue={verifyCASValue}
            setHazardPhraseIds={setHazardPhraseIds}
            hazardPhraseIds={hazardPhraseIds}
            productContentType={productContentType}
            setShowErrorMessage={setShowErrorMessage}
            showSubstanceSpecific={showSubstanceSpecific}
            setShowSubstanceSpecific={setShowSubstanceSpecific}
          />
        </ConfirmationModal>
      </FormProvider>
    </>
  );
};
