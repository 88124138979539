import { useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { Grid, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { CustomRadioButton } from "../../../../../../components/CustomRadioButton/CustomRadioButton";
import { CustomTextField } from "../../../../../../components/CustomTextField/CustomTextField";
import { useTranslation } from "../../../../../../helpers/useTranslation";
import { useGetDateFnsLocale } from "../../../../../../helpers/useGetDateFnsLocale";
import ErrorIcon from "@mui/icons-material/Error";
import { ErrorMessage } from "@hookform/error-message";

type Props = {
  data?: ProductContent;
  DocumentIsReadOnly: boolean;
};
export const ChemicalContentInformation = ({ data, DocumentIsReadOnly }: Props) => {
  const { get } = useTranslation();
  const [candidateListEdition, setCandidateListEdition] = useState(data?.CandidateListEdition);

  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext<EBVDDocument>();

  const productContentType = useWatch({
    control,
    name: "ProductContent.ProductContentTypeId",
  });

  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ mb: 2 }}>
      <Grid item xs={12}>
        <Typography>{get("chemicalContentProductContentTypeId") + " *"}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Controller
          render={({ field: { onChange, value } }) => (
            <RadioGroup
              aria-labelledby="ProductContentType"
              value={value}
              onChange={(e) => {
                onChange(parseInt(e.currentTarget.value, 10));
              }}
              name="ProductContent.ProductContentType"
              row={true}
            >
              <FormControlLabel
                value={0}
                control={<Radio />}
                label={get("radioButtonChemicalProduct")}
                disabled={DocumentIsReadOnly}
              />
              <FormControlLabel
                value={1}
                control={<Radio />}
                label={get("radioButtonProduct")}
                disabled={DocumentIsReadOnly}
              />
            </RadioGroup>
          )}
          name="ProductContent.ProductContentTypeId"
          control={control}
          defaultValue={data?.ProductContentTypeId || undefined}
        />
      </Grid>
      <Grid item md={0} sx={{ mt: 3 }}>
        {errors?.ProductContent?.ProductContentTypeId && (
          <ErrorIcon
            color="error"
            style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "center" }}
          />
        )}
      </Grid>
      <Grid item md={11} sx={{ mt: 3 }} style={{ padding: "0" }}>
        {errors?.ProductContent?.ProductContentTypeId && (
          <Typography variant="subtitle1" sx={{ color: "#E95450", margin: "3px 12px 0px 12px" }}>
            {errors?.ProductContent?.ProductContentTypeId?.message}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography>{get("chemicalContentDescription1")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>{get("chemicalContentDescription2")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>{get("chemicalContentDescription3")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>{get("chemicalContentDescription4")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>{get("chemicalContentDescription5")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>{get("chemicalContentDescription6")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>{get("chemicalContentHasSafetyDatasheet")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <CustomRadioButton
          name={"ProductContent.HasSafetyDatasheet"}
          controlValue={control}
          dataValue={data?.HasSafetyDatasheet || "0"}
          isNA={true}
          yesValue={1}
          noValue={2}
          disabled={DocumentIsReadOnly}
          errorFieldMessage={errors?.ProductContent?.HasSafetyDatasheet?.message}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography>{get("chemicalContentHasProductClassification")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <CustomRadioButton
          name={"ProductContent.HasProductClassification"}
          controlValue={control}
          dataValue={data?.HasProductClassification || "0"}
          isNA={true}
          yesValue={1}
          noValue={2}
          disabled={DocumentIsReadOnly}
          errorFieldMessage={errors?.ProductContent?.HasProductClassification?.message}
        />
      </Grid>
      <Grid item xs={12} sx={{ mt: 1 }}>
        <CustomTextField
          isDisabled={DocumentIsReadOnly}
          name={"ProductContent.ProductClassification"}
          controlValue={control}
          defaultValue={data?.ProductClassification || ""}
          isMultiLine={true}
          rowSpan={3}
          label={get("chemicalContentProductClassification")}
          errors={errors}
        />
      </Grid>
      <Grid item md={0} sx={{ mt: 3 }}>
        <ErrorMessage
          message=""
          as={
            <ErrorIcon
              color="error"
              style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "center" }}
            />
          }
          errors={errors}
          name="ProductContent.ProductClassification"
        />
      </Grid>
      <Grid item md={11} sx={{ mt: 3 }} style={{ padding: "0" }}>
        <ErrorMessage
          as={<Typography variant="subtitle1" sx={{ color: "#E95450", margin: "3px 12px 0px 12px" }} />}
          errors={errors}
          name="ProductContent.ProductClassification"
        />
      </Grid>
      <Grid item xs={6} sx={{ mt: 3 }}>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={useGetDateFnsLocale()}>
          <DesktopDatePicker
            label={get("chemicalContentCandidateListEdition") + " *"}
            inputFormat="yyyy/MM/dd"
            value={candidateListEdition || null}
            disabled={DocumentIsReadOnly}
            onChange={(value) => {
              setCandidateListEdition(value || "");
              setValue("ProductContent.CandidateListEdition", value || "", { shouldDirty: true });
            }}
            renderInput={(params) => (
              <TextField {...params} sx={{ width: "85%" }} error={candidateListEdition ? false : true} />
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={6} sx={{ mt: 3 }}></Grid>
      <Grid item md={0} sx={{ mt: 3 }}>
        <ErrorMessage
          message=""
          as={
            <ErrorIcon
              color="error"
              style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "center" }}
            />
          }
          errors={errors}
          name="ProductContent.CandidateListEdition"
        />
      </Grid>
      <Grid item md={11} sx={{ mt: 3 }} style={{ padding: "0" }}>
        <ErrorMessage
          as={<Typography variant="subtitle1" sx={{ color: "#E95450", margin: "3px 12px 0px 12px" }} />}
          errors={errors}
          name="ProductContent.CandidateListEdition"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography>{get("chemicalContentRoHS")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <CustomRadioButton
          name={"ProductContent.RoHS"}
          controlValue={control}
          dataValue={data?.RoHS || false}
          isNA={false}
          yesValue={true}
          noValue={false}
          disabled={DocumentIsReadOnly}
          errorFieldMessage={errors?.ProductContent?.RoHS?.message}
        />
      </Grid>
      <Grid item xs={12} md={3} sx={{ mt: 1 }}>
        <CustomTextField
          isDisabled={DocumentIsReadOnly}
          name={"ProductContent.Weight"}
          controlValue={control}
          defaultValue={data?.Weight || ""}
          type="number"
          label={get("chemicalContentWeight")}
          errors={errors}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <Controller
          render={({ field: { onChange, value } }) => (
            <Select value={value} onChange={onChange} size="medium" sx={{ pl: 1, mt: 1 }} disabled={DocumentIsReadOnly}>
              <MenuItem value="kg">Kg</MenuItem>
              <MenuItem value="kg_m">Kg/m</MenuItem>
              <MenuItem value="kg_m2">Kg/m2</MenuItem>
              <MenuItem value="kg_m3">Kg/m3</MenuItem>
            </Select>
          )}
          name="ProductContent.WeightUnit"
          control={control}
          defaultValue={data?.WeightUnit || ""}
        />
      </Grid>
      <Grid item md={7} sx={{ mt: 3 }}>
        <CustomTextField
          isDisabled={DocumentIsReadOnly}
          name={"ProductContent.DeclaredWeightPercentage"}
          controlValue={control}
          defaultValue={data?.DeclaredWeightPercentage || null}
          type="number"
          label={get("chemicalContentDeclaredWeightPercentage") + " *"}
          errors={errors}
        />
      </Grid>
      <Grid item md={5} sx={{ mt: 3 }}></Grid>
      <Grid item md={0} sx={{ mt: 3 }}>
        <ErrorMessage
          message=""
          as={
            <ErrorIcon
              color="error"
              style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "center" }}
            />
          }
          errors={errors}
          name="ProductContent.DeclaredWeightPercentage"
        />
      </Grid>
      <Grid item md={11} sx={{ mt: 3 }} style={{ padding: "0" }}>
        <ErrorMessage
          as={<Typography variant="subtitle1" sx={{ color: "#E95450", margin: "3px 12px 0px 12px" }} />}
          errors={errors}
          name="ProductContent.DeclaredWeightPercentage"
        />
      </Grid>
      <Grid item xs={12} sx={{ mt: 3 }}>
        <CustomTextField
          isDisabled={DocumentIsReadOnly}
          name={"ProductContent.DeclaredWeightComment"}
          controlValue={control}
          defaultValue={data?.DeclaredWeightComment || ""}
          isMultiLine={true}
          rowSpan={2}
          label={get("chemicalContentDeclaredWeightComment") + " *"}
          errors={errors}
        />
      </Grid>
      <Grid item md={0} sx={{ mt: 3 }}>
        <ErrorMessage
          message=""
          as={
            <ErrorIcon
              color="error"
              style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "center" }}
            />
          }
          errors={errors}
          name="ProductContent.DeclaredWeightComment"
        />
      </Grid>
      <Grid item md={11} sx={{ mt: 3 }} style={{ padding: "0" }}>
        <ErrorMessage
          as={<Typography variant="subtitle1" sx={{ color: "#E95450", margin: "3px 12px 0px 12px" }} />}
          errors={errors}
          name="ProductContent.DeclaredWeightComment"
        />
      </Grid>
      <Grid item xs={12} sx={{ mt: 3 }}>
        <CustomTextField
          isDisabled={DocumentIsReadOnly || productContentType !== 1}
          name={"ProductContent.ProductNanoMaterial"}
          controlValue={control}
          defaultValue={data?.ProductNanoMaterial || ""}
          isMultiLine={true}
          rowSpan={4}
          label={get("chemicalContentProductNanoMaterial")}
          errors={errors}
        />
      </Grid>
      <Grid item xs={12} sx={{ mt: 1 }}>
        <Typography>{get("chemicalContentProductNanoMaterialIsRegistered")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <CustomRadioButton
          name={"ProductContent.ProductNanoMaterialIsRegistered"}
          controlValue={control}
          dataValue={data?.ProductNanoMaterialIsRegistered || null}
          isNA={false}
          yesValue={true}
          noValue={false}
          disabled={DocumentIsReadOnly}
          errorFieldMessage={errors?.ProductContent?.ProductNanoMaterialIsRegistered?.message}
        />
      </Grid>
      <Grid item md={7} sx={{ mt: 1 }}>
        <CustomTextField
          isDisabled={DocumentIsReadOnly}
          name={"ProductContent.ProductVolatileOrganicPart"}
          controlValue={control}
          defaultValue={data?.ProductVolatileOrganicPart || null}
          type="number"
          label={get("chemicalContentProductVolatileOrganicPart")}
          errors={errors}
        />
      </Grid>
    </Grid>
  );
};
