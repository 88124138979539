import { useContext, useEffect } from "react";
import { UNSAFE_NavigationContext } from "react-router";
import { History } from "history";

type ExtendNavigator = Navigator & Pick<History, "block">;

export const useBlocker = (blocker: any, when = true) => {
  const { navigator } = useContext(UNSAFE_NavigationContext);

  useEffect(() => {
    if (!when) return;

    const unblock = (navigator as any as ExtendNavigator).block((tx) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
};
