import { Grid } from "@mui/material";
import { useTranslation } from "../../../../helpers/useTranslation";
import { CustomTextField } from "../../../../components/CustomTextField/CustomTextField";
import { CustomCheckBox } from "../../../../components/CustomCheckBox/CustomCheckBox";
import { useFormContext } from "react-hook-form";

type Props = {
  data?: UserGroup;
};

export const CompanyCertification = ({ data }: Props) => {
  const { get } = useTranslation();
  const { control, handleSubmit } = useFormContext();
  return (
    <form onSubmit={handleSubmit as any}>
      <Grid container rowSpacing={1} px={1} columnSpacing={{ md: 3 }}>
        <Grid item md={3} sx={{ mt: 3 }}>
          <CustomCheckBox
            name="ISO9001"
            label="ISO 9001"
            dataValue={data?.ISO9001}
            controlValue={control}
            disable={false}
          />
        </Grid>
        <Grid item md={3} sx={{ mt: 3 }}>
          <CustomCheckBox
            name="ISO14001"
            label="ISO 14001"
            dataValue={data?.ISO14001}
            controlValue={control}
            disable={false}
          />
        </Grid>
        <Grid item md={12} sx={{ mt: 3, mb: 3 }}>
          <CustomTextField
            name={"OtherCertificates"}
            controlValue={control}
            defaultValue={data?.OtherCertificates}
            label={get("fieldOther")}
            isDisabled={false}
          />
        </Grid>
      </Grid>
    </form>
  );
};
