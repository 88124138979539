import { ReactNode } from "react";
import { useGetUser } from "../../services/userService";

type Props = {
  children?: ReactNode;
};

export const AdminAuthenticationTemplate = ({ children }: Props) => {
  const { data } = useGetUser();

  if (data && data?.UserGroups.filter((x) => x.CanEditUserGroup || x.IsUserGroupAdmin).length > 0) {
    return <>{children}</>;
  }
  return <></>;
};
