import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { Controller } from "react-hook-form";
import { Typography } from "@mui/material";
import { useGetTurnOvers } from "../../services/userGroupService";

type Props = {
  name: string;
  controlValue: any;
  dataValue: any;
  disabled?: boolean;
  errorFieldMessage?: any;
  
};

export const CustomAccountRadioButton = ({ name, controlValue, dataValue, errorFieldMessage, disabled }: Props) => {
  const { data: turnOverRadioValue } = useGetTurnOvers();
  return (
    <>
      <Controller 
        render={({ field: { onChange, value } }) => (
          <RadioGroup aria-labelledby="TurnOverGroup" name="TurnOverGroup" row={true} value={value} onChange={onChange}>
            {turnOverRadioValue?.map((item: TurnOverRadio) => (
              <FormControlLabel value={item.Id} control={<Radio />}  label={item?.Name} disabled={disabled} />
            ))}
          </RadioGroup>
        )}
        
        name={name}
        control={controlValue}
        defaultValue={dataValue}
      />
      {errorFieldMessage && (
        <Typography variant="subtitle1" sx={{ color: "#E95450", margin: "3px 12px 0px 12px" }}>
        {errorFieldMessage}
        </Typography>
      )}
    </>
  );
};
