import { useRequest } from "../helpers/useRequest";
import { api } from "../config/apiEndpoints";
import { queryEnums } from "../config/queryEnums";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useMessage } from "../helpers/useMessages";
import { useTranslation } from "../helpers/useTranslation";
import { fileToByteArray } from "../helpers/fileToByteArray";
import { useNavigate } from "react-router";

const usergroupServiceUrl = api.usergroup;

export const useGetUserGroup = (id: number | string | undefined) => {
  const { get } = useRequest();

  return useQuery<UserGroup>([queryEnums.userGroup, id], () => get(`${usergroupServiceUrl}/GetUserGroup/${id}`, null), {
    enabled: Boolean(id),
    suspense: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};

export const useInviteUser = () => {
  const { post } = useRequest();
  const qc = useQueryClient();
  const { send } = useMessage();
  const { get } = useTranslation();

  return useMutation((inviteUser: InviteUser) => post(`${usergroupServiceUrl}/InviteUserGroupUser`, inviteUser), {
    onSuccess: () => {
      qc.invalidateQueries([queryEnums.userGroup]);
      send(`${get("toastSaved")}.`);
    },
  });
};
export const useAddUser = () => {
  const { post } = useRequest();

  return useMutation((addUser: InviteUser) => post(`${usergroupServiceUrl}/AddUserGroupUserGroup`, addUser), {});
};

export const useUpdateDataItem = () => {
  const { post } = useRequest();
  const { send } = useMessage();
  const { get } = useTranslation();
  const qc = useQueryClient();

  return useMutation((userGroup: UserGroup) => post(`${usergroupServiceUrl}/UpdateDataItem`, userGroup), {
    onSuccess: (data: UserGroup) => {
      qc.invalidateQueries([queryEnums.userGroup, String(data.UserGroupId)]);
      send(`${get("toastSaved")}.`);
    },
  });
};
export const useCreateAccount = () => {
  const { post } = useRequest();
  const navigate = useNavigate();
  return useMutation((userGroup: CreateAccount) => post(`${usergroupServiceUrl}/CreateNewAccount`, userGroup), {
    onSuccess: () => {
      navigate("/registrationRequestSent");
    },
  });
};
export const useUpdateUserGroupUser = () => {
  const { post } = useRequest();
  const { send } = useMessage();
  const { get } = useTranslation();

  const qc = useQueryClient();

  return useMutation((user: UserGroupUser) => post(`${usergroupServiceUrl}/UpdateUserGroupUser`, user), {
    onSuccess: (data: UserGroupUser) => {
      qc.invalidateQueries([queryEnums.userGroup, String(data.UserGroupId)]);
      qc.invalidateQueries([queryEnums.userGroupUser, String(data.UserGroupId), data.UserId]);
      send(`${get("toastSaved")}.`);
    },
  });
};

export const useDeleteUserGroupUser = () => {
  const { post } = useRequest();
  const qc = useQueryClient();
  const { send } = useMessage();
  const { get } = useTranslation();
  return useMutation(
    (user: DeleteUser) =>
      post(`${usergroupServiceUrl}/DeleteUserGroupUser`, {
        UserGroupId: user.UserGroupId,
        UserId: user.UserId,
      }),
    {
      onSuccess: (_data, variables) => {
        qc.invalidateQueries([queryEnums.userGroup, String(variables.UserGroupId)]);
        send(`${get("toastDeleted")}.`);
      },
    },
  );
};

export const useUploadUserGroupLogo = () => {
  const { post } = useRequest();
  const { get } = useTranslation();
  const { send } = useMessage();
  const qc = useQueryClient();

  return useMutation(
    async (logo: UploadFile) => {
      const bytebuffer = await fileToByteArray(logo.Files);
      post(`${usergroupServiceUrl}/UploadUserGroupLogo`, {
        UserGroupId: logo.UserGroupId,
        Position: 0,
        Buffer: bytebuffer,
        LastBuffer: true,
        Extension: logo.Extension,
      });
    },

    {
      onSuccess: (_data, variables) => {
        qc.invalidateQueries([queryEnums.userGroup, String(variables.UserGroupId)]);
        send(`${get("toastSaved")}.`);
      },
    },
  );
};

export const useDeleteUserGroupLogo = () => {
  const { get } = useRequest();
  const qc = useQueryClient();
  const { get: getTranslation } = useTranslation();
  const { send } = useMessage();

  return useMutation(
    async (logo: DeleteFile) => {
      get(`${usergroupServiceUrl}/DeleteUserGroupLogo/` + logo.UserGroupId, null);
    },
    {
      onSuccess: (_data, variables) => {
        qc.invalidateQueries([queryEnums.userGroup, String(variables.UserGroupId)]);
        send(`${getTranslation("toastDeleted")}.`);
      },
    },
  );
};

export const useGetUserGroupUser = (userGroupId: string | undefined, userId: number | undefined) => {
  const { post } = useRequest();

  return useQuery<UserGroupUser>(
    [queryEnums.userGroupUser, userGroupId, userId],
    () =>
      post(`${usergroupServiceUrl}/GetUserGroupUser`, {
        UserGroupId: userGroupId,
        UserId: userId,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: userId !== 0,
      suspense: false,
    },
  );
};
export const useGetDigitalTransfer = (
  userGroupId: string | undefined,
  webApiConsumerUserGroupId: number | undefined,
) => {
  const { post } = useRequest();

  return useQuery<UserGroupDigital>(
    [queryEnums.digitalTransfer, userGroupId, webApiConsumerUserGroupId],
    () =>
      post(`${usergroupServiceUrl}/GetUserGroupUserGroup`, {
        UserGroupId: userGroupId,
        WebApiConsumerUserGroupId: webApiConsumerUserGroupId,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: webApiConsumerUserGroupId !== 0,
      suspense: false,
    },
  );
};
export const useUpdateUserGroups = () => {
  const { post } = useRequest();
  const { send } = useMessage();
  const { get } = useTranslation();
  const qc = useQueryClient();

  return useMutation(
    (UserGroups: UserGroupDigital) => post(`${usergroupServiceUrl}/UpdateUserGroupUserGroup`, UserGroups),
    {
      onSuccess: (data: UserGroupDigital) => {
        qc.invalidateQueries([queryEnums.userGroup, String(data.UserGroupId)]);
        qc.invalidateQueries([queryEnums.digitalTransfer, String(data.UserGroupId), data.WebApiConsumerUserGroupId]);
        send(`${get("toastDTSaved")}.`);
      },
    },
  );
};
export const useUsergroupGetDataItems = () => {
  const { post } = useRequest();

  return useQuery<DataItemUserGroups>(
    [queryEnums.userGroupData],
    () =>
      post(`${usergroupServiceUrl}/GetDataItems`, {
        IsWebApiConsumer: true,
      }),
    {},
  );
};
export const useAddUserGroup = () => {
  const { post } = useRequest();
  const qc = useQueryClient();
  const { send } = useMessage();
  const { get } = useTranslation();

  return useMutation(
    (userGroupDigital: UserGroupDigital) =>
      post(`${usergroupServiceUrl}/AddUserGroupUserGroupDefault`, userGroupDigital),
    {
      onSuccess: () => {
        qc.invalidateQueries([queryEnums.userGroup]);
        send(`${get("toastDTSaved")}.`);
      },
    },
  );
};

export const useDeleteUserGroupUserGroup = () => {
  const { post } = useRequest();
  const qc = useQueryClient();
  const { send } = useMessage();
  const { get } = useTranslation();
  return useMutation(
    (user: DeleteUser) =>
      post(`${usergroupServiceUrl}/DeleteUserGroupUserGroup`, {
        UserGroupId: user.UserGroupId,
        WebApiConsumerUserGroupId: user.WebApiConsumerUserGroupId,
      }),
    {
      onSuccess: (_data, variables) => {
        qc.invalidateQueries([queryEnums.userGroup, String(variables.UserGroupId)]);
        send(`${get("toastDTDeleted")}.`);
      },
    },
  );
};

export const useGetTurnOvers = () => {
  const { get } = useRequest();

  return useQuery<TurnOverRadio[]>([queryEnums.turnovers], () => get(`${usergroupServiceUrl}/GetTurnOvers`, null));
};
export const useGetEvbdCount = () => {
  const { get } = useRequest();
  return useQuery<TurnOverRadio[]>([queryEnums.evbdsCount], () =>
    get(`${usergroupServiceUrl}/GetEstimatedDocumentCounts`, null),
  );
};
