import { Box, Divider, Typography } from "@mui/material";
import { useRecentlyViewed } from "../../../helpers/useRecentlyViewed";
import { useTranslation } from "../../../helpers/useTranslation";
import { RecentItem } from "./RecentItem";

export const RecentlyViewed = () => {
  const { recentlyViewed } = useRecentlyViewed();
  const { get } = useTranslation();

  return (
    <Box
      sx={{
        mt: 4,
        flexBasis: "30%",
        pl: 2,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          mr: 4,
          mb: 3,
        }}
      >
        <Typography variant="overline">{get("recentlyViewed")}</Typography>
        <Divider color="#44a4b7" />
      </Box>

      {recentlyViewed.map((doc) => (
        <Box
          key={doc.id}
          sx={{
            py: 1,
          }}
        >
          <RecentItem name={doc.name} id={doc.id} />
        </Box>
      ))}
    </Box>
  );
};
