import { Grid } from "@mui/material";
import { useTranslation } from "../../../../../../helpers/useTranslation";
import { CustomTypography } from "../../../../../../components/CustomTypography/CustomTypography";

type Props = {
  data?: EBVDDocument;
};

export const SystemInformation = ({ data }: Props) => {
  const { get } = useTranslation();
  const ID = data?.DocumentSystemId + "-" + data?.DocumentSystemCode + "-" + data?.SerialNumber;
  return (
    <Grid container rowSpacing={5} columnSpacing={{ md: 4 }}>
      <Grid item xs={12} md={3} sx={{ mt: 2 }}>
        <CustomTypography fieldName={get("fieldSysInfoDocumentBvdId")} fieldValue={ID} />
      </Grid>
      <Grid item xs={12} md={3} sx={{ mt: 2 }}>
        <CustomTypography fieldName={get("fieldSysInfoDocumentId")} fieldValue={data?.DocumentId} />
      </Grid>
      <Grid item xs={12} md={6} sx={{ mt: 2 }}>
        <CustomTypography fieldName={get("fieldSysInfoName")} fieldValue={data?.UserGroup?.Name} />
      </Grid>
      <Grid item xs={12} sx={{ mt: 3, mb: 2 }}>
        <CustomTypography fieldName={get("fieldSysInfoLatestPdfFileAddress")} fieldValue={data?.LatestPdfFileAddress} />
      </Grid>
    </Grid>
  );
};
