export const fileToByteArray = (file: Blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      const array = new Uint8Array(reader.result as ArrayBuffer);
      const fileByteArray = [];
      for (let i = 0; i < array.length; i += 1) {
        fileByteArray.push(array[i]);
      }
      return resolve(fileByteArray);
    };
    reader.onerror = (error) => reject(error);
  });
