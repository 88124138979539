import { IconButton } from "@mui/material";
import AboutIcon from "@mui/icons-material/Info";
import { useNavigate } from "react-router";

export const AboutLink = () => {
  const navigate = useNavigate();
  return (
    <>
      <div>
        <IconButton sx={{ p: 1 }} onClick={() => navigate(`/about`)}>
          <AboutIcon />
        </IconButton>
      </div>
    </>
  );
};
