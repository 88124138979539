import { Grid } from "@mui/material";
import { useTranslation } from "../../../../../../helpers/useTranslation";
import { CustomTextField } from "../../../../../../components/CustomTextField/CustomTextField";
import { ArticleSubComponentTable } from "../ArticleSubComponentTable/ArticleSubComponentTable";
import { useFormContext } from "react-hook-form";

type Props = {
  data?: EBVDDocument;
  DocumentIsReadOnly: boolean;
  hPhrasesData?: HPhrases;
  existingArticleData?: ExistingProductContentComponent;
};
export const ArticleSubComponents = ({ data, DocumentIsReadOnly, hPhrasesData, existingArticleData }: Props) => {
  const { get } = useTranslation();
  const { control } = useFormContext();

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <ArticleSubComponentTable
          data={data}
          hPhrasesData={hPhrasesData}
          existingArticleData={existingArticleData}
          DocumentIsReadOnly={DocumentIsReadOnly}
        />
        <Grid item xs={12} sx={{ mt: 2 }}>
          <CustomTextField
            isDisabled={DocumentIsReadOnly}
            name={"ProductContent.Information"}
            controlValue={control}
            defaultValue={data?.ProductContent.Information || ""}
            isMultiLine={true}
            rowSpan={4}
            label={get("chemicalContentInformation")}
          />
        </Grid>
      </Grid>
    </>
  );
};
