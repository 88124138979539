import { useState } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { PageLayout } from "../../components/PageLayout/PageLayout";
import { useTranslation } from "../../helpers/useTranslation";
import { InternalSearchPage } from "./InternalSearch/InternalSearchPage";
import { CreateDocumentModal } from "./CreateDocumentModal/CreateDocumentModal";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

export const DocumentsPage = () => {
  const { get } = useTranslation();
  const appInsights = useAppInsightsContext();
  const pageView = {
    name: "DocumentsPage",
  };
  appInsights.trackPageView(pageView);

  const [showAdd, setShowAdd] = useState(false);

  return (
    <>
      <AuthenticatedTemplate>
        <PageLayout title={get("searcheBVDPageTitle")}>
          <InternalSearchPage handleCreateEBVDForm={() => setShowAdd(true)} />
          <CreateDocumentModal open={showAdd} setOpen={setShowAdd} />
        </PageLayout>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <PageLayout title={get("searcheBVDTitle")}>
          <InternalSearchPage handleCreateEBVDForm={() => setShowAdd(true)} />
          <CreateDocumentModal open={showAdd} setOpen={setShowAdd} />
        </PageLayout>
      </UnauthenticatedTemplate>
    </>
  );
};
