import { Grid } from "@mui/material";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { Controller } from "react-hook-form";
import { useTranslation } from "../../../../../../helpers/useTranslation";
import { CustomTextField } from "../../../../../../components/CustomTextField/CustomTextField";
import { CustomErrorTypography } from "../../../../../../components/CustomTypography/CustomErrorTypography";
import { useEffect } from "react";

type Props = {
  handleSubmit: any;
  control: any;
  data?: any;
  errors: any;
  reset: any;
};

export const TotalRecycledMaterialForm = ({ handleSubmit, control, data, errors, reset }: Props) => {
  const { get } = useTranslation();

  useEffect(() => {
    if (data !== undefined) reset(data);
    else
      reset({
        Material: "",
        WeightOrWeightPercentage: "",
        WeightIsPercentage: false,
        PreconsumerPart: "",
        PostconsumerPart: "",
        ExternalProductionWastePart: "",
        ReusedMaterialPart: "",
        Comment: "",
        ProductRecycledMaterialId: -1,
      });
  }, [data, reset]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container rowSpacing={3} columnSpacing={{ md: 2.3 }}>
          <Grid item xs={12}>
            <CustomTextField
              name="Material"
              isDisabled={false}
              controlValue={control}
              defaultValue={data?.Material || ""}
              label={get("totalRecycledMaterialModalMaterialTitle") + " *"}
              errors={errors}
            />
            <CustomErrorTypography errorMessage={errors.Material && errors.Material.message} />
          </Grid>
          <Grid item xs={8}>
            <CustomTextField
              name="WeightOrWeightPercentage"
              isDisabled={false}
              controlValue={control}
              defaultValue={data?.WeightOrWeightPercentage || ""}
              type="number"
              label={get("totalRecycleMaterialModalProportionWeight") + " *"}
              errors={errors}
            />
            <CustomErrorTypography
              errorMessage={errors.WeightOrWeightPercentage && errors.WeightOrWeightPercentage.message}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              render={({ field: { onChange, value } }) => (
                <RadioGroup name="Percentage" row={true} value={value} onChange={onChange}>
                  <FormControlLabel value={true} control={<Radio />} label="%" />
                  <FormControlLabel value={false} control={<Radio />} label="g" />
                </RadioGroup>
              )}
              name="WeightIsPercentage"
              control={control}
              defaultValue={data?.WeightIsPercentage || false}
            />
          </Grid>
          <Grid item md={6}>
            <CustomTextField
              name="PreconsumerPart"
              isDisabled={false}
              controlValue={control}
              defaultValue={data?.PreconsumerPart || ""}
              type="number"
              label={get("totalRecycleMaterialNotPassedConsumer") + " *"}
              errors={errors}
            />
            <CustomErrorTypography errorMessage={errors.PreconsumerPart && errors.PreconsumerPart.message} />
          </Grid>
          <Grid item md={6}>
            <CustomTextField
              name="PostconsumerPart"
              isDisabled={false}
              controlValue={control}
              defaultValue={data?.PostconsumerPart || ""}
              type="number"
              label={get("totalRecycleMaterialPassedConsumer") + " *"}
              errors={errors}
            />
            <CustomErrorTypography errorMessage={errors.PostconsumerPart && errors.PostconsumerPart.message} />
          </Grid>
          <Grid item md={6}>
            <CustomTextField
              name="ExternalProductionWastePart"
              isDisabled={false}
              controlValue={control}
              defaultValue={data?.ExternalProductionWastePart || ""}
              type="number"
              label={get("totalRecycleMaterialModalSharePercentage") + " *"}
              errors={errors}
            />
            <CustomErrorTypography
              errorMessage={errors.ExternalProductionWastePart && errors.ExternalProductionWastePart.message}
            />
          </Grid>
          <Grid item md={6}>
            <CustomTextField
              name="ReusedMaterialPart"
              isDisabled={false}
              controlValue={control}
              defaultValue={data?.ReusedMaterialPart || ""}
              type="number"
              label={get("totalRecycleMaterialModalRecycled") + " *"}
              errors={errors}
            />
            <CustomErrorTypography errorMessage={errors.ReusedMaterialPart && errors.ReusedMaterialPart.message} />
          </Grid>
          <Grid item md={12}>
            <CustomTextField
              name="Comment"
              isDisabled={false}
              controlValue={control}
              defaultValue={data?.Comment || ""}
              isMultiLine={true}
              rowSpan={4}
              label={get("totalRecycleMaterialModalComment")}
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
};
