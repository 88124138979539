import { useRequest } from "../helpers/useRequest";
import { api } from "../config/apiEndpoints";
import { queryEnums } from "../config/queryEnums";
import { useQuery } from "react-query";

const productGroupUrl = api.productgroup;

export const useGetProductGroups = (filter: any) => {
  const { post } = useRequest();

  return useQuery<ProductGroups>(
    [queryEnums.productGroups, filter.TextFilter, filter.ProductGroupSystemId],
    () =>
      post(`${productGroupUrl}/GetProductGroups`, {
        TextFilterIsExactMath: false,
        SortType: 0,
        SortAscending: true,
        ProductGroupSystemId: filter.ProductGroupSystemId,
        TextFilter: filter.TextFilter,
      }),
    {
      enabled: Boolean(filter.TextFilter),
      suspense: false,
    },
  );
};
