import { Container, Grid, Paper } from "@mui/material";
import { Box } from "@mui/system";
import { ReactNode } from "react";
import { MainBanner } from "../MainBanner/MainBanner";

type Props = {
  children?: ReactNode;
  title?: string;
};

export const PageLayout = ({ children, title }: Props) => {
  return (
    <Grid item container direction="column">
      <Container
        maxWidth={false}
        sx={{
          background: "linear-gradient(206.57deg, #58BAC1 0%, #008387 100%)",
        }}
      >
        <Container maxWidth="xl">
          <Paper
            sx={{
              boxShadow: 0,
              background: "transparent",
            }}
          >
            <Box>
              <MainBanner title={title} />
            </Box>
          </Paper>
        </Container>
      </Container>
      <Container maxWidth="xl">
      <Box sx={{ marginTop: "50px", marginRight: 2, marginLeft: 2 }}>{children}</Box>
      </Container>
    </Grid>
  );
};
