import React, { useState, useMemo } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useTranslation } from "../../helpers/useTranslation";
import {
  AccessType,
  IPublicAccessPreset,
  PublicAccessAllInformation,
  PublicAccessMinimumInformation,
  PublicAccessNoConfidentialInformation,
  PublicAccessSelectedInformation,
} from "../../helpers/usePublicAccessPresets";
import { translationRows } from "../../constants/translationRows";

type Props = {
  data?: InformationPermission;
  setValue: any;
};

interface Preset {
  name: string;
  value: IPublicAccessPreset;
  key: string;
  propertySelection: number;
}

export const InformationAccess = ({ data, setValue }: Props) => {
  const { get } = useTranslation();

  const presetSelected = useMemo(() => PublicAccessSelectedInformation(data), [data]);

  const presets: Preset[] = useMemo(
    () => [
      { name: get("allInformation"), value: PublicAccessAllInformation, key: "allInformation", propertySelection: 0 },
      {
        name: get("noConfidentialInformation"),
        value: PublicAccessNoConfidentialInformation,
        key: "noConfidentialInformation",
        propertySelection: 1,
      },
      {
        name: get("minimumInformation"),
        value: PublicAccessMinimumInformation,
        key: "minimumInformation",
        propertySelection: 2,
      },
      {
        name: get("selectedInformation"),
        value: presetSelected,
        key: "selectedInformation",
        propertySelection: 3,
      },
    ],
    [get, presetSelected],
  );

  const [dataItems, setDataItems] = useState<DataItem[]>(data?.DataItems || []);

  const [openList, setOpenList] = useState<Array<number>>([1003, 1004]);

  const [preset, setActivePreset] = useState(
    presets.find((p) => p.propertySelection === data?.PropertySelection) || presets[0],
  );

  const openChange = (index: number) => {
    if (openList.includes(index)) {
      setOpenList((old) => old.filter((a) => a !== index));
    } else {
      setOpenList((old) => [...old, index]);
    }
  };

  const setDataItemsFromPreset = (preset: Preset) => {
    const items = dataItems.map((item) => {
      if (preset.value[item.BusinessObjectPropertyGroupId as keyof IPublicAccessPreset]) {
        const childItems = item.ChildItems?.map((childItem) => {
          if (preset.value[childItem.BusinessObjectPropertyGroupId as keyof IPublicAccessPreset]) {
            return {
              ...childItem,
              CanRead: preset.value[childItem.BusinessObjectPropertyGroupId as keyof IPublicAccessPreset].canRead,
              ConfidentialityType: preset.value[childItem.BusinessObjectPropertyGroupId as keyof IPublicAccessPreset]
                .canReadConfidential
                ? 1
                : 0,
            };
          }
          return childItem;
        });

        return {
          ...item,
          ChildItems: childItems,
          CanRead: preset.value[item.BusinessObjectPropertyGroupId as keyof IPublicAccessPreset].canRead,
        } as DataItem;
      }

      return item;
    });
    setDataItems(items);
    setActivePreset(preset);
    setValue("InformationPermissions.PropertySelection", preset.propertySelection, { shouldDirty: true });
    setValue("InformationPermissions.DataItems", items, { shouldDirty: true });
  };

  return (
    <>
      <RadioGroup
        sx={{
          mb: 3,
        }}
        row
        name="row-radio-buttons-group"
        value={preset.key}
        onChange={(_event, value) => setDataItemsFromPreset(presets.find((p) => p.key === value) || presets[0])}
      >
        {presets.map((preset, index) => (
          <FormControlLabel value={preset.key} control={<Radio />} label={preset.name} key={index} />
        ))}
      </RadioGroup>

      <Paper sx={{ overflow: "hidden" }}>
        <TableContainer sx={{ width: "100%" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "rgba(211,211,211,.2)" }}>
                <TableCell sx={{ width: "10%" }}>
                  <Typography variant="body1"></Typography>
                </TableCell>
                <TableCell sx={{ width: "30%" }}>
                  <Typography variant="body1">
                    <b>{get("userFieldGroup")}</b>
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: "10%" }}>
                  <Typography variant="body1">
                    <b>{get("userRoleRead")}</b>
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: "50%" }}>
                  <Typography variant="body1">
                    <b>{get("userConfidentiality")}</b>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataItems?.map((row, index) => (
                <React.Fragment key={row.BusinessObjectPropertyGroupId}>
                  <TableRow>
                    {row.ChildItems?.length ? (
                      <TableCell sx={{ width: "10%" }}>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => openChange(row.BusinessObjectPropertyGroupId)}
                        >
                          {openList.includes(row.BusinessObjectPropertyGroupId) ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </TableCell>
                    ) : (
                      <TableCell sx={{ width: "10%" }}></TableCell>
                    )}
                    <TableCell sx={{ width: "30%" }}>
                      {++index} {"."} {get(translationRows[row.BusinessObjectPropertyGroupId as AccessType])}
                    </TableCell>
                    <TableCell sx={{ width: "10%" }}>
                      <Checkbox
                        color="primary"
                        disabled={
                          preset.propertySelection !== 3 ||
                          row.BusinessObjectPropertyGroupId === 1001 ||
                          row.BusinessObjectPropertyGroupId === 1002
                        }
                        checked={row.CanRead}
                        onChange={(_event, value) => {
                          let dataItem = dataItems.map((item) => {
                            if (item.BusinessObjectPropertyGroupId === row.BusinessObjectPropertyGroupId) {
                              return {
                                ...item,
                                CanRead: value,
                              };
                            }
                            return item;
                          });
                          setDataItems(dataItem);
                          setValue("InformationPermissions.DataItems", dataItem, { shouldDirty: true });
                        }}
                      />
                    </TableCell>
                    <TableCell sx={{ width: "50%" }}></TableCell>
                  </TableRow>

                  {openList.includes(row.BusinessObjectPropertyGroupId) &&
                    row.ChildItems?.map((childRow) => (
                      <TableRow key={childRow.BusinessObjectPropertyGroupId}>
                        <TableCell></TableCell>
                        <TableCell
                          sx={{
                            paddingLeft: "2rem",
                          }}
                        >
                          {get(translationRows[childRow.BusinessObjectPropertyGroupId as AccessType])}
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            disabled={preset.propertySelection !== 3}
                            color="primary"
                            checked={childRow.CanRead}
                            onChange={(_event, value) => {
                              let dataItem = dataItems.map((item) => {
                                if (item.BusinessObjectPropertyGroupId === row.BusinessObjectPropertyGroupId) {
                                  if (!item.ChildItems) return item;
                                  return {
                                    ...item,
                                    ChildItems: item.ChildItems?.map((childItem) => {
                                      if (
                                        childItem.BusinessObjectPropertyGroupId ===
                                        childRow.BusinessObjectPropertyGroupId
                                      ) {
                                        return {
                                          ...childItem,
                                          CanRead: value,
                                        };
                                      } else {
                                        return childItem;
                                      }
                                    }),
                                  };
                                }
                                return item;
                              });
                              setDataItems(dataItem);
                              setValue("InformationPermissions.DataItems", dataItem, { shouldDirty: true });
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <FormControl>
                            <RadioGroup
                              row
                              name="row-radio-buttons-group"
                              onChange={(_event, value) => {
                                let dataItem = dataItems.map((item) => {
                                  if (item.BusinessObjectPropertyGroupId === row.BusinessObjectPropertyGroupId) {
                                    if (!item.ChildItems) return item;
                                    return {
                                      ...item,
                                      ChildItems: item.ChildItems?.map((childItem) => {
                                        if (
                                          childItem.BusinessObjectPropertyGroupId ===
                                          childRow.BusinessObjectPropertyGroupId
                                        ) {
                                          return {
                                            ...childItem,
                                            ConfidentialityType: Number(value),
                                          };
                                        } else {
                                          return childItem;
                                        }
                                      }),
                                    };
                                  }
                                  return item;
                                });
                                setDataItems(dataItem);
                                setValue("InformationPermissions.DataItems", dataItem, { shouldDirty: true });
                              }}
                            >
                              <FormControlLabel
                                value={0}
                                control={
                                  <Radio
                                    disabled={preset.propertySelection !== 3}
                                    checked={childRow.ConfidentialityType === 0}
                                  />
                                }
                                label={get("radioButtonCannotread")}
                              />
                              <FormControlLabel
                                value={1}
                                control={
                                  <Radio
                                    disabled={preset.propertySelection !== 3}
                                    checked={childRow.ConfidentialityType === 1}
                                  />
                                }
                                label={get("radioButtonCanread")}
                              />
                            </RadioGroup>
                          </FormControl>
                        </TableCell>
                      </TableRow>
                    ))}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};
