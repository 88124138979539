import { TranslatedKey } from "../translation/translatedKey";

interface Language {
  key: string;
  labelKey: TranslatedKey;
}

export const languages: Language[] = [
  {
    key: "sv",
    labelKey: "languageSV",
  },
  {
    key: "en",
    labelKey: "languageEN",
  },
];
