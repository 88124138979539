import { useRequest } from "../helpers/useRequest";
import { api } from "../config/apiEndpoints";
import { queryEnums } from "../config/queryEnums";
import { useQuery } from "react-query";
import { useIsAuthenticated } from "@azure/msal-react";

const userServiceUrl = api.user;

export const useGetUser = () => {
  const { post } = useRequest();
  const isAuthenticated = useIsAuthenticated();

  return useQuery<User>(
    [queryEnums.userService],
    () =>
      post(`${userServiceUrl}/GetMyself`, {
        LoadUserGroups: true,
        IgnoreLastLoginTime: true,
        LoadApplicationFeatureIds: true,
      }),
    {
      suspense: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: isAuthenticated,
    },
  );
};

export const useGetDataItems = (textFilter: string | undefined) => {
  const { post } = useRequest();

  return useQuery<UserList>(
    [queryEnums.userService, textFilter],
    () =>
      post(`${userServiceUrl}/GetDataItems`, {
        TextFilter: textFilter,
      }),
    {
      enabled: Boolean(textFilter),
    },
  );
};
