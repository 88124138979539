import Paper from "@mui/material/Paper";
import { useState } from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useForm, useFormContext } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "../../../../../../helpers/useTranslation";
import { useFormValidation } from "../../../../../../helpers/useFormValidation";
import { ConfirmationModal } from "../../../../../../components/ConfirmationModal/ConfirmationModal";
import { CustomRadioButton } from "../../../../../../components/CustomRadioButton/CustomRadioButton";
import { ArticleForm } from "./ArticleForm";
import { ArticleProductGroup } from "./ArticleProductGroup/ArticleProductGroup";
import { CustomTextField } from "../../../../../../components/CustomTextField/CustomTextField";
import ErrorIcon from "@mui/icons-material/Error";
import { ErrorMessage } from "@hookform/error-message";

type Props = {
  data?: EBVDDocument;
};

export const ArticleNameInformation = ({ data }: Props) => {
  const { get } = useTranslation();
  const {
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { articleNoTableValidationSchema } = useFormValidation();
  const [productSystem, setProductSystem] = useState<DocumentProductSystem[]>(
    getValues("DocumentProductSystems") || [],
  );

  const [articleItemID, setArticleItemId] = useState<string>("");

  const {
    control,
    handleSubmit,
    setValue: setAttachmentValue,
    register,
    formState: { errors: articleError },
    reset: resetAttachmentValue,
  } = useForm<ProductItemNo>({ resolver: yupResolver(articleNoTableValidationSchema) });
  const [showAdd, setShowAdd] = useState(false);
  const [indexes, setIndexes] = useState<number[]>([]);
  const [rowColor, setRowColorRequired] = useState(false);

  const { control: controlValue } = useFormContext();

  const handleConfirmation = (data: ProductItemNo) => {
    var productIdList = data.ProductId.split(/[,;]+/);
    var productArticles = productSystem;
    for (var productId in productIdList) {
      var article = productIdList[productId];
      if (data.ProductSystemId.includes("VAT")) {
        article = data.VATNumber + "-" + article;
      }
      var productArticle = {
        ProductSystemId: data.ProductSystemId,
        ProductId: article,
      };
      productArticles.push(productArticle);
    }
    setProductSystem(productArticles);
    setArticleItemId("");
    setShowAdd(false);

    setIndexes([]);
    setValue("DocumentProductSystems", productArticles, { shouldDirty: true });
    resetAttachmentValue({
      ProductSystemId: "",
      VATNumber: "",
      ProductId: "",
    });
  };

  const remove = () => {
    if (indexes.length > 0) {
      var sortedArray: number[] = indexes.sort((n1, n2) => n2 - n1);
      sortedArray.forEach((index) => {
        productSystem.splice(index, 1);
      });

      setProductSystem([...productSystem]);
      setValue("DocumentProductSystems", [...productSystem], { shouldDirty: true });
      setArticleItemId("");
      setIndexes([]);
    }
  };

  const handleRowClick = (idx: number) => {
    if (indexes.includes(idx)) {
      setIndexes(indexes.filter((val) => val !== idx));
    } else {
      setIndexes([...indexes, ...[idx]]);
    }

    setRowColorRequired(true);
    setArticleItemId("");
  };
  const handleModalClose = () => {
    setShowAdd(false);
    setIndexes([]);
    setArticleItemId("");
    resetAttachmentValue({
      ProductSystemId: "",
      VATNumber: "",
      ProductId: "",
    });
  };

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ mb: 2 }}>
        <Grid item md={12} sx={{ mt: 2 }}>
          <CustomTextField
            name={"Product.Name"}
            isDisabled={data?.DocumentIsReadOnly || false}
            controlValue={controlValue}
            defaultValue={data?.Product.Name}
            isMultiLine={false}
            rowSpan={0}
            label={get("articleName") + " *"}
            errors={errors}
          />
        </Grid>
        <Grid item md={12} sx={{ mt: 2 }}>
          <Typography color="text.secondary" gutterBottom>
            {get("articleConceptTitle") + " *"}
          </Typography>
        </Grid>
        <Grid item md={11}>
          <Paper
            sx={{
              overflow: "hidden",
              border: errors?.DocumentProductSystems?.message ? "1px solid red!important" : "",
            }}
          >
            <TableContainer sx={{ height: 300, width: "100%" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="body1">
                        <b>{get("articleIdentitiesTableTitle")}</b>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        <b>{get("articleIDTableTitle")}</b>
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {productSystem.map((item, idx) => (
                    <TableRow
                      key={idx}
                      onClick={() => handleRowClick(idx)}
                      sx={{
                        backgroundColor: rowColor && indexes.includes(idx) ? "#b1b8b8" : "",
                        cursor: "pointer",
                      }}
                    >
                      <TableCell>{item.ProductSystemId}</TableCell>
                      <TableCell>{item.ProductId}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid item md={1}>
          <Stack direction="column" spacing={2} sx={{ float: "right" }}>
            <Button
              variant="contained"
              onClick={() => setShowAdd(true)}
              size="small"
              sx={{ width: "36px", minWidth: "0px" }}
              disabled={data?.DocumentIsReadOnly || false}
            >
              <AddIcon />
            </Button>
            <Button
              color="error"
              size="small"
              sx={{ m: 1, width: "36px", minWidth: "0px" }}
              disabled={indexes.length === 0 || data?.DocumentIsReadOnly}
              onClick={() => remove()}
            >
              <DeleteIcon />
            </Button>
          </Stack>
        </Grid>
        <Grid item md={0} sx={{ mt: 3 }}>
          {errors?.DocumentProductSystems && (
            <ErrorIcon
              color="error"
              style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "center" }}
            />
          )}
        </Grid>
        <Grid item md={11} sx={{ mt: 3 }} style={{ padding: "0" }}>
          {errors?.DocumentProductSystems && (
            <Typography variant="subtitle1" sx={{ color: "#E95450", margin: "3px 12px 0px 12px" }}>
              {errors?.DocumentProductSystems?.message}
            </Typography>
          )}
        </Grid>
        <Grid item md={12} sx={{ mt: 3 }}>
          <CustomTextField
            name={"NoGTINComment"}
            isDisabled={data?.DocumentIsReadOnly || false}
            controlValue={controlValue}
            defaultValue={data?.NoGTINComment}
            isMultiLine={true}
            rowSpan={4}
            label={get("articleGTINTitle") + " *"}
            errors={errors}
          />
        </Grid>
        <Grid item md={0} sx={{ mt: 3 }}>
          {errors?.NoGTINComment && (
            <ErrorIcon
              color="error"
              style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "center" }}
            />
          )}
        </Grid>
        <Grid item md={11} sx={{ mt: 3 }} style={{ padding: "0" }}>
          {errors?.NoGTINComment && (
            <Typography variant="subtitle1" sx={{ color: "#E95450", margin: "3px 12px 0px 12px" }}>
              {errors?.NoGTINComment.message}
            </Typography>
          )}
        </Grid>
        <Grid item md={12}>
          <ArticleProductGroup
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            DocumentIsReadOnly={data?.DocumentIsReadOnly || false}
          />
        </Grid>
        <Grid item md={12} sx={{ mt: 4 }}>
          <CustomTextField
            name={"Product.Description"}
            isDisabled={data?.DocumentIsReadOnly || false}
            controlValue={controlValue}
            defaultValue={data?.Product.Description}
            isMultiLine={true}
            rowSpan={4}
            label={get("articleDescription") + " *"}
            errors={errors}
          />
        </Grid>
        <Grid item md={0} sx={{ mt: 3 }}>
          <ErrorMessage
            message=""
            as={
              <ErrorIcon
                color="error"
                style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "center" }}
              />
            }
            errors={errors}
            name="Product.Description"
          />
        </Grid>
        <Grid item md={11} sx={{ mt: 3 }} style={{ padding: "0" }}>
          <ErrorMessage
            as={<Typography variant="subtitle1" sx={{ color: "#E95450", margin: "3px 12px 0px 12px" }} />}
            errors={errors}
            name="Product.Description"
          />
        </Grid>
        <Grid item md={12} sx={{ mt: 2 }}>
          <Typography color="text.secondary" gutterBottom>
            {get("articleDeclarationOfPerformanceNumberSubtitle")}
          </Typography>
        </Grid>
        <Grid item md={12}>
          <CustomRadioButton
            name={"Product.DeclarationOfPerformance"}
            controlValue={controlValue}
            dataValue={data?.Product.DeclarationOfPerformance}
            isNA={true}
            yesValue={1}
            noValue={2}
            disabled={data?.DocumentIsReadOnly || false}
          />
        </Grid>
        <Grid item md={4} sx={{ mt: 1 }}>
          <CustomTextField
            name={"Product.DeclarationOfPerformanceNumber"}
            isDisabled={data?.DocumentIsReadOnly || false}
            controlValue={controlValue}
            defaultValue={data?.Product.DeclarationOfPerformanceNumber}
            isMultiLine={false}
            rowSpan={0}
            label={get("articleDeclarationNumber")}
            errors={errors}
          />
        </Grid>
        <Grid item md={8} sx={{ mt: 3 }}></Grid>
        <Grid item md={0} sx={{ mt: 3 }}>
          <ErrorMessage
            message=""
            as={
              <ErrorIcon
                color="error"
                style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "center" }}
              />
            }
            errors={errors}
            name="Product.DeclarationOfPerformanceNumber"
          />
        </Grid>
        <Grid item md={11} sx={{ mt: 3 }} style={{ padding: "0" }}>
          <ErrorMessage
            as={<Typography variant="subtitle1" sx={{ color: "#E95450", margin: "3px 12px 0px 12px" }} />}
            errors={errors}
            name="Product.DeclarationOfPerformanceNumber"
          />
        </Grid>
        <Grid item md={12} sx={{ mt: 3 }}>
          <CustomTextField
            name={"Product.Information"}
            isDisabled={data?.DocumentIsReadOnly || false}
            controlValue={controlValue}
            defaultValue={data?.Product.Information}
            isMultiLine={false}
            rowSpan={0}
            label={get("articleOtherInformation")}
            errors={errors}
          />
        </Grid>
        <ConfirmationModal
          show={showAdd}
          handleClose={() => handleModalClose()}
          handleConfirmation={() => handleSubmit(handleConfirmation)()}
          title={get("articleAddItemNumber")}
          saveButtonName={get("saveButtonText")}
          cancelButtonName={get("cancelButtonText")}
        >
          <ArticleForm
            handleSubmit={handleSubmit}
            controlValue={control}
            setValue={setAttachmentValue}
            errors={articleError}
            articleItemID={articleItemID}
            setArticleItemId={setArticleItemId}
            userGroupId={data?.UserGroupId || 0}
            register={register}
          />
        </ConfirmationModal>
      </Grid>
    </>
  );
};
